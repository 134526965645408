import { requestGet, requestPost } from "./RequestService"

const prefix = '/system-settings'

const store = async (data) => {
    return await requestPost(prefix+'/store', data)
}
const get = async () => {
    return await requestGet(prefix+'/get')
}
const createWebhookEndpoint = async (data) => {
    return await requestPost(prefix+'/create-webhook', data)
}

export { store, get, createWebhookEndpoint }