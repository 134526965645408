import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import InputAdornment from '@mui/material/InputAdornment';
import { IconButton } from "@mui/material";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

const TextFieldComponent = (props) => {
    const [passwordShow, setPasswordShow] = useState(false);

    const handleClickShowPassword = () => {
        setPasswordShow(!passwordShow);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return <TextField type={props.type} id={props.id} label={props.label} variant="outlined" sx={{marginTop:props.marginTop === 0 ? 0 : 3}} size={props.size === undefined ? "normal" : props.size} fullWidth={true} autoFocus={props.autoFocus} error={props.error} onChange={props.onChange} value={props.value} step="0.01" helperText={props.error ? props.helperText : null}  />
}

export default TextFieldComponent;