import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addCardState:false,
    addBankState:false,
}

export const paymentSlice = createSlice({
    name: 'payments',
    initialState,
    reducers:{
        setAddCardState: (state, action) => {
            state.addCardState = action.payload
        },
        setAddBankState: (state, action) => {
            state.addBankState = action.payload
        }
    }
})

// export individual action creator functions
export const { setAddCardState, setAddBankState} = paymentSlice.actions;

// often the reducer is a default export, but that doesn't matter
export default paymentSlice.reducer;

