import React, { useEffect, useState } from "react";
import { CardContent, Divider, Grid, Typography } from "@mui/material";
import PageContainer from "../PageContainer/PageContainer";
import CardComponent from "../../common/CardComponent/CardComponent";
import { useDispatch, useSelector, connect } from "react-redux";
import { useSnackbar } from "notistack";
import { setActivityRead, setTopLoader } from "../../../features/mainSlice/mainSlice";
import { getAll, read } from "../../../services/ActivityService";
import EnhancedTable from "./EnhancedTable";
import DataDialog from "../../common/DataDialog/DataDialog";
import moment from "moment";
import TagIcon from "@mui/icons-material/Tag";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import DataObjectOutlinedIcon from "@mui/icons-material/DataObjectOutlined";
import JSONFormatter from "json-formatter-js";
import { Box } from "@mui/system";
import DOMPurify from "dompurify";
import { useTheme } from "@emotion/react";
import { snackBarPosition } from "../../../services/CommonService";
const ActivityLog = () => {
  // component states
  const [rows, setRows] = useState([]);
  const [isRowsSet, setIsRowsSet] = useState(false);
  const [activityDialogOpen, setActivityDialogOpen] = useState(false);
  const [activityDetails, setActivityDetails] = useState(null);
  const [jsonData, setJsonData] = useState({});
  // snackbar
  const { enqueueSnackbar } = useSnackbar();

  const sideBarRoutes = useSelector((state) => state.common.sideBarRoutes);
  const activityRead = useSelector((state) => state.main.activityRead);
  const dispatch = useDispatch();
  const theme = useTheme();
  // for
  useEffect(() => {
    getActivty();
  }, []);

  function createData(name, description, data) {
    return {
      name,
      description,
      data,
    };
  }
  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Name",
      align: "left",
    },
    {
      id: "description",
      numeric: true,
      disablePadding: false,
      label: "Description",
      align: "center",
    },
  ];

  const getActivty = () => {
    dispatch(setTopLoader(true));
    setRows([]);
    getAll()
      .then((response) => {
        let rows = [];
        rows = [...rows];
        response.data.data.map((item, index) => {
          rows.push(createData(item.name, item.description, item));
        });

        setRows(rows);
        setIsRowsSet(true);
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        console.log(error);
        showError(error.response.data.message);
        dispatch(setTopLoader(false));
      });
  };

  const handleRowClick = (event, data) => {
    var dta = JSON.parse(data.data.data);
    const formatter = new JSONFormatter(dta);
    formatter.openAtDepth('Infinity')
    setJsonData(formatter.render());
    setActivityDetails(data);
    setActivityDialogOpen(true);
    read({id:data.data.id})
      .then((response) => {
        dispatch(setActivityRead(!activityRead));
        getActivty()
      })
      .catch((error) => {
        console.log(error);
        showError(error.response.data.message);
      });
  };

  const showError = (message) => {
    enqueueSnackbar(message, { variant: "error", anchorOrigin: snackBarPosition });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, { variant: "success", anchorOrigin: snackBarPosition });
  };
  const hadndleActivityDialogClose = () => {
    setActivityDialogOpen(false);
    setActivityDetails(null);
  };
  return (
    <PageContainer>
      <Divider />
      <DataDialog onClickCancel={hadndleActivityDialogClose} open={activityDialogOpen} header="Activity Details" loading={false} maxWidth="md" fullWidth={true}>
        {activityDetails !== null && activityDetails.data.code && (
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item sm={4}>
              <Box sx={{ display: "flex", pl: 1.5, pb: 1.2 }}>
                <TagIcon fontSize="small" />
                <Typography sx={{ ml: 1 }} variant="body2">
                  Code
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={8}>
              <Typography variant="body2">{activityDetails.data.code}</Typography>
            </Grid>
          </Grid>
        )}
        {activityDetails !== null && activityDetails.data.name && (
          <React.Fragment>
            <Grid container spacing={2} sx={{ mb: 1 }}>
              <Grid item sm={4}>
                <Box sx={{ display: "flex", pl: 1.5, pb: 1.2 }}>
                  <FormatListBulletedIcon fontSize="small" />
                  <Typography sx={{ ml: 1 }} variant="body2">
                    Name
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={8}>
                <Typography variant="body2">{activityDetails.data.name}</Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        {activityDetails !== null && activityDetails.data.user_name && (
          <React.Fragment>
            <Grid container spacing={2} sx={{ mb: 1 }}>
              <Grid item sm={4}>
                <Box sx={{ display: "flex", pl: 1.5, pb: 1.2 }}>
                  <PersonOutlineOutlinedIcon fontSize="small" />
                  <Typography sx={{ ml: 1 }} variant="body2">
                    Activity done by
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={8}>
                <Typography variant="body2">{activityDetails.data.user_name}</Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        {activityDetails !== null && activityDetails.data.created_at && (
          <React.Fragment>
            <Grid container spacing={2} sx={{ mb: 1 }}>
              <Grid item sm={4}>
                <Box sx={{ display: "flex", pl: 1.5, pb: 1.2 }}>
                  <AccessTimeOutlinedIcon fontSize="small" />
                  <Typography sx={{ ml: 1 }} variant="body2">
                    Activity done at
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={8}>
                <Typography variant="body2">{moment(activityDetails.data.created_at).calendar()}</Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        {activityDetails !== null && activityDetails.data.description && (
          <React.Fragment>
            <Grid container spacing={2} sx={{ mb: 1 }}>
              <Grid item sm={4}>
                <Box sx={{ display: "flex", pl: 1.5, pb: 1.2 }}>
                  <InfoOutlinedIcon fontSize="small" />
                  <Typography sx={{ ml: 1 }} variant="body2">
                    Description
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={8}>
                <Typography variant="body2">{activityDetails.data.description}</Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        {activityDetails !== null && activityDetails.data.data && (
          <React.Fragment>
            <Grid container spacing={2} sx={{ mb: 1 }}>
              <Grid item sm={4}>
                <Box sx={{ display: "flex", pl: 1.5, pb: 1.2 }}>
                  <DataObjectOutlinedIcon fontSize="small" />
                  <Typography sx={{ ml: 1 }} variant="body2">
                    Data
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={8}>
                <Box sx={{ backgroundColor: theme.palette.mode === "dark" && "aliceblue" }}>
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(jsonData) }} />
                </Box>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </DataDialog>
      <Grid container spacing={4}>
        <Grid item sm={12}>
          <CardComponent>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item sm={12}>
                  {/* {isRowsSet && <DataTable rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection disableSelectionOnClick experimentalFeatures={{ newEditingApi: false }} />} */}
                  {isRowsSet && <EnhancedTable headCells={headCells} rows={rows} header="Activity Logs" handleRowClick={handleRowClick} />}
                </Grid>
              </Grid>
            </CardContent>
          </CardComponent>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  topLoader: state.topLoader,
  topLoader: state.activityRead
});

const mapDispatchToProps = { setTopLoader, setActivityRead};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLog);