import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { setTopBarTitle } from "../../features/dashboardSlice/dashboardSlice";
import { useDispatch } from "react-redux";
import { Divider } from "@mui/material";
import PageToolbar from "../../components/admin/PageToolbar/PageToolbar";
import ViewJob from "../../components/admin/ManageJob/ViewJob/ViewJob";
import { Container } from "@mui/system";
import { useSearchParams } from "react-router-dom";

const ViewJobPage = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const job_id = searchParams.get("job_id");
  // component states

  const title = "ImageMall | Job - #"+job_id;
  //Redux states
  const dispatch = useDispatch();

  // use effect
  useEffect(() => {
    dispatch(setTopBarTitle("Job"));
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageToolbar />
      <Divider />
      <Container sx={{mb:4}}>
        <ViewJob />
      </Container>
    </React.Fragment>
  );
};

export default ViewJobPage;
