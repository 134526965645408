import {Box, Button, Chip, CircularProgress, Divider, Grid, Paper, Skeleton, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import Search from "../../common/Search/Search";
import PageContainer from "../PageContainer/PageContainer";
import { get, searchVendor } from "../../../services/FindVendorService";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import VendorCard from "../../common/VendorCard/VendorCard";
import PortfolioView from "../Portfolio/PortfolioView/PortfolioView";
import DataDialog from "../../common/DataDialog/DataDialog";
import { snackBarPosition } from "../../../services/CommonService";

const VendorList = () => {
  const [search, setSearch] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageLoadingEnd, setPageLoadingEnd] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [portfolioView, setPortfolioView] = useState(false);
  const [vId, setVid] = useState(null);
  const enqueueSnackbar = useSnackbar();

  const vendorLoading = [];
  for (let index = 0; index < 16; index++) {
    vendorLoading.push({ test: index });
  }
  // scrolling
  const { ref, inView } = useInView({
    threshold: 0,
  });
  useEffect(() => {
    if (inView && !search) {
      setPageNumber((current) => current + 1);
    }
  }, [inView]);

  useEffect(() => {
    if (requestData.length > 0) {
      if (pageNumber <= requestData.last_page) {
        getVendors();
      } else {
        setPageLoadingEnd(true);
      }
    } else {
      getVendors();
    }
  }, [pageNumber]);
  const getVendors = () => {
    setLoading(true);
    get(pageNumber)
      .then((response) => {
        if (response.data.data.data.length === 0) setPageLoadingEnd(true);
        setRequestData(response.data.data);
        if (pageNumber === 1) {
          setVendors(response.data.data.data);
        } else {
          let all = new Set([...vendors, ...response.data.data.data]);
          setVendors([...all]);
        }

        setLoading(false);
      })
      .catch((error) => {
        showError(error.response.data.message);
      });
  };
  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const handleSearchClick = (keyword) => {
    if(keyword === '') return  
    setLoading(true);
    setSearch(true);
    setVendors([]);
    searchVendor({ keyword })
      .then((response) => {
        if (response.data.data.length === 0) setPageLoadingEnd(true);
        setRequestData(response.data.data);
        setVendors(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        showError(error.response.data.message);
      });
  };
  const handleCloseClick = () => {
    setRequestData([]);
    setVendors([]);
    setSearch(false);
    setPageLoadingEnd(false);
    if (pageNumber === 1) {
      getVendors();
    } else {
      setPageNumber(1);
    }
  };
  const handleVendorCardClick = (id) => {
    setVid(id)
    setPortfolioView(true)
  };
  const handlePortfolioViewClose = () => {
    setPortfolioView(false)
  }
  return (
    <PageContainer>
      <DataDialog onClickCancel={handlePortfolioViewClose} open={portfolioView} header="Portfolio" loading={loading} maxWidth="xl" fullWidth={true}>
      <PortfolioView vendor={false} vId={vId} />
      </DataDialog>
      <Grid container spacing={2}>
        <Grid item sm={3}></Grid>
        <Grid item sm={6}>
          <Search onClick={handleSearchClick} onClickClose={handleCloseClick} search={search} placeHolder="Search Vendor Name" />
        </Grid>
        <Grid item sm={3}>
          <Box sx={{ overflowY: "auto", whiteSpace: "nowrap" }} className="scroll-bar-none">
            <Button color="primary" size="small" variant="outlined" sx={{ borderRadius: 5, mt: "5px", mr: 1, borderLeft: 2 }}>
              Most Rated
            </Button>
            <Button color="primary" size="small" variant="outlined" sx={{ borderRadius: 5, mt: "5px", mr: 1, borderLeft: 2 }}>
              Most Worker
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {vendors?.map((item, index) => {
          return (
            <Grid item xs={3} sx={{ mt: 0 }} key={index} onClick={() => handleVendorCardClick(item.id)}>
              {/* onClick={() => handleVendorCardClick(item.job_id)} */}
              <VendorCard avatar={item.avatar} title={item.title} cover={item.samples} name={item.name} avgRate={item.avg_rate} reviewCount={item.total_reviews} jobsDone={item.jobs_done} services={item.services}/>
            </Grid>
          );
        })}
      </Grid>
      {!pageLoadingEnd && loading && (
        <Grid container spacing={2}>
          {vendorLoading?.map((item, index) => {
            return (
              <Grid item sm={3} key={index}>
                <Paper sx={{ my: 1 }} elevation={0}>
                  <Box sx={{ pt: 0.5 }}>
                    <Skeleton variant="rectangular" width={"100%"} height={160} sx={{ borderRadius: 6 }} key={"loading_" + index} />
                    <Box sx={{ display: "inline-flex", width: "100%", mt: 1 }}>
                      <Skeleton variant="circular" width={40} height={40} />
                      <Skeleton width="60%" sx={{ marginLeft: 1 }} />
                    </Box>
                    <Skeleton />
                    <Skeleton width="80%" />
                  </Box>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      )}
      <Box sx={{ width: "100%", mt: 2 }}>
        {pageLoadingEnd && <Divider> {search && vendors.length === 0 ? <Chip label="No result found" /> : <Chip label="No more data to load" />}</Divider>}
        {!pageLoadingEnd && !loading && !search && vendors.length > 0 && (
          <Typography ref={ref} align="center">
            <CircularProgress />
          </Typography>
        )}
      </Box>
    </PageContainer>
  );
};

export default VendorList;
