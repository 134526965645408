import React, { useState } from "react";
import { CircularProgress, Button, Typography, Grid, Paper, Stack, Divider } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { deleteFiles, UploadFiles } from "../../../services/UploadFilesService";
import { clientGet, get } from "../../../services/DropZoneService";
import LinearWithValueLabel from "../LinearProgressWithLabel/LinearProgressWithLabel";
import { Box } from "@mui/system";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useRef } from "react";
import { useEffect } from "react";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import { clearFileNames, setFileNames, setIsUploadComplete } from "../../../features/dropzoneSlice/dropzoneSlice";

const DropZone = (props) => {
  const { jobId, prefix } = props;
  const [files, setFiles] = useState([]);
  const [rejectFiles, setRejectFiles] = useState([]);
  const [totalFilesSize, setTotalFilesSize] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [accept, setAccept] = useState("");
  const [acceptLbl, setAcceptLbl] = useState("");
  const [maxSize, setMaxSize] = useState(0);
  const [loading, setLoading] = useState(true);
  const auth = useSelector((state) => state.main.auth);

  const fileNames = useSelector((state) => state.dropzone.fileNames);
  const divRef = useRef(null);
  const dispatch = useDispatch();

  // Scroll to last file view
  useEffect(() => {
    files.length > 0 && divRef.current.scrollIntoView({ behavior: "smooth" });
  }, [files]);

  useEffect(() => {
    auth ? getDropzone() : getClientDropzone();
  }, [auth]);
  // On Drop Event
  const onDrop = (acceptedFiles, rejectedFiles) => {
    const newFiles = acceptedFiles.map((file, index) => ({
      file,
      progress: 0,
      uploaded: false,
      uploaded_size: 0,
      total_size: 0,
      failed: false,
      job_id: jobId,
    }));
    const reFiles = rejectedFiles.map((file) => ({
      file,
    }));
    const totalSize = acceptedFiles.reduce((acc, file) => acc + file.size, 0);

    setFiles([...files, ...newFiles]);
    setRejectFiles([...rejectFiles, ...reFiles]);
    setTotalFilesSize(totalFilesSize + totalSize);
    acceptedFiles.length > 0 && setUploading(false);
  };

  //dropzone
  const getDropzone = () => {
    setLoading(true);
    get().then((response) => {
      if (response.data.success === true) {
        const data = response.data.data;
        var accept = {};
        let acceptLbl = [];
        data.accept.map((item) => {
          acceptLbl.push(item.name);
        });
        // Ex {'image/jpg':[]} created this array for dropzone accept
        for (var i = 0; i < data.accept.length; ++i) accept[data.accept[i].mime_type] = [data.accept[i].extention];
        setAccept(accept);
        setMaxSize(mbToBytes(data.max_file_size));
        setAcceptLbl(acceptLbl.toString());
        setLoading(false);
        console.log("accept", accept)
      }
    });
  };
  //dropzone
  const getClientDropzone = () => {
    setLoading(true);
    clientGet().then((response) => {
      if (response.data.success === true) {
        const data = response.data.data;
        var accept = {};
        let acceptLbl = [];
        data.accept.map((item) => {
          acceptLbl.push(item.name);
        });
        // Ex {'image/jpg':[]} created this array for dropzone accept
        for (var i = 0; i < data.accept.length; ++i) accept[data.accept[i].mime_type] = [data.accept[i].extention];
        setAccept(accept);
        setMaxSize(mbToBytes(data.max_file_size));
        setAcceptLbl(acceptLbl.toString());
        setLoading(false);
      }
    });
  };
  const mbToBytes = (mb) => {
    return mb * 1024 * 1024;
  };
  const bytesToMb = (bytes) => {
    return bytes / 1024 / 1024;
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept, maxSize });

  const uploadFile = (fileIndex) => {
    const fileToUpload = files[fileIndex].file;
    const job_id = files[fileIndex].job_id;
    const formData = new FormData();
    const source = axios.CancelToken.source();
    formData.append("file", fileToUpload);
    formData.append("job_id", job_id);

    UploadFiles(formData, prefix, {
      onUploadProgress: (progressEvent) => {
        const uploaded_size = progressEvent.loaded;
        const total_size = progressEvent.total;
        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setFiles((prevFiles) => prevFiles.map((prevFile, index) => (index === fileIndex ? { ...prevFile, progress, uploaded_size, total_size, source } : prevFile)));
      },
      cancelToken: source.token,
    })
      .then((response) => {
        setFiles((prevFiles) => prevFiles.map((prevFile, index) => (index === fileIndex ? { ...prevFile, uploaded: true } : prevFile)));
        dispatch(setFileNames(response.data.data));
      })
      .catch(() => {
        setFiles((prevFiles) => prevFiles.map((prevFile, index) => (index === fileIndex ? { ...prevFile, failed: true } : prevFile)));
      });
  };

  const upload = () => {
    setUploading(true);
    dispatch(setIsUploadComplete(false));
    files.map((item, index) => {
      !item.uploaded && !item.failed && uploadFile(index);
    });
    dispatch(setIsUploadComplete(true));
  };

  const cancelUpload = (source, index) => {
    source.cancel("Request canceled");
    const newItems = [...files]; // create a copy of the original array
    newItems.splice(index, 1); // remove the object at the specified index
    setFiles(newItems);
  };

  const removeUploaded = (filename, index) => {
    console.log(fileNames);
    const newItems = [...fileNames]; // create a copy of the original array
    const newItems2 = [...files]; // create a copy of the original array
    deleteFiles("jobs/file-delete", { job_id: jobId, filename: newItems[index].filename });
    newItems.splice(index, 1); // remove the object at the specified index
    newItems2.splice(index, 1); // remove the object at the specified index
    // re create the filesname array with image names
    let newNames = [];
    for (let i = 0; i < newItems.length; i++) {
      const count = i + 1;
      const paddedCount = count.toString().padStart(4, "0");
      const fileName = "IMG" + paddedCount;
      const extention = newItems[i].filename.slice(newItems[i].filename.indexOf("."));
      const fname = { filename: fileName + extention, original_name: newItems[i].original_name };
      newNames.push(fname);
    }
    dispatch(clearFileNames());
    dispatch(setFileNames(newNames));
    setFiles(newItems2);
  };

  // Format Bytes
  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  return (
    <div>
      {!loading && (
        <div {...getRootProps({ className: "dropzone " })} className={"dropzone"}>
          <input {...getInputProps()} />
          <Typography variant="subtitle1">Drag 'n' drop some files here, or click to select files</Typography>
          <Typography variant="h6">({acceptLbl})</Typography>
          <Typography variant="body1">(Max : {bytesToMb(maxSize)}MB Per file)</Typography>
        </div>
      )}
      <aside ref={divRef}>
        <h4>File Details</h4>
        <Paper variant="outlined" sx={{ p: 1, mt: 1 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
            <Typography variant="subtitle2">Total Accepted Files : {files.length}</Typography>
            <Typography variant="subtitle2">Uploaded Files : {fileNames.length} </Typography>
            <Typography variant="subtitle2">Total Files Size : {formatBytes(totalFilesSize)} </Typography>
            <Box sx={{ marginLeft: "auto" }}>
              <Button variant="outlined" size="small" startIcon={<UploadFileIcon />} onClick={upload} disabled={uploading || files.length === 0}>
                Start to Upload
              </Button>
            </Box>
          </Stack>
        </Paper>
        <ul>
          {files.map((file, index) => (
            <div key={file.file.name}>
              <li>
                <Grid container>
                  <Grid item sm={9}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: file.uploaded ? "success.main" : file.failed ? "error.main" : "primary:main",
                      }}
                    >
                      {file.file.name} - {file.total_size === 0 ? formatBytes(file.file.size) : formatBytes(file.total_size)} | ( {formatBytes(file.uploaded_size)} ) {!file.uploaded && uploading && !file.failed && <CircularProgress color="primary" size={15} />}
                    </Typography>
                  </Grid>
                  <Grid item sm={3} sx={{ textAlign: "right" }}>
                    {!file.failed && (
                      <Typography align="right" variant="body2">
                        {file.uploaded && (
                          <Button variant="text" color="error" size="small" onClick={() => removeUploaded(file.file.name, index)}>
                            Remove
                          </Button>
                        )}
                        {!file.uploaded && uploading && !file.failed && (
                          <Button variant="text" color="error" size="small" onClick={() => cancelUpload(file.source, index)}>
                            Cancel
                          </Button>
                        )}

                        {!file.uploaded && !uploading && "Ready to Upload"}
                      </Typography>
                    )}
                    {file.failed && (
                      <Typography color={"error"} variant="body2">
                        Failed
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <LinearWithValueLabel progress={file.progress} completed={file.uploaded} failed={file.failed} />
              </li>
            </div>
          ))}
        </ul>
        {rejectFiles.length > 0 && (
          <React.Fragment>
            <Paper variant="outlined" sx={{ p: 1, mt: 1 }}>
              <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                <Typography variant="subtitle2" sx={{ color: "error.main" }}>
                  Total Rejected Files : {rejectFiles.length}{" "}
                </Typography>
              </Stack>
            </Paper>
            <ul>
              {rejectFiles.map((file, index) => {
                return (
                  <li key={"reject_files_" + index}>
                    <Typography variant="subtitle2" sx={{ color: "error.main" }}>
                      {file.file.file.name} - {formatBytes(file.file.file.size)}
                    </Typography>
                    {file.file.errors.map((item, index) => {
                      return (
                        <Box key={"reject_files_error_" + index}>
                          <Typography variant="caption" sx={{ color: "error.main" }}>
                            <strong>{item.code}</strong> - {item.message}
                          </Typography>
                        </Box>
                      );
                    })}
                  </li>
                );
              })}
            </ul>
          </React.Fragment>
        )}
      </aside>
    </div>
  );
};
const mapStateToProps = (state) => ({
  fileNames: state.fileNames,
});

const mapDispatchToProps = { setFileNames };

export default connect(mapStateToProps, mapDispatchToProps)(DropZone);
