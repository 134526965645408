import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import RadioButtonUncheckedOutlinedIcon from "@mui/icons-material/RadioButtonUncheckedOutlined";
import { Box } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

const TrackOrder = (props) => {
  const { status } = props;

  const [notAssigned, setNotAssigned] = useState(false);
  const [assigned, setAssigned] = useState(false);
  const [onWorking, setOnWorking] = useState(false);
  const [delivered, setDelivered] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    setTrack(status);
  }, [status]);

  const setTrack = (status) => {
    switch (status) {
      case "not_assigned":
        setNotAssigned(true);
        break;
      case "assigned":
        setNotAssigned(false);
        setAssigned(true);
        break;
      case "on_working":
        setNotAssigned(false);
        setAssigned(false);
        setOnWorking(true);
        break;
      case "delivered":
        setNotAssigned(false);
        setAssigned(false);
        setOnWorking(false);
        setDelivered(true);
        break;
      case "canceled":
        setNotAssigned(false);
        setAssigned(true);
        setOnWorking(true);
        setDelivered(true);
        setCanceled(true);
        break;
      case "completed":
        setNotAssigned(false);
        setAssigned(true);
        setOnWorking(false);
        setDelivered(true);
        setCanceled(false);
        setCompleted(true);
        break;
      default:
        setNotAssigned(false);
        setAssigned(false);
        setOnWorking(false);
        setDelivered(false);
        setCanceled(false);
        setCompleted(false);
        break;
    }
  };
  return (
    <Box>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="subtitle1" color="text.secondary" gutterBottom>
            Track Job
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ margin: 0 }} color="success" variant={notAssigned ? "outlined" : "filled"}>
                  {notAssigned ? <RadioButtonUncheckedOutlinedIcon fontSize="small" /> : <TaskAltOutlinedIcon fontSize="small" />}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>{notAssigned ? "Not assigned" : "Assigned"}</TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ margin: 0 }} color="success" variant={(assigned || notAssigned) && !canceled && !completed ? "outlined" : "filled"}>
                  {(assigned || notAssigned) && !canceled && !completed ? <RadioButtonUncheckedOutlinedIcon fontSize="small" /> : <TaskAltOutlinedIcon fontSize="small" />}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>{(assigned || notAssigned) && !canceled && !completed ? "Waiting For Acception" : "Accepted"}</TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ margin: 0 }} color="success" variant={(onWorking || notAssigned || assigned) && !canceled && !completed ? "outlined" : "filled"}>
                  {(onWorking || notAssigned || assigned) && !canceled && !completed ? <RadioButtonUncheckedOutlinedIcon fontSize="small" /> : <TaskAltOutlinedIcon fontSize="small" />}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>{(onWorking || notAssigned || assigned) && !canceled && !completed ? "On Working" : "Delivered"}</TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ margin: 0 }} color="success" variant={(delivered || assigned || notAssigned || onWorking) && !canceled && !completed ? "outlined" : "filled"}>
                  {(delivered || assigned || notAssigned || onWorking) && !canceled && !completed ? <RadioButtonUncheckedOutlinedIcon fontSize="small" /> : <TaskAltOutlinedIcon fontSize="small" />}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>{(delivered || assigned || notAssigned || onWorking) && !canceled && !completed ? "Delivery reviewing" : "Delivery reviewed"}</TimelineContent>
            </TimelineItem>
            {canceled && (
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot sx={{ margin: 0 }} color="error" variant="filled">
                  <TaskAltOutlinedIcon fontSize="small" />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>Canceled</TimelineContent>
              </TimelineItem>
            )}
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot sx={{ margin: 0 }} color="success" variant={completed ? "filled" : "outlined"}>
                  {!canceled ? <TaskAltOutlinedIcon fontSize="small" /> : <RadioButtonUncheckedOutlinedIcon fontSize="small" /> }
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>Completed</TimelineContent>
              </TimelineItem>
          </Timeline>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default TrackOrder;
