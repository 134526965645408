import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   dataDialogPasswordResetModalOpen:false
}

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers:{
        setDataDialogPasswordResetModalOpen: (state, action) => {
            state.dataDialogPasswordResetModalOpen = action.payload
        },
    }
})

// export individual action creator functions
export const { setDataDialogPasswordResetModalOpen } = usersSlice.actions;

// often the reducer is a default export, but that doesn't matter
export default usersSlice.reducer;

