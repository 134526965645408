import { requestGet, requestPost } from "./RequestService"

const prefix = '/payments'
const clientPrefix = 'client/payments'

const saveCard = async (data) => {
    return await requestPost(prefix + '/save-card', data)
}
const saveBankAccount = async (data) => {
    return await requestPost(prefix + '/save-bank-acc', data)
}
const getPaymentMethods = async (data) => {
    return await requestGet(prefix + '/get-payment-methods')
}
const deleteCard = async (data) => {
    return await requestGet(prefix + '/delete-card')
}
const getDefaultMethod = async (data) => {
    return await requestGet(prefix + '/get-default-methods')
}
const getPaymentDetails = async (data) => {
    return await requestPost(prefix + '/get-details', data)
}
const getPublicKey = async (data) => {
    return await requestGet(prefix + '/get-public-key')
}
const getClientPublicKey = async () => {
    return await requestGet(clientPrefix + '/get-public-key')
}
const payByCard = async (data) => {
    return await requestPost(prefix + '/pay-by-card', data)
}
const payByCustomer = async (data) => {
    return await requestPost(prefix + '/pay-by-customer', data)
}
const getTransactons = async (page) => {
    return await requestGet(prefix + '/transactions?page=' + page)
}
const getVendorTransactons = async (page) => {
    return await requestGet(prefix + '/v-transactions?page=' + page)
}
const getEarningBarData = async () => {
    return await requestGet(prefix + '/earnings-bar')
}
const getVendorBalance = async () => {
    return await requestGet(prefix + '/v-balance')
}
const getEarningAreaChart = async () => {
    return await requestGet(prefix + '/v-earnings-area-chart')
}
const getThreshold = async () => {
    return await requestGet(prefix + '/get-threshold')
}
const countrySpecs = async (data) => {
    return await requestPost(prefix + '/country-specs', data)
}
const verifyBankAccount = async () => {
    return await requestGet(prefix + '/verify-bank-account')
}
const deleteBankAcc = async (data) => {
    return await requestGet(prefix + '/delete-bank-acc')
}
const withdraw = async (data) => {
    return await requestPost(prefix + '/withdraw', data)
}
const getPaymentVendorList = async (data) => {
    return await requestGet(prefix + '/payment-vendor-list')
}
const manualWithdraw = async (data) => {
    return await requestPost(prefix + '/manual-withdraw', data)
}
export {
    saveCard,
    getPaymentMethods,
    deleteCard,
    getDefaultMethod,
    getPaymentDetails,
    getPublicKey,
    payByCard,
    payByCustomer,
    getTransactons,
    getVendorTransactons,
    getEarningBarData,
    getVendorBalance,
    getEarningAreaChart,
    getThreshold,
    countrySpecs,
    saveBankAccount,
    verifyBankAccount,
    deleteBankAcc,
    withdraw,
    getPaymentVendorList,
    manualWithdraw,
    getClientPublicKey
}