const countryListCode = [
    {"name": "Afghanistan", "value": "AF"},
    {"name": "Åland Islands", "value": "AX"},
    {"name": "Albania", "value": "AL"},
    {"name": "Algeria", "value": "DZ"},
    {"name": "American Samoa", "value": "AS"},
    {"name": "AndorrA", "value": "AD"},
    {"name": "Angola", "value": "AO"},
    {"name": "Anguilla", "value": "AI"},
    {"name": "Antarctica", "value": "AQ"},
    {"name": "Antigua and Barbuda", "value": "AG"},
    {"name": "Argentina", "value": "AR"},
    {"name": "Armenia", "value": "AM"},
    {"name": "Aruba", "value": "AW"},
    {"name": "Australia", "value": "AU"},
    {"name": "Austria", "value": "AT"},
    {"name": "Azerbaijan", "value": "AZ"},
    {"name": "Bahamas", "value": "BS"},
    {"name": "Bahrain", "value": "BH"},
    {"name": "Bangladesh", "value": "BD"},
    {"name": "Barbados", "value": "BB"},
    {"name": "Belarus", "value": "BY"},
    {"name": "Belgium", "value": "BE"},
    {"name": "Belize", "value": "BZ"},
    {"name": "Benin", "value": "BJ"},
    {"name": "Bermuda", "value": "BM"},
    {"name": "Bhutan", "value": "BT"},
    {"name": "Bolivia", "value": "BO"},
    {"name": "Bosnia and Herzegovina", "value": "BA"},
    {"name": "Botswana", "value": "BW"},
    {"name": "Bouvet Island", "value": "BV"},
    {"name": "Brazil", "value": "BR"},
    {"name": "British Indian Ocean Territory", "value": "IO"},
    {"name": "Brunei Darussalam", "value": "BN"},
    {"name": "Bulgaria", "value": "BG"},
    {"name": "Burkina Faso", "value": "BF"},
    {"name": "Burundi", "value": "BI"},
    {"name": "Cambodia", "value": "KH"},
    {"name": "Cameroon", "value": "CM"},
    {"name": "Canada", "value": "CA"},
    {"name": "Cape Verde", "value": "CV"},
    {"name": "Cayman Islands", "value": "KY"},
    {"name": "Central African Republic", "value": "CF"},
    {"name": "Chad", "value": "TD"},
    {"name": "Chile", "value": "CL"},
    {"name": "China", "value": "CN"},
    {"name": "Christmas Island", "value": "CX"},
    {"name": "Cocos (Keeling) Islands", "value": "CC"},
    {"name": "Colombia", "value": "CO"},
    {"name": "Comoros", "value": "KM"},
    {"name": "Congo", "value": "CG"},
    {"name": "Congo, The Democratic Republic of the", "value": "CD"},
    {"name": "Cook Islands", "value": "CK"},
    {"name": "Costa Rica", "value": "CR"},
    {"name": "Cote D\"Ivoire", "value": "CI"},
    {"name": "Croatia", "value": "HR"},
    {"name": "Cuba", "value": "CU"},
    {"name": "Cyprus", "value": "CY"},
    {"name": "Czech Republic", "value": "CZ"},
    {"name": "Denmark", "value": "DK"},
    {"name": "Djibouti", "value": "DJ"},
    {"name": "Dominica", "value": "DM"},
    {"name": "Dominican Republic", "value": "DO"},
    {"name": "Ecuador", "value": "EC"},
    {"name": "Egypt", "value": "EG"},
    {"name": "El Salvador", "value": "SV"},
    {"name": "Equatorial Guinea", "value": "GQ"},
    {"name": "Eritrea", "value": "ER"},
    {"name": "Estonia", "value": "EE"},
    {"name": "Ethiopia", "value": "ET"},
    {"name": "Falkland Islands (Malvinas)", "value": "FK"},
    {"name": "Faroe Islands", "value": "FO"},
    {"name": "Fiji", "value": "FJ"},
    {"name": "Finland", "value": "FI"},
    {"name": "France", "value": "FR"},
    {"name": "French Guiana", "value": "GF"},
    {"name": "French Polynesia", "value": "PF"},
    {"name": "French Southern Territories", "value": "TF"},
    {"name": "Gabon", "value": "GA"},
    {"name": "Gambia", "value": "GM"},
    {"name": "Georgia", "value": "GE"},
    {"name": "Germany", "value": "DE"},
    {"name": "Ghana", "value": "GH"},
    {"name": "Gibraltar", "value": "GI"},
    {"name": "Greece", "value": "GR"},
    {"name": "Greenland", "value": "GL"},
    {"name": "Grenada", "value": "GD"},
    {"name": "Guadeloupe", "value": "GP"},
    {"name": "Guam", "value": "GU"},
    {"name": "Guatemala", "value": "GT"},
    {"name": "Guernsey", "value": "GG"},
    {"name": "Guinea", "value": "GN"},
    {"name": "Guinea-Bissau", "value": "GW"},
    {"name": "Guyana", "value": "GY"},
    {"name": "Haiti", "value": "HT"},
    {"name": "Heard Island and Mcdonald Islands", "value": "HM"},
    {"name": "Holy See (Vatican City State)", "value": "VA"},
    {"name": "Honduras", "value": "HN"},
    {"name": "Hong Kong", "value": "HK"},
    {"name": "Hungary", "value": "HU"},
    {"name": "Iceland", "value": "IS"},
    {"name": "India", "value": "IN"},
    {"name": "Indonesia", "value": "ID"},
    {"name": "Iran, Islamic Republic Of", "value": "IR"},
    {"name": "Iraq", "value": "IQ"},
    {"name": "Ireland", "value": "IE"},
    {"name": "Isle of Man", "value": "IM"},
    {"name": "Israel", "value": "IL"},
    {"name": "Italy", "value": "IT"},
    {"name": "Jamaica", "value": "JM"},
    {"name": "Japan", "value": "JP"},
    {"name": "Jersey", "value": "JE"},
    {"name": "Jordan", "value": "JO"},
    {"name": "Kazakhstan", "value": "KZ"},
    {"name": "Kenya", "value": "KE"},
    {"name": "Kiribati", "value": "KI"},
    {"name": "Korea, Democratic People\"S Republic of", "value": "KP"},
    {"name": "Korea, Republic of", "value": "KR"},
    {"name": "Kuwait", "value": "KW"},
    {"name": "Kyrgyzstan", "value": "KG"},
    {"name": "Lao People\"S Democratic Republic", "value": "LA"},
    {"name": "Latvia", "value": "LV"},
    {"name": "Lebanon", "value": "LB"},
    {"name": "Lesotho", "value": "LS"},
    {"name": "Liberia", "value": "LR"},
    {"name": "Libyan Arab Jamahiriya", "value": "LY"},
    {"name": "Liechtenstein", "value": "LI"},
    {"name": "Lithuania", "value": "LT"},
    {"name": "Luxembourg", "value": "LU"},
    {"name": "Macao", "value": "MO"},
    {"name": "Macedonia, The Former Yugoslav Republic of", "value": "MK"},
    {"name": "Madagascar", "value": "MG"},
    {"name": "Malawi", "value": "MW"},
    {"name": "Malaysia", "value": "MY"},
    {"name": "Maldives", "value": "MV"},
    {"name": "Mali", "value": "ML"},
    {"name": "Malta", "value": "MT"},
    {"name": "Marshall Islands", "value": "MH"},
    {"name": "Martinique", "value": "MQ"},
    {"name": "Mauritania", "value": "MR"},
    {"name": "Mauritius", "value": "MU"},
    {"name": "Mayotte", "value": "YT"},
    {"name": "Mexico", "value": "MX"},
    {"name": "Micronesia, Federated States of", "value": "FM"},
    {"name": "Moldova, Republic of", "value": "MD"},
    {"name": "Monaco", "value": "MC"},
    {"name": "Mongolia", "value": "MN"},
    {"name": "Montserrat", "value": "MS"},
    {"name": "Morocco", "value": "MA"},
    {"name": "Mozambique", "value": "MZ"},
    {"name": "Myanmar", "value": "MM"},
    {"name": "Namibia", "value": "NA"},
    {"name": "Nauru", "value": "NR"},
    {"name": "Nepal", "value": "NP"},
    {"name": "Netherlands", "value": "NL"},
    {"name": "Netherlands Antilles", "value": "AN"},
    {"name": "New Caledonia", "value": "NC"},
    {"name": "New Zealand", "value": "NZ"},
    {"name": "Nicaragua", "value": "NI"},
    {"name": "Niger", "value": "NE"},
    {"name": "Nigeria", "value": "NG"},
    {"name": "Niue", "value": "NU"},
    {"name": "Norfolk Island", "value": "NF"},
    {"name": "Northern Mariana Islands", "value": "MP"},
    {"name": "Norway", "value": "NO"},
    {"name": "Oman", "value": "OM"},
    {"name": "Pakistan", "value": "PK"},
    {"name": "Palau", "value": "PW"},
    {"name": "Palestinian Territory, Occupied", "value": "PS"},
    {"name": "Panama", "value": "PA"},
    {"name": "Papua New Guinea", "value": "PG"},
    {"name": "Paraguay", "value": "PY"},
    {"name": "Peru", "value": "PE"},
    {"name": "Philippines", "value": "PH"},
    {"name": "Pitcairn", "value": "PN"},
    {"name": "Poland", "value": "PL"},
    {"name": "Portugal", "value": "PT"},
    {"name": "Puerto Rico", "value": "PR"},
    {"name": "Qatar", "value": "QA"},
    {"name": "Reunion", "value": "RE"},
    {"name": "Romania", "value": "RO"},
    {"name": "Russian Federation", "value": "RU"},
    {"name": "RWANDA", "value": "RW"},
    {"name": "Saint Helena", "value": "SH"},
    {"name": "Saint Kitts and Nevis", "value": "KN"},
    {"name": "Saint Lucia", "value": "LC"},
    {"name": "Saint Pierre and Miquelon", "value": "PM"},
    {"name": "Saint Vincent and the Grenadines", "value": "VC"},
    {"name": "Samoa", "value": "WS"},
    {"name": "San Marino", "value": "SM"},
    {"name": "Sao Tome and Principe", "value": "ST"},
    {"name": "Saudi Arabia", "value": "SA"},
    {"name": "Senegal", "value": "SN"},
    {"name": "Serbia and Montenegro", "value": "CS"},
    {"name": "Seychelles", "value": "SC"},
    {"name": "Sierra Leone", "value": "SL"},
    {"name": "Singapore", "value": "SG"},
    {"name": "Slovakia", "value": "SK"},
    {"name": "Slovenia", "value": "SI"},
    {"name": "Solomon Islands", "value": "SB"},
    {"name": "Somalia", "value": "SO"},
    {"name": "South Africa", "value": "ZA"},
    {"name": "South Georgia and the South Sandwich Islands", "value": "GS"},
    {"name": "Spain", "value": "ES"},
    {"name": "Sri Lanka", "value": "LK"},
    {"name": "Sudan", "value": "SD"},
    {"name": "Suriname", "value": "SR"},
    {"name": "Svalbard and Jan Mayen", "value": "SJ"},
    {"name": "Swaziland", "value": "SZ"},
    {"name": "Sweden", "value": "SE"},
    {"name": "Switzerland", "value": "CH"},
    {"name": "Syrian Arab Republic", "value": "SY"},
    {"name": "Taiwan", "value": "TW"},
    {"name": "Tajikistan", "value": "TJ"},
    {"name": "Tanzania, United Republic of", "value": "TZ"},
    {"name": "Thailand", "value": "TH"},
    {"name": "Timor-Leste", "value": "TL"},
    {"name": "Togo", "value": "TG"},
    {"name": "Tokelau", "value": "TK"},
    {"name": "Tonga", "value": "TO"},
    {"name": "Trinidad and Tobago", "value": "TT"},
    {"name": "Tunisia", "value": "TN"},
    {"name": "Turkey", "value": "TR"},
    {"name": "Turkmenistan", "value": "TM"},
    {"name": "Turks and Caicos Islands", "value": "TC"},
    {"name": "Tuvalu", "value": "TV"},
    {"name": "Uganda", "value": "UG"},
    {"name": "Ukraine", "value": "UA"},
    {"name": "United Arab Emirates", "value": "AE"},
    {"name": "United Kingdom", "value": "GB"},
    {"name": "United States", "value": "US"},
    {"name": "United States Minor Outlying Islands", "value": "UM"},
    {"name": "Uruguay", "value": "UY"},
    {"name": "Uzbekistan", "value": "UZ"},
    {"name": "Vanuatu", "value": "VU"},
    {"name": "Venezuela", "value": "VE"},
    {"name": "Viet Nam", "value": "VN"},
    {"name": "Virgin Islands, British", "value": "VG"},
    {"name": "Virgin Islands, U.S.", "value": "VI"},
    {"name": "Wallis and Futuna", "value": "WF"},
    {"name": "Western Sahara", "value": "EH"},
    {"name": "Yemen", "value": "YE"},
    {"name": "Zambia", "value": "ZM"},
    {"name": "Zimbabwe", "value": "ZW"}
];
const countryList = [
    { name: "Afghanistan", value: "Afghanistan" },
    { name: "Albania", value: "Albania" },
    { name: "Algeria", value: "Algeria" },
    { name: "American Samoa", value: "American Samoa" },
    { name: "Andorra", value: "Andorra" },
    { name: "Angola", value: "Angola" },
    { name: "Anguilla", value: "Anguilla" },
    { name: "Antarctica", value: "Antarctica" },
    { name: "Antigua and Barbuda", value: "Antigua and Barbuda" },
    { name: "Argentina", value: "Argentina" },
    { name: "Armenia", value: "Armenia" },
    { name: "Aruba", value: "Aruba" },
    { name: "Australia", value: "Australia" },
    { name: "Austria", value: "Austria" },
    { name: "Azerbaijan", value: "Azerbaijan" },
    { name: "Bahamas (the)", value: "Bahamas (the)" },
    { name: "Bahrain", value: "Bahrain" },
    { name: "Bangladesh", value: "Bangladesh" },
    { name: "Barbados", value: "Barbados" },
    { name: "Belarus", value: "Belarus" },
    { name: "Belgium", value: "Belgium" },
    { name: "Belize", value: "Belize" },
    { name: "Benin", value: "Benin" },
    { name: "Bermuda", value: "Bermuda" },
    { name: "Bhutan", value: "Bhutan" },
    {
      name: "Bolivia (Plurinational State of)",
      value: "Bolivia (Plurinational State of)",
    },
    {
      name: "Bonaire, Sint Eustatius and Saba",
      value: "Bonaire, Sint Eustatius and Saba",
    },
    { name: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
    { name: "Botswana", value: "Botswana" },
    { name: "Bouvet Island", value: "Bouvet Island" },
    { name: "Brazil", value: "Brazil" },
    {
      name: "British Indian Ocean Territory (the)",
      value: "British Indian Ocean Territory (the)",
    },
    { name: "Brunei Darussalam", value: "Brunei Darussalam" },
    { name: "Bulgaria", value: "Bulgaria" },
    { name: "Burkina Faso", value: "Burkina Faso" },
    { name: "Burundi", value: "Burundi" },
    { name: "Cabo Verde", value: "Cabo Verde" },
    { name: "Cambodia", value: "Cambodia" },
    { name: "Cameroon", value: "Cameroon" },
    { name: "Canada", value: "Canada" },
    { name: "Cayman Islands (the)", value: "Cayman Islands (the)" },
    {
      name: "Central African Republic (the)",
      value: "Central African Republic (the)",
    },
    { name: "Chad", value: "Chad" },
    { name: "Chile", value: "Chile" },
    { name: "China", value: "China" },
    { name: "Christmas Island", value: "Christmas Island" },
    {
      name: "Cocos (Keeling) Islands (the)",
      value: "Cocos (Keeling) Islands (the)",
    },
    { name: "Colombia", value: "Colombia" },
    { name: "Comoros (the)", value: "Comoros (the)" },
    {
      name: "Congo (the Democratic Republic of the)",
      value: "Congo (the Democratic Republic of the)",
    },
    { name: "Congo (the)", value: "Congo (the)" },
    { name: "Cook Islands (the)", value: "Cook Islands (the)" },
    { name: "Costa Rica", value: "Costa Rica" },
    { name: "Croatia", value: "Croatia" },
    { name: "Cuba", value: "Cuba" },
    { name: "Curaçao", value: "Curaçao" },
    { name: "Cyprus", value: "Cyprus" },
    { name: "Czechia", value: "Czechia" },
    { name: "Côte d'Ivoire", value: "Côte d'Ivoire" },
    { name: "Denmark", value: "Denmark" },
    { name: "Djibouti", value: "Djibouti" },
    { name: "Dominica", value: "Dominica" },
    { name: "Dominican Republic (the)", value: "Dominican Republic (the)" },
    { name: "Ecuador", value: "Ecuador" },
    { name: "Egypt", value: "Egypt" },
    { name: "El Salvador", value: "El Salvador" },
    { name: "Equatorial Guinea", value: "Equatorial Guinea" },
    { name: "Eritrea", value: "Eritrea" },
    { name: "Estonia", value: "Estonia" },
    { name: "Eswatini", value: "Eswatini" },
    { name: "Ethiopia", value: "Ethiopia" },
    {
      name: "Falkland Islands (the) [Malvinas]",
      value: "Falkland Islands (the) [Malvinas]",
    },
    { name: "Faroe Islands (the)", value: "Faroe Islands (the)" },
    { name: "Fiji", value: "Fiji" },
    { name: "Finland", value: "Finland" },
    { name: "France", value: "France" },
    { name: "French Guiana", value: "French Guiana" },
    { name: "French Polynesia", value: "French Polynesia" },
    {
      name: "French Southern Territories (the)",
      value: "French Southern Territories (the)",
    },
    { name: "Gabon", value: "Gabon" },
    { name: "Gambia (the)", value: "Gambia (the)" },
    { name: "Georgia", value: "Georgia" },
    { name: "Germany", value: "Germany" },
    { name: "Ghana", value: "Ghana" },
    { name: "Gibraltar", value: "Gibraltar" },
    { name: "Greece", value: "Greece" },
    { name: "Greenland", value: "Greenland" },
    { name: "Grenada", value: "Grenada" },
    { name: "Guadeloupe", value: "Guadeloupe" },
    { name: "Guam", value: "Guam" },
    { name: "Guatemala", value: "Guatemala" },
    { name: "Guernsey", value: "Guernsey" },
    { name: "Guinea", value: "Guinea" },
    { name: "Guinea-Bissau", value: "Guinea-Bissau" },
    { name: "Guyana", value: "Guyana" },
    { name: "Haiti", value: "Haiti" },
    {
      name: "Heard Island and McDonald Islands",
      value: "Heard Island and McDonald Islands",
    },
    { name: "Holy See (the)", value: "Holy See (the)" },
    { name: "Honduras", value: "Honduras" },
    { name: "Hong Kong", value: "Hong Kong" },
    { name: "Hungary", value: "Hungary" },
    { name: "Iceland", value: "Iceland" },
    { name: "India", value: "India" },
    { name: "Indonesia", value: "Indonesia" },
    { name: "Iran (Islamic Republic of)", value: "Iran (Islamic Republic of)" },
    { name: "Iraq", value: "Iraq" },
    { name: "Ireland", value: "Ireland" },
    { name: "Isle of Man", value: "Isle of Man" },
    { name: "Israel", value: "Israel" },
    { name: "Italy", value: "Italy" },
    { name: "Jamaica", value: "Jamaica" },
    { name: "Japan", value: "Japan" },
    { name: "Jersey", value: "Jersey" },
    { name: "Jordan", value: "Jordan" },
    { name: "Kazakhstan", value: "Kazakhstan" },
    { name: "Kenya", value: "Kenya" },
    { name: "Kiribati", value: "Kiribati" },
    {
      name: "Korea (the Democratic People's Republic of)",
      value: "Korea (the Democratic People's Republic of)",
    },
    { name: "Korea (the Republic of)", value: "Korea (the Republic of)" },
    { name: "Kuwait", value: "Kuwait" },
    { name: "Kyrgyzstan", value: "Kyrgyzstan" },
    {
      name: "Lao People's Democratic Republic (the)",
      value: "Lao People's Democratic Republic (the)",
    },
    { name: "Latvia", value: "Latvia" },
    { name: "Lebanon", value: "Lebanon" },
    { name: "Lesotho", value: "Lesotho" },
    { name: "Liberia", value: "Liberia" },
    { name: "Libya", value: "Libya" },
    { name: "Liechtenstein", value: "Liechtenstein" },
    { name: "Lithuania", value: "Lithuania" },
    { name: "Luxembourg", value: "Luxembourg" },
    { name: "Macao", value: "Macao" },
    { name: "Madagascar", value: "Madagascar" },
    { name: "Malawi", value: "Malawi" },
    { name: "Malaysia", value: "Malaysia" },
    { name: "Maldives", value: "Maldives" },
    { name: "Mali", value: "Mali" },
    { name: "Malta", value: "Malta" },
    { name: "Marshall Islands (the)", value: "Marshall Islands (the)" },
    { name: "Martinique", value: "Martinique" },
    { name: "Mauritania", value: "Mauritania" },
    { name: "Mauritius", value: "Mauritius" },
    { name: "Mayotte", value: "Mayotte" },
    { name: "Mexico", value: "Mexico" },
    {
      name: "Micronesia (Federated States of)",
      value: "Micronesia (Federated States of)",
    },
    { name: "Moldova (the Republic of)", value: "Moldova (the Republic of)" },
    { name: "Monaco", value: "Monaco" },
    { name: "Mongolia", value: "Mongolia" },
    { name: "Montenegro", value: "Montenegro" },
    { name: "Montserrat", value: "Montserrat" },
    { name: "Morocco", value: "Morocco" },
    { name: "Mozambique", value: "Mozambique" },
    { name: "Myanmar", value: "Myanmar" },
    { name: "Namibia", value: "Namibia" },
    { name: "Nauru", value: "Nauru" },
    { name: "Nepal", value: "Nepal" },
    { name: "Netherlands (the)", value: "Netherlands (the)" },
    { name: "New Caledonia", value: "New Caledonia" },
    { name: "New Zealand", value: "New Zealand" },
    { name: "Nicaragua", value: "Nicaragua" },
    { name: "Niger (the)", value: "Niger (the)" },
    { name: "Nigeria", value: "Nigeria" },
    { name: "Niue", value: "Niue" },
    { name: "Norfolk Island", value: "Norfolk Island" },
    {
      name: "Northern Mariana Islands (the)",
      value: "Northern Mariana Islands (the)",
    },
    { name: "Norway", value: "Norway" },
    { name: "Oman", value: "Oman" },
    { name: "Pakistan", value: "Pakistan" },
    { name: "Palau", value: "Palau" },
    { name: "Palestine, State of", value: "Palestine, State of" },
    { name: "Panama", value: "Panama" },
    { name: "Papua New Guinea", value: "Papua New Guinea" },
    { name: "Paraguay", value: "Paraguay" },
    { name: "Peru", value: "Peru" },
    { name: "Philippines (the)", value: "Philippines (the)" },
    { name: "Pitcairn", value: "Pitcairn" },
    { name: "Poland", value: "Poland" },
    { name: "Portugal", value: "Portugal" },
    { name: "Puerto Rico", value: "Puerto Rico" },
    { name: "Qatar", value: "Qatar" },
    {
      name: "Republic of North Macedonia",
      value: "Republic of North Macedonia",
    },
    { name: "Romania", value: "Romania" },
    { name: "Russian Federation (the)", value: "Russian Federation (the)" },
    { name: "Rwanda", value: "Rwanda" },
    { name: "Réunion", value: "Réunion" },
    { name: "Saint Barthélemy", value: "Saint Barthélemy" },
    {
      name: "Saint Helena, Ascension and Tristan da Cunha",
      value: "Saint Helena, Ascension and Tristan da Cunha",
    },
    { name: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
    { name: "Saint Lucia", value: "Saint Lucia" },
    { name: "Saint Martin (French part)", value: "Saint Martin (French part)" },
    { name: "Saint Pierre and Miquelon", value: "Saint Pierre and Miquelon" },
    {
      name: "Saint Vincent and the Grenadines",
      value: "Saint Vincent and the Grenadines",
    },
    { name: "Samoa", value: "Samoa" },
    { name: "San Marino", value: "San Marino" },
    { name: "Sao Tome and Principe", value: "Sao Tome and Principe" },
    { name: "Saudi Arabia", value: "Saudi Arabia" },
    { name: "Senegal", value: "Senegal" },
    { name: "Serbia", value: "Serbia" },
    { name: "Seychelles", value: "Seychelles" },
    { name: "Sierra Leone", value: "Sierra Leone" },
    { name: "Singapore", value: "Singapore" },
    { name: "Sint Maarten (Dutch part)", value: "Sint Maarten (Dutch part)" },
    { name: "Slovakia", value: "Slovakia" },
    { name: "Slovenia", value: "Slovenia" },
    { name: "Solomon Islands", value: "Solomon Islands" },
    { name: "Somalia", value: "Somalia" },
    { name: "South Africa", value: "South Africa" },
    {
      name: "South Georgia and the South Sandwich Islands",
      value: "South Georgia and the South Sandwich Islands",
    },
    { name: "South Sudan", value: "South Sudan" },
    { name: "Spain", value: "Spain" },
    { name: "Sri Lanka", value: "Sri Lanka" },
    { name: "Sudan (the)", value: "Sudan (the)" },
    { name: "Suriname", value: "Suriname" },
    { name: "Svalbard and Jan Mayen", value: "Svalbard and Jan Mayen" },
    { name: "Sweden", value: "Sweden" },
    { name: "Switzerland", value: "Switzerland" },
    { name: "Syrian Arab Republic", value: "Syrian Arab Republic" },
    { name: "Taiwan", value: "Taiwan" },
    { name: "Tajikistan", value: "Tajikistan" },
    {
      name: "Tanzania, United Republic of",
      value: "Tanzania, United Republic of",
    },
    { name: "Thailand", value: "Thailand" },
    { name: "Timor-Leste", value: "Timor-Leste" },
    { name: "Togo", value: "Togo" },
    { name: "Tokelau", value: "Tokelau" },
    { name: "Tonga", value: "Tonga" },
    { name: "Trinidad and Tobago", value: "Trinidad and Tobago" },
    { name: "Tunisia", value: "Tunisia" },
    { name: "Turkey", value: "Turkey" },
    { name: "Turkmenistan", value: "Turkmenistan" },
    {
      name: "Turks and Caicos Islands (the)",
      value: "Turks and Caicos Islands (the)",
    },
    { name: "Tuvalu", value: "Tuvalu" },
    { name: "Uganda", value: "Uganda" },
    { name: "Ukraine", value: "Ukraine" },
    { name: "United Arab Emirates (the)", value: "United Arab Emirates (the)" },
    {
      name: "United Kingdom of Great Britain and Northern Ireland (the)",
      value: "United Kingdom of Great Britain and Northern Ireland (the)",
    },
    {
      name: "United States Minor Outlying Islands (the)",
      value: "United States Minor Outlying Islands (the)",
    },
    {
      name: "United States of America (the)",
      value: "United States of America (the)",
    },
    { name: "Uruguay", value: "Uruguay" },
    { name: "Uzbekistan", value: "Uzbekistan" },
    { name: "Vanuatu", value: "Vanuatu" },
    {
      name: "Venezuela (Bolivarian Republic of)",
      value: "Venezuela (Bolivarian Republic of)",
    },
    { name: "Viet Nam", value: "Viet Nam" },
    { name: "Virgin Islands (British)", value: "Virgin Islands (British)" },
    { name: "Virgin Islands (U.S.)", value: "Virgin Islands (U.S.)" },
    { name: "Wallis and Futuna", value: "Wallis and Futuna" },
    { name: "Western Sahara", value: "Western Sahara" },
    { name: "Yemen", value: "Yemen" },
    { name: "Zambia", value: "Zambia" },
    { name: "Zimbabwe", value: "Zimbabwe" },
    { name: "Åland Islands", value: "Åland Islands" },
];
const snackBarPosition = { vertical: "bottom", horizontal: "center" }
export { countryListCode, snackBarPosition, countryList }