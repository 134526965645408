import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboardStatistics: [
    {
      name: "Page A",
      uv: 4000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 3000,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 2000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 2390,
      pv: 3800,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 3490,
      pv: 4300,
      amt: 2100,
    },
  ],
  topBarTitle: "Dashboard",
  newPermissionCreated: false,
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardStatistics: (state, action) => {
      state.selectBoxItems = action.payload;
    },
    setTopBarTitle: (state, action) => {
      state.topBarTitle = action.payload;
    },
    setNewPermissionCreated: (state, action) => {
      state.newPermissionCreated = action.payload;
    },
  },
});

// export individual action creator functions
export const { setDashboardStatistics, setTopBarTitle, setNewPermissionCreated } =
  dashboardSlice.actions;

// often the reducer is a default export, but that doesn't matter
export default dashboardSlice.reducer;
