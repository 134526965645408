
import React, { useEffect, useState } from "react";
import { CardContent, Grid, Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import PageContainer from "../PageContainer/PageContainer"
import CardComponent from "../../common/CardComponent/CardComponent"
import TextFieldComponent from "../../common/TextFieldComponent/TextFieldComponent";
import LoadingButtons from "../../common/LoadingButton/LoadingButtonComponent"
import { useDispatch, useSelector } from "react-redux";
import { createPermission } from "../../../services/PermissionService";
import { useSnackbar } from 'notistack';
import { setTopLoader } from "../../../features/mainSlice/mainSlice";
import { setSideBarRoutes } from "../../../features/commonSlice/commonSlice";
import { setNewPermissionCreated } from "../../../features/dashboardSlice/dashboardSlice";
const CreatePermission = () => {

  // component states
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  // snackbar
  const { enqueueSnackbar } = useSnackbar();

  const sideBarRoutes = useSelector((state) => state.common.sideBarRoutes)

  const dispatch = useDispatch()
  // form
  const { handleSubmit, control, reset, formState } = useForm({
    mode: 'onChange'
  });

  const onSubmit = data => {
    // // Show loaders
    setBtnLoading(true)
    dispatch(setTopLoader(true))
    // Send auth request
    createPermission(data).then((response) => {
      console.log(response)
      if (response.data.success === true) {
        showSuccess(response.data.message)
        dispatch(setNewPermissionCreated(true))
      }
      setBtnLoading(false)
      dispatch(setTopLoader(false))
      reset()
    }).catch((response) => {
      showError(response.response.data.data.message)
      setBtnLoading(false)
      dispatch(setTopLoader(false))
      console.log("error", response)
    })
    
  }
  const showError = (message) => {
    enqueueSnackbar(message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
  }
  const showSuccess = (message) => {
    enqueueSnackbar(message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
  }

  return (
    <PageContainer >
      <Grid container spacing={4}>
        <Grid item sm={12}>
          <CardComponent>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Create a Permission
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={4}>
                  <Grid item sm={6}>
                    <Controller
                      name="name"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return <TextFieldComponent type={'text'} id="name" label="Name*" autoFocus={true} error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />
                      }}
                      rules={{ required: 'Name is required' }}
                    />
                    <Controller
                      name="path"
                      control={control}
                      defaultValue="partners/"
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return <TextFieldComponent type={'text'} id="path" label="Path*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />
                      }}
                      rules={{ required: 'Path is required' }}
                    />
                    <Controller
                      name="exact"
                      control={control}
                      defaultValue="0"
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return <TextFieldComponent type={'text'} id="exact" label="Exact*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />
                      }}
                      rules={{ required: 'Exact is required' }}
                    />
                    <Controller
                      name="slug"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return <TextFieldComponent type={'text'} id="slug" label="Slug*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />
                      }}
                      rules={{ required: 'Slug is required' }}
                    />
                    <Controller
                      name="element"
                      control={control}
                      defaultValue="<Sample />"
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return <TextFieldComponent type={'text'} id="element" label="Element*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />
                      }}
                      rules={{ required: 'Element is required' }}
                    />
                    <Controller
                      name="is_sidenav"
                      control={control}
                      defaultValue="1"
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return <TextFieldComponent type={'text'} id="is_sidenav" label="Is Sidenav*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />
                      }}
                      rules={{ required: 'Is Sidenav is required' }}
                    />
                    <Controller
                      name="icon"
                      control={control}
                      defaultValue="<ExampleIcon />"
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return <TextFieldComponent type={'text'} id="icon" label="Icon*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />
                      }}
                      rules={{ required: 'Icon is required' }}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Controller
                      name="is_dropdown"
                      control={control}
                      defaultValue="0"
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return <TextFieldComponent type={'text'} id="is_dropdown" label="Is Dropdown*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />
                      }}
                      rules={{ required: 'Is Dropdown is required' }}
                    />
                    <Controller
                      name="parent_position"
                      control={control}
                      defaultValue={sideBarRoutes.length + 1}
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return <TextFieldComponent type={'text'} id="parent_position" label="Parent Position*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />
                      }}
                      rules={{ required: 'Parent Position is required' }}
                    />
                    <Controller
                      name="is_sublink"
                      control={control}
                      defaultValue="0"
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return <TextFieldComponent type={'text'} id="is_sublink" label="Is Sublink*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />
                      }}
                      rules={{ required: 'Is Sublink is required' }}
                    />
                    <Controller
                      name="parent_id"
                      control={control}
                      defaultValue="null"
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return <TextFieldComponent type={'text'} id="parent_id" label="Parent Id*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />
                      }}
                      rules={{ required: 'Parent Id is required' }}
                    />
                    <Controller
                      name="sublink_position"
                      control={control}
                      defaultValue="null"
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return <TextFieldComponent type={'text'} id="sublink_position" label="Sublink Position*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />
                      }}
                      rules={{ required: 'Sublink Position is required' }}
                    />
                    <Controller
                      name="is_action"
                      control={control}
                      defaultValue="0"
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return <TextFieldComponent type={'text'} id="is_action" label="Is Action*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />
                      }}
                      rules={{ required: 'Is Action is required' }}
                    />
                    <Typography align='right'>
                      <LoadingButtons loading={btnLoading} name="Save" variant="contained" type="submit" disabled={!formState.isValid} />
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </CardComponent>
        </Grid>
      </Grid>

    </PageContainer>

  );
}



export default CreatePermission;