import { requestGet, requestPost } from "./RequestService"

const prefix = '/job-types'

const create = async (data) => {
    return await requestPost(prefix+'/create', data)
}
const getById = async (data) => {
    return await requestPost(prefix+'/get', data)
}
const getAll = async () => {
    return await requestGet(prefix+'/get')
} 
const update = async (data) => {
    return await requestPost(prefix+'/update', data)
}
const deleteData = async (data) => {
    return await requestPost(prefix+'/delete', data)
}
const getAllForPublic = async () => {
    return await requestGet(prefix+'/get-pub')
}
const getVendorServices = async (data) => {
    return await requestPost(prefix+'/get-v-services', data)
} 
export { create, getById, getAll, update, deleteData, getAllForPublic, getVendorServices }