import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CardContent, Checkbox, Chip, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CardComponent from "../../common/CardComponent/CardComponent";
import PageContainer from "../PageContainer/PageContainer";
import {
  getPermissionsByRoleId,
  getRoles,
  updateRolePermissions,
} from "../../../services/PermissionService";
import { useDispatch } from "react-redux";
import { setTopLoader } from "../../../features/mainSlice/mainSlice";
import SelectBox from "../../common/SelectBoxCompnent/SelectBoxComponent";
import LoadingButtons from "../../common/LoadingButton/LoadingButtonComponent";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { CheckBox, SubdirectoryArrowLeftOutlined } from "@mui/icons-material";
import { snackBarPosition } from "../../../services/CommonService";

export default function DataTable() {
  function createData(
    id,
    is_action,
    is_sidenav,
    name,
    path,
    permission,
    role_access_permission
  ) {
    return {
      id,
      is_action,
      is_sidenav,
      name,
      path,
      permission,
      role_access_permission,
    };
  }
  const label = { inputProps: { "aria-label": "Permission" } };

  const [rows, setRows] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnDisabled, setBtnDisable] = useState(true);
  const [roles, setRoles] = useState([]);
  const [roleId, setRoleId] = useState(undefined);
  const [permissionCheckedChange, setPermissionCheckedChange] = useState(0);
  const [accessPermissionCheckedChange, setAccessPermissionCheckedChange] =
    useState(0);

  const { control, register, handleSubmit, onChange } = useForm();

  // snackbar
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  // use effect
  useEffect(() => {
    dispatch(setTopLoader(true));
    getRoles()
      .then((response) => {
        let roles = [];
        roles = [...roles];
        response.data.data.map((item) => {
          return roles.push({ name: item.name, value: item.id });
        });
        setRoles(roles);
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        console.log(error);
        // showError(response.data.data.message);
        dispatch(setTopLoader(false));
        // console.log("error", response.response);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accessPermissionCheckedChange > 0 || permissionCheckedChange > 0) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  }, [accessPermissionCheckedChange, permissionCheckedChange]);

  const handleRoleChange = (e) => {
    const roleId = e.target.value;
    setRoleId(roleId);
    setRows([]);
    setPermissionCheckedChange(0);
    setAccessPermissionCheckedChange(0);
    dispatch(setTopLoader(true));
    const data = { role_id: roleId };
    // hide top loader after component load
    getPermissionsByRoleId(data)
      .then((response) => {
        let rows = [];
        rows = [...rows];
        response.data.data.map((item, index) => {
          return rows.push(
            createData(
              item.id,
              item.is_action ? "true" : "false",
              item.is_sidenav ? "true" : "false",
              item.name,
              item.path,
              item.has_permission,
              item.has_access_permission
            )
          );
        });
        console.log(rows)
        setRows(rows);
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        console.log(error);
        // showError(response.data.data.message);
        dispatch(setTopLoader(false));
        // console.log("error", response.response);
      });
  };

  const handlePermissionChange = (e, id) => {
    console.log(e.target.checked)
    if (e.target.checked) {
      setPermissionCheckedChange(permissionCheckedChange + 1);
    } else {
      if (permissionCheckedChange > 0) {
        setPermissionCheckedChange(permissionCheckedChange - 1);
      } else {
        setPermissionCheckedChange(permissionCheckedChange + 1);
      }
    }
  };
  const handleAccessPermissionChange = (e, id) => {
    if (e.target.checked) {
      setAccessPermissionCheckedChange(accessPermissionCheckedChange + 1);
    } else {
      if (accessPermissionCheckedChange > 0) {
        setAccessPermissionCheckedChange(accessPermissionCheckedChange - 1);
      } else {
        setAccessPermissionCheckedChange(accessPermissionCheckedChange + 1);
      }
    }
  };

  const onSubmit = (data) => {
    dispatch(setTopLoader(true));
    setBtnLoading(true);
    const permissions = data.permissions;
    const access_permissions = data.access_permissions;
    updateRolePermissions({
      permissions,
      access_permissions,
      role_id: roleId,
    })
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
        }
        dispatch(setTopLoader(false));
        setBtnLoading(false);
        setBtnDisable(true);
      })
      .catch((error) => {
        console.log(error);
        showError(error.response.data.message);
        dispatch(setTopLoader(false));
        setBtnLoading(false);
        setBtnDisable(false);
        // console.log("error", response.response);
      });
  };

  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };

  return (
    <PageContainer>
      <Grid container spacing={4}>
        <Grid item sm={12}>
          <CardComponent>
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Typography align="right">
                  <LoadingButtons
                    loading={btnLoading}
                    variant="contained"
                    disabled={btnDisabled}
                    type="submit"
                    name="save"
                  />
                </Typography>
                {/* <Typography gutterBottom variant="h6" component="div">
                Role Permission
              </Typography> */}

                <SelectBox
                  label="Select Role"
                  items={roles}
                  error={false}
                  defaultValue={roleId}
                  onChange={handleRoleChange}
                />

                <TableContainer component={Paper}>
                  <Table
                    sx={{ height: 400, width: "100%" }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align="right" flex={1}>
                          Action Permission
                        </TableCell>
                        <TableCell align="right" flex={1}>
                          SideNav Permission
                        </TableCell>
                        <TableCell align="right" flex={1}>
                          Name
                        </TableCell>
                        <TableCell align="right" flex={2}>
                          Path
                        </TableCell>
                        <TableCell align="right" flex={1}>
                          Permission
                        </TableCell>
                        <TableCell align="right" flex={1}>
                          Role Access Permission
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row, index) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.id}
                          </TableCell>
                          <TableCell align="right">
                            <Chip label={row.is_action} size="small" />
                          </TableCell>
                          <TableCell align="right">
                            <Chip
                              label={row.is_sidenav}
                              size="small"
                              variant="outlined"
                            />
                          </TableCell>
                          <TableCell align="right">{row.name}</TableCell>
                          <TableCell align="right">{row.path}</TableCell>
                          <TableCell align="right">
                            
                            <Checkbox
                            control={control}
                              key={row.id}
                              value={row.id}
                              defaultChecked={index === 0 ? true : row.permission}
                              {...register('permissions', {
                                onChange: (e) =>
                                  handlePermissionChange(e, row.id),
                              })}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Checkbox
                            control={control}
                              key={row.id + 2 * 10}
                              value={row.id}
                              defaultChecked={
                                index === 0 ? true : row.role_access_permission
                              }
                              {...register(
                                'access_permissions',
                                {
                                  onChange: (e) =>
                                    handleAccessPermissionChange(e, row.id),
                                }
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography align="right">
                  <LoadingButtons
                    loading={btnLoading}
                    variant="contained"
                    disabled={btnDisabled}
                    type="submit"
                    name="save"
                  />
                </Typography>
              </form>
            </CardContent>
          </CardComponent>
        </Grid>
      </Grid>
    </PageContainer>
  );
}
