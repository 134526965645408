
import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import { setTopBarTitle } from "../../features/dashboardSlice/dashboardSlice";
import { useDispatch } from "react-redux";
import { Divider } from "@mui/material";
import Payments from "../../components/admin/Payments/Payments";
import PageToolbar from "../../components/admin/PageToolbar/PageToolbar";

const PaymentsPage = (props) => {

  // component states

  const title = "ImageMall | Payments";
  //Redux states
  const dispatch = useDispatch()
  
  // use effect
  useEffect(() => {
    dispatch(setTopBarTitle('Payments'))
  }, [])
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageToolbar
        color="info"
        alert="Connect your payment methods and withdrawal methods. We do not keep your card details. everything handled by stripe.com"
        severity="info"
        close={true}
      />
      <Divider />
      <Payments />
      
    </React.Fragment>
  );
}

export default PaymentsPage;