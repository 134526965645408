import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";

const DataTable = (props) => {
  const sortingModel = [
    { field: 'id', sort: 'desc' }, 
  ];
  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        autoHeight 
        rows={props.rows}
        columns={props.columns}
        pageSize={props.pageSize}
        rowsPerPageOptions={props.rowsPerPageOptions}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={props.experimentalFeatures}
        loading={props.loading}
        sortModel={sortingModel}
      />
    </Box>
  );
};

export default DataTable;
