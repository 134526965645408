import React, { useState, useEffect } from "react";
import { List, ListItem, ListItemText, Button, CircularProgress, Chip, Grid, Typography, Card, Box } from "@mui/material";
import { getTransactons } from "../../../services/PaymentService";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
const TransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const loadTransactions = () => {
    setIsLoading(true);
    getTransactons(page)
      .then((response) => {
        const data = response.data.data.data;
        if (data.length > 0) {
          setTransactions((prevTransactions) => [...prevTransactions, ...data]);
          setPage(page + 1);
          setLoadMore(true);
          setIsLoading(false);
          setLoading(false);
        } else {
          setLoadMore(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadTransactions();
  }, []);

  return (
    <Card variant="outlined" sx={{ p: 4, mt: 2 }}>
      <Typography variant="h5">Transactions</Typography>
      {!loading ? (
        <List sx={{ width: "100%" }}>
          {transactions.map((transaction) => (
            <ListItem key={transaction.id}>
              <ListItemText
                primary={
                  <Grid container spacing={2}>
                    <Grid item sm={4}>
                      <strong>{"US$" + transaction.total.toFixed(2)}</strong>
                    </Grid>
                    <Grid item sm={4}>
                      {transaction.status === "succeeded" ? (
                        <Chip icon={transaction.status === "succeeded" ? <DoneOutlinedIcon /> : <WatchLaterIcon />} size="small" label={transaction.status} color="success" />
                      ) : (
                        <Chip icon={transaction.status === "succeeded" ? <DoneOutlinedIcon /> : <WatchLaterIcon />} size="small" label={transaction.status} />
                      )}
                    </Grid>
                    <Grid item sm={4}>
                      {transaction.job_id}
                      {/* <Link to={"/partners/pay?payment_id=" + transaction.job_id} style={{ color: "inherit" }}>
                      
                    </Link> */}
                    </Grid>
                  </Grid>
                }
                secondary={
                  <Grid container spacing={2}>
                    <Grid item sm={4}>
                      {moment(transaction.created_at).format("lll")}
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress size={24} />
        </Box>
      )}
      {loadMore && (
        <Button startIcon={<ExpandMoreOutlinedIcon />} fullWidth onClick={loadTransactions}>
          {isLoading ? <CircularProgress size={24} /> : "Load More"}
        </Button>
      )}
    </Card>
  );
};

export default TransactionHistory;
