import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import LoadingButtons from "../../common/LoadingButton/LoadingButtonComponent";
import { keyframes } from "@mui/system";
import { useState } from "react";
import { jobAcception, jobCompletion } from "../../../services/JobService";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { setJobEffect, setJobStatus } from "../../../features/jobSlice/jobSlice";
import ConfirmDialog from "../../common/ConfirmDialog/ConfirmDialog";
import DataDialog from "../../common/DataDialog/DataDialog"
import RateAndReview from "../../common/RateAndReview/RateAndReview"
import { snackBarPosition } from "../../../services/CommonService";

const JobComplete = (props) => {
  const blink = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
    `;
  const { enqueueSnackbar } = useSnackbar();
  const [btnCLoading, setBtnCLoading] = useState(false);
  const [btnRLoading, setBtnRLoading] = useState(false);
  const [acceptConfirmDialog, setCompleteConfirmDialog] = useState(false);
  const [rejectConfirmDialog, setRejectConfirmDialog] = useState(false);
  const jobEffect = useSelector((state)=>state.job.jobEffect)
  const ratingEffect = useSelector((state)=>state.job.ratingEffect)
  const dispatch = useDispatch();

  useEffect(()=>{
    if(acceptConfirmDialog == true){
      handleCompleteConfirmDialog()
    }
  },[ratingEffect])

  const handleComplete = () => {
    setCompleteConfirmDialog(true);
  };
  const handleReject = () => {
    setRejectConfirmDialog(true);
  };
  const handleCompleteConfirmDialog = () => {
    setBtnCLoading(true);
    const job_id = props.job_id;
    const completion = "completed";
    jobCompletion({ job_id, completion })
      .then((response) => {
        if (response.data.success === true) {
          dispatch(setJobStatus("on_working"));
          showSuccess(response.data.message)
          dispatch(setJobEffect(!jobEffect))
        }
        setBtnCLoading(false);
      })
      .catch((error) => {
        console.log(error);
        showError(error.response.data.message);
        setBtnCLoading(false);
      });
    setCompleteConfirmDialog(false);
  };
  const handleCompleteCancelDialog = () => {
    setCompleteConfirmDialog(false);
  };
  const handleRejectConfirmDialog = () => {
    setBtnRLoading(true);
    const job_id = props.job_id;
    const completion = "rejected";
    jobCompletion({ job_id, completion })
      .then((response) => {
        if (response.data.success === true) {
          window.location.reload();
        }
        setBtnRLoading(false);
      })
      .catch((error) => {
        console.log(error);
        showError(error.response.data.message);
        setBtnRLoading(false);
      });
    setRejectConfirmDialog(false);
  };
  const handleRejectCancelDialog = () => {
    setRejectConfirmDialog(false);
  };
  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };

  return (
    <Box sx={{ marginTop: 1, marginBottom: 1 }}>
      <Paper
        variant="elevation"
        elevation={4}
        sx={{ padding: 2, animation: `${blink} 1s linear 3` }}
      >
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Do you want to complete this job ?.
        </Typography>
        <Grid container sx={{ textAlign: "center" }}>
          <Grid item sm={7}>
            <LoadingButtons
              loading={btnCLoading}
              variant="contained"
              type="button"
              name="Yes, Complete"
              color="success"
              onClick={handleComplete}
            />
          </Grid>
          <Grid item sm={5}>
            <LoadingButtons
              loading={btnRLoading}
              variant="outlined"
              type="button"
              name="No, Reject"
              color="error"
              onClick={handleReject}
            />
          </Grid>
        </Grid>
      </Paper>
      
      <DataDialog
        onClickCancel={handleCompleteCancelDialog}
        open={acceptConfirmDialog}
        header="Review & Rating"
        loading={false}
      >
        <RateAndReview buttonLbl="Submit & Complete" job_id={props.job_id} btnIcon={null} url="jobs/rating" zipDownload={false}/>
      </DataDialog>
      <ConfirmDialog
        onClickConfirm={handleRejectConfirmDialog}
        onClickCancel={handleRejectCancelDialog}
        open={rejectConfirmDialog}
        header="Are You Sure?"
        message="Please confirm to reject this job."
      />
    </Box>
  );
};

export default JobComplete;
