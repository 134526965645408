import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import EarningsBar from "../EarningsBar/EarningsBar";
import { Container } from "@mui/system";
import { Alert, Box, Button, Chip, CircularProgress, Grid, IconButton, LinearProgress, Paper, Tooltip } from "@mui/material";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import AddCard from "./AddCard/AddCard";
import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { deleteBankAcc, deleteCard, getPaymentMethods, getThreshold, verifyBankAccount } from "../../../services/PaymentService";
import { setTopLoader } from "../../../features/mainSlice/mainSlice";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ConfirmDialog from "../../common/ConfirmDialog/ConfirmDialog";
import { useSnackbar } from "notistack";
import TransactionHistory from "../../common/TransactionHistory/TransactionHistory";
import VendorTransactionHistory from "../../common/VendorTransactionHistory/VendorTransactionHistory";
import LoadingButtons from "../../common/LoadingButton/LoadingButtonComponent";
import AddWithdrawalMethod from "./AddWithdrawalMethod/AddWithdrawalMethod";
import PageToolbar from "../PageToolbar/PageToolbar";
import { setAddBankState } from "../../../features/PaymentsSlice/PaymentsSlice";
import WithdrawBalance from "./WithdrawBalance/WithdrawBalance";
import { snackBarPosition } from "../../../services/CommonService";

export default function Payments() {
  const [addCardSts, setAddCardSts] = useState(false);
  const [addWithdrawalMethodSts, setAddWithdrawalMethodSts] = useState(false);
  const [withdrawBalanceSts, setWithdrawBalanceSts] = useState(false);
  const [paymentCard, setPaymentCard] = useState([]);
  const [withdrawalMethod, setWithdrawalMethod] = useState([]);
  const [paymentCardLoading, setPaymentCardLoading] = useState(true);
  const [deleteCardSts, setDeleteCardSts] = useState(false);
  const [deleteCardConfirmDialog, setDeleteCardConfirmDialog] = useState(false);
  const [thresholdLoading, setThresholdLoading] = useState(true);
  const [thresholdData, setThresholdData] = useState([]);
  const [withdrawalBtnLoading, setWithdrawalBtnLoading] = useState(false);
  const [withdrawalBtnDisabled, setWithdrawalBtnDisabled] = useState(true);
  const [verifyBankAccDialog, setVerifyBankAccDialog] = useState(false);
  const [deleteBankConfirmDialog, setDeleteBankConfirmDialog] = useState(false);

  const role = useSelector((state) => state.common.role);
  const availableBalance = useSelector((state) => state.common.availableBalance);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const addCardState = useSelector((state) => state.payments.addCardState);
  const addBankState = useSelector((state) => state.payments.addBankState);
  useEffect(() => {
    setAddCardSts(false);
    setAddWithdrawalMethodSts(false);
    setWithdrawBalanceSts(false)
    getCards();
  }, [addCardState, deleteCardSts, addBankState]);
  useEffect(() => {
    if (role === "vendor") {
      getThresholdData();
    }
  }, [role]);

  const getCards = () => {
    getPaymentMethods()
      .then((response) => {
        const data = response.data.data.card;
        if (data && Object.keys(data).length !== 0) {
          setPaymentCard(data);
        }
        const wData = response.data.data.withdrawal_method;
        if (wData && Object.keys(wData).length !== 0) {
          setWithdrawalMethod(wData);
        }
        dispatch(setTopLoader(false));
        setPaymentCardLoading(false);
      })
      .catch((error) => {
        dispatch(setTopLoader(false));
        setPaymentCardLoading(false);
      });
  };
  const getThresholdData = () => {
    setThresholdLoading(true);
    getThreshold()
      .then((response) => {
        const data = response.data.data;
        setThresholdData(data);
        dispatch(setTopLoader(false));
        setThresholdLoading(false);
        if (data.precentage >= 100) {
          setWithdrawalBtnDisabled(false);
        }
      })
      .catch((error) => {
        dispatch(setTopLoader(false));
        setThresholdLoading(false);
      });
  };
  const handleDeleteCardConfirmDialog = () => {
    dispatch(setTopLoader(true));
    deleteCard()
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          setPaymentCard({});
        }
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        showError(error.response.data.message);
        dispatch(setTopLoader(false));
      });
    setDeleteCardConfirmDialog(false);
  };
  const handleDeleteBankConfirmDialog = () => {
    dispatch(setTopLoader(true));
    deleteBankAcc()
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          setWithdrawalMethod({});
        }
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        showError(error.response.data.message);
        dispatch(setTopLoader(false));
      });
    setDeleteBankConfirmDialog(false);
  };

  const handleCancelDialog = () => {
    setDeleteCardConfirmDialog(false);
    setVerifyBankAccDialog(false);
    setDeleteBankConfirmDialog(false);
  };
  const handleDeleteCard = () => {
    setDeleteCardConfirmDialog(true);
  };
  const handleDeleteBankAcc = () => {
    setDeleteBankConfirmDialog(true);
  };
  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };
  const handleWithdrawClick = () => {
    setWithdrawBalanceSts(true)
  };
  const setColorForWithdrawalMethodStatusChip = (status) => {
    switch (status) {
      case "new":
        return "warning";
        break;
      case "validated":
        return "success";
        break;
      case "verified":
        return "success";
        break;
      default:
        return "error";
        break;
    }
  };
  const handleVerifyBankAccDialog = () => {
    dispatch(setTopLoader(true));

    verifyBankAccount()
      .then((response) => {
        if (response.data.success === true) {
          showSuccess("Verification status updated to" + response.data.data.status);
          dispatch(setAddBankState(!addBankState));
        }
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        showError(error.response.data.message);
        dispatch(setTopLoader(false));
      });
    setVerifyBankAccDialog(false);
  };
  return (
    <React.Fragment>
      <Container sx={{ mb: 4 }}>
        {!addCardSts && !addWithdrawalMethodSts && !withdrawBalanceSts && (
          <React.Fragment>
            {!paymentCardLoading && (withdrawalMethod.status === "new" || withdrawalMethod.status === "validated") && (
              <PageToolbar
                color="warning"
                alert={
                  <Box>
                    <Typography>Your bank account not verified yet</Typography>
                    <Button onClick={() => setVerifyBankAccDialog(true)}>Verify Now</Button>
                  </Box>
                }
                severity="warning"
                close={true}
              />
            )}
            <EarningsBar />
            <Grid container spacing={4}>
              {(role === "creator" || role === "super_admin") && (
                <Grid item sm={6}>
                  <Card variant="outlined" sx={{ p: 4 }}>
                    <Typography variant="h5" sx={{ fontWeight: "500" }}>
                      Payment Card
                    </Typography>
                    {!paymentCardLoading ? (
                      <Box sx={{ mt: 2 }}>
                        {paymentCard && Object.keys(paymentCard).length !== 0 ? (
                          <Grid container spacing={2}>
                            <Grid item sm={4}>
                              <Paper elevation={0} sx={{ p: 2, textAlign: "center", backgroundColor: "text.secondary", color: "white" }} variant="outlined">
                                <PaymentOutlinedIcon sx={{ fontSize: "3rem" }} />
                              </Paper>
                            </Grid>
                            <Grid item sm={8}>
                              <Grid container spacing={4}>
                                <Grid item sm={2}>
                                  {paymentCard.brand.toLowerCase() === "visa" ? <img src="/img/visa_icon.svg" alt="" width="50px" style={{ backgroundColor: "#80808033" }} /> : <img src="/img/mastercard.svg" alt="" width="50px" style={{ backgroundColor: "#80808033" }} />}
                                </Grid>
                                <Grid item sm={10}>
                                  <Box>
                                    <strong>
                                      {paymentCard.brand} •••• {paymentCard.last4}
                                    </strong>
                                    <Chip label="Default" size="small" color="info" sx={{ ml: 2 }} />
                                  </Box>
                                  <Box color="text.secondary">
                                    Expires {moment(paymentCard.exp_month, "MM").format("MMM")} {paymentCard.exp_year}{" "}
                                    <Tooltip title="Edit">
                                      <IconButton aria-label="edit" onClick={() => setAddCardSts(true)}>
                                        <EditOutlinedIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                      <IconButton aria-label="edit" onClick={() => handleDeleteCard()}>
                                        <DeleteOutlineOutlinedIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid container spacing={2}>
                            <Grid item sm={4}>
                              <Paper elevation={0} sx={{ p: 2, textAlign: "center", backgroundColor: "text.secondary", color: "white" }} variant="outlined">
                                <AddCardOutlinedIcon sx={{ fontSize: "3rem" }} />
                              </Paper>
                            </Grid>
                            <Grid item sm={8}>
                              <Button variant="text" startIcon={<AddOutlinedIcon />} onClick={() => setAddCardSts(true)}>
                                Add Card
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </Box>
                    ) : (
                      <Box sx={{ textAling: "center", mt: 2 }}>
                        <CircularProgress size={24} />
                      </Box>
                    )}
                  </Card>
                  <TransactionHistory />
                </Grid>
              )}
              {(role === "vendor" || role === "super_admin") && (
                <React.Fragment>
                  <Grid item sm={6}>
                    <Card variant="outlined" sx={{ p: 4 }}>
                      <Typography variant="h5" sx={{ fontWeight: "500" }}>
                        How you get paid
                      </Typography>
                      {!paymentCardLoading ? (
                        <Box sx={{ mt: 2 }}>
                          {withdrawalMethod && Object.keys(withdrawalMethod).length !== 0 ? (
                            <Grid container spacing={2}>
                              <Grid item sm={4}>
                                <Paper elevation={0} sx={{ p: 2, textAlign: "center", backgroundColor: "text.secondary", color: "white" }} variant="outlined">
                                  <AccountBalanceOutlinedIcon sx={{ fontSize: "3rem" }} />
                                </Paper>
                              </Grid>
                              <Grid item sm={8}>
                                <Box>{withdrawalMethod.account_holder_name.toUpperCase()}</Box>
                                <Grid container spacing={4}>
                                  <Grid item sm={10}>
                                    <Box>
                                      <strong>ACCOUNT •••• {withdrawalMethod.last4} </strong>
                                      <Chip label="Default" size="small" color="info" sx={{ ml: 2 }} />
                                    </Box>
                                    <Box color="text.secondary">
                                      country: {withdrawalMethod.country} currency: {withdrawalMethod.currency}
                                      <Tooltip title="Edit">
                                        <IconButton aria-label="edit" onClick={() => setAddWithdrawalMethodSts(true)}>
                                          <EditOutlinedIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Delete">
                                        <IconButton aria-label="edit" onClick={() => handleDeleteBankAcc()}>
                                          <DeleteOutlineOutlinedIcon fontSize="small" />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                    <Box>
                                      <Chip label={withdrawalMethod.status === "new" ? "not verified" : withdrawalMethod.status} size="small" variant="contained" color={setColorForWithdrawalMethodStatusChip(withdrawalMethod.status)} sx={{ ml: 2 }} />
                                      {!paymentCardLoading && (withdrawalMethod.status === "new" || withdrawalMethod.status === "validated") && (
                                        <Button size="small" variant="outlined" color="success" sx={{ ml: 2 }} onClick={() => setVerifyBankAccDialog(true)}>
                                          Verify Now
                                        </Button>
                                      )}
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid container spacing={2}>
                              <Grid item sm={4}>
                                <Paper elevation={0} sx={{ p: 2, textAlign: "center", backgroundColor: "text.secondary", color: "white" }} variant="outlined">
                                  <AccountBalanceOutlinedIcon sx={{ fontSize: "3rem" }} />
                                </Paper>
                              </Grid>
                              <Grid item sm={8}>
                                <Button variant="text" startIcon={<AddOutlinedIcon />} onClick={() => setAddWithdrawalMethodSts(true)}>
                                  Add Withdrawal Method
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        </Box>
                      ) : (
                        <Box sx={{ textAling: "center", mt: 2 }}>
                          <CircularProgress size={24} />
                        </Box>
                      )}
                    </Card>
                    {role === "vendor" && <VendorTransactionHistory />}
                  </Grid>
                  <Grid item sm={6}>
                    {role === "vendor" && (
                      <Card variant="outlined" sx={{ p: 4 }}>
                        <Typography variant="h5" sx={{ fontWeight: "500" }}>
                          Your payout threshold
                        </Typography>
                        {!thresholdLoading ? (
                          <React.Fragment>
                            <Typography variant="caption" sx={{ color: "text.secondary" }}>
                              You can withdraw if the total is at least ${thresholdData.threshold.toFixed(2)} (your payout threshold)
                            </Typography>
                            <Typography variant="h6" align="right">
                              ${availableBalance}
                            </Typography>
                            <Box sx={{ mt: 2 }}>
                              <Grid container spacing={2}>
                                <Grid item sm={12}>
                                  <Box sx={{ width: "100%" }}>
                                    <LinearProgress variant="determinate" value={thresholdData.precentage} sx={{ height: 20 }} />
                                  </Box>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item sm={7} sx={{ textAlign: "left" }}>
                                  <Typography align="left" variant="caption" sx={{ color: "text.secondary" }}>
                                    You've reached {thresholdData.precentage}% of your payment threshold
                                  </Typography>
                                </Grid>
                                <Grid item sm={5} sx={{ textAlign: "right" }}>
                                  <Typography align="right" variant="caption" sx={{ color: "text.secondary" }}>
                                    Payment threshold: ${thresholdData.threshold.toFixed(2)}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                                <LoadingButtons loading={withdrawalBtnLoading} variant="contained" type="button" name="Withdraw Balance" color="primary" onClick={handleWithdrawClick} disabled={withdrawalBtnDisabled} marginTop={2} />
                              </Box>
                            </Box>
                          </React.Fragment>
                        ) : (
                          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: 2 }}>
                            <CircularProgress size={24} />
                          </Box>
                        )}
                      </Card>
                    )}
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          </React.Fragment>
        )}
        {addCardSts && (
          <Box sx={{ mt: 4 }}>
            <Button variant="text" startIcon={<ArrowBackIcon />} sx={{ mb: 2 }} onClick={() => setAddCardSts(false)}>
              {" "}
              Back To Payments{" "}
            </Button>
            <Alert severity="success" sx={{ mb: 4 }}>
              Save your card and pay instantly.
            </Alert>
            <AddCard />
          </Box>
        )}
        {addWithdrawalMethodSts && (
          <Box sx={{ mt: 4 }}>
            <Button variant="text" startIcon={<ArrowBackIcon />} sx={{ mb: 2 }} onClick={() => setAddWithdrawalMethodSts(false)}>
              {" "}
              Back To Payments{" "}
            </Button>
            <Alert severity="success" sx={{ mb: 4 }}>
              Add your bank account or debit card for receiving earnings.
            </Alert>
            <AddWithdrawalMethod />
          </Box>
        )}
        {withdrawBalanceSts && (
          <Box sx={{ mt: 4 }}>
            <Button variant="text" startIcon={<ArrowBackIcon />} sx={{ mb: 2 }} onClick={() => setWithdrawBalanceSts(false)}>
              {" "}
              Back To Payments{" "}
            </Button>
            <WithdrawBalance withdrawalMethod={withdrawalMethod} threshold={thresholdData.threshold}/>
          </Box>
        )}
        <ConfirmDialog onClickConfirm={handleDeleteCardConfirmDialog} onClickCancel={handleCancelDialog} open={deleteCardConfirmDialog} header="Are You Sure?" message="Please confirm to delete this card." />
        <ConfirmDialog onClickConfirm={handleDeleteBankConfirmDialog} onClickCancel={handleCancelDialog} open={deleteBankConfirmDialog} header="Are You Sure?" message="Please confirm to delete this bank account." />
        <ConfirmDialog onClickConfirm={handleVerifyBankAccDialog} onClickCancel={handleCancelDialog} open={verifyBankAccDialog} header="Verify bank account" message="ImageMall sent two small deposits to this bank account. To verify this account, please confirm the amounts of these deposits." />
        
      </Container>
    </React.Fragment>
  );
}
