import { requestGet, requestPost } from "./RequestService"

const prefix = '/image-type'

const store = async (data) => {
    return await requestPost(prefix+'/store', data)
}
const get = async () => {
    return await requestGet(prefix+'/get')
}

export { store, get }