import { CardContent, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setTopLoader } from "../../../features/mainSlice/mainSlice";
import { getRoles } from "../../../services/PermissionService";
import { storeUser } from "../../../services/UsersService";
import AvatarUpload from "../../common/AvatarUpload/AvatarUpload";
import CardComponent from "../../common/CardComponent/CardComponent";
import LoadingButtons from "../../common/LoadingButton/LoadingButtonComponent";
import SelectBox from "../../common/SelectBoxCompnent/SelectBoxComponent";
import TextFieldComponent from "../../common/TextFieldComponent/TextFieldComponent";
import PageContainer from "../PageContainer/PageContainer";
import { useSnackbar } from "notistack";
import { snackBarPosition } from "../../../services/CommonService";
import { countryList } from "../../../services/CommonService";
const CreateUser = () => {
  // component states
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [roles, setRoles] = useState([]);
  const [preview, setPreview] = useState(undefined);
  const [profileImage, setProfileImage] = useState(undefined);

  const role = useSelector((state) => state.common.role);
  const dispatch = useDispatch();
  // form
  const { handleSubmit, control, reset, formState, watch, setValue } = useForm({
    mode: "onChange",
  });
  // snackbar
  const { enqueueSnackbar } = useSnackbar();
  const password = useRef({});
  password.current = watch("password", "");

  const statuses = [
    { name: "Active", value: 1 },
    { name: "Deactivate", value: 0 },
  ];

  // use effect
  useEffect(() => {
    if (role === "super_admin") {
      dispatch(setTopLoader(true));
      getRoles()
        .then((response) => {
          let roles = [];
          roles = [...roles];
          response.data.data.map((item) => {
            return roles.push({ name: item.name, value: item.id });
          });
          setRoles(roles);
          dispatch(setTopLoader(false));
        })
        .catch((error) => {
          console.log(error);
          dispatch(setTopLoader(false));
          // console.log("error", response.response);
        });
    }
  }, []);

  const onSubmit = (data) => {
    dispatch(setTopLoader(true));
    setBtnLoading(true);
    if (profileImage != undefined) {
      data["image"] = profileImage;
    }

    storeUser(data)
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          dispatch(setTopLoader(false));
          setBtnLoading(false);
          setBtnDisabled(false);
        }
        reset();
        setPreview(undefined);
        setProfileImage(undefined);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 422) {
          showError(error.response.data.data[0]);
        } else {
          showError(error.response.data.message);
        }
        dispatch(setTopLoader(false));
        setBtnLoading(false);
        setBtnDisabled(false);
        // console.log("error", response.response);
      });
    setBtnLoading(false);
    setBtnDisabled(false);
  };
  const handleFileUpload = (e) => {
    dispatch(setTopLoader(true));
    if (!e.target.files || e.target.files.length === 0) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
    setProfileImage(e.target.files[0]);
    dispatch(setTopLoader(false));
  };
  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };
  return (
    <PageContainer>
      <Grid container spacing={4}>
        <Grid item sm={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item sm={3}>
                <CardComponent>
                  <CardContent align="center">
                    <AvatarUpload
                      onChange={handleFileUpload}
                      image={
                        preview === undefined ? "/img/avatar.png" : preview
                      }
                      loading={false}
                    />
                  </CardContent>
                </CardComponent>
              </Grid>
              <Grid item sm={9}>
                <CardComponent>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item sm={6}>
                        {role === "super_admin" && (
                          <Controller
                            name="role_id"
                            control={control}
                            defaultValue={""}
                            render={({
                              field: { onChange, value = "" },
                              fieldState: { error },
                            }) => {
                              return (
                                <SelectBox
                                  id={"role_id"}
                                  label="Select Role*"
                                  items={roles}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  onChange={onChange}
                                  defaultValue={value}
                                  rules={{ required: "Role is required" }}
                                />
                              );
                            }}
                          />
                        )}
                        {role !== "super_admin" && (
                          <Typography sx={{ visibility: "hidden" }}>
                            *
                          </Typography>
                        )}
                        <Controller
                          name="name"
                          control={control}
                          defaultValue=""
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => {
                            return (
                              <TextFieldComponent
                                type={"text"}
                                id="name"
                                label="Name*"
                                autoFocus={true}
                                error={!!error}
                                helperText={error ? error.message : null}
                                onChange={onChange}
                                value={value}
                                marginTop={0}
                              />
                            );
                          }}
                          rules={{ required: "Name is required" }}
                        />
                        <Controller
                          name="email"
                          control={control}
                          defaultValue=""
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => {
                            return (
                              <TextFieldComponent
                                type={"email"}
                                id="email"
                                label="Email*"
                                error={!!error}
                                helperText={error ? error.message : null}
                                onChange={onChange}
                                value={value}
                              />
                            );
                          }}
                          rules={{
                            required: "Email is required",
                            pattern: {
                              value: /^\S+@\S+$/i,
                              message: "Invalid email address",
                            },
                          }}
                        />
                        <Controller
                          name="country"
                          control={control}
                          defaultValue={""}
                          render={({
                            field: { onChange, value = "" },
                            fieldState: { error },
                          }) => {
                            return (
                              <SelectBox
                                id={"country"}
                                label="Select Country"
                                items={countryList}
                                error={!!error}
                                helperText={error ? error.message : null}
                                onChange={onChange}
                                defaultValue={value}
                              />
                            );
                          }}
                        />
                        <Controller
                          name="mobile"
                          control={control}
                          defaultValue=""
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => {
                            return (
                              <TextFieldComponent
                                type={"mobile"}
                                id="mobile"
                                label="Mobile"
                                error={!!error}
                                helperText={error ? error.message : null}
                                onChange={onChange}
                                value={value}
                                marginTop={0}
                              />
                            );
                          }}
                        />
                      </Grid>
                      <Grid item sm={6}>
                        <Controller
                          name="password"
                          control={control}
                          defaultValue=""
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => {
                            return (
                              <TextFieldComponent
                                type={"password"}
                                id="password"
                                label="Password*"
                                error={!!error}
                                helperText={error ? error.message : null}
                                onChange={onChange}
                                value={value}
                              />
                            );
                          }}
                          rules={{
                            required: "Password is required",
                            minLength: {
                              value: 6,
                              message: "Password is too short min:6",
                            },
                            maxLength: {
                              value: 14,
                              message: "Password is too long max:14",
                            },
                          }}
                        />
                        <Controller
                          name="confirm_password"
                          control={control}
                          defaultValue=""
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => {
                            return (
                              <TextFieldComponent
                                type={"password"}
                                id="confirm_password"
                                label="Confirm Password*"
                                error={!!error}
                                helperText={error ? error.message : null}
                                onChange={onChange}
                                value={value}
                              />
                            );
                          }}
                          rules={{
                            required: "Password is required",
                            minLength: {
                              value: 6,
                              message: "Password is too short min:6",
                            },
                            maxLength: {
                              value: 14,
                              message: "Password is too long max:14",
                            },
                            validate: (value) =>
                              value === password.current ||
                              "The passwords do not match",
                          }}
                        />
                        <Controller
                          name="status"
                          control={control}
                          defaultValue={""}
                          render={({
                            field: { onChange, value = "" },
                            fieldState: { error },
                          }) => {
                            return (
                              <SelectBox
                                id={"status"}
                                label="Select Status*"
                                items={statuses}
                                error={!!error}
                                helperText={error ? error.message : null}
                                rules={{ required: "Status is required" }}
                                onChange={onChange}
                                defaultValue={value}
                              />
                            );
                          }}
                        />
                        <Controller
                          name="description"
                          control={control}
                          defaultValue=""
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => {
                            return (
                              <TextField
                                id="description"
                                label="Describe About User"
                                multiline
                                rows={5}
                                onChange={onChange}
                                value={value}
                                fullWidth
                              />
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Typography align="right">
                      <LoadingButtons
                        loading={btnLoading}
                        name="Save"
                        variant="contained"
                        type="submit"
                        disabled={!formState.isValid}
                      />
                    </Typography>
                  </CardContent>
                </CardComponent>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default CreateUser;
