import React from "react";
import { Button, Chip, Divider, Grid, Typography, CardContent, Card, Paper, Stack, Skeleton, Alert, AlertTitle, Rating, IconButton, Tooltip } from "@mui/material";
import { CloseOutlined, EditOutlined } from "@mui/icons-material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Box } from "@mui/system";
import TrackOrder from "../../TrackOrder/TrackOrder";
import Timer from "../../Timer/Timer";
import ImageShower from "../../../common/ImageList/ImageList";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import { useDispatch, useSelector } from "react-redux";
import JobAccept from "../../JobAccept/JobAccept";
import { useEffect } from "react";
import { useState } from "react";
import { cancel, deliver, getByJobId, vCancel, zipCompress } from "../../../../services/JobService";
import DataDialog from "../../../common/DataDialog/DataDialog";
import ConfirmDialog from "../../../common/ConfirmDialog/ConfirmDialog";
import { useSnackbar } from "notistack";
import { setJobEffect } from "../../../../features/jobSlice/jobSlice";
import { setSnackbarLoading } from "../../../../features/commonSlice/commonSlice";
import { baseURL, baseWebURL } from "../../../../services/RequestService";
import LoadingButtons from "../../../common/LoadingButton/LoadingButtonComponent";
import JobComplete from "../../JobComplete/JobComplete";
import MenuOptions from "../../../common/MenuOptions/MenuOptions";
import StarIcon from "@mui/icons-material/Star";
import AssignJob from "../../AssignJob/AssignJob";
import { ArchiveOutlined } from "@mui/icons-material";
import PageToolbar from "../../PageToolbar/PageToolbar";
import CloseIcon from "@mui/icons-material/Close";
import ShareBar from "../../../common/ShareBar/ShareBar";
import DropZone from "../../../common/DropZone/DropZone";
import { snackBarPosition } from "../../../../services/CommonService";
const ViewJob = () => {
  //redux
  const jobStatus = useSelector((state) => state.job.jobStatus);
  const jobEffect = useSelector((state) => state.job.jobEffect);
  const actions = useSelector((state) => state.common.actions);
  const isUploadComplete = useSelector((state) => state.dropzone.isUploadComplete);
  const fileNames = useSelector((state) => state.dropzone.fileNames);

  // const [status, setStatus] = useState(!loading ? jobData.status : "ongoing");
  // const [delivery_status, setDeliveryStatus] = useState(!loading ? jobData.delivery_status : "late");
  // const paymentStatus = jobData.paymentStatus ? jobData.paymentStatus.paymentStatus : "not_paid";

  const [jobData, setJobData] = useState({});
  const [paymentStatus, setPaymentStatus] = useState("");
  const [status, setStatus] = useState("");
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const [loading, setLoading] = useState(true);

  const [due_time, setDueTime] = useState(undefined);
  const [dataDialogOpen, setDataDialogOpen] = useState(false);
  const [vCancelConfirmDialog, setVCancelConfirmDialog] = useState(false);
  const [cancelConfirmDialog, setCancelConfirmDialog] = useState(false);
  const [deliverBtnLoading, setDeliverBtnLoading] = useState(false);
  const [zipDownloadMenuOpen, setZipDownloadMenuOpen] = useState(false);
  const [avgCreatorRate, setCreatorAvgRate] = useState(0.0);
  const [avgClientRate, setClientAvgRate] = useState(0.0);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const job_id = searchParams.get("job_id");
  const statusColor = {
    not_assigned: "warning",
    assigned: "primary",
    on_working: "secondary",
    delivered: "info",
    canceled: "error",
    completed: "success",
    ongoing: "primary",
  };

  const dispatch = useDispatch();

  const menuItems = [
    { name: "work files", value: true, icon: <ArchiveOutlined fontSize="small" sx={{ mr: 2 }} /> },
    { name: "completed files", value: false, icon: <ArchiveOutlined fontSize="small" sx={{ mr: 2 }} /> },
  ];


  useEffect(() => {
    getJob(job_id);
  }, [jobEffect]);

  const getJob = (job_id) => {
    setLoading(true);
    setJobData({});
    getByJobId({ id: job_id })
      .then((response) => {
        const data = response.data.data;
        setJobData(data);
        setPaymentStatus(data.payment_status ? data.payment_status.payment_status : '');
        setStatus(data.status);
        setDeliveryStatus(data.delivery_status);
        setCreatorAvgRate(data.avg_rate);
        setClientAvgRate(data.avg_rate_client);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error)
        // showError(error.response.data.message);
      });
  };

  const handleDeliverNowClick = () => {
    setDataDialogOpen(true);
  };
  const handleCancelDataDialog = () => {
    setDataDialogOpen(false);
  };
  // Cancel Ongoing Job
  const handleCancelClick = () => {
    setCancelConfirmDialog(true);
  };
  const handleCancelConfirmDialog = () => {
    cancel({ job_id: job_id })
      .then((response) => {
        if (response.data.success === true) {
          setCancelConfirmDialog(false)
          showSuccess(response.data.message);
          dispatch(setJobEffect(!jobEffect));
        } else {
          showError(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        showError(error.response.data.message);
        setCancelConfirmDialog(false);
      });
  };
  const handleCancelCancelDialog = () => {
    setCancelConfirmDialog(false);
  };
  const handleDownloadZip = (working) => {
    dispatch(setSnackbarLoading(true));
    setZipDownloadMenuOpen(false);
    zipCompress({ job_id: job_id, working: working })
      .then((response) => {
        const filename = response.data.data.filename;
        const working = response.data.data.working;
        window.location.href = baseWebURL + "zip/download?job_id=" + job_id + "&working=" + working + "&filename=" + filename;
        dispatch(setSnackbarLoading(false));
      })
      .catch((response) => {
        console.log("error", response);
        dispatch(setSnackbarLoading(false));
      });
  };
  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };
  const handleDeliver = () => {
    setDeliverBtnLoading(true);
    deliver({ filenames: fileNames, job_id: job_id })
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          setDataDialogOpen(false);
          dispatch(setJobEffect(!jobEffect));
        } else {
          showError(response.data.message);
        }
        setDeliverBtnLoading(false);
      })
      .catch((error) => {
        console.log(error);
        showError(error.response.data.message);
        setDeliverBtnLoading(false);
      });
  };
  const handleVCancelConfirmDialog = () => {
    vCancel({
      job_id: job_id,
      c: jobData.vendor_contractor.contractor.id,
    })
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          dispatch(setJobEffect(!jobEffect));
        } else {
          showError(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        showError(error.response.data.message);
        setCancelConfirmDialog(false);
      });
    setVCancelConfirmDialog(false);
  };
  const handleVendorCancelClick = () => {
    setVCancelConfirmDialog(true);
  };
  const handleCancelDialog = () => {
    setVCancelConfirmDialog(false);
  };
  const goBack = () => {
    navigate(-1);
  };
  return (
    <Grid container spacing={2}>
      <Grid item sm={8}>
        {actions.includes("paying") && (paymentStatus === "not_paid" || paymentStatus === "failed" || paymentStatus === "rejected" || paymentStatus === "canceled") && (
          <PageToolbar
            alert={
              <Typography variant="body2">
                When payment is complete, job will automatically publish and assign to vendor you selected <br /> <strong>Still not paid for the job? </strong>
                <Link to={"/partners/pay?payment_id=" + jobData.payment_id} style={{ color: "inherit" }}>
                  Pay
                </Link>
              </Typography>
            }
            color="warning"
            close={true}
            severity="warning"
          />
        )}

        {status === "delivered" && <PageToolbar alert={<Typography variant="body2">You must accept the job for the download original files</Typography>} color="warning" close={true} severity="warning" />}
        <Typography variant="h5" color="text.secondary" gutterBottom sx={{ mt: 2 }}>
          {!loading ? "#" + jobData.job_id : <Skeleton animation="wave" />}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {!loading ? (
            jobData.description
          ) : (
            <React.Fragment>
              <Skeleton animation="wave" /> <Skeleton animation="wave" />
            </React.Fragment>
          )}
        </Typography>
        <Divider />
        {!loading ? (
          <Paper variant="outlined" sx={{ p: 2, mt: 1 }}>
            <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={1}>
              <Typography variant="body2">Raw Files : {jobData.work_files_count} </Typography>
              <Typography variant="body2">Output Files : {jobData.total_output_files} </Typography>
              {jobData.role !== "creator_contractor" && <Typography variant="body2">Delivered Files : {jobData.completed_files_count} </Typography>}
              <Typography variant="body2">Files Size : {jobData.file_size} </Typography>
              {status === "completed" && jobData.role !== "creator_contractor" ? (
                <MenuOptions menuItems={menuItems} label="Download Zip" optionClick={handleDownloadZip} menuOpen={zipDownloadMenuOpen} />
              ) : (
                <React.Fragment>
                  <Button onClick={() => handleDownloadZip(true)} size="small" endIcon={<ArchiveOutlinedIcon />}>
                    Download Work Files Zip
                  </Button>
                </React.Fragment>
              )}
            </Stack>
          </Paper>
        ) : (
          <Skeleton animation="wave" height={100} />
        )}
        {(status === "delivered" || status === "completed") && jobData.role !== "creator_contractor" ? (
          <React.Fragment>
            <Divider sx={{ mt: 3 }}>
              <Chip label="Delivered files thumbnails." color="success" />
            </Divider>
            <ImageShower download loading={loading} images={jobData.completed_file_names} jobId={jobData.job_id} path={"storage/completed-files/"} cols={4} />
          </React.Fragment>
        ) : (
          <div></div>
        )}
        <Divider sx={{ mt: 3 }}>
          <Chip label="Working files thumbnails." />
        </Divider>
        <ImageShower download loading={loading} images={jobData.work_file_names} jobId={jobData.job_id} path={"storage/job-files/"} cols={4} descriptions={jobData.image_descriptions} client={jobData.client}/>
      </Grid>
      <Grid item sm={4}>
        <Box sx={{ textAlign: "right" }}>
          <Tooltip title="Close">
            <IconButton onClick={() => goBack()} sx={{ mb: 1, mt: 1 }} color="default">
              <CloseIcon sx={{ fontSize: 25 }} />
            </IconButton>
          </Tooltip>
          {status === "completed" && (
            <React.Fragment>
              <Divider sx={{ mb: 1 }} />
              <ShareBar url={baseURL + "sample?sample_id=" + jobData.job_id} />
              <Divider sx={{ mt: 1 }} />
            </React.Fragment>
          )}
        </Box>
        {!loading ? (
          <React.Fragment>
            {/* {actions.includes("store_job") && (
              <Typography align="right">
                <Button onClick={handleEditJobClick} sx={{ mb: 3 }}>
                  <EditOutlined />
                  &nbsp; Edit Job
                </Button>
              </Typography>
            )} */}
            <Card>
              <CardContent>
                {status === "assigned" && jobData.role === "vendor" && <JobAccept job_id={jobData.job_id} />}
                <Grid container sx={{ mb: 2 }}>
                  <Grid item sm={6}>
                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                      Job Details
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Box sx={{ textAlign: "right" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Rating name="text-feedback" value={avgCreatorRate} readOnly precision={0.5} emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />
                        <Box sx={{ ml: 2 }}>
                          <Chip color="warning" label={avgCreatorRate} size="small" />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Paper variant="outlined" sx={{ padding: 1 }}>
                  <Grid container direction="row">
                    <Grid item xs={4}>
                      <Typography variant="body2" color="text.secondary" sx={{ paddingBottom: 1.2 }}>
                        Name
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body2" align="right" color="text.secondary" sx={{ paddingBottom: 1.2 }}>
                        {jobData.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item xs={4}>
                      <Typography variant="body2" color="text.secondary" sx={{ paddingBottom: 1.2 }}>
                        Type
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body2" align="right" color="text.secondary" sx={{ paddingBottom: 1.2 }}>
                        {jobData.client === 1 ? 'Client Job' : jobData.job_type.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item xs={4}>
                      <Typography variant="body2" color="text.secondary" sx={{ paddingBottom: 1.2 }}>
                        Duration
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body2" align="right" color="text.secondary" sx={{ paddingBottom: 1.2 }}>
                        {jobData.duration} Hours
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item xs={4}>
                      <Typography variant="body2" color="text.secondary" sx={{ paddingBottom: 1.2 }}>
                        Assign to
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body2" align="right" color="text.success" sx={{ paddingBottom: 1.2 }}>
                        <Link to="" style={{ color: "inherit" }}>
                          {" "}
                          {jobData.assigned_vendor ? jobData.assigned_vendor.name : ""}{" "}
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row">
                    <Grid item xs={4}>
                      <Typography variant="body2" color="text.secondary" sx={{ paddingBottom: 1.2 }}>
                        Job ID
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body2" align="right" color="text.secondary" sx={{ paddingBottom: 1.2 }}>
                        #{jobData.job_id}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" sx={{ marginBottom: 2 }}>
                    <Grid item xs={4}>
                      <Typography variant="body2" color="text.secondary" sx={{ paddingBottom: 1.2 }}>
                        Status
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Box sx={{ paddingBottom: 0.5, textAlign: "right" }}>
                        <Chip
                          label={status?.replace("_", " ").toUpperCase()} //{st.replace('_',' ').toUpperCase()}
                          size="small"
                          variant="filled"
                          color={status !== undefined ? statusColor[status] : "primary"}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider />
                  <TrackOrder status={status} />
                </Paper>
                {status === "delivered" || status === "completed" ? (
                  <Paper variant="outlined" sx={{ padding: 2, mt: 1 }}>
                    <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                      Job delivered
                    </Typography>
                    <Grid container direction="row">
                      <Grid item xs={7}>
                        <Typography variant="body2" color="text.secondary" sx={{ paddingBottom: 1.2 }}>
                          Deliver Status
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Box sx={{ paddingBottom: 0.5, textAlign: "right" }}>
                          <Chip
                            label={deliveryStatus?.replace("_", " ").toUpperCase()} //{st.replace('_',' ').toUpperCase()}
                            size="small"
                            variant="filled"
                            color={deliveryStatus === "on_time" ? "success" : "error"}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    {(jobData.role === "vendor" || jobData.role === "vendor_contractor") && status === "delivered" && (
                      <Alert severity="info" sx={{ mb: 1 }}>
                        <AlertTitle>Reviewing...</AlertTitle>
                        Your job is now being reviewed.
                      </Alert>
                    )}
                    {jobData.role !== "vendor" && jobData.role !== "vendor_contractor" && status === "delivered" && jobData.role !== "creator_contractor" && (
                      <Alert severity="warning" sx={{ mb: 1 }}>
                        <AlertTitle>Waiting...</AlertTitle>
                        {jobData.assigned_vendor.name} is waiting for your response. And don't forget to rate them.
                      </Alert>
                    )}
                    {jobData.role !== "vendor" && jobData.role !== "vendor_contractor" && status === "delivered" && jobData.role !== "creator_contractor" && (
                      <React.Fragment>
                        <JobComplete job_id={jobData.job_id} />
                      </React.Fragment>
                    )}
                    {(jobData.role === "vendor" || jobData.role === "vendor_contractor") && status === "completed" && (
                      <React.Fragment>
                        <Alert severity="success" sx={{ mb: 1 }}>
                          <AlertTitle>Completed</AlertTitle>
                          Your job marked as completed.
                        </Alert>
                        {jobData.rate_and_reviews?.map((item, index) => {
                          return (
                            <React.Fragment key={'review_'+index}>
                              <Divider sx={{ mt: 1, mb: 1 }} />
                              <Typography component="legend">{item.points} Rating</Typography>
                              <Rating name="text-feedback" value={item.points} readOnly precision={0.5} emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />
                              <Typography>{item.review}</Typography>
                              {index === 0 && (
                                <React.Fragment>
                                  <Divider sx={{ mt: 1, mb: 1 }} />
                                  <Paper sx={{ textAlign: "right", mt: 2, mb: 2, p: 2, pl: 1.2 }}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      Client Reviews <Rating name="text-feedback" value={avgClientRate} readOnly precision={0.5} emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />
                                      <Box sx={{ ml: 2 }}>
                                        <Chip color="warning" label={avgClientRate} size="small" />
                                      </Box>
                                    </Box>
                                  </Paper>
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </React.Fragment>
                    )}
                    {jobData.role !== "vendor" && status === "completed" && (
                      <React.Fragment>
                        <Alert severity="success" sx={{ mb: 1 }}>
                          <AlertTitle>Completed</AlertTitle>
                          You marked this job as completed.
                        </Alert>
                        {jobData.rate_and_reviews?.map((item, index) => {
                          return (
                            <React.Fragment key={'review_'+index}>
                              <Divider sx={{ mt: 1, mb: 1 }} />
                              <Typography component="legend">{item.points} Rating</Typography>
                              <Rating name="text-feedback" value={item.points} readOnly precision={0.5} emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />
                              <Typography>{item.review}</Typography>
                              {index === 0 && (
                                <React.Fragment>
                                  <Divider sx={{ mt: 1, mb: 1 }} />
                                  <Paper sx={{ textAlign: "right", mt: 2, mb: 2, p: 2, pl: 1.2 }}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      Client Reviews <Rating name="text-feedback" value={avgClientRate} readOnly precision={0.5} emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />
                                      <Box sx={{ ml: 2 }}>
                                        <Chip color="warning" label={avgClientRate} size="small" />
                                      </Box>
                                    </Box>
                                  </Paper>
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          );
                        })}
                        <Divider sx={{ mt: 1, mb: 1 }} />
                      </React.Fragment>
                    )}
                  </Paper>
                ) : (
                  <Timer due_time={due_time !== undefined ? due_time : jobData.due_time} role={jobData.role} />
                )}
                {status === "on_working" && jobData.role === "vendor" && jobData.vendor_contractor === null && actions.includes("deliver_job") && (
                  <Button sx={{ marginTop: 2 }} color="success" fullWidth={true} variant="contained" size="large" onClick={handleDeliverNowClick}>
                    Deliver Now
                  </Button>
                )}
                {console.log(status, jobData.role, actions, actions.includes("deliver_job"))}
                {status === "on_working" && jobData.role === "vendor_contractor" && actions.includes("deliver_job") && (
                  <Button sx={{ marginTop: 2 }} color="success" fullWidth={true} variant="contained" size="large" onClick={handleDeliverNowClick}>
                    Deliver Now
                  </Button>
                )}
                {status === "on_working" && jobData.role === "vendor" && jobData.vendor_contractor === null && (
                  <React.Fragment>
                    <Divider sx={{ mt: 3 }}>
                      <Chip label="OR" />
                    </Divider>
                    <Typography variant="subtitle2" sx={{ mt: 2, mb: 2 }} color="text.secondary" gutterBottom>
                      You can assign to one of your contractor.
                    </Typography>
                    <AssignJob job_id={jobData.job_id} accept_and_assign={false} />
                  </React.Fragment>
                )}

                {jobData.vendor_contractor !== null && status === "on_working" && jobData.role === "vendor" && (
                  <React.Fragment>
                    <Alert sx={{ mt: 2 }}>
                      You assigned this job to contractor{" "}
                      <Link to="" style={{ color: "inherit" }}>
                        {jobData.vendor_contractor.contractor.name}
                      </Link>
                    </Alert>
                    <Button sx={{ marginTop: 2 }} color="error" fullWidth={true} variant="contained" size="large" onClick={handleVendorCancelClick}>
                      Cancel Now
                    </Button>
                    <ConfirmDialog onClickConfirm={handleVCancelConfirmDialog} onClickCancel={handleCancelDialog} open={vCancelConfirmDialog} header="Are You Sure?" message={"Please confirm to cancel this job from " + jobData.vendor_contractor.contractor.name} />
                  </React.Fragment>
                )}
                {status === "on_working" && actions.includes("cancel_job") && (
                  <Button sx={{ marginTop: 2 }} color="error" fullWidth={true} variant="contained" size="large" onClick={handleCancelClick}>
                    Cancel Now
                  </Button>
                )}
                <ConfirmDialog onClickConfirm={handleCancelConfirmDialog} onClickCancel={handleCancelCancelDialog} open={cancelConfirmDialog} header="Are You Sure?" message="Please confirm to cancel this job. this action cannot revert" />
              </CardContent>
            </Card>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Skeleton animation="wave" variant="rounded" height={350} />
            <Skeleton animation="wave" variant="rounded" height={150} sx={{ mt: 1 }} />
          </React.Fragment>
        )}

        <DataDialog onClickCancel={handleCancelDataDialog} open={dataDialogOpen} header="Deliver Now" loading={false}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="info">
              Expected file count <strong>{jobData.total_output_files}</strong> you must upload at least <strong>{jobData.total_output_files}</strong> files to deliver this job
            </Alert>
          </Stack>
          <Typography align="right">
            <LoadingButtons loading={deliverBtnLoading} name="Deliver" variant="contained" type="submit" disabled={!isUploadComplete || fileNames.length <= 0} onClick={handleDeliver} />
          </Typography>
          <DropZone prefix="jobs/completed-files" jobId={jobData.job_id} />
          <Typography align="right">
            <LoadingButtons loading={deliverBtnLoading} name="Deliver" variant="contained" type="submit" disabled={!isUploadComplete || fileNames.length <= 0} onClick={handleDeliver} />
          </Typography>
        </DataDialog>
      </Grid>
    </Grid>
  );
};

export default ViewJob;
