import React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { Typography } from '@mui/material';

const InlineChipsCarousel = ({ items }) => {
  const chipsListStyles = {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      width: '0px',
      background: 'transparent',
    },
  };

  const chipStyles = {
    margin: 0.2,
    whiteSpace: 'nowrap',
  };

  return (
    <Box sx={chipsListStyles}>
      {items?.map((item, index) => (
        <Chip key={index} label={<Typography variant="caption">
        {item.types ? item.types.name : item.name} <strong>${item.price.toFixed(2)}</strong>
      </Typography>} sx={chipStyles} size="small"/>
      ))}
    </Box>
  );
};

export default InlineChipsCarousel;
