import { Avatar, Divider, Grid, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { Box } from "@mui/system";
import React from "react";
const RateAndReviewView = (props) => {
  const { name, avatar, rate, review, country, published } = props;
  return (
    <React.Fragment>
    <Grid container spacing={2} sx={{p:1,mb:2, mt:1}}>
      <Grid item sm={1}>
        <Box >
        {avatar !== null && avatar !== undefined ? <Avatar src={avatar}/> : <Avatar>{name[0].toUpperCase()}</Avatar>}
        </Box>
      </Grid>
      <Grid item sm={11}>
        <Box sx={{ display: "flex" }}>
          <Typography variant="subtitle1">
            <strong>{name}</strong>
          </Typography>
          <StarIcon sx={{ color: "#faaf00", ml:2 }} />
          <Typography variant="subtitle1" sx={{ color: "#faaf00"}}>
            <strong>{rate}</strong>
          </Typography>
        </Box>
        <Typography variant="subtitle1" color="text.secondary">
          {country}
        </Typography>
        <Typography variant="subtitle2" sx={{mt:1}}>
          {review}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary" sx={{mt:1}}>
          Published {published}
        </Typography>
      </Grid>
    </Grid>
    <Divider />
    </React.Fragment>
  );
};

export default RateAndReviewView;
