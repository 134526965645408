import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ImageListLoading from "../ImageListLoader/ImageListLoader";
import { baseWebURL } from "../../../services/RequestService";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Backdrop, Button, Chip, CircularProgress, Divider, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { deleteCreatedJobFiles } from "../../../services/JobService";
import { Box } from "@mui/system";
import DataDialog from "../DataDialog/DataDialog";

export default function ImageShower(props) {
  const [loading, setLoading] = React.useState(true);
  const [images, setImages] = React.useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
  const [backdropOpen, setBackdropOpen] = React.useState(false);
  const [descriptions, setDescriptions] = React.useState("");
  const [imageDetailsData, setImageDetailsData] = React.useState("");
  const [imageDetailsDialog, setImageDetailsDialog] = React.useState(false);

  React.useEffect(() => {
    if (!props.loading) {
      setImages(JSON.parse(props.images));
      setLoading(false);
      
      props.client === 1 && setDescriptions(JSON.parse(props.descriptions));
    }
  }, [props]);
  const deleteImage = (filename, job_id) => {
    setBackdropOpen(true);
    deleteCreatedJobFiles({ filename, job_id })
      .then((response) => {
        if (response.data.success) {
          const index = getIndex(images, filename);
          let _images = [...images];
          _images.splice(index, 1);
          setImages(_images);
        }
        setBackdropOpen(false);
      })
      .catch((response) => {
        console.log("error", response);
        setBackdropOpen(false);
      });
  };
  const download = (filename, job_id) => {
    setBackdropOpen(true);
    const working = true;
    // window.open(baseWebURL+'file/download?job_id='+job_id+'&working='+working+'&filename='+filename, '_blank');
    window.location.href = baseWebURL + "file/download?job_id=" + job_id + "&working=" + working + "&filename=" + filename;
    setBackdropOpen(false);
    // fileDownload({ job_id , working, filename,}).then((response) => {
    //     window.open(response.data, '_blank');
    //     console.log(response)
    //     setBackdropOpen(false)
    // }).catch((response) => {
    //     console.log("error", response)
    //     setBackdropOpen(false)
    // })
  };
  // Get index of the array by id
  const getIndex = (array, value) => {
    const index = array.findIndex((item) => item.filename === value);
    if (index !== -1) {
      return index;
    }
    return false;
  };
  const imageDetails = (index) => {
    setImageDetailsData(
      <Box sx={{ mt: 1}}>
        <Grid container spacing={2}>
            <Grid item> <Typography variant="body1">Image type</Typography></Grid>
            <Grid item>
                <Chip label={descriptions[0]["selectBox_" + index].replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())} size="small" />
            </Grid>
        </Grid>
        <Divider sx={{mt:1, mb:1}} />
        <Grid container spacing={2}>
            <Grid item> <Typography variant="body1">Description</Typography></Grid>
            <Grid item>
            <Typography variant="body2">{descriptions[1]["description_" + index]}</Typography>
            </Grid>
        </Grid>
        
      </Box>
    );
    setImageDetailsDialog(true)
  };
  const handleImageDetailsDialogClose = () => {
    setImageDetailsDialog(false);
  };
  return (
    <React.Fragment>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ImageList cols={props.cols}>
        {!loading ? (
          <React.Fragment>
            {images.map((item, index) => (
              <React.Fragment key={item.filename + "_section"}>
                {item.filename && (
                  <ImageListItem key={item.filename + "_item"}>
                    <img
                      src={`${baseWebURL + props.path + props.jobId + "/thumbnails/" + item.filename.split(".")[0] + ".jpg"}?w=248&fit=crop&auto=format`}
                      srcSet={`${baseWebURL + props.path + props.jobId + "/thumbnails/" + item.filename.split(".")[0] + ".jpg"}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      alt={"no image found"}
                      loading="lazy"
                      key={item.filename}
                    />
                    <ImageListItemBar
                      key={item.filename + "_action"}
                      title={item.filename}
                      subtitle={
                        <React.Fragment>
                          <span>type: {item.filename.split(".")[1]}</span>
                          {props.client === 1 && <Tooltip title="Click to see details"><Button size="small"  sx={{textTransform:'capitalize', ml:1}} onClick={()=>imageDetails(index)}>Details</Button></Tooltip>}
                        </React.Fragment>
                      }
                      position="below"
                      actionIcon={
                        <React.Fragment>
                          {props.download && (
                            <Tooltip title="Download Thumbnail">
                              <IconButton onClick={() => download(item.filename, props.jobId)}>
                                <DownloadForOfflineOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {props.delete && (
                            <Tooltip title="Delete">
                              <IconButton onClick={() => deleteImage(item.filename, props.jobId)}>
                                <HighlightOffIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </React.Fragment>
                      }
                    />
                  </ImageListItem>
                )}
              </React.Fragment>
            ))}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {images.map((item, index) => (
              <ImageListLoading key={index} />
            ))}
          </React.Fragment>
        )}
      </ImageList>
      <DataDialog onClickCancel={handleImageDetailsDialogClose} open={imageDetailsDialog} header="Image Details" loading={false} maxWidth="sm" minWidth="200px" styleMaxWith={"450px"}>
        {imageDetailsData}
      </DataDialog>
    </React.Fragment>
  );
}
