import { baseApiURL, requestGet, requestPost } from "./RequestService";
import axios from "axios";
import Cookies from "js-cookie";

const prefix = "/vendor/portfolio";

const storePortfolioAvatar = async (data) => {
  return await axios.post(baseApiURL + prefix.replace('/','') + "/avatar", data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + Cookies.get("token") ?? "",
    },
  });
};
const get = async () => {
  return await requestGet(prefix + "/get");
}
const saveName = async (name) => {
    return await requestGet(prefix + "/update?name="+name);
};
const saveTitle = async (title) => {
    return await requestGet(prefix + "/update?title="+title);
};
const saveDescription = async (description) => {
    return await requestGet(prefix + "/update?description="+description);
};
const saveServices = async (services) => {
    return await requestGet(prefix + "/update?services="+services);
};
const storePortfolioSamples = async (data) => {
    return await axios.post(baseApiURL + prefix.replace('/','') + "/samples", data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + Cookies.get("token") ?? "",
      },
    });
  };
const setPrimarySample = async (data) => {
    return await requestPost(prefix + "/set-primary", data);
};
const deleteSample = async (data) => {
    return await requestPost(prefix + "/delete", data);
};
const portfolioPreview = async (type) => {
    return await requestGet(prefix + "/preview?type="+type);
};
const portfolioPreviewById = async (id) => {
    return await requestGet(prefix + "/view?id="+id);
};
export { storePortfolioAvatar, get, saveName, saveTitle, saveDescription, saveServices, storePortfolioSamples, setPrimarySample, deleteSample, portfolioPreview, portfolioPreviewById};
