import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import { setActions, setAvalableBalance, setDrawerOpen, setFeedbackDrawerOpen, setRole, setUserAvatar, setUserName } from "../../../features/commonSlice/commonSlice";
import { Badge, Chip, ListItem, ListItemButton, ListItemIcon, ListItemText, Skeleton, Tooltip } from "@mui/material";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import BrightnessHighIcon from "@mui/icons-material/BrightnessHigh";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import { setSwitchThemeClass, setTheme, setTopLoader } from "../../../features/mainSlice/mainSlice";
import SideNavLink from "./SideNavLink/SideNavLink";
import Cookies from "js-cookie";
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ConfirmDialog from "../../common/ConfirmDialog/ConfirmDialog";
import { getActions, logOut } from "../../../services/AuthService";
import Progress from "../../common/Progress/Progress";
import Page404 from "../../../pages/Page404";
import AdminRoutes from "../../../routes/AdminRoutes";
import { setSideBarRoutes } from "../../../features/commonSlice/commonSlice";
import { getNav } from "../../../services/AuthService";
import { baseWebURL } from "../../../services/RequestService";
import { Logout } from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import NotificationsMenuBox from "../../common/NotificationsMenuBox/NotificationsMenuBox";
import ActivityNotifyMenuBox from "../../common/ActivityNotifyMenuBox/ActivityNotifyMenuBox";
import ExceptionNotifyMenuBox from "../../common/ExceptionNotifyMenuBox/ExceptionNotifyMenuBox";
import PageToolbar from "../PageToolbar/PageToolbar";
import { isPriceSet } from "../../../services/ServicePricesService";
import TopBarEarningBadge from "../TopBarEarningBadge/TopBarEarningBadge";
import SmsFailedOutlinedIcon from "@mui/icons-material/SmsFailedOutlined";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const SideBar = (props) => {
  // component states
  // const [anchorElNav, setAnchorElNav] = useState(null)
  const [anchorElUser, setAnchorElUser] = useState(null);
  // const [navBarLinks, setNavBarLinks] = useState([])
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  // const [admin, setAdmin] = useState(Cookies.get('admin'))
  const [isGetNav, SetisGetNav] = useState(false);
  const [pricesCount, setPricesCount] = useState(true);
  const theme = useTheme();

  // Redux states
  const drawerOpen = useSelector((state) => state.common.drawerOpen);
  const themeLight = useSelector((state) => state.main.themeLight);
  const switchThemeClass = useSelector((state) => state.main.switchThemeClass);
  // const auth = useSelector((state) => state.main.auth)
  const topBarTitle = useSelector((state) => state.dashboard.topBarTitle);
  const newPermissionCreated = useSelector((state) => state.dashboard.newPermissionCreated);
  const sideBarRoutes = useSelector((state) => state.common.sideBarRoutes);
  const role = useSelector((state) => state.common.role);
  const topLoader = useSelector((state) => state.main.topLoader);
  const usrAvtr = useSelector((state)=> state.common.userAvatar)
  const [userName, setName] = useState(null);
  const [userAvatar, setAvatar] = useState(null);
  const dispatch = useDispatch();
  const date = new Date();

  const navigate = useNavigate()

  useEffect(() => {
    getNaves();
    // set action permission
    getActions().then((response) => {
      if (response.data.success) {
        const data = response.data.data.actions;
        const role = response.data.data.role;
        const userName = response.data.data.name;
        const userAvatar = response.data.data.avatar;
        // broadcast_id
        const br_id = response.data.data.br_id;
        Cookies.set("br_id", br_id);
        // broadcast_id
        dispatch(setRole(role));
        setName(userName);
        setAvatar(userAvatar);
        dispatch(setUserName(userName));
        dispatch(setUserAvatar(userAvatar));
        let actions = [];
        data?.map((item) => {
          actions.push(item.slug);
        });
        dispatch(setActions(actions));
      }
    });
    isPriceSet().then((response) => {
      setPricesCount(response.data.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPermissionCreated]);
  useEffect(()=>{
    setAvatar(usrAvtr)
  }, [usrAvtr])
  const getNaves = () => {
    // Get nav routes
    getNav().then((response) => {
      if (response.data.success) {
        dispatch(setSideBarRoutes(response.data.data.nav.permissions));
        SetisGetNav(true);
        dispatch(setTopLoader(false));
      }
    });
  };

  // const handleOpenNavMenu = (event) => {
  //     setAnchorElNav(event.currentTarget)
  // };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  // const handleCloseNavMenu = () => {
  //     setAnchorElNav(null)
  // };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  //fileder navbar links login and sign up buttons
  // const filterNavBarLinks = () => {
  //     const linkArr = [];
  //     ClientRoutes.forEach(element => {
  //         if (element.is_nav) {
  //             linkArr.push(element)
  //         }
  //     });

  //     return linkArr;
  // }
  const handleDrawerOpen = () => {
    dispatch(setDrawerOpen(true));
  };
  //handle click on <responsiveAppBar> theme change button.
  const handleThemeChange = () => {
    //change transitoin of icon button inside of <responsiveAppBar>
    if (themeLight) {
      dispatch(setSwitchThemeClass("theme-light-change-btn"));
      Cookies.set("themeLight", "false", { secure: true });
      dispatch(setTheme(false));
    } else {
      dispatch(setSwitchThemeClass("theme-dark-change-btn"));
      Cookies.set("themeLight", "true", { secure: true });
      dispatch(setTheme(true));
    }
  };
  const handleDrawerClose = () => {
    dispatch(setDrawerOpen(false));
  };

  const handleClientNavigate = () => {
    window.open("/");
  };
  const handleLogOutClick = () => {
    setLogoutDialogOpen(true);
    setAnchorElUser(null);
  };
  const handleLogOutConfirm = () => {
    logOut().then((response) => {
      Cookies.remove("isLoggedIn");
      Cookies.remove("admin");
      Cookies.remove("token");
      window.location.href = "/login";
    });
    setLogoutDialogOpen(false);
  };
  const handleLogOutCancel = () => {
    setLogoutDialogOpen(false);
  };
  const handleMyAccountClick = () => {
    navigate('partners/my-account')
    handleCloseUserMenu()
  }
  return (
    <React.Fragment>
      <Box sx={{ display: "flex" }}>
        <ConfirmDialog onClickConfirm={handleLogOutConfirm} onClickCancel={handleLogOutCancel} open={logoutDialogOpen} header="Are You Sure?" message="Please confirm to the end your session" />
        <AppBar position="fixed" open={drawerOpen} color="inherit">
          <Progress loading={topLoader} />
          <Toolbar>
            <Tooltip title="Menu Open">
              <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" sx={{ marginRight: 5, ...(drawerOpen && { display: "none" }) }}>
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" noWrap component="div">
              {topBarTitle}
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}> </Box>
            {role !== "client" && <TopBarEarningBadge />}
            <Tooltip title="swich to change theme">
              <IconButton sx={{ flexGrow: 0, marginRight: 1 }} className={switchThemeClass} onClick={handleThemeChange}>
                {themeLight ? <Brightness4Icon /> : <BrightnessHighIcon />}
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="Notifications">
              <IconButton sx={{ flexGrow: 0, marginRight: 1 }} onClick={()=>setNotificationMenuOpen(true)} >
                <Badge badgeContent={14} color="error">
                  <NotificationsNoneIcon />
                </Badge>
              </IconButton>
            </Tooltip> */}
            <NotificationsMenuBox />
            {/* <Tooltip title="Messages">
              <IconButton sx={{ flexGrow: 0, marginRight: 1 }}>
                <Badge badgeContent={2} color="error">
                  <EmailOutlinedIcon />
                </Badge>
              </IconButton>
            </Tooltip> */}
            {role === "super_admin" && (
              <React.Fragment>
                <ExceptionNotifyMenuBox />
                <ActivityNotifyMenuBox />
              </React.Fragment>
            )}
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  {userAvatar !== null ? <Avatar alt={userName} src={baseWebURL + userAvatar} /> : <Avatar alt={userName} src={'/img/avatar.png'} /> } 
                  {/* <Skeleton variant="circular" width={40} height={40} /> */}
                </IconButton>
              </Tooltip>
              <Menu sx={{ mt: "45px" }} id="user-menu" anchorEl={anchorElUser} anchorOrigin={{ vertical: "top", horizontal: "right" }} keepMounted transformOrigin={{ vertical: "top", horizontal: "right" }} open={Boolean(anchorElUser)} onClose={handleCloseUserMenu}>
                <MenuItem>
                  <Typography variant="caption">{userName}</Typography>
                </MenuItem>
                <MenuItem key={"profile"} onClick={handleMyAccountClick}>
                  {userAvatar !== null ? <Avatar alt={userName} src={baseWebURL + userAvatar} sx={{ width: "35px", height: "35px" }} /> : <Avatar alt={userName} src={'/img/avatar.png'} sx={{ width: "35px", height: "35px" }} />}{" "}
                  <Typography textAlign="center" sx={{ ml: 1 }}>
                    {" "}
                    My Account
                  </Typography>
                </MenuItem>
                <Divider />
                <MenuItem key={"client"} onClick={handleClientNavigate}>
                  <ListItemIcon>
                    <OpenInNewIcon />
                  </ListItemIcon>{" "}
                  <Typography textAlign="center">Client Home</Typography>
                </MenuItem>
                <MenuItem key={"logout"} onClick={handleLogOutClick}>
                  <ListItemIcon>
                    <Logout />
                  </ListItemIcon>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={drawerOpen}>
          <DrawerHeader>
            <Box component="img" sx={{ display: { md: "flex" }, mr: 1, width: "40px" }} alt="Logo" src={"/favicon.png"} />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="partners/dashboard"
              sx={{
                mr: 2,
                display: { md: "flex" },
                fontFamily: "inherit",
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
              }}
            >
              ImageMall
              <Typography variant="caption">TM</Typography>
            </Typography>
            <Tooltip title="Show Icon Menu">
              <IconButton onClick={handleDrawerClose}>{theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
            </Tooltip>
          </DrawerHeader>
          <Divider />
          <List sx={{ mb: 7 }}>
            {isGetNav &&
              sideBarRoutes.map((route, index) => {
                return (
                  route.is_sidenav !== 0 && (
                    <React.Fragment key={index}>
                      {route.slug === "notifications" ||
                        (route.slug === "activity_log" && (
                          <React.Fragment>
                            <Divider sx={{ mt: 1, mb: 1 }} />
                            {/* <Typography variant="caption" sx={{ ml: 2, textTransform: "uppercase" }}>
                            NOTIFICATIONS
                          </Typography> */}
                          </React.Fragment>
                        ))}
                      {route.slug === "payments" && (
                        <React.Fragment>
                          <Divider sx={{ mt: 1, mb: 1 }} />
                          {/* <Typography variant="caption" sx={{ ml: 2, textTransform: "uppercase" }}>
                            PAYMENTS
                          </Typography> */}
                        </React.Fragment>
                      )}
                      {route.slug === "manage_feedback" && (
                        <React.Fragment>
                          <Divider sx={{ mt: 1, mb: 1 }} />
                          {/* <Typography variant="caption" sx={{ ml: 2, textTransform: "uppercase" }}>
                            PAYMENTS
                          </Typography> */}
                        </React.Fragment>
                      )}
                      <SideNavLink route={route} />
                    </React.Fragment>
                  )
                );
              })}
            {/* Client New Job Post */}
            {role === "client" && (
              <a href="/upload-images" style={{ color: "inherit", textDecoration: "none" }}>
                <ListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton sx={{ minHeight: 48, justifyContent: drawerOpen ? "initial" : "center", px: 2.5 }}>
                    <ListItemIcon sx={{ minWidth: 0, mr: drawerOpen ? 3 : "auto", justifyContent: "center" }}>
                      <AddPhotoAlternateOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Post New Job" sx={{ opacity: drawerOpen ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              </a>
            )}
            {/* Client New Job Post */}
            {role === "client" && <Divider />}
            {/* FeedBack */}
            <Link to="#" style={{ color: "inherit", textDecoration: "none" }} onClick={() => dispatch(setFeedbackDrawerOpen(true))}>
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton sx={{ minHeight: 48, justifyContent: drawerOpen ? "initial" : "center", px: 2.5 }}>
                  <ListItemIcon sx={{ minWidth: 0, mr: drawerOpen ? 3 : "auto", justifyContent: "center" }}>
                    <SmsFailedOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Feedback" sx={{ opacity: drawerOpen ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </Link>
            {/* FeedBack */}
          </List>
          <Box sx={{ position: "fixed", bottom: "0px", width: "100%", backgroundColor: "inherit", height: "30px" }}>
            <Divider />
            {drawerOpen ? (
              <Typography variant="caption" sx={{ fontWeight: 600, ml: 3 }}>
                <strong>ImageMall</strong> V2 &copy; 2021 - {date.getFullYear()}
              </Typography>
            ) : (
              <Typography variant="caption" sx={{ fontWeight: 600, ml: 3 }}>
                <strong>IM</strong>
              </Typography>
            )}
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
          <DrawerHeader />
          {props.children}
          {role === "vendor" && pricesCount === false && (
            <PageToolbar
              alert={
                <Typography>
                  You still not set the service prices you provided. your card not showing to contractors until you set service prices.{" "}
                  <Link to="partners/services" style={{ color: "inherit" }}>
                    set price
                  </Link>
                </Typography>
              }
              color="error"
              severity="warning"
            />
          )}
          {isGetNav && (
            <Routes>
              <React.Fragment>
                {sideBarRoutes.map((route, index) => (
                  <Route key={route.id} path={route.path} exact={route.exact} element={AdminRoutes[route.element]} />
                ))}
                <Route path="*" element={<Page404 />} />
              </React.Fragment>
            </Routes>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default SideBar;
