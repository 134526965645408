import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { setTopBarTitle } from "../../features/dashboardSlice/dashboardSlice";
import { useDispatch } from "react-redux";
import { Divider, Typography } from "@mui/material";
import Payments from "../../components/admin/Payments/Payments";
import PageToolbar from "../../components/admin/PageToolbar/PageToolbar";
import ServicePrices from "../../components/admin/ServicePrices/ServicePrices";
import { Link } from "react-router-dom";

const Services = (props) => {
  // component states

  const title = "ImageMall | Services";
  //Redux states
  const dispatch = useDispatch();

  // use effect
  useEffect(() => {
    dispatch(setTopBarTitle("Services"));
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageToolbar />
      <Divider />
      <ServicePrices />
    </React.Fragment>
  );
};

export default Services;
