import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import styles from "../../../components/common/Login/Login.module.css";
import { LoadingButton } from "@mui/lab";
import { Link as UiLink } from "@mui/material";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useForm, Controller } from "react-hook-form";
import { setTopLoader } from "../../../features/mainSlice/mainSlice";
import TextFieldComponent from "../TextFieldComponent/TextFieldComponent";
import { forgetPassword } from "../../../services/AuthService";
import { useState } from "react";

const ForgetPassword = () => {
  // component states
  const [loginBtnLoad, setLoginBtnLoad] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const dispatch = useDispatch();

  // snackbar
  const { enqueueSnackbar } = useSnackbar();

  // form
  const { handleSubmit, control, resetField } = useForm({
    mode: "onChange",
  });

  useEffect(() => {}, []);

  const onSubmit = (data) => {
    // Show loaders
    setLoginBtnLoad(true);
    dispatch(setTopLoader(true));
    forgetPassword({ email: data.email })
      .then((response) => {
        if(response.data.success){
            showSuccess(response.data.message)
            setCodeSent(true)
            resetField("email");
        }
        setLoginBtnLoad(false);
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        showError(error.response.data.message);
        setLoginBtnLoad(false);
        dispatch(setTopLoader(false));
      });
  };
  const showError = (message) => {
    enqueueSnackbar(message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" } });
  };
  // Load sing up page inside of upload process page
  // use effect
  useEffect(() => {
    // hide top loader after component load
  }, []);
  return (
    <Container maxWidth="lg" sx={{ marginTop: 10, marginBottom: 6 }}>
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "80vh", marginTop: 2, marginBottom: 2 }}>
        <Grid item xs={3}>
          <Card className={styles.login_card}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <CardContent>
                <Typography variant="h4" component={"div"}>
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography>Forget Password</Typography>
                    </Grid>
                  </Grid>
                </Typography>
                <Typography sx={{ marginTop: 2 }} component="div">
                  <Typography variant="caption">Enter your email</Typography>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => <TextFieldComponent type={"email"} id="email" label="email *" autoFocus={true} error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />}
                    rules={{ required: "Email is required", pattern: { value: /^\S+@\S+$/i, message: "Invalid email address" } }}
                  />
                </Typography>
                <Grid container marginTop={3}>
                  <Grid item xs={6}>
                    <UiLink href="/login" underline="none" sx={{ fontSize: 14 }}>
                      {"Need to login ?"}
                    </UiLink>
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent="flex-end">
                    <LoadingButton type="submit" loading={loginBtnLoad} variant={"contained"} sx={{}} size="large">
                      {" "}
                      Reset{" "}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions></CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ForgetPassword