import "../App.css";
import React from "react";
import { Helmet } from "react-helmet";
import ForgetPassword from "../components/common/ForgetPassword/ForgetPassword";


const ForgetPasswordPage = () => {
  const title = "ImageMall | Forget Password";

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ForgetPassword />
    </>
  );
};

export default ForgetPasswordPage;
