import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { setTopBarTitle } from "../../features/dashboardSlice/dashboardSlice";
import { useDispatch } from "react-redux";
import { Divider } from "@mui/material";
import StripeForm from "../../components/common/StripeForm/StripeForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PageToolbar from "../../components/admin/PageToolbar/PageToolbar";
import { useState } from "react";
import { getPublicKey } from "../../services/PaymentService";

const PaymentFormPage = (props) => {
  // component states

  const title = "ImageMall | Pay";
  const [publicKey, setPublicKey] = useState(null);
  //Redux states
  const dispatch = useDispatch();

  // use effect
  useEffect(() => {
    dispatch(setTopBarTitle("Pay"));
    getPublicKey().then((response) => {
      setPublicKey(response.data.data.key);
    });
  }, []);
  const stripePromise = publicKey !== null ? loadStripe(publicKey) : null;
  return (
    <React.Fragment >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageToolbar />
      <Divider />
      {stripePromise !== null && (
        <Elements stripe={stripePromise}>
          <StripeForm />
        </Elements>
      )}
    </React.Fragment>
  );
};

export default PaymentFormPage;
