import React, { useState } from "react";
import { CircularProgress, Button, Typography, Grid, Paper, Stack, Divider } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { deleteFiles, UploadFiles } from "../../../services/UploadFilesService";
import { clientGet, get } from "../../../services/DropZoneService";
import LinearWithValueLabel from "../LinearProgressWithLabel/LinearProgressWithLabel";
import { Box } from "@mui/system";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useRef } from "react";
import { useEffect } from "react";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";
import { clearFileNames, setFileNames, setIsUploadComplete } from "../../../features/dropzoneSlice/dropzoneSlice";

const DropZoneClient = (props) => {
  const { 
    onDrop,
    files,
    rejectFiles,
    totalFilesSize,
    uploading,
    upload,
    removeUploaded,
    cancelUpload
} = props;
  const [accept, setAccept] = useState("");
  const [acceptLbl, setAcceptLbl] = useState("");
  const [maxSize, setMaxSize] = useState(0);

  const fileNames = useSelector((state) => state.dropzone.fileNames);
  const divRef = useRef(null);
  const dispatch = useDispatch();
  // Scroll to last file view
  useEffect(() => {
    files.length > 0 && divRef.current.scrollIntoView({ behavior: "smooth" });
  }, [files]);

  useEffect(() => {
     getClientDropzone();
  }, []);
  //dropzone
  const getClientDropzone = () => {
    clientGet().then((response) => {
      if (response.data.success === true) {
        const data = response.data.data;
        var accept = {};
        let acceptLbl = [];
        data.accept.map((item) => {
          acceptLbl.push(item.name);
        });
        // Ex {'image/jpg':[]} created this array for dropzone accept
        for (var i = 0; i < data.accept.length; ++i) accept[data.accept[i].type] = [];
        setAccept(accept);
        setMaxSize(mbToBytes(data.max_file_size));
        setAcceptLbl(acceptLbl.toString());
      }
    });
  };
  const mbToBytes = (mb) => {
    return mb * 1024 * 1024;
  };
  const bytesToMb = (bytes) => {
    return bytes / 1024 / 1024;
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept, maxSize });



  // Format Bytes
  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  return (
    <div>
      <div {...getRootProps({ className: "dropzone " })} className={"dropzone"}>
        <input {...getInputProps()} />
        <Typography variant="subtitle1">Drag 'n' drop some files here, or click to select files</Typography>
        <Typography variant="h6">({acceptLbl})</Typography>
        <Typography variant="body1">(Max : {bytesToMb(maxSize)}MB Per file)</Typography>
      </div>
      <aside ref={divRef}>
        <h4>File Details</h4>
        <Paper variant="outlined" sx={{ p: 1, mt: 1 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
            <Typography variant="subtitle2">Total Accepted Files : {files.length}</Typography>
            <Typography variant="subtitle2">Uploaded Files : {fileNames.length} </Typography>
            <Typography variant="subtitle2">Total Files Size : {formatBytes(totalFilesSize)} </Typography>
            <Box sx={{ marginLeft: "auto" }}>
              <Button variant="outlined" size="small" startIcon={<UploadFileIcon />} onClick={upload} disabled={uploading || files.length === 0}>
                Start to Upload
              </Button>
            </Box>
          </Stack>
        </Paper>
        <ul>
          {files.map((file, index) => (
            <div key={file.file.name}>
              <li>
                <Grid container>
                  <Grid item sm={9}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: file.uploaded ? "success.main" : file.failed ? "error.main" : "primary:main",
                      }}
                    >
                      {file.file.name} - {file.total_size === 0 ? formatBytes(file.file.size) : formatBytes(file.total_size)} | ( {formatBytes(file.uploaded_size)} ) {!file.uploaded && uploading && !file.failed && <CircularProgress color="primary" size={15} />}
                    </Typography>
                  </Grid>
                  <Grid item sm={3} sx={{ textAlign: "right" }}>
                    {!file.failed && (
                      <Typography align="right" variant="body2">
                        {file.uploaded && (
                          <Button variant="text" color="error" size="small" onClick={() => removeUploaded(file.file.name, index)}>
                            Remove
                          </Button>
                        )}
                        {!file.uploaded && uploading && !file.failed && (
                          <Button variant="text" color="error" size="small" onClick={() => cancelUpload(file.source, index)}>
                            Cancel
                          </Button>
                        )}

                        {!file.uploaded && !uploading && "Ready to Upload"}
                      </Typography>
                    )}
                    {file.failed && (
                      <Typography color={"error"} variant="body2">
                        Failed
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <LinearWithValueLabel progress={file.progress} completed={file.uploaded} failed={file.failed} />
              </li>
            </div>
          ))}
        </ul>
        {rejectFiles.length > 0 && (
          <React.Fragment>
            <Paper variant="outlined" sx={{ p: 1, mt: 1 }}>
              <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
                <Typography variant="subtitle2" sx={{ color: "error.main" }}>
                  Total Rejected Files : {rejectFiles.length}{" "}
                </Typography>
              </Stack>
            </Paper>
            <ul>
              {rejectFiles.map((file, index) => {
                return (
                  <li key={"reject_files_" + index}>
                    <Typography variant="subtitle2" sx={{ color: "error.main" }}>
                      {file.file.file.name} - {formatBytes(file.file.file.size)}
                    </Typography>
                    {file.file.errors.map((item, index) => {
                      return (
                        <Box key={"reject_files_error_" + index}>
                          <Typography variant="caption" sx={{ color: "error.main" }}>
                            <strong>{item.code}</strong> - {item.message}
                          </Typography>
                        </Box>
                      );
                    })}
                  </li>
                );
              })}
            </ul>
          </React.Fragment>
        )}
      </aside>
    </div>
  );
};
const mapStateToProps = (state) => ({
  fileNames: state.fileNames,
});

const mapDispatchToProps = { setFileNames };

export default connect(mapStateToProps, mapDispatchToProps)(DropZoneClient);
