import { requestGet, requestPost } from "./RequestService"
const prefix = '/feedback'
const clientPrefix = 'client/feedback'

const store = async (data) => {
    return await requestPost(clientPrefix+'/send', data);
};
const authStore = async (data) => {
    return await requestPost(prefix+'/auth-send', data);
};
const get = async (page) => {
    return await requestGet(prefix+'/get?page='+page);
};
export { store, authStore, get }