import {
    Grid, Paper, Typography,
    AlertTitle,
    Alert,
    Stack
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useState } from "react";

const Timer = (props) => {

    const countDownDate = new Date(props.due_time).getTime();

    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    );

    useEffect(() => {
        if (props.due_time !== null) {
            const interval = setInterval(() => {
                setCountDown(countDownDate - new Date().getTime());
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [countDownDate]);

    const getReturnValues = (countDown) => {
        // calculate time left
        const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
            (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

        return [days, hours, minutes, seconds];
    };
    const is_zero = getReturnValues(countDown)[0] + getReturnValues(countDown)[1] + getReturnValues(countDown)[2] + getReturnValues(countDown)[3]
    
    return (
        <Box sx={{ marginTop: 1 }}>
            <Stack sx={{ width: '100%' }} spacing={2}>
                {is_zero <= 0 && props.role === 'vendor' && props.due_time !== null &&
                    <Alert severity="error" sx={{mb: 1}}>
                        <AlertTitle>You Are Late!</AlertTitle>
                        Your job now mark as a LATE delivery and Maybe You Get Bad Review — <strong>Hurry!</strong>
                    </Alert>
                }
                {is_zero <= 0 && props.role !== 'vendor' && props.role !== "vendor_contractor" && props.due_time !== null &&
                    <Alert severity="error" sx={{mb: 1}}>
                        <AlertTitle>Late!</AlertTitle>
                        Ask him to deliver now — <strong> Contact Now </strong>
                    </Alert>
                }
            </Stack>
            <Paper variant='outlined' sx={{ padding: 2 }}>
                <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                    Time left to deliver  <strong>{props.due_time}</strong>
                </Typography>
                {is_zero <= 0 && props.due_time !== null ?
                    <Grid container spacing={2} sx={{ textAlign: 'center' }}>
                        <Grid item xs={3}>
                            <Typography variant="h4" sx={{ color: 'error.main' }}>#L</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="h4" sx={{ color: 'error.main' }}>#A</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="h4" sx={{ color: 'error.main' }}>#T</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="h4" sx={{ color: 'error.main' }}>#E</Typography>
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={2} sx={{ textAlign: 'center' }}>
                        {props.due_time !== null ?
                            <React.Fragment>
                                <Grid item xs={3}>
                                    <Typography variant="h4">{getReturnValues(countDown)[0] < 10 ? '0' + getReturnValues(countDown)[0] : getReturnValues(countDown)[0]}</Typography>
                                    <Typography>Days</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="h4">{getReturnValues(countDown)[1] < 10 ? '0' + getReturnValues(countDown)[1] : getReturnValues(countDown)[1]}</Typography>
                                    <Typography>Hours</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="h4">{getReturnValues(countDown)[2] < 10 ? '0' + getReturnValues(countDown)[2] : getReturnValues(countDown)[2]}</Typography>
                                    <Typography>Minutes</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="h4">{getReturnValues(countDown)[3] < 10 ? '0' + getReturnValues(countDown)[3] : getReturnValues(countDown)[3]}</Typography>
                                    <Typography>Seconds</Typography>
                                </Grid>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Grid item xs={3}>
                                    <Typography variant="h4">00</Typography>
                                    <Typography>Days</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="h4">00</Typography>
                                    <Typography>Hours</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="h4">00</Typography>
                                    <Typography>Minutes</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="h4">00</Typography>
                                    <Typography>Seconds</Typography>
                                </Grid>
                            </React.Fragment>
                        }
                    </Grid>
                }
            </Paper>
        </Box >
    )
}

export default Timer