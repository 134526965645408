
import React, { useEffect, useState } from "react";
import { CardContent, Divider, Grid, IconButton, Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import PageContainer from "../PageContainer/PageContainer"
import CardComponent from "../../common/CardComponent/CardComponent"
import TextFieldComponent from "../../common/TextFieldComponent/TextFieldComponent";
import LoadingButtons from "../../common/LoadingButton/LoadingButtonComponent"
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from 'notistack';
import { setTopLoader } from "../../../features/mainSlice/mainSlice";
import { setNewPermissionCreated } from "../../../features/dashboardSlice/dashboardSlice";
import { create, getAll, getById, update, deleteData } from "../../../services/JobTypeService";
import { VisibilityOutlined } from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DataTable from "../../common/DataTable/DataTable";
import DataDialog from "../../common/DataDialog/DataDialog";
import ConfirmDialog from "../../common/ConfirmDialog/ConfirmDialog";
const JobTypes = () => {

    // component states
    const [btnLoading, setBtnLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [isRowsSet, setIsRowsSet] = useState(false);
    const [dataDialogEditOpen, setDataDialogEditOpen] = useState(false);
    const [dataEditDialogLading, setDataEditDialogLading] = useState(true);
    const [typeData, setTypeData] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectId, setSelectId] = useState(null);
    // snackbar
    const { enqueueSnackbar } = useSnackbar();

    const sideBarRoutes = useSelector((state) => state.common.sideBarRoutes)

    const dispatch = useDispatch()
    // form
    const { handleSubmit, control, reset, formState } = useForm({
        mode: 'onChange'
    });
    const { handleSubmit: handleSubmit2, control: control2, reset: reset2, formState: formState2 } = useForm({
        mode: "onChange",
    });

    useEffect(() => {
        getTypes()
    }, [])

    function createData(
        id,
        name,
        slug,
        created_by,
        created_at,
        action
    ) {
        return {
            id,
            name,
            slug,
            created_by,
            created_at,
            action
        };
    }

    const columns = [
        { field: "id", headerName: "ID", minWidth: 50, type: "number" },
        {
            field: "name",
            headerName: "Name",
            minWidth: 200,
            editable: false,
        },
        {
            field: "slug",
            headerName: "Slug",
            minWidth: 200,
            editable: false,
        },
        {
            field: "created_by",
            headerName: "Created By",
            minWidth: 200,
            editable: false,
        },
        {
            field: "created_at",
            headerName: "Created At",
            minWidth: 150,
            editable: false,
        },
        {
            field: "action",
            headerName: "Action",
            align: "center",
            minWidth: 150,
            editable: true,
            renderCell: (params) => {
                return params.value;
            },
        },
    ];

    const onSubmit = data => {
        // // Show loaders
        setBtnLoading(true)
        dispatch(setTopLoader(true))
        // Send auth request
        create(data).then((response) => {
            if (response.data.success === true) {
                showSuccess(response.data.message)
            }
            setBtnLoading(false)
            dispatch(setTopLoader(false))
            reset()
            getTypes()
        }).catch((response) => {
            showError("Somthing went wrong");
            // showError(response.response.data.data.message)
            setBtnLoading(false)
            dispatch(setTopLoader(false))
            console.log("error", response)
        })

    }
    const getTypes = () => {
        dispatch(setTopLoader(true));
        setRows([]);
        getAll()
            .then((response) => {
                let rows = [];
                rows = [...rows];
                response.data.data.map((item, index) => {

                    const action = (
                        <React.Fragment>
                            <IconButton onClick={() => handleEditClick(item.id)}>
                                <EditOutlinedIcon fontSize="small" color="action" />
                            </IconButton>
                            <IconButton onClick={()=>handleDelete(item.id)}>
                                <DeleteOutlineOutlinedIcon fontSize="small" color="action" />
                            </IconButton>
                        </React.Fragment>
                    );
                    const created_at = new Date(item.created_at).toLocaleDateString();
                    rows.push(
                        createData(
                            item.id,
                            item.name,
                            item.slug,
                            item.created_user.name,
                            created_at,
                            action
                        )
                    );
                });

                setRows(rows);
                setIsRowsSet(true);
                dispatch(setTopLoader(false));
            })
            .catch((error) => {
                console.log(error);
                showError(error.response.data.message);
                dispatch(setTopLoader(false));
            });
    };

    const handleEditClick = (id) => {
        reset2()
        setDataEditDialogLading(true);
        setTypeData({});
        getById({ id })
            .then((response) => {
                setTypeData(response.data.data);
                setDataEditDialogLading(false);
                setDataDialogEditOpen(true);
            })
            .catch((error) => {
                console.log(error);
                showError(error.response.data.message);
                setDataEditDialogLading(false);
            });
    };
    const handleDelete = (id) => {
        setDialogOpen(true);
        setSelectId(id);
    };
    const handleCancelDataDialog = () => {
        setDataDialogEditOpen(false)
    };
    const showError = (message) => {
        enqueueSnackbar(message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    }
    const showSuccess = (message) => {
        enqueueSnackbar(message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    }
    const onEditSubmit = (data) => {
        reset2()
        setBtnLoading(true)
        setDataEditDialogLading(true)
        data['id'] = typeData.id
        update(data).then((response) => {
            if (response.data.success === true) {
                showSuccess(response.data.message)
            }
            setBtnLoading(false)
            setDataEditDialogLading(false)
            setDataDialogEditOpen(false);
            reset2()
            getTypes()
        }).catch((response) => {
            showError("Somthing went wrong");
            // showError(response.response.data.data.message)
            setBtnLoading(false)
            setDataEditDialogLading(false)
            setDataDialogEditOpen(false);
            console.log("error", response)
        })
    }
    const handleConfirmDialog = (event) => {
        setDialogOpen(false);
        dispatch(setTopLoader(true));
        deleteData({ id: selectId })
          .then((response) => {
            if (response.data.success === true) {
              showSuccess(response.data.message);
              dispatch(setTopLoader(false));
              setDataDialogEditOpen(false)
              getTypes();
            }
          })
          .catch((error) => {
            console.log(error);
            showError(error.response.data.message);
            dispatch(setTopLoader(false));
          });
      };
      const handleCancelDialog = () => {
        setDialogOpen(false);
      };
    return (
        <PageContainer >
            <ConfirmDialog
                onClickConfirm={handleConfirmDialog}
                onClickCancel={handleCancelDialog}
                open={dialogOpen}
                header="Are You Sure?"
                message="Please confirm to delete this data. this action cannot revert"
            />
            <DataDialog
                onClickCancel={handleCancelDataDialog}
                open={dataDialogEditOpen}
                header="Edit User"
                loading={dataEditDialogLading}
            >

                <form onSubmit={handleSubmit2(onEditSubmit)}>
                    <Controller
                        name="name"
                        control={control2}
                        defaultValue={typeData.name}
                        render={({ field: { onChange, value }, fieldState: { error } }) => {
                            return <TextFieldComponent type={'text'} id="name" label="Name*" autoFocus={true} error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />
                        }}
                        rules={{ required: 'Name is required' }}
                    />
                    <Typography align="right">
                        <LoadingButtons
                            loading={btnLoading}
                            name="Save"
                            variant="contained"
                            type="submit"
                            disabled={!formState2.isValid}
                        />
                    </Typography>
                </form>
            </DataDialog>
            <Grid container spacing={4}>
                <Grid item sm={12}>
                    <CardComponent>
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="div">
                                Create a Type
                            </Typography>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={4}>
                                    <Grid item sm={12}>
                                        <Controller
                                            name="name"
                                            control={control}
                                            defaultValue=""
                                            render={({ field: { onChange, value }, fieldState: { error } }) => {
                                                return <TextFieldComponent type={'text'} id="name" label="Name*" autoFocus={true} error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />
                                            }}
                                            rules={{ required: 'Name is required' }}
                                        />
                                        <Typography align='right'>
                                            <LoadingButtons loading={btnLoading} name="Save" variant="contained" type="submit" disabled={!formState.isValid} />
                                        </Typography>
                                        <Divider sx={{ mb: 3, mt: 3 }} />
                                        {isRowsSet && (
                                            <DataTable
                                                rows={rows}
                                                columns={columns}
                                                pageSize={10}
                                                rowsPerPageOptions={[10]}
                                                checkboxSelection
                                                disableSelectionOnClick
                                                experimentalFeatures={{ newEditingApi: true }}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </CardComponent>
                </Grid>
            </Grid>

        </PageContainer>

    );
}



export default JobTypes;