import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { setTopBarTitle } from "../../features/dashboardSlice/dashboardSlice";
import { useDispatch } from "react-redux";
import { CircularProgress, Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import PageToolbar from "../../components/admin/PageToolbar/PageToolbar";
import { Box, Container } from "@mui/system";
import { get, resetPassword, update } from "../../services/MyAccountService";
import { useState } from "react";
import { baseWebURL } from "../../services/RequestService";
import AvatarUpload from "../../components/common/AvatarUpload/AvatarUpload";
import { Controller, useForm } from "react-hook-form";
import TextFieldComponent from "../../components/common/TextFieldComponent/TextFieldComponent";
import SelectBox from "../../components/common/SelectBoxCompnent/SelectBoxComponent";
import { countryList, snackBarPosition } from "../../services/CommonService";
import { setTopLoader } from "../../features/mainSlice/mainSlice";
import LoadingButtons from "../../components/common/LoadingButton/LoadingButtonComponent";
import { useSnackbar } from "notistack";
import { setUserAvatar } from "../../features/commonSlice/commonSlice";
const MyAccount = (props) => {
  // component states
  const [accountData, setAccountData] = useState(undefined);
  const [preview, setPreview] = useState(undefined);
  const [profileImage, setProfileImage] = useState(undefined);
  const [btnLoading, setBtnLoading] = useState(false);
  const title = "ImageMall | My Account";

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, formState, watch } = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit: handleSubmit2,
    control: control2,
    reset: reset2,
    formState: formState2,
    watch: watch2,
  } = useForm({
    mode: "onChange",
  });
  const password = useRef({});
  password.current = watch2("password", "");
  // use effect
  useEffect(() => {
    dispatch(setTopBarTitle("My Account"));
    getMyAccount();
  }, []);
  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };
  const getMyAccount = () => {
    get()
      .then((response) => {
        if (response.data.success === true) {
          setAccountData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleAvatarUpload = (e) => {
    dispatch(setTopLoader(true));
    if (!e.target.files || e.target.files.length === 0) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
    setProfileImage(e.target.files[0]);
    dispatch(setTopLoader(false));
  };
  const onSubmit = (data) => {
    setBtnLoading(true);
    if (profileImage != undefined) {
      data["image"] = profileImage;
    }
    update(data)
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          if(response.data.data.image !== ''){
            dispatch(setUserAvatar(response.data.data.image))
          }
          setBtnLoading(false);
          getMyAccount()
        }
        setPreview(undefined);
        setProfileImage(undefined);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 422) {
          showError(error.response.data.data[0]);
        } else {
          showError(error.response.data.message);
        }
        setBtnLoading(false);
      });
  };
  // password reset
  const passwordReset = (data) => {
    setBtnLoading(true);
    resetPassword(data)
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          setBtnLoading(false);
          reset2();
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 422) {
          showError(error.response.data.data[0]);
        } else {
          showError(error.response.data.message);
        }
        setBtnLoading(false);
        // console.log("error", response.response);
      });
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageToolbar />
      <Divider />
      <Container sx={{ mb: 4, mt: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "right" }}></Box>
        {accountData !== undefined ? (
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <Paper variant="outlined" sx={{ textAlign: "center", p: 2 }}>
                <AvatarUpload onChange={handleAvatarUpload} image={preview === undefined ? baseWebURL + accountData.image : preview} loading={false} size="large" />
                {/* <Typography variant="subtitle1" align="center">
                  {accountData.name}
                </Typography> */}
              </Paper>
            </Grid>
            <Grid item sm={8}>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
                  <Controller
                    name="name"
                    control={control}
                    defaultValue={accountData.name}
                    render={({ field: { onChange, value = accountData.name !== null ? accountData.name : "" }, fieldState: { error } }) => {
                      return <TextFieldComponent type={"text"} id="name" label="Name*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} marginTop={0} />;
                    }}
                    rules={{ required: "Name is required" }}
                  />
                  <Controller
                    name="email"
                    control={control}
                    defaultValue={accountData.email}
                    render={({ field: { onChange, value = accountData.email !== null ? accountData.email : "" }, fieldState: { error } }) => {
                      return <TextFieldComponent type={"text"} id="email" label="Email*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} marginTop={2} />;
                    }}
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: "Invalid email address",
                      },
                    }}
                  />
                  <Controller
                    name="country"
                    control={control}
                    defaultValue={accountData.country !== null ? accountData.country : ""}
                    render={({ field: { onChange, value = accountData.country !== null ? accountData.country : "" }, fieldState: { error } }) => {
                      return <SelectBox id={"country"} label="Select Country" items={countryList} error={!!error} helperText={error ? error.message : null} onChange={onChange} defaultValue={value} />;
                    }}
                  />
                  <Controller
                    name="mobile"
                    control={control}
                    defaultValue={accountData.mobile !== null ? accountData.mobile : ""}
                    render={({ field: { onChange, value = accountData.mobile !== null ? accountData.mobile : "" }, fieldState: { error } }) => {
                      return <TextFieldComponent type={"mobile"} id="mobile" label="Mobile" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} marginTop={0} />;
                    }}
                  />
                  <Controller
                    name="description"
                    control={control}
                    defaultValue={accountData.description !== null ? accountData.description : ""}
                    render={({ field: { onChange, value = accountData.description !== null ? accountData.description : "" }, fieldState: { error } }) => {
                      return <TextField id="description" label="Describe About User" multiline rows={5} onChange={onChange} value={value} fullWidth sx={{ mt: 3 }} />;
                    }}
                  />
                  <Box textAlign={"right"}>
                    <LoadingButtons loading={btnLoading} name="Save" variant="contained" type="submit" disabled={!formState.isValid} />
                  </Box>
                </form>
                <form onSubmit={handleSubmit2(passwordReset)}>
                  <Divider sx={{ mt: 2 }}>
                    <Typography variant="caption">Password Reset</Typography>
                  </Divider>

                  <Controller
                    name="password"
                    control={control2}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                      return <TextFieldComponent type={"password"} id="password" label="New Password*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />;
                    }}
                    rules={{
                      required: "Password is required",
                      minLength: {
                        value: 6,
                        message: "Password is too short min:6",
                      },
                      maxLength: {
                        value: 14,
                        message: "Password is too long max:14",
                      },
                    }}
                  />
                  <Controller
                    name="confirm_password"
                    control={control2}
                    defaultValue={""}
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                      return <TextFieldComponent type={"password"} id="confirm_password" label="Confirm New Password*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />;
                    }}
                    rules={{
                      required: "Password is required",
                      minLength: {
                        value: 6,
                        message: "Password is too short min:6",
                      },
                      validate: (value) => value === password.current || "The passwords does not match",
                      maxLength: {
                        value: 14,
                        message: "Password is too long max:14",
                      },
                    }}
                  />
                  <Box textAlign={"right"}>
                    <LoadingButtons loading={btnLoading} name="Reset Password" variant="contained" type="submit" disabled={!formState2.isValid} />
                  </Box>
                </form>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "50vh" }}>
            <CircularProgress />
          </Box>
        )}
      </Container>
    </React.Fragment>
  );
};

export default MyAccount;
