import "./App.css";
// import Progress from './components/common/Progress/Progress'
import React, { StrictMode, useEffect, useState } from "react";
import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { setAuthentication, setTheme, setTopLoader } from "./features/mainSlice/mainSlice";
import { isAuthenticated } from "./services/AuthService";
import ClientRoutes from "./routes/ClientRoutes";
import ResponsiveAppBar from "./components/client/responsiveAppBar/ResponsiveAppBar";
import Footer from "./components/client/footer/Footer";
import SideBar from "./components/admin/SideBar/SideBar";
import Progress from "./components/common/Progress/Progress";
import Cookies from "js-cookie";
import Page404 from "./pages/Page404";
import SnackBarLoading from "./components/common/SnackBarLoading/SnackBarLoading";
import FeedBackDrawer from "./components/common/FeedBackDrawer/FeedBackDrawer";
const App = (props) => {
  //Redux states
  const themeLight = useSelector((state) => state.main.themeLight);
  const topLoader = useSelector((state) => state.main.topLoader);
  const auth = useSelector((state) => state.main.auth);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (Cookies.get("themeLight") === "true") {
      dispatch(setTheme(true));
    } else {
      dispatch(setTheme(false));
    }
    // Check authenticated
    if (Cookies.get("isLoggedIn")) {
      isAuthenticated()
        .then((response) => {
          if (response.data.success) {
            dispatch(setAuthentication(true));
          } else {
            Cookies.remove("auth");
            Cookies.remove("admin");
            Cookies.remove("isLoggedIn");
            window.location.href = "/login";
          }
        })
        .catch((error) => {
          Cookies.remove("auth");
          Cookies.remove("admin");
          Cookies.remove("isLoggedIn");
          window.location.href = "/login";
        });
    }
    dispatch(setTopLoader(false));
  }, []);

  // geth path
  const getPath = () => {
    return window.location.pathname;
  };
  // Component states
  const [admin, setAdmin] = useState(Cookies.get("admin"));
  const [pathname, setPathname] = useState(getPath());

  let theme = createTheme({
    palette: {
      mode: themeLight ? "light" : "dark",
    },
    typography: {
      fontFamily: "'Product Sans'",
    },
  });
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Progress loading={topLoader} />
      <SnackBarLoading title="Compressing..." />
      {admin === "true" && pathname.search("/partners/") !== -1 ? (
        <SideBar />
      ) : (
        <React.Fragment>
          <ResponsiveAppBar />
          <Routes>
            {ClientRoutes.map((route, index) => (
              <Route key={route.key} path={route.path} exact={route.exact} element={route.element} />
            ))}
            <Route path="*" element={<Page404 />} />
          </Routes>
        </React.Fragment>
      )}
      {/* FeedBackDrawer */}
      <FeedBackDrawer />
      {location.pathname.includes("partners") ? <></> : <Footer />}
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  themeLight: state.themeLight,
  auth: state.auth,
  topLoader: state.topLoader,
});

const mapDispatchToProps = { setTheme, setAuthentication, setTopLoader };

export default connect(mapStateToProps, mapDispatchToProps)(App);
