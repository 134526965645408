import { createSlice } from "@reduxjs/toolkit";
import Cookies from 'js-cookie'

const initialState = {
    jobId: '',
    jobStatus: undefined,
    jobEffect:false,
    ratingEffect:false
}

export const jobSlice = createSlice({
    name: 'job',
    initialState,
    reducers:{
        setJobId: (state, action) => {
            state.jobId = action.payload
        },
        setJobStatus: (state, action) => {
            state.jobStatus = action.payload
        },
        setJobEffect: (state, action) => {
            state.jobEffect = action.payload
        },
        setRatingEffect: (state, action) => {
            state.ratingEffect = action.payload
        }
    }
})

// export individual action creator functions
export const { setJobId, setJobStatus, setJobEffect, setRatingEffect } = jobSlice.actions;

// often the reducer is a default export, but that doesn't matter
export default jobSlice.reducer;

