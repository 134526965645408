import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fileNames: [],
    isUploadComplete: false,
    fileNamesForStepper: [],
}

export const dropzoneSlice = createSlice({
    name: 'dropzone',
    initialState,
    reducers: {
        setFileNames: (state, action) => {
            // state.fileNames.push(action.payload)
            return {
                ...state,
                fileNames: state.fileNames.concat(action.payload)
            };
        },
        setIsUploadComplete: (state, action) => {
            state.isUploadComplete = action.payload
        },
        clearFileNames: (state, action) => {
            return {
                ...state,
                fileNames: []
            };
        },
        setFileNamesForStepper: (state, action) => {
            return {
                ...state,
                fileNamesForStepper: state.fileNamesForStepper.concat(action.payload)
            };
        },
    }
})

// export individual action creator functions
export const { setFileNames, setIsUploadComplete, clearFileNames, setFileNamesForStepper } = dropzoneSlice.actions;

// often the reducer is a default export, but that doesn't matter
export default dropzoneSlice.reducer;

