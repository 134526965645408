import { Avatar, Box, Button, Chip, Divider, Grid, IconButton, Paper, Rating, TextField, Typography } from "@mui/material";
import React from "react";
import AvatarUpload from "../AvatarUpload/AvatarUpload";
import StarIcon from "@mui/icons-material/Star";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";
import SendIcon from "@mui/icons-material/Send";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import moment from "moment/moment";
import { baseWebURL } from "../../../services/RequestService";
import { useState } from "react";
import { saveDescription, saveName, saveServices, saveTitle } from "../../../services/PortfolioService";
import MultipleSelectChip from "../MultipleSelectChip/MultipleSelectChip";
import { useDispatch } from "react-redux";
import { setTopLoader } from "../../../features/mainSlice/mainSlice";
import ShareBar from "../ShareBar/ShareBar";
const ProfileCard = (props) => {
  const { handleFileUpload, edit, loading, image, name, title, ratePoints, totalReviews, from, since, jobsDone, lastDelivery, description, services, url } = props;
  // name
  const [nameEditClick, setNameEditClick] = useState(false);
  const [nameSt, setName] = useState(name);
  // title
  const [titleEditClick, setTitleEditClick] = useState(false);
  const [titleSt, setTitle] = useState(title ? title : "");
  // description
  const [descriptionEditClick, setDescriptionEditClick] = useState(false);
  const [descriptionSt, setDescription] = useState(description ? description : "");
  // services
  const [servicesEditClick, setServicesEditClick] = useState(false);
  const [servicesSt, setServices] = useState(services);
  const dispatch = useDispatch();
  // Name Update
  const nameOnChange = (e) => {
    setName(e.target.value);
  };
  const handleNameEditClick = () => {
    setNameEditClick(true);
  };
  const handleNameEditCancel = () => {
    setNameEditClick(false);
    setName(name);
  };
  const handleNameSave = () => {
    dispatch(setTopLoader(true));
    saveName(nameSt)
      .then((response) => {
        if (response.data.success === true) {
          setNameEditClick(false);
          setName(response.data.data);
        }
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setTopLoader(false));
      });
  };
  // Title Update
  const titleOnChange = (e) => {
    setTitle(e.target.value);
  };
  const handleTitleEditClick = () => {
    setTitleEditClick(true);
  };
  const handleTitleEditCancel = () => {
    setTitleEditClick(false);
    setTitle(titleSt);
  };
  const handleTitleSave = () => {
    dispatch(setTopLoader(true));
    saveTitle(titleSt)
      .then((response) => {
        if (response.data.success === true) {
          setTitleEditClick(false);
          setTitle(response.data.data);
        }
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setTopLoader(false));
      });
  };
  // Description Update
  const descriptionOnChange = (e) => {
    setDescription(e.target.value);
  };
  const handleDescriptionEditClick = () => {
    setDescriptionEditClick(true);
  };
  const handleDescriptionEditCancel = () => {
    setDescriptionEditClick(false);
    setDescription(descriptionSt);
  };
  const handleDescriptionSave = () => {
    dispatch(setTopLoader(true));
    saveDescription(descriptionSt)
      .then((response) => {
        if (response.data.success === true) {
          setDescriptionEditClick(false);
          setDescription(response.data.data);
        }
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setTopLoader(false));
      });
  };
  // Services Update
  const servicesOnChange = (e) => {
    setServices(e.toString());
  };
  const handleServicesEditClick = () => {
    setServicesEditClick(true);
  };
  const handleServicesEditCancel = () => {
    setServicesEditClick(false);
    setServices(servicesSt);
  };
  const handleServicesSave = () => {
    dispatch(setTopLoader(true));
    saveServices(servicesSt)
      .then((response) => {
        if (response.data.success === true) {
          setServicesEditClick(false);
          setServices([]);
          setServices(response.data.data);
        }
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setTopLoader(false));
      });
  };
  return (
    <React.Fragment>
      <Paper variant="outlined" sx={{ textAlign: "center" }}>
        {edit ? (
          <AvatarUpload onChange={handleFileUpload} image={image !== null && image.includes("storage") ? baseWebURL + image : image} size="medium" loading={loading} />
        ) : (
          <Box sx={{ display: "inline-table" }}>
            <Avatar
              src={image !== null && image.includes("storage") ? baseWebURL + image : image}
              style={{
                margin: "10px",
                width: "150px",
                height: "150px",
              }}
            />
          </Box>
        )}
        <Typography variant="subtitle1" align="center">
          {!nameEditClick && <strong>{nameSt} </strong>}
          {edit && !nameEditClick && (
            <IconButton size="small" onClick={handleNameEditClick}>
              <EditOutlinedIcon sx={{ fontSize: "20px" }} />
            </IconButton>
          )}
          {nameEditClick && (
            <React.Fragment>
              <TextField fullWidth variant="standard" value={nameSt} onChange={nameOnChange} />
              <Box sx={{ mt: 1 }}>
                <Button size="small" variant="outlined" color="primary" sx={{ mr: 2 }} onClick={handleNameEditCancel}>
                  Cancel
                </Button>
                <Button size="small" variant="contained" color="success" onClick={handleNameSave}>
                  Save
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Typography>
        <Typography variant="subtitle2" align="center" sx={{ mb: 1 }}>
          {!titleEditClick && titleSt}{" "}
          {edit && !titleEditClick && (
            <IconButton size="small" onClick={handleTitleEditClick}>
              <EditOutlinedIcon sx={{ fontSize: "20px" }} />
            </IconButton>
          )}
          {titleEditClick && (
            <React.Fragment>
              <TextField fullWidth variant="standard" value={titleSt} onChange={titleOnChange} multiline />
              <Box sx={{ mt: 1 }}>
                <Button size="small" variant="outlined" color="primary" sx={{ mr: 2 }} onClick={handleTitleEditCancel}>
                  Cancel
                </Button>
                <Button size="small" variant="contained" color="success" onClick={handleTitleSave}>
                  Save
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Typography>
        <Box sx={{ display: "inline-flex", alignContent: "center" }}>
          <Rating name="text-feedback" value={parseInt(ratePoints)} readOnly precision={0.5} emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />
          <Box sx={{ ml: 1 }}>
            {" "}
            <Typography color="text.secondary">
              <strong>{ratePoints}</strong> {totalReviews !== undefined ? "(" + totalReviews + " reviews)" : "(no reviews)"}
            </Typography>
          </Box>
        </Box>
        {url && (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <ShareBar url={url} />
          </Box>
        )}

        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid container spacing={4}>
          <Grid item sm={4} sx={{ textAlign: "left" }}>
            <Box sx={{ display: "flex", pl: 1.5, pb: 1.7 }}>
              <LocationOnIcon fontSize="small" />
              <Typography sx={{ ml: 1 }} variant="body2">
                From
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={8} sx={{ textAlign: "right" }}>
            <Box sx={{ pr: 1.5, pb: 1.7 }}>
              <Typography variant="body2">
                <strong>{from}</strong>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item sm={6} sx={{ textAlign: "left" }}>
            <Box sx={{ display: "flex", pl: 1.5, pb: 1.7 }}>
              <PersonIcon fontSize="small" />
              <Typography sx={{ ml: 1 }} variant="body2">
                Vendor since
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={6} sx={{ textAlign: "right" }}>
            <Box sx={{ pr: 1.5, pb: 1.7 }}>
              <Typography variant="body2">
                <strong>{moment(since).format("MMM YYYY")}</strong>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item sm={6} sx={{ textAlign: "left" }}>
            <Box sx={{ display: "flex", pl: 1.5, pb: 1.7 }}>
              <PermMediaIcon fontSize="small" />
              <Typography sx={{ ml: 1 }} variant="body2">
                Total Jobs Done
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={6} sx={{ textAlign: "right" }}>
            <Box sx={{ pr: 1.5, pb: 1.7 }}>
              <Typography variant="body2">
                <strong>{jobsDone}</strong>
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item sm={6} sx={{ textAlign: "left" }}>
            <Box sx={{ display: "flex", pl: 1.5, pb: 1.7 }}>
              <SendIcon fontSize="small" />
              <Typography sx={{ ml: 1 }} variant="body2">
                Last Delivery
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={6} sx={{ textAlign: "right" }}>
            <Box sx={{ pr: 1.5, pb: 1.7 }}>
              <Typography variant="body2">
                <strong>{lastDelivery !== undefined && lastDelivery !== null ? moment(lastDelivery).fromNow() : "no deliveries"}</strong>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Paper variant="outlined" sx={{ p: 2, mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography variant="subtitle1">
              <strong>Description</strong>
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography align="right" variant="subtitle2">
              {edit && !descriptionEditClick && (
                <Button size="small" onClick={handleDescriptionEditClick} startIcon={<EditOutlinedIcon />}>
                  Edit
                </Button>
              )}
            </Typography>
          </Grid>
        </Grid>

        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
          {!descriptionEditClick && descriptionSt}
        </Typography>
        {descriptionEditClick && (
          <React.Fragment>
            <TextField fullWidth variant="standard" value={descriptionSt} onChange={descriptionOnChange} multiline />
            <Box sx={{ mt: 1 }}>
              <Button size="small" variant="outlined" color="primary" sx={{ mr: 2 }} onClick={handleDescriptionEditCancel}>
                Cancel
              </Button>
              <Button size="small" variant="contained" color="success" onClick={handleDescriptionSave}>
                Save
              </Button>
            </Box>
          </React.Fragment>
        )}
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item sm={6}>
            <Typography variant="subtitle1">
              <strong>Services</strong>
            </Typography>
          </Grid>
          <Grid item sm={6}>
            {/* <Typography align="right" variant="subtitle2">
              {edit && !servicesEditClick && (
                <Button size="small" startIcon={<EditOutlinedIcon />} onClick={handleServicesEditClick}>
                  Edit
                </Button>
              )}
            </Typography> */}
          </Grid>
        </Grid>
        <Box>
          {!servicesEditClick && (
            <React.Fragment>
              {servicesSt?.map((item, index) => {
                return (
                  <Chip
                    label={
                      <Typography variant="body2">
                        {item.service} <strong>${item.price.toFixed(2)}</strong>
                      </Typography>
                    }
                    key={index}
                    sx={{ mt: 2, mb: 0.2, mr: 1 }}
                  />
                );
              })}
            </React.Fragment>
          )}
          {/* {servicesEditClick && (
            <React.Fragment>
              <MultipleSelectChip onChange={servicesOnChange} values={servicesSt} />
              <Box sx={{ mt: 1 }}>
                <Button size="small" variant="outlined" color="primary" sx={{ mr: 2 }} onClick={handleServicesEditCancel}>
                  Cancel
                </Button>
                <Button size="small" variant="contained" color="success" onClick={handleServicesSave}>
                  Save
                </Button>
              </Box>
            </React.Fragment>
          )} */}
        </Box>
      </Paper>
    </React.Fragment>
  );
};

export default ProfileCard;
