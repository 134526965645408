import { requestGet, requestPost } from "./RequestService"

const prefix = '/services'

const store = async (data) => {
    return await requestPost(prefix+'/store', data)
}
const get = async () => {
    return await requestGet(prefix+'/get')
}
const isPriceSet = async () => {
    return await requestGet(prefix+'/is-price-set')
}

export { store, get, isPriceSet }