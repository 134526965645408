import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import styles from "./SideNavLink.module.css";
import AdminRoutes from "../../../../routes/AdminRoutes";
import { Box } from "@mui/system";

const SideNavLink = (props) => {
  const location = useLocation();
  const themeLight = useSelector(state=>state.main.themeLight)
  // Redux states
  const drawerOpen = useSelector((state) => state.common.drawerOpen);
  return (
    <Box sx={{
        backgroundColor: location.pathname === "/" + props.route.path && 'primary.main',
        borderLeft:location.pathname === "/" + props.route.path && 5, 
        borderLeftColor:location.pathname === "/" + props.route.path && 'primary.light',
        borderTopRightRadius:location.pathname === "/" + props.route.path && '40px',
        borderBottomRightRadius:location.pathname === "/" + props.route.path && '40px',
        marginRight:location.pathname === "/" + props.route.path && '10px',
        color:location.pathname === "/" + props.route.path && themeLight && 'white',
        }}>
      <Link to={props.route.path} className={[styles.link]}>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton sx={{ minHeight: 48, justifyContent: drawerOpen ? "initial" : "center", px: 2.5 }}>
            <ListItemIcon sx={{ minWidth: 0, mr: drawerOpen ? 3 : "auto", justifyContent: "center", color:location.pathname === "/" + props.route.path && themeLight && 'white'}}>{AdminRoutes[props.route.icon]}</ListItemIcon>
            <ListItemText primary={props.route.name} sx={{ opacity: drawerOpen ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </Link>
    </Box>
  );
};

export default SideNavLink;
