
import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import PageToolbar from "../../components/admin/PageToolbar/PageToolbar";
import { setTopBarTitle } from "../../features/dashboardSlice/dashboardSlice";
import { useDispatch } from "react-redux";
import VendorList from "../../components/admin/VendorList/VendorList";

const Vendor = (props) => {

  // component states

  const title = "ImageMall | Find Vendor";
  //Redux states
  const dispatch = useDispatch()
  
  // use effect
  useEffect(() => {
    // hide top loader after component load
    dispatch(setTopBarTitle('Find Vendor'))
  }, [])
  
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageToolbar />
      <VendorList />
    </React.Fragment>
  );
}

export default Vendor;