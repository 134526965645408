import { requestGet, requestPost } from "./RequestService"

const prefix = '/statistics'

const getVendorJobsDoneAreaChart = async () => {
    return await requestGet(prefix + '/v-j-a-chart-data')
}
// admin
const getAdminEarningBarData = async () => {
    return await requestGet(prefix + '/ad-e-bar-data')
}
const getAdminTransactionsAreaChart = async () => {
    return await requestGet(prefix + '/ad-t-a-chart-data')
}
const getAdminJobsDoneAreaChart = async () => {
    return await requestGet(prefix + '/ad-j-a-chart-data')
}
// creator
const getCreatorEarningBarData = async () => {
    return await requestGet(prefix + '/c-e-bar-data')
}
const getCreatorTransactionsAreaChart = async () => {
    return await requestGet(prefix + '/c-t-a-chart-data')
}
const getCreatorJobsDoneAreaChart = async () => {
    return await requestGet(prefix + '/c-j-a-chart-data')
}
const getCreatorSpends = async () => {
    return await requestGet(prefix + '/c-spends')
}
const getAdminTotalTransaction = async () => {
    return await requestGet(prefix + '/ad-all-transactions')
}
const getClientPostedJobsChart = async () => {
    return await requestGet(prefix + '/cl-posted-jobs')
}
const getCCJobsChart = async () => {
    return await requestGet(prefix + '/cc-a-jobs')
}
const getVCJobsChart = async () => {
    return await requestGet(prefix + '/vc-a-jobs')
}
export {
    getAdminEarningBarData,
    getVendorJobsDoneAreaChart,
    getAdminTransactionsAreaChart,
    getAdminJobsDoneAreaChart,
    getCreatorEarningBarData,
    getCreatorTransactionsAreaChart,
    getCreatorJobsDoneAreaChart,
    getCreatorSpends,
    getAdminTotalTransaction,
    getClientPostedJobsChart,
    getCCJobsChart,
    getVCJobsChart
}