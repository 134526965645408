import React, { useEffect, useState } from "react";
import { Alert, Chip, Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import TextFieldComponent from "../../../common/TextFieldComponent/TextFieldComponent";
import LoadingButtons from "../../../common/LoadingButton/LoadingButtonComponent";
import { connect, useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { setTopLoader } from "../../../../features/mainSlice/mainSlice";
import SelectBox from "../../../common/SelectBoxCompnent/SelectBoxComponent";
import { getAll, getVendorServices } from "../../../../services/JobTypeService";
import { getLastCount, update } from "../../../../services/JobService";
import { setJobEffect } from "../../../../features/jobSlice/jobSlice";
import { getVendors } from "../../../../services/UsersService";
import { clearFileNames, setFileNames } from "../../../../features/dropzoneSlice/dropzoneSlice";
import Cookies from "js-cookie";
import ImageShower from "../../../common/ImageList/ImageList";
import { useNavigate } from "react-router-dom";
import DropZone from "../../../common/DropZone/DropZone";
import { snackBarPosition } from "../../../../services/CommonService";

const EditJob = (props) => {
  // component states
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [autoSaveDisabled, setAutoSaveDisabled] = useState(true);
  const [types, setTypes] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [onSubmitComplete, setOnSubmitComplete] = useState(false);
  const [autoPublishProgress, setAutoPublishProgress] = useState(0);
  const [dialogEditLoading, setDialogEditLoading] = useState(true);
  const [eCount, setEcount] = useState(0);
  const [prices, setPrices] = useState([]);
  const [priceCantSetAlert, setPriceCantSetAlert] = useState(false);
  const [pricePerImage, setPricePerImage] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [outPutFiles, setOutPutFiles] = useState(0);
  const [expectedOutputCount, setExpectedOutputCount] = useState(false);

  // snackbar
  const { enqueueSnackbar } = useSnackbar();

  const topLoader = useSelector((state) => state.main.topLoader);
  const role = useSelector((state) => state.common.role);
  const fileNames = useSelector((state) => state.dropzone.fileNames);
  const jobId = useSelector((state) => state.job.jobId);
  const jobEffect = useSelector((state) => state.job.jobEffect);
  const isUploadComplete = useSelector((state) => state.dropzone.isUploadComplete);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // form
  const { handleSubmit, control, setValue, formState } = useForm({
    mode: "onChange",
  });
  const durations = [
    { name: "12 Hours", value: 12 },
    { name: "24 Hours", value: 24 },
    { name: "36 Hours", value: 36 },
    { name: "48 Hours", value: 48 },
  ];
  // use effect
  useEffect(() => {
    dispatch(clearFileNames());
    getTypesForContractors();
    // get job types
  }, []);

  useEffect(() => {
    if (formState.isValid) {
      setAutoSaveDisabled(false);
    }
  }, [formState]);

  useEffect(() => {
    if (role !== "creator_contractor") {
      // get vendors list
      getVendors()
        .then((response) => {
          setVendors(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  useEffect(() => {
    if (props.jobData.job_id !== undefined) {
      getLastCount({ job_id: props.jobData.job_id })
        .then((response) => {
          setEcount(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [props]);
  const getServiceTypes = (id) => {
    dispatch(setTopLoader(true));
    setPriceCantSetAlert(false);
    getVendorServices({ id })
      .then((response) => {
        let types = [];
        let prices = [];
        types = [...types];
        prices = [...prices];
        response.data.data.map((item) => {
          types.push({ name: item.service, value: item.id });
          prices.push({ id: item.id, price: item.price });
        });
        setValue("type_id", "");
        setValue("total_output_files", "");
        setExpectedOutputCount(props.jobData.total_output_files);
        setTypes(types);
        setPrices(prices);
        setTotalAmount(calculateTotalPrice(outPutFiles, pricePerImage));
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setTopLoader(false));
      });
  };
  const getTypesForContractors = () => {
    dispatch(setTopLoader(true));
    getAll()
      .then((response) => {
        let types = [];
        types = [...types];
        response.data.data.map((item) => {
          return types.push({ name: item.name, value: item.id });
        });
        setTypes(types);
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setTopLoader(false));
      });
  };

  const onSubmit = (data) => {
    // // Show loaders
    setBtnLoading(true);
    // Send auth request
    data["job_id"] = props.jobData.job_id;
    data["work_file_names"] = fileNames;
    data["id"] = props.jobData.id;
    update(data)
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          dispatch(setFileNames([]));
          setOnSubmitComplete(true);
          dispatch(setJobEffect(!jobEffect));
          if (role !== "creator_contractor") {
            if (response.data.data.payment_id !== null) {
              navigate("/partners/pay?payment_id=" + response.data.data.payment_id);
            } else {
              navigate("/partners/jobs/manage");
            }
          } else {
            navigate("/partners/jobs/manage");
          }
        }
        setBtnLoading(false);
      })
      .catch((response) => {
        showError(response.response.data.data.message);
        setBtnLoading(false);
        console.log("error", response);
      });
  };
  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };
  const handleAutoPublishChange = (e) => {
    Cookies.set("auto_publish", e.target.checked);
  };
  const getServicePrices = (id) => {
    if (prices.length === 0 && role !== "creator_contractor") {
      setPriceCantSetAlert(true);
    }
    if (role !== "creator_contractor" && prices.length > 0) {
      var price;
      for (var i = 0; i < prices.length; i++) {
        if (prices[i].id === id) {
          price = prices[i].price;
          break;
        }
      }
      setPricePerImage(price.toFixed(2));
      setTotalAmount(calculateTotalPrice(outPutFiles, price));
    }
  };
  const handleFileCountChange = (count) => {
    setOutPutFiles(count);
    setTotalAmount(calculateTotalPrice(count, pricePerImage));
  };
  const calculateTotalPrice = (fileCount, price_per_image) => {
    if (role !== "creator_contractor") {
      const total = fileCount * price_per_image;
      return total.toFixed(2);
    }
  };
  return (
    <Grid container spacing={4}>
      <Grid item sm={12}>
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" id="job_form">
          <Typography align="right" sx={{ mb: 2 }}>
            <LoadingButtons loading={btnLoading} name="Continue" variant="contained" type="submit" disabled={!formState.isValid} />
          </Typography>
          {!props.loading && (
            <Grid container spacing={4}>
              <Grid item sm={6}>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={props.jobData.name}
                  render={({ field: { onChange, value = props.jobData.name }, fieldState: { error } }) => {
                    return <TextFieldComponent type={"text"} id="name" label="Name*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} marginTop={0} />;
                  }}
                  rules={{ required: "Name is required" }}
                />
                {role !== "creator_contractor" && (
                  <Controller
                    name="assigned_vendor"
                    control={control}
                    defaultValue={props.jobData.assigned_vendor !== null ? props.jobData.assigned_vendor.id : ""}
                    render={({ field: { onChange, value = props.jobData.assigned_vendor !== null ? props.jobData.assigned_vendor.id : "" }, fieldState: { error } }) => {
                      return (
                        <SelectBox
                          id={"assigned_vendor"}
                          label="Select a Vendor"
                          items={vendors}
                          error={!!error}
                          helperText={error ? error.message : null}
                          onChange={(e) => {
                            onChange(e);
                            getServiceTypes(e.target.value);
                          }}
                          defaultValue={value}
                          marginBottom={0}
                        />
                      );
                    }}
                  />
                )}
                {role === "creator_contractor" && <Typography sx={{ mt: 3 }}></Typography>}
                <Controller
                  name="type_id"
                  control={control}
                  defaultValue={props.jobData.client ? "" : props.jobData.job_type.id}
                  render={({ field: { onChange, value = props.jobData.job_type.id }, fieldState: { error } }) => {
                    return (
                      <SelectBox
                        id={"type_id"}
                        label="Select Service Type*"
                        items={types}
                        error={!!error}
                        helperText={error ? error.message : null}
                        onChange={(e) => {
                          onChange(e);
                          getServicePrices(e.target.value);
                        }}
                        defaultValue={value}
                        marginBottom={0}
                      />
                    );
                  }}
                  rules={{ required: "Service Type is required" }}
                />
                {/* {role !== "creator_contractor" && (
                  <Controller
                    name="price_per_image"
                    control={control}
                    defaultValue={props.jobData.price_per_image}
                    render={({
                      field: {
                        onChange,
                        value = props.jobData.price_per_image,
                      },
                      fieldState: { error },
                    }) => {
                      return (
                        <TextFieldComponent
                          type={"number"}
                          id="price_per_image"
                          label="Price Per Image $"
                          error={!!error}
                          helperText={error ? error.message : null}
                          onChange={onChange}
                          value={value}
                          marginTop={2}
                        />
                      );
                    }}
                  />
                )} */}
                <Controller
                  name="duration"
                  control={control}
                  defaultValue={props.jobData.duration}
                  render={({ field: { onChange, value = props.jobData.duration }, fieldState: { error } }) => {
                    return <SelectBox id={"duration"} label="Select Duration*" items={durations} error={!!error} helperText={error ? error.message : null} onChange={onChange} defaultValue={value} />;
                  }}
                  rules={{ required: "Duration is required" }}
                />
                <Controller
                  name="total_output_files"
                  control={control}
                  defaultValue={props.jobData.total_output_files}
                  render={({ field: { onChange, value = props.jobData.total_output_files }, fieldState: { error } }) => {
                    return (
                      <TextFieldComponent
                        type={"number"}
                        id="total_output_files"
                        label="Total Output Files*"
                        error={!!error}
                        helperText={error ? error.message : null}
                        onChange={(e) => {
                          onChange(e);
                          handleFileCountChange(e.target.value);
                        }}
                        value={value}
                        marginTop={0}
                      />
                    );
                  }}
                  rules={{ required: "Total Output Files is required" }}
                />
                {expectedOutputCount && (
                  <Typography variant="caption" sx={{ mt: 1, color: "info.main" }}>
                    <strong>Expected output files count : {expectedOutputCount}</strong>
                  </Typography>
                )}
              </Grid>
              <Grid item sm={6}>
                <Controller
                  name="description"
                  control={control}
                  defaultValue={props.jobData.description !== null ? props.jobData.description : ""}
                  render={({ field: { onChange, value = props.jobData.description !== null ? props.jobData.description : "" }, fieldState: { error } }) => {
                    return <TextField id="description" label="Describe Your Job" multiline rows={7} onChange={onChange} value={value} fullWidth />;
                  }}
                />
                {priceCantSetAlert && (
                  <Alert variant="outlined" severity="warning" sx={{ p: 0, pl: 2, pr: 2, mt: 2, mb: 2 }} onClose={() => setPriceCantSetAlert(false)}>
                    You should select a vendor for the calculate the price. <br />
                    <strong>Note: This will not effect to the job creation.</strong>
                  </Alert>
                )}
                {role !== "creator_contractor" && (
                  <Paper elevation={1} sx={{ p: 3, mt: 2 }}>
                    <Grid container spacing={4}>
                      <Grid item sm={8} sx={{ textAlign: "right" }}>
                        <Typography variant="body1">Price per image </Typography>
                        <Typography variant="body1">Total output files </Typography>
                        <Typography variant="body1">-</Typography>
                        <Typography variant="subtitle1">Total </Typography>
                        <Typography variant="caption" color={"text.secondary"}>
                          *ImageMall processing charges will be added in next step. -
                        </Typography>
                      </Grid>
                      <Grid item sm={4} sx={{ textAlign: "right" }}>
                        <Typography variant="body1">
                          $<strong>{pricePerImage}</strong>
                        </Typography>
                        <Typography variant="body1">
                          <strong>{outPutFiles}</strong>
                        </Typography>
                        <Typography variant="caption">
                          <strong>
                            ${pricePerImage} x {outPutFiles}{" "}
                          </strong>
                        </Typography>
                        <Typography variant="subtitle1">
                          $<strong>{totalAmount}</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                )}
              </Grid>
            </Grid>
          )}
          <Divider sx={{ mt: 3 }}>
            <Chip label="Work Files" />
          </Divider>
          <Grid container>
            <Grid item xs={12}>
              <ImageShower delete workingDirectroy={true} loading={props.loading} images={props.jobData.work_file_names} jobId={props.jobData.job_id} path={"storage/job-files/"} cols={5} />
            </Grid>
          </Grid>
          <Divider sx={{ mt: 3 }}>
            <Chip label="Upload Files" />
          </Divider>
          {/* <Paper variant="outlined" sx={{ p: 2, mt: 2 }}>
            <FormGroup>
              <FormControlLabel disabled={autoSaveDisabled} control={<Switch defaultChecked={Cookies.get('auto_publish') === 'true'} onChange={handleAutoPublishChange} />} label={<span><strong>Auto Publish</strong> Publish When Upload Is Completed. <CircularProgress variant="determinate" value={autoPublishProgress} size={15} /></span>} />
            </FormGroup>
          </Paper>
          {autoPublishProgress > 0 && <LinearProgress variant="determinate" value={autoPublishProgress} color="secondary" />} */}
          <Typography align="right">
            <LoadingButtons loading={btnLoading} name="Continue" variant="contained" type="submit" disabled={!formState.isValid} />
          </Typography>
        </form>
        <Grid container>
          <Grid item sm={12}>
            <DropZone prefix="jobs/upload-files" jobId={props.jobData.job_id} e_count={eCount} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  topLoader: state.topLoader,
  fileNames: state.fileNames,
  jobEffect: state.jobEffect,
});

const mapDispatchToProps = { setTopLoader,  setFileNames, setJobEffect, clearFileNames};

export default connect(mapStateToProps, mapDispatchToProps)(EditJob);
