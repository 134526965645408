
import React, {useState, useEffect} from "react";
import { Helmet } from "react-helmet";
import CreatePermission from "../../components/admin/CreatePermission/CreatePermission";
import PageToolbar from "../../components/admin/PageToolbar/PageToolbar";
import { setTopBarTitle } from "../../features/dashboardSlice/dashboardSlice";
import { useDispatch } from "react-redux";

const CreatePermissionPage = (props) => {

  // component states

  const title = "ImageMall | Create Permission";
  //Redux states
  const dispatch = useDispatch()
  
  // use effect
  useEffect(() => {
    // hide top loader after component load
    dispatch(setTopBarTitle('Create Permission'))
  }, [])
  
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageToolbar />
      <CreatePermission />
    </React.Fragment>
  );
}

export default CreatePermissionPage;