import React, { useState, useEffect } from "react";
import { List, ListItem, ListItemText, Button, CircularProgress, Grid, Typography, Card, Avatar, Chip } from "@mui/material";
import { getVendorTransactons } from "../../../services/PaymentService";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { Box } from "@mui/system";
const VendorTransactionHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [page, setPage] = useState(1);

  const loadTransactions = () => {
    setIsLoading(true);
    getVendorTransactons(page)
      .then((response) => {
        const data = response.data.data.data;
        if (data.length > 0) {
          setTransactions((prevTransactions) => [...prevTransactions, ...data]);
          setPage(page + 1);
          setLoadMore(true);
          setIsLoading(false);
        } else {
          setLoadMore(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadTransactions();
  }, []);
  const withdrawalStatusChipColor = (status) => {
    switch (status) {
      case 'paid':
        return 'success'
        break;
        case 'pending':
          return undefined
          break;
          case 'in_transit':
        return 'primary'
      default:
        return 'error'
        break;
    }
  }
  return (
    <Card variant="outlined" sx={{ p: 4, mt: 2 }}>
      <Typography variant="h5">Transactions</Typography>
      <List sx={{ width: "100%" }}>
        {transactions.map((transaction) => (
          <ListItem key={transaction.id}>
            <Grid container spacing={2}>
              <Grid item sm={3}>
                <ListItemText
                  primary={
                    <Box display="flex" flexDirection="row">
                      {transaction.earnings > 0 && (
                        <React.Fragment>
                          <Avatar sx={{ width: 21, height: 21, mr: 1, bgcolor:'success.main' }} >
                            <AddOutlinedIcon size='small' />
                          </Avatar>
                          <Typography variant="body2" color="primary"> {"US$" + transaction.earnings.toFixed(2)}</Typography>
                        </React.Fragment>
                      )}
                      {transaction.withdrawal > 0 && (
                        <React.Fragment>
                          <Avatar sx={{ width: 21, height: 21, mr: 1, bgcolor:'error.main' }}>
                            <RemoveOutlinedIcon size='small'/>
                          </Avatar>
                          <Typography variant="body2" color="success"> {"US$" + transaction.withdrawal.toFixed(2)}</Typography>
                        </React.Fragment>
                      )}
                    </Box>
                  }
                  sx={{fontSize:'0.2rem'}}
                />
              </Grid>
              <Grid item sm={3}>
              {transaction.earnings === 0 && <ListItemText primary={<Chip label={transaction.withdrawal_status.toUpperCase()} color={withdrawalStatusChipColor(transaction.withdrawal_status)} size="small"/>}></ListItemText> }
              {transaction.withdrawal === 0 && <ListItemText primary={<Typography variant="body2" color="primary">{transaction.job_id}</Typography>}></ListItemText>}

                {/* <Link to={"/partners/pay?payment_id=" + transaction.job_id} style={{ color: "inherit" }}>
                      
                    </Link> */}
              </Grid>
              <Grid item sm={5} sx={{ textAlign: "right" }}>
                <ListItemText primary={<Typography variant="body2" color={transaction.withdrawal === 0 ? 'primary' : 'success'}>{moment(transaction.created_at).format("lll")}</Typography>}></ListItemText>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>

      {loadMore && (
        <Button startIcon={<ExpandMoreOutlinedIcon />} fullWidth onClick={loadTransactions}>
          {isLoading ? <CircularProgress size={24} /> : "Load More"}
        </Button>
      )}
    </Card>
  );
};

export default VendorTransactionHistory;
