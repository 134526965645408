import { configureStore } from "@reduxjs/toolkit";
import mainReducer from "../features/mainSlice/mainSlice"
import commonReducer from "../features/commonSlice/commonSlice"
import dashboardReducer from "../features/dashboardSlice/dashboardSlice"
import rolePermissionReducer from '../features/RolePermissionSlice/RolePermissionSlice'
import usersReducer from '../features/usersSlice/usersSlice';
import dropzoneReducer from '../features/dropzoneSlice/dropzoneSlice';
import jobReducer from '../features/jobSlice/jobSlice';
import samplePageReducer from '../features/samplePageSlice/samplePageSlice';
import paymentReducer from '../features/PaymentsSlice/PaymentsSlice'

export const store = configureStore({
    reducer: {
        main: mainReducer,
        common: commonReducer,
        dashboard: dashboardReducer,
        role_permissions: rolePermissionReducer,
        users: usersReducer,
        dropzone: dropzoneReducer,
        job: jobReducer,
        samples_page: samplePageReducer,
        payments: paymentReducer
    }
});

