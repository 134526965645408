import React from "react";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

const AreaChartComponent = (props) => {
  const { width, height, data, dataKey, reversed} = props;
  return (
    <AreaChart
      width={width}
      height={height}
      data={data}
      margin={{
        top: 10,
        right: 30,
        left: 0,
        bottom: 0,
      }}
    >
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#1976d2" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#1976d2" stopOpacity={0} />
        </linearGradient>
      </defs>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" reversed={reversed}/>
      <YAxis />
      <Tooltip />
      <Area type="monotone" dataKey={dataKey} stackId="1" stroke="#1976d2" fill="url(#colorUv)" fillOpacity={1} />
    </AreaChart>
  );
};

export default AreaChartComponent;
