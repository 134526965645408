
import React, {useState, useEffect} from "react";
import { Helmet } from "react-helmet";
import RolePermissions from "../../components/admin/RolePermissions/RolePermissions";
import PageToolbar from "../../components/admin/PageToolbar/PageToolbar";
import { setTopBarTitle } from "../../features/dashboardSlice/dashboardSlice";
import { useDispatch } from "react-redux";

import { useSnackbar } from 'notistack';

const RolePermissionsPage = (props) => {

  // component states

  const title = "ImageMall | Role Permissions";
  //Redux states
  const dispatch = useDispatch()
  
  // snackbar
  const { enqueueSnackbar } = useSnackbar();

  useEffect(()=>{
    dispatch(setTopBarTitle('Role Permissions'))
  },[])
  
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageToolbar />
      <RolePermissions />
    </React.Fragment>
  );
}

export default RolePermissionsPage;