import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { Backdrop, Box, Chip, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import LoadingButtons from "../LoadingButton/LoadingButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import { setFeedbackDrawerOpen } from "../../../features/commonSlice/commonSlice";
import { authStore, store } from "../../../services/FeedBackService";
import { snackBarPosition } from "../../../services/CommonService";
import { useSnackbar } from "notistack";
export default function FeedBackDrawer() {
  const [feedback, setFeedback] = useState("");
  const [sendBtnDisable, setSendBtnDisable] = useState(true);
  const [sendBtnLoading, setSendBtnLoading] = useState(false);
  const feedbackDrawerOpen = useSelector((state) => state.common.feedbackDrawerOpen);
  const auth = useSelector((state) => state.main.auth);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const handleSend = () => {
    setSendBtnLoading(true);
    if (!auth) {
      store({ feedback })
        .then((response) => {
          if (response.data.success === true) {
            showSuccess(response.data.message);
            setFeedback("");
            setSendBtnDisable(false);
            setSendBtnLoading(false);
            dispatch(setFeedbackDrawerOpen(false));
          }
        })
        .catch((error) => {
          showError(error.response.data.message);
          setSendBtnDisable(false);
          setSendBtnLoading(false);
        });
    } else {
      authStore({ feedback })
        .then((response) => {
          if (response.data.success === true) {
            showSuccess(response.data.message);
            setFeedback("");
            setSendBtnDisable(false);
            setSendBtnLoading(false);
            dispatch(setFeedbackDrawerOpen(false));
          }
        })
        .catch((error) => {
          showError(error.response.data.message);
          setSendBtnDisable(false);
          setSendBtnLoading(false);
        });
    }
  };
  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };
  const feedBackOnChange = (e) => {
    if (e.target.value === "") {
      setSendBtnDisable(true);
    } else {
      setSendBtnDisable(false);
    }
    setFeedback(e.target.value);
  };
  return (
    <div>
      <Backdrop open={feedbackDrawerOpen} sx={{ zIndex: 1201 }} />
      <Drawer anchor={"right"} open={feedbackDrawerOpen} variant="persistent" elevation={20} PaperProps={{ sx: { zIndex: 1201, borderTopLeftRadius: 15, borderBottomLeftRadius: 15 } }}>
        <Box sx={{ width: 400 }}>
          <Grid container spacing={2} sx={{ boxShadow: 2 }}>
            <Grid item sm={10}>
              <Typography variant="inherit" sx={{ p: 2, fontSize: 18 }}>
                Send feedback to ImageMall
              </Typography>
            </Grid>
            <Grid item sm={2} sx={{ textAlign: "right" }}>
              <Box sx={{ pt: 0.8, pr: 0.2 }}>
                <Tooltip title="Close">
                  <IconButton onClick={() => dispatch(setFeedbackDrawerOpen(false))} sx={{ p: 1.5 }}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 4, p: 2, boxShadow: 2 }}>
            <Typography variant="body2" sx={{ mt: 1, mb: 1 }}>
              Describe your feedback
            </Typography>
            <TextField label="" multiline rows={4} placeholder="Tell us what prompted this feedback..." fullWidth onChange={feedBackOnChange} value={feedback} />
            <Typography variant="caption" sx={{ mt: 1, mb: 1 }} color="textSecondary">
              You can describe any of them
            </Typography>
            <br />
            <Chip label="request new features" size="small" sx={{ mr: 2 }} variant="outlined" />
            <Chip label="report bugs & errors" size="small" variant="outlined" />
          </Box>
          <Box sx={{ mt: 5, p: 2 }}>
            <Typography variant="body2" fontSize={12.5} color="textSecondary">
              Thank you for your feedback. We appreciate your input and will carefully review it. Rest assured, we will keep you updated on any progress or changes based on your feedback.
            </Typography>
          </Box>

          <Box sx={{ textAlign: "right", p: 2 }}>
            <LoadingButtons loading={sendBtnLoading} variant="contained" type="button" name={"Send"} color="primary" onClick={handleSend} disabled={sendBtnDisable} />
          </Box>
        </Box>
      </Drawer>
    </div>
  );
}
