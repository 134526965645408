import {
  Chip,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import LoadingButtons from "../../common/LoadingButton/LoadingButtonComponent";
import { keyframes } from "@mui/system";
import { useState } from "react";
import { jobAcception } from "../../../services/JobService";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { setJobEffect, setJobStatus } from "../../../features/jobSlice/jobSlice";
import ConfirmDialog from "../../common/ConfirmDialog/ConfirmDialog";

import AssignJob from "../AssignJob/AssignJob";
import { snackBarPosition } from "../../../services/CommonService";
import { useNavigate } from "react-router-dom";

const JobAccept = (props) => {
  const blink = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
    `;
  const { enqueueSnackbar } = useSnackbar();
  const [btnALoading, setBtnALoading] = useState(false);
  const [btnRLoading, setBtnRLoading] = useState(false);
  const [acceptConfirmDialog, setAcceptConfirmDialog] = useState(false);
  const [rejectConfirmDialog, setRejectConfirmDialog] = useState(false);
  const jobEffect = useSelector((state) => state.job.jobEffect);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAccept = () => {
    setAcceptConfirmDialog(true);
  };
  const handleReject = () => {
    setRejectConfirmDialog(true);
  };
  const handleAcceptConfirmDialog = () => {
    setBtnALoading(true);
    const job_id = props.job_id;
    const acception = "accept";
    jobAcception({ job_id, acception })
      .then((response) => {
        if (response.data.success === true) {
          dispatch(setJobStatus("on_working"));
          showSuccess(response.data.message);
          dispatch(setJobEffect(!jobEffect));
        }
        setBtnALoading(false);
      })
      .catch((error) => {
        console.log(error);
        showError(error.response.data.message);
        setBtnALoading(false);
      });
    setAcceptConfirmDialog(false);
  };
  const handleRejectConfirmDialog = () => {
    setBtnRLoading(true);
    const job_id = props.job_id;
    const acception = "reject";
    jobAcception({ job_id, acception })
      .then((response) => {
        if (response.data.success === true) {
          navigate("/partners/jobs/manage");
        }
        setBtnRLoading(false);
      })
      .catch((error) => {
        console.log(error);
        showError(error.response.data.message);
        setBtnRLoading(false);
      });
    setRejectConfirmDialog(false);
  };
  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };
  
  const handleCancelDialog = () => {
    setRejectConfirmDialog(false);
    setAcceptConfirmDialog(false);
  };
  return (
    <Box sx={{ marginTop: 1, marginBottom: 1 }}>
      <Paper
        variant="elevation"
        elevation={4}
        sx={{ padding: 2, animation: `${blink} 1s linear 3` }}
      >
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          You are assigned to this job. do you want to accept this job ?.
        </Typography>
        <Grid container sx={{ textAlign: "center" }}>
          <Grid item sm={6}>
            <LoadingButtons
              loading={btnALoading}
              variant="contained"
              type="button"
              name="Yes, Accept"
              color="success"
              onClick={handleAccept}
            />
          </Grid>
          <Grid item sm={6}>
            <LoadingButtons
              loading={btnRLoading}
              variant="outlined"
              type="button"
              name="No, Reject"
              color="warning"
              onClick={handleReject}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 3 }}>
          <Chip label="OR" />
        </Divider>
        <Typography
          variant="subtitle2"
          sx={{ mt: 2, mb:2 }}
          color="text.secondary"
          gutterBottom
        >
          You can accept & assign to your contractor.
        </Typography>
        <AssignJob job_id={props.job_id} accept_and_assign={true}/>
      </Paper>
      <ConfirmDialog
        onClickConfirm={handleAcceptConfirmDialog}
        onClickCancel={handleCancelDialog}
        open={acceptConfirmDialog}
        header="Are You Sure?"
        message="Please confirm to accept this job."
      />
      <ConfirmDialog
        onClickConfirm={handleRejectConfirmDialog}
        onClickCancel={handleCancelDialog}
        open={rejectConfirmDialog}
        header="Are You Sure?"
        message="Please confirm to reject this job."
      />
    </Box>
  );
};

export default JobAccept;
