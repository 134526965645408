import { Badge, Button, CardContent, Chip, Divider, Grid, IconButton, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import CardComponent from "../../common/CardComponent/CardComponent";
import PageContainer from "../PageContainer/PageContainer";
import { setTopLoader } from "../../../features/mainSlice/mainSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { updateUser } from "../../../services/UsersService";
import { useNavigate } from "react-router-dom";
import { VisibilityOutlined } from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ConfirmDialog from "../../common/ConfirmDialog/ConfirmDialog";
import DataDialog from "../../common/DataDialog/DataDialog";
import DataTable from "../../common/DataTable/DataTable";
import { useForm } from "react-hook-form";
import { deleteData, getAll, getById } from "../../../services/JobService";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import ViewJob from "./ViewJob/ViewJob";
import EditJob from "./EditJob/EditJob";
import { snackBarPosition } from "../../../services/CommonService";

const ManageJob = () => {
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [isRowsSet, setIsRowsSet] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dataDialogOpen, setDataDialogOpen] = useState(false);
  const [dataDialogEditOpen, setDataDialogEditOpen] = useState(false);
  const [dataDialogLading, setDataDialogLading] = useState(true);
  const [dataEditDialogLading, setDataEditDialogLading] = useState(true);
  const [selectId, setSelectId] = useState(null);
  const [jobData, setJobData] = useState({});
  const [loading, setLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [preview, setPreview] = useState(undefined);
  const [profileImage, setProfileImage] = useState(undefined);
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [dataTableLoading, setDataTableLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);

  const [roles, setRoles] = useState([]);
  // form
  const { handleSubmit, control, reset, formState, watch } = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit: handleSubmit2,
    control: control2,
    reset: reset2,
    formState: formState2,
  } = useForm({
    mode: "onChange",
  });
  const password = useRef({});
  password.current = watch("password", "");

  // snackbar
  const { enqueueSnackbar } = useSnackbar();
  const dataDialogPasswordResetModalOpen = useSelector((state) => state.users.dataDialogPasswordResetModalOpen);
  const actions = useSelector((state) => state.common.actions);
  const jobEffect = useSelector((state) => state.job.jobEffect);
  const role = useSelector((state) => state.common.role);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  function createData(id, job_id, type, name, duration, total_output_files, vendor, due_time, amount, payment, status, created_by, created_at, action) {
    return {
      id,
      job_id,
      type,
      name,
      duration,
      total_output_files,
      vendor,
      due_time,
      amount,
      payment,
      status,
      created_by,
      created_at,
      action,
    };
  }
  const status_color = {
    not_assigned: "warning",
    assigned: "primary",
    on_working: "secondary",
    delivered: "info",
    canceled: "error",
    completed: "success",
    ongoing: "primary",
  };
  const payment_status_color = {
    processing: "warning",
    not_paid: "secondary",
    refunded: "info",
    failed: "error",
    rejected: "error",
    canceled: "error",
    succeeded: "success",
  };
  // use effect
  useEffect(() => {
    if (actions.length > 0) {
      getJobs();
      setDataDialogOpen(false);
    }
  }, [actions, jobEffect]);
  // tab Change
  useEffect(() => {
    if (role === "super_admin") {
      if (activeTab === 0) {
        getJobs("all");
      } else if (activeTab === 1) {
        getJobs("client");
      } else if (activeTab === 2) {
        getJobs("on_working");
      } else if (activeTab === 3) {
        getJobs("completed");
      } else if (activeTab === 4) {
        getJobs("canceled");
      }
    } else {
      if (activeTab === 0) {
        getJobs("all");
      } else if (activeTab === 1) {
        getJobs("on_working");
      } else if (activeTab === 2) {
        getJobs("completed");
      } else if (activeTab === 3) {
        getJobs("canceled");
      }
    }
  }, [activeTab]);
  const getJobs = (type = "all") => {
    dispatch(setTopLoader(true));
    setRows([]);
    getAll(type)
      .then((response) => {
        let columns = [];
        Object.values(response.data.data.columns).map((item) => {
          const render_cell = item.render_cell
            ? (params) => {
                return params.value;
              }
            : false;
          const align = item.align ? item.align : false;
          const headerAlign = item.align ? item.align : false;
          columns.push({
            field: item.field,
            headerName: item.headerName,
            minWidth: item.minWidth,
            editable: item.editable,
            renderCell: render_cell,
            align: align,
            headerAlign: headerAlign,
          });
        });
        setColumns(columns);
        let rows = [];
        rows = [...rows];
        response.data.data.rows.map((item, index) => {
          let st = item.status;
          let p_st = item.payment_status ? item.payment_status.payment_status : "not_paid";
          let amount;
          if (role === "vendor") {
            amount = item.payment_status_for_vendor ? "$" + item.payment_status_for_vendor.amount.toFixed(2) : "$0";
          } else {
            amount = item.payment_status ? "$" + item.payment_status.amount.toFixed(2) : "$0";
          }

          const color = status_color[st];
          const payment_color = payment_status_color[p_st];

          const status = <Chip label={st.replace("_", " ").toUpperCase()} size="small" variant="outlined" color={color} />;
          const payment_status = <Chip label={p_st.replace("_", " ").toUpperCase()} size="small" variant="outlined" color={payment_color} />;
          const action = (
            <React.Fragment>
              {/* {actions.includes("view_job") && ( */}
              <Tooltip title="View">
                <IconButton onClick={(e) => handleViewClick(item.job_id)}>
                  <VisibilityOutlined fontSize="small" color="action" />
                </IconButton>
              </Tooltip>
              {/* )} */}
              {actions.includes("update_job") && st !== "on_working" && st !== "completed" && st !== "delivered" && (
                <Tooltip title="Edit">
                  <IconButton onClick={(e) => handleEditClick(item.id)}>
                    <EditOutlinedIcon fontSize="small" color="action" />
                  </IconButton>
                </Tooltip>
              )}
              {actions.includes("delete_job") && st !== "on_working" && st !== "completed" && st !== "delivered" && (
                <Tooltip title="Delete">
                  <IconButton onClick={(e) => handleDelete(item.id)}>
                    <DeleteOutlineOutlinedIcon fontSize="small" color="action" />
                  </IconButton>
                </Tooltip>
              )}
              {actions.includes("paying") && st !== "not_assigned" && (p_st === "not_paid" || p_st === "failed" || p_st === "rejected" || p_st === "canceled") && (
                <Tooltip title="Pay Now">
                  <IconButton onClick={(e) => handlePayNow(item.payment_id)}>
                    <PaymentsOutlinedIcon fontSize="small" color="action" />
                  </IconButton>
                </Tooltip>
              )}
            </React.Fragment>
          );
          const created_at = new Date(item.created_at).toLocaleDateString();
          rows.push(
            createData(
              item.id,
              item.job_id,
              item.job_type !== null ? item.job_type.name : "",
              item.name,
              item.duration + " Hours",
              item.total_output_files,
              item.assigned_vendor ? item.assigned_vendor.name : "",
              item.due_time,
              amount,
              payment_status,
              status,
              item.created_user.name,
              created_at,
              action
            )
          );
        });

        setRows(rows);
        setIsRowsSet(true);
        dispatch(setTopLoader(false));
        setDataTableLoading(false);
      })
      .catch((error) => {
        showError(error.response.message);
        dispatch(setTopLoader(false));
        setDataTableLoading(false);
      });
  };

  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };
  // Delete Job
  const handleConfirmDialog = (event) => {
    setDialogOpen(false);
    dispatch(setTopLoader(true));
    deleteData({ id: selectId })
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          dispatch(setTopLoader(false));
          setDataDialogEditOpen(false);
          getJobs();
        }
      })
      .catch((error) => {
        showError(error.response.data.message);
        dispatch(setTopLoader(false));
      });
  };
  const handleDelete = (id) => {
    setDialogOpen(true);
    setSelectId(id);
  };
  const handleCancelDialog = () => {
    setDialogOpen(false);
  };
  // End Delete Job

  const handleCancelDataDialog = () => {
    setDataDialogOpen(false);
  };

  const handleCancelEditDataDialog = () => {
    setDataDialogEditOpen(false);
  };

  const handleCreateJobClick = () => {
    navigate("/partners/jobs/create");
  };

  const handleViewClick = (job_id) => {
    navigate("/partners/jobs/view?job_id=" + job_id);
  };
  const handleEditClick = (id) => {
    reset();
    setDataDialogEditOpen(true);
    setDataEditDialogLading(true);
    setEditLoading(true);
    getById({ id })
      .then((response) => {
        setJobData(response.data.data);
        setDataEditDialogLading(false);
        setEditLoading(false);
      })
      .catch((error) => {
        showError(error.response.data.message);
        setDataEditDialogLading(false);
        setEditLoading(false);
      });
  };
  const onSubmit = (data) => {
    setDataEditDialogLading(true);
    setBtnLoading(true);
    if (profileImage != undefined) {
      data["image"] = profileImage;
    }
    data["id"] = jobData.id;
    updateUser(data)
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          setDataEditDialogLading(false);
          setBtnLoading(false);
          setBtnDisabled(false);
          // getUser();
          setDataDialogEditOpen(false);
        }
        reset();
        setPreview(undefined);
        setProfileImage(undefined);
      })
      .catch((error) => {
        if (error.response.status === 422) {
          showError(error.response.data.data[0]);
        } else {
          showError(error.response.data.message);
        }
        setDataEditDialogLading(false);
        setBtnLoading(false);
        setBtnDisabled(false);
      });
  };
  const handlePayNow = (payment_id) => {
    navigate("/partners/pay?payment_id=" + payment_id);
  };
  const handlePostNewJobClick = () => {
    window.location.href = "/upload-images";
  };
  return (
    <PageContainer>
      <ConfirmDialog onClickConfirm={handleConfirmDialog} onClickCancel={handleCancelDialog} open={dialogOpen} header="Are You Sure?" message="Please confirm to delete this data. this action cannot revert" />
      {/* // job edit dialog */}
      <DataDialog onClickCancel={handleCancelEditDataDialog} open={dataDialogEditOpen} header="Edit Job" loading={dataEditDialogLading} fullWidth={true} maxWidth="lg">
        <EditJob jobData={jobData} loading={editLoading} />
      </DataDialog>
      <Grid container spacing={4}>
        <Grid item sm={12}>
          <CardComponent>
            <CardContent>
              <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)}>
                <Tab label="All" />
                {role === "super_admin" && <Tab label="Client" />}
                <Tab label="Ongoing" />
                <Tab label="Archived" />
                <Tab label="Canceled" />
              </Tabs>
              {actions.includes("store_job") && (
                <Typography align="right">
                  <Button onClick={handleCreateJobClick} sx={{ mb: 3 }}>
                    <AddPhotoAlternateOutlinedIcon />
                    &nbsp; Create Job
                  </Button>
                </Typography>
              )}
              {role === "client" && (
                <Typography align="right">
                  <Button onClick={handlePostNewJobClick} sx={{ mb: 3 }}>
                    <AddPhotoAlternateOutlinedIcon />
                    &nbsp; Post New Job
                  </Button>
                </Typography>
              )}
              <DataTable rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection disableSelectionOnClick experimentalFeatures={{ newEditingApi: true }} loading={dataTableLoading} />
            </CardContent>
          </CardComponent>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default ManageJob;
