import React, { useEffect } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setTopLoader } from "../../../../features/mainSlice/mainSlice";
import { saveCard } from "../../../../services/PaymentService";
import { useSnackbar } from "notistack";
import { setAddCardState } from "../../../../features/PaymentsSlice/PaymentsSlice";
import LoadingButtons from "../../../common/LoadingButton/LoadingButtonComponent";
import { useState } from "react";
import { useTheme } from "@mui/material";
import { snackBarPosition } from "../../../../services/CommonService";
const AddCard = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const addCardState = useSelector((state) => state.payments.addCardState);
  const theme = useTheme();
  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };

  const onSubmit = (data) => {
    dispatch(setTopLoader(true));
    setBtnLoading(true);
    saveCard(data)
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          dispatch(setAddCardState(!addCardState));
        }
        dispatch(setTopLoader(false));
        setBtnLoading(false);
      })
      .catch((error) => {
        console.log(error);
        showError(error.response.data.message);
        dispatch(setTopLoader(false));
        setBtnLoading(false);
      });
  };
  // validate expiration date
  useEffect(() => {
    register("expirationDate", {
      required: "Expiration date is required",
      validate: (value) => {
        const monthYearRegex = /^(0[1-9]|1[0-2])\/[0-9]{2}$/;
        if (!monthYearRegex.test(value)) {
          return "Invalid expiration date";
        }

        const [month, year] = value.split("/");
        const currentYear = new Date().getFullYear().toString().substr(-2);
        const currentMonth = new Date().getMonth() + 1;
        if (parseInt(year, 10) < parseInt(currentYear, 10)) {
          return "Expired card";
        }
        if (parseInt(year, 10) === parseInt(currentYear, 10) && parseInt(month, 10) < currentMonth) {
          return "Expired card";
        }

        return true;
      },
    });
  }, [register]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
        maxWidth: "400px",
        margin: "0 auto",
      }}
    >
      <Typography variant="h4" align="center" gutterBottom>
          Card Details
        </Typography>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 3 }}>
          <img src={theme.palette.mode == "dark" ? "/img/Stripe wordmark - white (small).png" : "/img/Stripe wordmark - blurple (small).png"} alt="Stripe logo" width="20%" />
          <Typography variant="body1">Trusted and Powered by Stripe</Typography>
        </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Name on Card"
          variant="outlined"
          fullWidth
          {...register("cardName", {
            required: "Card name is required",
          })}
          error={errors.cardName ? true : false}
          helperText={errors.cardName?.message}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Card number"
          variant="outlined"
          fullWidth
          {...register("cardNumber", {
            required: "Card number is required",
            pattern: {
              value: /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
              message: "Invalid card number",
            },
          })}
          error={errors.cardNumber ? true : false}
          helperText={errors.cardNumber?.message}
          sx={{ mb: 2 }}
        />
        <TextField label="Expiration date MM/YY" variant="outlined" fullWidth {...register("expirationDate")} error={errors.expirationDate ? true : false} helperText={errors.expirationDate?.message} sx={{ mb: 2 }} />
        <TextField
          label="CVC"
          variant="outlined"
          fullWidth
          {...register("cvc", {
            required: "CVC is required",
            pattern: {
              value: /^[0-9]{3}$/,
              message: "Invalid CVC",
            },
          })}
          error={errors.cvc ? true : false}
          helperText={errors.cvc?.message}
          sx={{ mb: 2 }}
        />
        <LoadingButtons loading={btnLoading} name="Save Card" variant="contained" type="submit" />
      </form>
    </Box>
  );
};

export default AddCard;
