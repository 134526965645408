import axios from "axios";
import Cookies from "js-cookie";
import { baseApiURL, requestGet, requestPost } from "./RequestService"
const prefix = '/my-account'

const get = async () => {
    return await requestGet(prefix + '/get');
};
const resetPassword = async (data) => {
    return await requestPost(prefix + "/reset-password", data);
};
const update = async (data) => {
    return await axios.post(baseApiURL + prefix.replace('/','') + "/update", data, {
        headers: {
          Accept: "application/json",
          "Content-type": "multipart/form-data",
          Authorization: "Bearer " + Cookies.get("token") ?? "",
        },
      });
};
export { get, resetPassword, update }