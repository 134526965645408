import React from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setJobEffect,
  setJobStatus,
} from "../../../features/jobSlice/jobSlice";
import { assign } from "../../../services/JobService";
import { getVendorContractors } from "../../../services/UsersService";
import ConfirmDialog from "../../common/ConfirmDialog/ConfirmDialog";
import LoadingButtons from "../../common/LoadingButton/LoadingButtonComponent";
import { useNavigate } from "react-router-dom";
import { PersonAddAlt1Outlined } from "@mui/icons-material";
import { snackBarPosition } from "../../../services/CommonService";

const AssignJob = (props) => {
  const [assignConfirmDialog, setAssignConfirmDialog] = useState(false);
  const [vendorContractors, setVendorContractros] = useState([]);
  const [vendorContractor, setVendorContractor] = useState("");
  const [vendorCSlectErr, setVendorCSelectErr] = useState(false);
  const [btnAssignLoading, setBtnAssignLoading] = useState(false);
  const [vendorCNotFound, setVendorCNoFound] = useState(false);
  const jobEffect = useSelector((state) => state.job.jobEffect);
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { job_id, accept_and_assign } = props;
  useEffect(() => {
    getVendorContractors()
      .then((response) => {
        if (response.data.success === true) {
          response.data.data.length > 0
            ? setVendorContractros(response.data.data)
            : setVendorCNoFound(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };
  const handleAssignConfirmDialog = () => {
    setBtnAssignLoading(true);
    assign({ job_id, vendor: vendorContractor, accept_and_assign })
      .then((response) => {
        if (response.data.success === true) {
          dispatch(setJobStatus("on_working"));
          showSuccess(response.data.message);
          dispatch(setJobEffect(!jobEffect));
        }
        setBtnAssignLoading(false);
      })
      .catch((error) => {
        console.log(error);
        showError(error.response.data.message);
        setBtnAssignLoading(false);
      });
    setAssignConfirmDialog(false);
  };
  const handleCancelDialog = () => {
    setAssignConfirmDialog(false);
  };
  const vcOnChange = (e) => {
    setVendorContractor(e.target.value);
  };
  const handleAssign = () => {
    if (vendorContractor !== "") {
      setVendorCSelectErr(false);
      setAssignConfirmDialog(true);
    } else {
      setVendorCSelectErr(true);
    }
  };
  const handleCreateUserClick = () => {
    navigate("/partners/users/create-user");
  };
  return (
    <React.Fragment>
      {vendorContractors.length > 0 && (
        <FormControl fullWidth error={vendorCSlectErr}>
          <InputLabel id="demo-simple-select-error-label">
            Select Contractor*
          </InputLabel>
          <Select
            labelId="select-box"
            id="select-box"
            label="Select Contractor*"
            value={vendorContractor}
            onChange={vcOnChange}
            sx={{ color: "inherit" }}
            error={vendorCSlectErr}
          >
            {vendorContractors?.map((item) => {
              return (
                <MenuItem value={item.value} key={item.value}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
          {vendorCSlectErr && (
            <FormHelperText sx={{ color: "error.main" }}>
              Please Select Your Contractor
            </FormHelperText>
          )}
          <LoadingButtons
            loading={btnAssignLoading}
            variant="contained"
            type="button"
            name={accept_and_assign ? "Accept & Assign" : "Assign"}
            color="primary"
            onClick={handleAssign}
          />
        </FormControl>
      )}
      {vendorCNotFound && (
          <Paper sx={{p:2}}>
          <Typography variant="subtitle2">Contractors not found.</Typography>
          <Button onClick={handleCreateUserClick} sx={{ mb: 3 }} size="small">
            <PersonAddAlt1Outlined />
            &nbsp; Create Contractor
          </Button>{" "}
        </Paper>
      )}
      <ConfirmDialog
        onClickConfirm={handleAssignConfirmDialog}
        onClickCancel={handleCancelDialog}
        open={assignConfirmDialog}
        header="Are You Sure?"
        message="Please confirm to assign this job."
      />
    </React.Fragment>
  );
};

export default AssignJob;
