import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { TextField, Select, MenuItem, Button, FormControl, Grid, Divider, Typography, List, ListItem, ListItemText, ListItemButton, IconButton } from "@mui/material";
import SelectBox from "../../common/SelectBoxCompnent/SelectBoxComponent";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setTopLoader } from "../../../features/mainSlice/mainSlice";
import { getAllForPublic } from "../../../services/JobTypeService";
import LoadingButtons from "../../common/LoadingButton/LoadingButtonComponent";
import TextFieldComponent from "../../common/TextFieldComponent/TextFieldComponent";
import { Container } from "@mui/system";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { get, store } from "../../../services/ServicePricesService";
import { useSnackbar } from "notistack";
import { snackBarPosition } from "../../../services/CommonService";

const ServicePrices = () => {
  const [types, setTypes] = useState([]);
  const [services, setServices] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState('')
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, formState, setValue } = useForm({
    mode: "onChange",
  });
  useEffect(() => {
    getTypes();
    getServices();
  }, []);
  const showError = (message) => {
    enqueueSnackbar(message, { variant: "error", anchorOrigin: snackBarPosition });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, { variant: "success", anchorOrigin: snackBarPosition });
  };
  const getTypes = () => {
    getAllForPublic()
      .then((response) => {
        let types = [];
        types = [...types];
        response.data.data.map((item) => {
          return types.push({ name: item.name, value: item.id });
        });
        setTypes(types);
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        dispatch(setTopLoader(false));
      });
  };
  const getServices = () => {
    get()
      .then((response) => {
        setServices(response.data.data);
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        dispatch(setTopLoader(false));
      });
  };
  const onSubmit = (data) => {
    setBtnLoading(true);
    store(data)
      .then((response) => {
        showSuccess(response.data.message);
        getServices();
        setBtnLoading(false);
        setValue('type_id', '');
        setValue('price', '')
      })
      .catch((error) => {
        showError(error.response.data.message);
        setBtnLoading(false);
      });
  };
  const edit = (service_id, price) => {
    setValue('type_id', service_id);
    setValue('price', price.toFixed(2))
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item sm={6}>
            <Controller
              name="type_id"
              control={control}
              defaultValue={""}
              render={({ field: { onChange, value = "" }, fieldState: { error } }) => {
                return <SelectBox size="small" id={"type_id"} label="Select Type*" items={types} error={!!error} helperText={error ? error.message : null} defaultValue={value} onChange={onChange} />;
              }}
              rules={{ required: "Type is required" }}
            />
          </Grid>
          <Grid item sm={4}>
            <Controller
              name="price"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                return <TextFieldComponent size="small" type={"number"} id="price" label="Price*" error={!!error} helperText={error ? error.message : null} value={value} marginTop={3} onChange={onChange} />;
              }}
              rules={{
                required: "Price is required",
                min: {
                  value: 0.30,
                  message: "Minimum value is 0.30",
                },
                max: {
                  value: 100.0,
                  message: "Maximum value is 100.00",
                },
              }}
            />
          </Grid>
          <Grid item sm={2} sx={{ textAlign: "center" }}>
            <LoadingButtons loading={btnLoading} variant="contained" type="submit" name={"Submit"} color="primary" disabled={!formState.isValid} />
          </Grid>
        </Grid>
      </form>
      <Divider />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Your Services Price List
          </Typography>
          <List sx={{ width: "100%" }}>
            {services?.map((item) => (
              <ListItem
                secondaryAction={
                  <IconButton edge="end" aria-label="edit" onClick={()=>edit(item.service_id,item.price)}>
                    <EditOutlinedIcon />
                  </IconButton>
                }
                key={item.id}
              >
                <ListItemButton onClick={()=>edit(item.service_id,item.price)}>
                  <ListItemText primary={item.service} secondary={"$" + item.price.toFixed(2)} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ServicePrices;
