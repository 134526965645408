import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   selectedPermissionsId:[]
}

export const rolePermissionSlice = createSlice({
    name: 'role_permissions',
    initialState,
    reducers:{
        setSelectedPermissionsId: (state, action) => {
            state.selectedPermissionsId = action.payload
        },
    
    }
})

// export individual action creator functions
export const { setSelectedPermissionsId } = rolePermissionSlice.actions;

// often the reducer is a default export, but that doesn't matter
export default rolePermissionSlice.reducer;

