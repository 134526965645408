import { Fragment, useEffect, useState } from "react";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { IconButton, Tooltip } from "@mui/material";
const ScrollToTop = () => {
  const [scrollButton, setScrollButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      if (scrollPosition > 200) {
        setScrollButton(true);
      } else {
        setScrollButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <Fragment>
      {scrollButton && (
        <Tooltip title="Scroll to top">
          <IconButton onClick={scrollToTop} sx={{ position: "fixed", bottom: 30, right: 30, backgroundColor: "primary.main" }} size="large">
            <KeyboardArrowUpOutlinedIcon/>
          </IconButton>
        </Tooltip>
      )}
    </Fragment>
  );
};

export default ScrollToTop;
