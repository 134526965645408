import React, { useState, useEffect } from "react";
import { Alert, Button, Collapse, Stack } from "@mui/material";


const ToolbarAlert = (props) => {

  const [open, setOpen] = useState(true);

  return (
    <React.Fragment>
      <Stack sx={{ width: '100%', mb:2,mt:2 }} spacing={2}>
      <Collapse in={open}>
        <Alert
          color={props.color}
          severity={props.severity}
          action={props.close &&
            <Button onClick={()=>setOpen(false)} color="inherit" size="small">
              DISMISS
            </Button>
          }
        >
          {props.alert}
        </Alert>
        </Collapse>
      </Stack>
    </React.Fragment>
  );
}


export default ToolbarAlert;