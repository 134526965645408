import { Avatar, Badge, Button, ClickAwayListener, Divider, Grid, IconButton, List, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, Paper, Popper, Tooltip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { baseApiURL, baseWebURL } from "../../../services/RequestService";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import moment from "moment";
import WorkIcon from "@mui/icons-material/Work";
import { useState } from "react";
import { useEffect } from "react";
import RawOnOutlinedIcon from "@mui/icons-material/RawOnOutlined";
import { Box } from "@mui/system";
import { getActivitiesTop, topRead } from "../../../services/ActivityService";
// import { chnlActivity } from "../../../services/PusherService";
import { setActivityRead, setTopLoader } from "../../../features/mainSlice/mainSlice";
import { useNavigate } from "react-router-dom";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import StarHalfOutlinedIcon from "@mui/icons-material/StarHalfOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { chnlActivity } from "../../../services/PusherService";

const ActivityNotifyMenuBox = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [activities, setActvities] = useState([]);
  const [activitiesCount, setActvitiesCount] = useState(0);
  const userAvatar = useSelector((state) => state.common.userAvatar);
  const activityRead = useSelector((state) => state.main.activityRead);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    // Pusher
    chnlActivity.bind("activity-log", function (data) {
      if (data.activities !== undefined) {
        setActvities((current) => [data.activities, ...current]);
        setActvitiesCount(data.count);
      }
    }, chnlActivity.unbind());
  },[]);
  // activities
  useEffect(() => {
    getActivities();
  }, [activityRead]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
    topRead()
    dispatch(setActivityRead(!activityRead))
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const getActivities = () => {
    // Get nav routes
    getActivitiesTop().then((response) => {
      if (response.data.success) {
        setActvities(response.data.data.activites);
        setActvitiesCount(response.data.data.count);
        dispatch(setTopLoader(false));
      }
    });
  };
  const seeAllActivities = () => {
    handleClose();
    navigate("/partners/activity-log");
  };
  return (
    <React.Fragment>
      <Tooltip title="Activity">
        <IconButton sx={{ flexGrow: 0, marginRight: 1 }} onClick={handleClick}>
          <Badge badgeContent={activitiesCount} color="error">
            <FormatListBulletedIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popper anchorEl={anchorEl} open={open} placement="bottom-end" sx={{ minWidth: "400px", maxWidth: "400px" }} disablePortal>
        <ClickAwayListener onClickAway={handleClose}>
          <Paper sx={{ mt: "40px" }} elevation={12}>
            <Grid container spacing={2} sx={{ p: 1 }}>
              <Grid item sm={8}>
                <Typography variant="body2">
                  <strong>Activity</strong>
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <Typography align="right" sx={{ mt: -1 }}>
                  <IconButton onClick={handleClick}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 1 }} />
            <Box sx={{ maxHeight: "450px", overflowY: "auto" }}>
              {activities?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <ListItemButton selected={!item.read} onClick={seeAllActivities}>
                      <ListItemAvatar>
                        {item.code === "ACTIVITY_JOB_TYPES" && (
                          <Avatar
                            sx={{
                              color: "secondary.main",
                              bgcolor: "primary.lighter",
                            }}>
                            <RawOnOutlinedIcon />
                          </Avatar>
                        )}
                        {item.code === "ACTIVITY_JOB" && (
                          <Avatar
                            sx={{
                              color: "success.main",
                              bgcolor: "primary.lighter",
                            }}>
                            <WorkIcon />
                          </Avatar>
                        )}
                        {item.code === "ACTIVITY_PERMISSION" && (
                          <Avatar
                            sx={{
                              color: "success.main",
                              bgcolor: "primary.lighter",
                            }}>
                            <VerifiedUserOutlinedIcon />
                          </Avatar>
                        )}
                        {item.code === "ACTIVITY_PORTFOLIO" && (
                          <Avatar
                            sx={{
                              color: "success.main",
                              bgcolor: "primary.lighter",
                            }}>
                            <BadgeOutlinedIcon />
                          </Avatar>
                        )}
                        {item.code === "ACTIVITY_RATING" && (
                          <Avatar
                            sx={{
                              color: "success.main",
                              bgcolor: "primary.lighter",
                            }}>
                            <StarHalfOutlinedIcon />
                          </Avatar>
                        )}
                        {item.code === "ACTIVITY_REGISTER" && (
                          <Avatar
                            sx={{
                              color: "success.main",
                              bgcolor: "primary.lighter",
                            }}>
                            <PersonAddOutlinedIcon />
                          </Avatar>
                        )}
                        {item.code === "ACTIVITY_ROLEPERMISSION" && (
                          <Avatar
                            sx={{
                              color: "success.main",
                              bgcolor: "primary.lighter",
                            }}>
                            <VerifiedUserOutlinedIcon />
                          </Avatar>
                        )}
                        {item.code === "ACTIVITY_USER" && (
                          <Avatar
                            sx={{
                              color: "success.main",
                              bgcolor: "primary.lighter",
                            }}>
                            <PersonOutlineOutlinedIcon />
                          </Avatar>
                        )}
                        {item.code === "ACTIVITY_STRIPE_CUSTOMER" && (
                          <Avatar
                            sx={{
                              color: "primary.main",
                              bgcolor: "primary.lighter",
                            }}>
                            <PaymentsOutlinedIcon />
                          </Avatar>
                        )}
                        {item.code === "ACTIVITY_PRICING" && (
                          <Avatar
                            sx={{
                              color: "primary.main",
                              bgcolor: "primary.lighter",
                            }}>
                            <RawOnOutlinedIcon />
                          </Avatar>
                        )}
                        {item.code === "ACTIVITY_SYSTEM_SETTING" && (
                          <Avatar
                            sx={{
                              color: "primary.main",
                              bgcolor: "primary.lighter",
                            }}>
                            <SettingsOutlinedIcon />
                          </Avatar>
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="body2">
                            {item.name.substring(0, 25)} {item.name.length > 25 && "..."}
                          </Typography>
                        }
                        secondary={
                          item.description !== undefined && item.description !== null ? (
                            <span>
                              <strong>{item.user_name}</strong> {item.description}
                            </span>
                          ) : (
                            moment(item.created_at).fromNow()
                          )
                        }
                      />
                      <ListItemSecondaryAction>
                        <Typography variant="caption" noWrap>
                          {item.description !== undefined && item.description !== null && moment(item.created_at).fromNow()}
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItemButton>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </Box>
            <ListItemButton sx={{ textAlign: "center", py: `${12}px !important` }} onClick={seeAllActivities}>
              <ListItemText
                primary={
                  <Typography variant="body2" color="primary">
                    Show all activities
                  </Typography>
                }
              />
            </ListItemButton>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </React.Fragment>
  );
};

export default ActivityNotifyMenuBox;
