import * as React from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { Button, CircularProgress, Grid, ListItemButton, ListItemIcon, ListItemSecondaryAction, Paper } from "@mui/material";
import { Box } from "@mui/system";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useState } from "react";
import { getAll, read } from "../../../services/NotificationService";
import { baseWebURL } from "../../../services/RequestService";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationRead, setTopLoader } from "../../../features/mainSlice/mainSlice";
import moment from "moment/moment";
import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined";
import { useSnackbar } from "notistack";
import { useTheme } from "@emotion/react";
import { useRef } from "react";
import { snackBarPosition } from "../../../services/CommonService";

export default function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [selectNotification, setSelectNotification] = useState(false);
  const [selectedNotificationData, setSelectedNotificationData] = useState([]);
  const [selectedNotificationDataDetails, setSelectedNotificationDataDetails] = useState([]);
  const [notificationListSet, setIsNotificationListSet] = useState(false);
  const [prevId, setPrevId] = useState(undefined);
  const notificationRead = useSelector((state) => state.main.notificationRead);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  // useEffect(() => {
  //   // Pusher
  //   chnlException.bind(
  //     "notification",
  //     function (data) {
  //       console.log(data);
  //       if (data.exception !== undefined) {
  //         setNotifications((current) => [data.notification, ...current]);
  //         setNotificationCount(data.count);
  //       }
  //     },
  //     chnlException.unbind()
  //   );
  // });
  // notification
  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = () => {
    dispatch(setTopLoader(true));
    getAll()
      .then((response) => {
        let notifications = [];
        notifications = [...notifications];
        response.data.data.map((item) => {
          notifications.push(item);
        });

        setNotifications(notifications);
        setIsNotificationListSet(true);
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        dispatch(setTopLoader(false));
      });
  };
  const notificationClick = (index, data) => {
    setSelectedNotificationData([]);
    setSelectedNotificationData(data);
    // setSelectedNotificationDataDetails(JSON.parse(data.data))
    setSelectNotification(true);
    activeNotify(index)
    if (!data.read) {
      read({ id: data.id })
        .then((response) => {
          dispatch(setNotificationRead(!notificationRead));
          getNotifications();
        })
        .catch((error) => {
          console.log(error);
          showError(error.response.data.message);
        });
    }
  };
  const showError = (message) => {
    enqueueSnackbar(message, { variant: "error", anchorOrigin: snackBarPosition });
  };
  const activeNotify = (index) => {
    const bgC = theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.08)" : "rgba(0, 0, 0, 0.04)"
    document.getElementById("list_item_" + index).style.backgroundColor = bgC;
    setPrevId("list_item_" + index)
    if (prevId !== undefined && prevId !== "list_item_" + index) {
      document.getElementById(prevId).style.backgroundColor = "transparent";
    }
  };
  return (
    <Grid container>
      <Grid item sm={4}>
        <Paper variant="outlined" square sx={{ mt: 1, minHeight: "80.25vh", bgcolor: "background.paper" }}>
          <Box sx={{ p: 3 }}>All Notifications</Box>
          <Divider />
          {notificationListSet ? (
            <List sx={{ width: "100%", maxHeight: "71vh", overflow: "auto" }}>
              {notifications.map((item, index) => {
                return (
                  <React.Fragment key={"main_" + index}>
                    <ListItemButton selected={!item.read} onClick={() => notificationClick(index, item)} id={"list_item_" + index}>
                      <ListItemAvatar>
                        <Avatar alt={item.user_name} src={baseWebURL + item.avatar} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={item.title}
                        secondary={
                          <React.Fragment>
                            <Typography sx={{ display: "inline" }} component="span" variant="body2" color="text.primary">
                              {item.user_name}
                            </Typography>
                            {" — " + item.message.substring(0, 25) + "..."}
                          </React.Fragment>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Typography variant="caption" noWrap>
                          {moment(item.created_at).fromNow()}
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItemButton>
                    <Divider component="li" />
                  </React.Fragment>
                );
              })}
            </List>
          ) : (
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "80vh" }}>
              <CircularProgress />
            </Grid>
          )}
          {notifications.length === 0 && (
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "80vh" }}>
              <Typography variant="caption">No notifications yet to show</Typography>
            </Grid>
          )}
        </Paper>
      </Grid>
      <Grid item sm={8}>
        {selectNotification ? (
          <Paper variant="outlined" square sx={{ mt: 1, minHeight: "80.25vh" }}>
            <Box sx={{ p: 1.5 }}>
              <strong>{selectedNotificationData.title}</strong>
              <br />
              <Typography variant="caption">{moment(selectedNotificationData.created_at).format("MMMM Do YYYY, h:mm a")}</Typography>
            </Box>
            <Divider />
            <List>
              <ListItemButton>
                <ListItemIcon>
                  <AdjustOutlinedIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body1" display="block" gutterBottom>
                    {selectedNotificationData.message}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </List>
          </Paper>
        ) : (
          <Paper variant="outlined" square sx={{ mt: 1 }}>
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "80vh" }}>
              <Grid item xs={4}>
                <React.Fragment>
                  <Typography align="center">
                    {" "}
                    <NotificationsIcon fontSize="large" color="success" />
                  </Typography>
                  <Typography variant="body1">Select a notification to view details.</Typography>
                </React.Fragment>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
}
