
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ToolbarAlert from "../ToolbarAlert/ToolbarAlert";
import { Divider, Toolbar } from "@mui/material";

const PageToolbar = (props) => {

    return (
      <React.Fragment>
        <Toolbar >
          {props.alert && <ToolbarAlert alert={props.alert} color={props.color} severity={props.severity} close={props.close}/> }
        </Toolbar>
        <Divider />
      </React.Fragment>
    );
}


export default PageToolbar;