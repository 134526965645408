import { baseApiURL, requestPost } from "./RequestService"
import axios from "axios";
import Cookies from "js-cookie";



const UploadFiles = async (data, prefix, config) => {
    config['headers'] = {
        "Accept": "application/json",
        "Content-Type": "multipart/form-data",
        "Authorization": "Bearer " + Cookies.get("token") ?? "",
    }
    return await axios.post(baseApiURL + prefix, data, config);
};

const deleteFiles = async (prefix, data) => {
    return await requestPost(baseApiURL + prefix, data);
};
export { UploadFiles, deleteFiles }