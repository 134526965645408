import { requestGet, requestPost } from "./RequestService"

const prefix = '/dropzone'
const cPrefix = 'client/dropzone'

const get = async () => {
    return await requestGet(prefix+'/get')
}
const clientGet = async () => {
    return await requestGet(cPrefix+'/get')
}
export { clientGet, get }