import { Alert, AlertTitle, CircularProgress, Grid, Snackbar } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const SnackBarLoading = (props) => {
  const snackbarLoading = useSelector((state) => state.common.snackbarLoading);
  const { title } = props;
  return (
    <Snackbar open={snackbarLoading} autoHideDuration={null} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
      <Alert severity="info" icon={false}>
        <AlertTitle>{title}</AlertTitle>
        <Grid container spacing={2}>
          <Grid item sm={10}>
            Do not <strong>close</strong> or <strong>refresh</strong> the browser.
          </Grid>
          <Grid item sm={2}>
            <CircularProgress size={"25px"} />
          </Grid>
        </Grid>
      </Alert>
    </Snackbar>
  );
};

export default SnackBarLoading;
