import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   lastImageLoaded:false
}

export const samplePageSlice = createSlice({
    name: 'samples_page',
    initialState,
    reducers:{
        setLastImageLoad: (state, action) => {
            state.lastImageLoaded = action.payload
        },
    
    }
})

// export individual action creator functions
export const { setLastImageLoad } = samplePageSlice.actions;

// often the reducer is a default export, but that doesn't matter
export default samplePageSlice.reducer;

