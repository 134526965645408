
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import CreateJob from "../../components/admin/CreateJob/CreateJob";
import PageToolbar from "../../components/admin/PageToolbar/PageToolbar";
import { setTopBarTitle } from "../../features/dashboardSlice/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import ContentAnimation from "../../components/common/Animations/ContentAnimation";

const CreateJobPage = (props) => {

  // component states

  const title = "ImageMall | Create Job";
  const topLoader = useSelector((state) => state.main.topLoader);
  //Redux states
  const dispatch = useDispatch()

  // use effect
  useEffect(() => {
    // hide top loader after component load
    dispatch(setTopBarTitle('Create Job'))
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageToolbar alert="You can drop files to upload and fill the form while uploading files." color="info" />
      <CreateJob />
    </React.Fragment>
  );
}

export default CreateJobPage;