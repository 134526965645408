import React, { useState } from "react";
import styles from "./ResponsiveAppBar.module.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import BrightnessHighIcon from "@mui/icons-material/BrightnessHigh";
import Brightness4Icon from "@mui/icons-material/Brightness4";
// import { Link } from '@mui/material';
import { Link } from "react-router-dom";
import ClientRoutes from "../../../routes/ClientRoutes";
import { useDispatch, useSelector } from "react-redux";
import { setTheme, setSwitchThemeClass } from "../../../features/mainSlice/mainSlice";
import { setActions, setFeedbackDrawerOpen, setRole, setUserAvatar, setUserName } from "../../../features/commonSlice/commonSlice";
import Cookies from "js-cookie";
import { baseWebURL } from "../../../services/RequestService";
import { Divider, ListItemIcon, Skeleton } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Logout } from "@mui/icons-material";
import ConfirmDialog from "../../common/ConfirmDialog/ConfirmDialog";
import { getActions, logOut } from "../../../services/AuthService";
import { useEffect } from "react";
import SmsFailedOutlinedIcon from "@mui/icons-material/SmsFailedOutlined";
import NotificationsMenuBox from "../../common/NotificationsMenuBox/NotificationsMenuBox";
const ResponsiveAppBar = (props) => {
  // component states
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

  // Redux states
  const themeLight = useSelector((state) => state.main.themeLight);
  const switchThemeClass = useSelector((state) => state.main.switchThemeClass);
  const auth = useSelector((state) => state.main.auth);
  const [userName, setName] = useState(null);
  const [userAvatar, setAvatar] = useState(null);

  const dispatch = useDispatch();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  useEffect(() => {
    getActions().then((response) => {
      if (response.data.success) {
        const data = response.data.data.actions;
        const role = response.data.data.role;
        const userName = response.data.data.name;
        const userAvatar = response.data.data.avatar;
        dispatch(setRole(role));
        setName(userName);
        setAvatar(userAvatar);
        dispatch(setUserName(userName));
        dispatch(setUserAvatar(userAvatar));
        let actions = [];
        data?.map((item) => {
          actions.push(item.slug);
        });
        dispatch(setActions(actions));
      }
    });
  }, []);

  //fileder navbar links login and sign up buttons
  const filterNavBarLinks = () => {
    const linkArr = [];
    ClientRoutes.forEach((element) => {
      if (element.is_nav) {
        linkArr.push(element);
      }
    });

    return linkArr;
  };

  //handle click on <responsiveAppBar> theme change button.
  const handleThemeChange = () => {
    //change transitoin of icon button inside of <responsiveAppBar>
    if (themeLight) {
      dispatch(setSwitchThemeClass("theme-light-change-btn"));
      Cookies.set("themeLight", "false", { secure: true });
      dispatch(setTheme(false));
    } else {
      dispatch(setSwitchThemeClass("theme-dark-change-btn"));
      Cookies.set("themeLight", "true", { secure: true });
      dispatch(setTheme(true));
    }
  };
  const handleDashboardNavigate = () => {
    setAnchorElUser(null);
    window.open("/partners/dashboard");
  };
  const handleLogOutClick = () => {
    setLogoutDialogOpen(true);
    setAnchorElUser(null);
  };
  const handleLogOutConfirm = () => {
    logOut().then((response) => {
      Cookies.remove("isLoggedIn");
      Cookies.remove("admin");
      Cookies.remove("token");
      window.location.href = "/login";
    });
    setLogoutDialogOpen(false);
  };
  const handleLogOutCancel = () => {
    setLogoutDialogOpen(false);
  };
  return (
    <AppBar position="fixed" color="inherit">
      <ConfirmDialog onClickConfirm={handleLogOutConfirm} onClickCancel={handleLogOutCancel} open={logoutDialogOpen} header="Are You Sure?" message="Please confirm to the end your session" />
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box component="img" sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} alt="Logo" src={"/favicon.png"} className={styles.app_bar_logo} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "inherit",
              fontWeight: 700,
              // letterSpacing: '.3rem',
              color: "inherit",
              textDecoration: "none",
            }}
          >
            ImageMall
            <Typography variant="caption">TM</Typography>
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton size="large" aria-label="nav menu" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleOpenNavMenu} color="inherit">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {filterNavBarLinks().map((route) => (
                <Link key={route.key} to={route.path} className="router-link">
                  <MenuItem key={route.key} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{route.name}</Typography>
                  </MenuItem>
                </Link>
              ))}

              {!auth && (
                <MenuItem>
                  <Link to="/login" className="router-link">
                    <Button variant="contained" key="sign-in" onClick={handleCloseNavMenu} sx={{ my: 2, mr: 1, color: "white", display: "block", textTransform: "capitalize" }}>
                      Log in
                    </Button>
                  </Link>
                  <Link to="/sign-up" className="router-link">
                    <Button key="sign-up" variant="outlined" onClick={handleCloseNavMenu} sx={{ my: 2, display: "block", textTransform: "capitalize" }}>
                      Sign Up
                    </Button>
                  </Link>
                </MenuItem>
              )}
            </Menu>
          </Box>
          <Box component="img" sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} alt="Logo" src={"/favicon.png"} className={styles.app_bar_logo} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "inherit",
              fontWeight: 500,
              letterSpacing: "inherit",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            ImageMall
            <Typography variant="caption">TM</Typography>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {filterNavBarLinks().map((route, index) => (
              <Link key={route.key} to={route.path} className="router-link">
                <Button key={route.key} onClick={handleCloseNavMenu} sx={{ my: 2, color: "inherit", display: "block", textTransform: "capitalize", fontWeight: 700 }}>
                  {route.name}
                </Button>
              </Link>
            ))}
          </Box>

          <Tooltip title="swich to change theme">
            <IconButton sx={{ flexGrow: 0, marginRight: 1 }} className={switchThemeClass} onClick={handleThemeChange}>
              {themeLight ? <Brightness4Icon /> : <BrightnessHighIcon />}
            </IconButton>
          </Tooltip>
          {auth && <NotificationsMenuBox />}
          <Tooltip title="Send feedback">
            <IconButton sx={{ flexGrow: 0, marginRight: 1 }} onClick={() => dispatch(setFeedbackDrawerOpen(true))}>
              <SmsFailedOutlinedIcon />
            </IconButton>
          </Tooltip>

          {auth && (
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  {userAvatar !== null ? <Avatar alt={userName} src={baseWebURL + userAvatar} /> : <Avatar />}
                </IconButton>
              </Tooltip>
              <Menu sx={{ mt: "45px" }} id="user-menu" anchorEl={anchorElUser} anchorOrigin={{ vertical: "top", horizontal: "right" }} keepMounted transformOrigin={{ vertical: "top", horizontal: "right" }} open={Boolean(anchorElUser)} onClose={handleCloseUserMenu}>
                <MenuItem>
                  <Typography variant="caption">{userName}</Typography>
                </MenuItem>
                <MenuItem key={"profile"} onClick={handleCloseUserMenu}>
                  {userAvatar !== null ? <Avatar alt={userName} src={baseWebURL + userAvatar} sx={{ width: "35px", height: "35px" }} /> : <Avatar />}{" "}
                  <Typography textAlign="center" sx={{ ml: 1 }}>
                    {" "}
                    My Account
                  </Typography>
                </MenuItem>
                <Divider />
                <MenuItem key={"client"} onClick={handleDashboardNavigate}>
                  <ListItemIcon>
                    <OpenInNewIcon />
                  </ListItemIcon>{" "}
                  <Typography textAlign="center">Dashboard</Typography>
                </MenuItem>
                <MenuItem key={"logout"} onClick={handleLogOutClick}>
                  <ListItemIcon>
                    <Logout />
                  </ListItemIcon>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          )}
          {!auth && (
            <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
              <Link to="/login" className="router-link">
                <Button variant="contained" key="sign-in" sx={{ my: 2, mr: 1, display: "block", textTransform: "capitalize", fontWeight: 700 }}>
                  Log in
                </Button>
              </Link>
              <Link to="/sign-up" className="router-link">
                <Button key="sign-up" variant="outlined" sx={{ my: 2, display: "block", textTransform: "capitalize", fontWeight: 700 }}>
                  Sign Up
                </Button>
              </Link>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default ResponsiveAppBar;
