import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Helmet } from "react-helmet";
import { setTopBarTitle } from "../../features/dashboardSlice/dashboardSlice";
import { useDispatch } from "react-redux";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { setTopLoader } from "../../features/mainSlice/mainSlice";
import { getPaymentVendorList, manualWithdraw } from "../../services/PaymentService";
import PageContainer from "../../components/admin/PageContainer/PageContainer";
import { Link } from "react-router-dom";
import { baseURL } from "../../services/RequestService";
import TextFieldComponent from "../../components/common/TextFieldComponent/TextFieldComponent";
import DataDialog from "../../components/common/DataDialog/DataDialog";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { snackBarPosition } from "../../services/CommonService";

const PayForVendors = (props) => {
  // component states

  const title = "ImageMall | Pay For Vendors";
  const [isRowsSet, setIsRowsSet] = useState(false);
  const [rows, setRows] = useState([]);
  const [amount, setAmount] = useState(0.0);
  const [vendor, setVendor] = useState({});
  const [sentAmountDialog, setSentAmountDialog] = useState(false);
  const [sentAmountDialogLoading, setSentAmountDialogLoading] = useState(false);

  //Redux states
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  // use effect
  useEffect(() => {
    // hide top loader after component load
    dispatch(setTopBarTitle("Pay For Vendors"));
    getActivty();
  }, []);
  function createData(vendor, vendor_id, total_earnings, total_withdrawals, available_amount) {
    return {
      vendor,
      vendor_id,
      total_earnings,
      total_withdrawals,
      available_amount,
    };
  }
  const headCells = [
    {
      id: "vendor",
      numeric: false,
      disablePadding: true,
      label: "Name",
      align: "left",
    },
    {
      id: "vendor_id",
      numeric: true,
      disablePadding: true,
      label: "Vendor Id",
      align: "right",
    },
    {
      id: "total_earnings",
      numeric: true,
      disablePadding: false,
      label: "Total Earnings(US$)",
      align: "right",
    },
    {
      id: "total_withdrawals",
      numeric: true,
      disablePadding: false,
      label: "Total Withdrawal(US$)",
      align: "right",
    },
    {
      id: "available_amount",
      numeric: true,
      disablePadding: false,
      label: "Available Amount(US$)",
      align: "right",
    },
    {
      id: "action",
      numeric: true,
      disablePadding: false,
      label: "Action",
      align: "right",
    },
  ];
  const getActivty = () => {
    dispatch(setTopLoader(true));
    setRows([]);
    getPaymentVendorList()
      .then((response) => {
        let rows = [];
        rows = [...rows];
        response.data.data.map((item, index) => {
          rows.push(createData(item.vendor, item.vendor_id, item.total_earnings, item.total_withdrawals, item.available_amount));
        });

        setRows(rows);
        setIsRowsSet(true);
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        dispatch(setTopLoader(false));
      });
  };
  const handleMarkSentAmount = (available_amount, name, vendor_id) => {
    setSentAmountDialog(true);
    setAmount(available_amount.toFixed(2));
    setVendor({ available_amount, name, vendor_id });
  };
  const handleSentCancelDialog = () => {
    setSentAmountDialog(false);
  };
  const handleSentSaveDialog = () => {
    setSentAmountDialogLoading(true);
    manualWithdraw({ amount, vendor_id: vendor.vendor_id })
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          setSentAmountDialogLoading(false);
          setSentAmountDialog(false);
          getActivty();
        }
      })
      .catch((error) => {
        showError(error.response.data.message);
        setSentAmountDialogLoading(false);
      });
  };
  const amountOnChange = (e) => {
    setAmount(e.target.value);
  };
  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Divider />
      <PageContainer>
        <Grid container spacing={4}>
          <Grid item sm={12}>
            {/* {isRowsSet && <DataTable rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection disableSelectionOnClick experimentalFeatures={{ newEditingApi: false }} />} */}
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {headCells.map((item) => (
                      <TableCell key={"cell_" + item.id} align={item.align}>
                        {item.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={"row_" + row.vendor_id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        <a href={"/portfolio?v_id=" + row.vendor_id + "&name=" + row.vendor} style={{ color: "inherit" }} target="_blank">
                          {row.vendor}
                        </a>
                      </TableCell>
                      <TableCell align="right">{row.vendor_id}</TableCell>
                      <TableCell align="right">{row.total_earnings.toFixed(2)}</TableCell>
                      <TableCell align="right">{row.total_withdrawals.toFixed(2)}</TableCell>
                      <TableCell align="right">{row.available_amount.toFixed(2)}</TableCell>
                      <TableCell align="right">
                        <Button size="small" color="primary" variant="contained" onClick={() => handleMarkSentAmount(row.available_amount, row.vendor, row.vendor_id)}>
                          Mark Sent Amount
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <DataDialog
          onClickSave={handleSentSaveDialog}
          onClickSaveButtonType="button"
          onClickSaveButtonVariant="contained"
          onClickSaveText="Confirm & Sent"
          onClickSaveButtonColor="success"
          onClickCloseButtonVariant="outlined"
          onClickCancel={handleSentCancelDialog}
          open={sentAmountDialog}
          header="Sent Balance"
          loading={sentAmountDialogLoading}
          maxWidth="xs"
          minWidth="400px"
        >
          <Typography variant="body2" sx={{ color: "text.secondary", mb: 2 }}>
            Please review amount and details before confirm
          </Typography>

          <Box sx={{ p: 2, border: "solid 1px gray" }}>
            <Grid container spacing={2}>
              <Grid item sm={6} sx={{ textAlign: "left" }}>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  Transfer To
                </Typography>
              </Grid>
              <Grid item sm={6} sx={{ textAlign: "right" }}>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  <a href={"/portfolio?v_id=" + vendor.vendor_id + "&name=" + vendor.name} style={{ color: "inherit" }} target="_blank">
                    {vendor.name}
                  </a>
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <Grid container spacing={2}>
              <Grid item sm={6} sx={{ textAlign: "left" }}>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  Amount
                </Typography>
              </Grid>
              <Grid item sm={6} sx={{ textAlign: "right" }}>
                <TextFieldComponent type={"number"} id="amount" label="Amount*" onChange={amountOnChange} value={amount} marginTop={0} />
              </Grid>
            </Grid>
          </Box>
        </DataDialog>
      </PageContainer>
    </React.Fragment>
  );
};

export default PayForVendors;
