import { requestGet, requestPost } from "./RequestService"

const prefix = '/permissions'

const createPermission = async (data) => {
    return await requestPost(prefix+'/create-permission', data)
    // return await requestPost('authenticate', data)
}

const getPermissionsByRoleId = async (data) => {
    return await requestPost(prefix+'/get-permissions', data)
}

const getRoles = async () => {
    return await requestGet(prefix+'/get-roles')
} 
const updateRolePermissions = async (data) => {
    return await requestPost(prefix+'/update-role-permissions', data)
} 

export { createPermission, getPermissionsByRoleId, getRoles, updateRolePermissions }