import React, { useEffect } from "react";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import TextFieldComponent from "../../../common/TextFieldComponent/TextFieldComponent";
import { useState } from "react";
import DataDialog from "../../../common/DataDialog/DataDialog";
import { withdraw } from "../../../../services/PaymentService";
import { setTopLoader } from "../../../../features/mainSlice/mainSlice";
import { useSnackbar } from "notistack";
import { snackBarPosition } from "../../../../services/CommonService";
const WithdrawBalance = (props) => {
  const { withdrawalMethod, threshold } = props;
  const [amount, setAmount] = useState(0.0);
  const [withdrawalDialog, setWithdrawalDialog] = useState(false);
  const [disableContinue, setDisableContinue] = useState(true);
  const [withdrawalDialogLoading, setWithdrawalDialogLoadinge] = useState(false);
  const availableBalance = useSelector((state) => state.common.availableBalance);
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setAmount(availableBalance);
    if (availableBalance >= threshold) {
      setDisableContinue(false);
    }
  }, []);
  const amountOnChange = (e) => {
    setAmount(e.target.value);
    if (e.target.value >= threshold) {
      if (e.target.value > availableBalance) {
        setDisableContinue(true);
        return;
      }
      if (e.target.value < threshold) {
        setDisableContinue(true);
        return;
      }

      setDisableContinue(false);
    } else {
      setDisableContinue(true);
    }
  };
  const handleContinueClick = () => {
    if (amount >= threshold) {
      setWithdrawalDialog(true);
    }
  };
  const handleWithdrawCancelDialog = () => {
    setWithdrawalDialog(false);
  };
  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };
  const handleWithdrawSaveDialog = () => {
    dispatch(setTopLoader(true));
    setWithdrawalDialogLoadinge(true);
    withdraw({ amount })
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          setWithdrawalDialogLoadinge(false);
          setWithdrawalDialog(false);
        }
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        showError(error.response.data.message);
        dispatch(setTopLoader(false));
      });
  };
  return (
    <Container>
      <Grid container spacing={4} sx={{ pl: 10, pr: 10 }}>
        <Grid item sm={6}>
          <Typography variant="h4">Withdraw balance</Typography>
          <Typography variant="body2" sx={{ mt: 4 }}>
            Available for withdrawal
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            US${availableBalance}
          </Typography>
          <Typography variant="body2" sx={{ mt: 4 }}>
            Your default payout method
          </Typography>
          {withdrawalMethod.status === "new" ? (
            <Typography variant="body2" color={"text.secondary"} sx={{ mt: 2 }}>
              Your default payout method <strong>{withdrawalMethod.account_holder_name.toUpperCase()}</strong> is not verified yet. verify and try again.
            </Typography>
          ) : (
            <React.Fragment>
              <Box sx={{ mt: 2 }}>{withdrawalMethod.account_holder_name.toUpperCase()}</Box>
              <Grid container spacing={4}>
                <Grid item sm={10}>
                  <Box>
                    <strong>ACCOUNT •••• {withdrawalMethod.last4} </strong>
                  </Box>
                  <Box color="text.secondary">
                    country: {withdrawalMethod.country} currency: {withdrawalMethod.currency}
                  </Box>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
        <Grid item sm={6}>
          <TextFieldComponent type={"number"} id="amount" label="Amount*" onChange={amountOnChange} value={amount} marginTop={0} />
        </Grid>
      </Grid>
      <Box sx={{ textAlign: "right" }}>
        <Button onClick={handleContinueClick} sx={{ marginTop: 2 }} variant="contained" size="medium" disabled={disableContinue}>
          Continue
        </Button>
      </Box>
      <DataDialog
        onClickSave={handleWithdrawSaveDialog}
        onClickSaveButtonType="button"
        onClickSaveButtonVariant="contained"
        onClickSaveText="Confirm & Withdraw"
        onClickSaveButtonColor="success"
        onClickCloseButtonVariant="outlined"
        onClickCancel={handleWithdrawCancelDialog}
        open={withdrawalDialog}
        header="Withdraw Balance"
        loading={withdrawalDialogLoading}
        maxWidth="xs"
        minWidth="400px"
      >
        <Typography variant="body2" sx={{ color: "text.secondary", mb: 2 }}>
          Please review your withdrawal details
        </Typography>

        <Box sx={{ p: 2, border: "solid 1px gray" }}>
          <Grid container spacing={2}>
            <Grid item sm={6} sx={{ textAlign: "left" }}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Transfer To
              </Typography>
            </Grid>
            <Grid item sm={6} sx={{ textAlign: "right" }}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Account •••• {withdrawalMethod.last4}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ mt: 1, mb: 1 }} />
          <Grid container spacing={2}>
            <Grid item sm={6} sx={{ textAlign: "left" }}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                Amount
              </Typography>
            </Grid>
            <Grid item sm={6} sx={{ textAlign: "right" }}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                US${amount}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Typography variant="body2" sx={{ color: "text.secondary", mt: 2 }}>
          Please note withdrawals are cannot be undone. it may take upto 2 business days to transfer funds.
        </Typography>
      </DataDialog>
    </Container>
  );
};

export default WithdrawBalance;
