import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setTopLoader } from "../../../../features/mainSlice/mainSlice";
import { countrySpecs, saveBankAccount } from "../../../../services/PaymentService";
import { useSnackbar } from "notistack";
import { setAddCardState, setAddBankState } from "../../../../features/PaymentsSlice/PaymentsSlice";
import LoadingButtons from "../../../common/LoadingButton/LoadingButtonComponent";
import { useState } from "react";
import { useTheme } from "@mui/material";
import TextFieldComponent from "../../../common/TextFieldComponent/TextFieldComponent";
import SelectBox from "../../../common/SelectBoxCompnent/SelectBoxComponent";
import { snackBarPosition } from "../../../../services/CommonService";

const AddWithdrawalMethod = () => {
  // form
  const { handleSubmit, control } = useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const addCardState = useSelector((state) => state.payments.addCardState);
  const addBankState = useSelector((state) => state.payments.addBankState);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countrySpecsData, setCountrySpecsData] = useState(null);
  const theme = useTheme();
  useEffect(() => {
    if (selectedCountry !== "") {
      getCountrySpecs(selectedCountry);
    }
  }, [selectedCountry]);
  const countryListCode =[
    {"name": "United States", "value": "US"}
  ]
  const getCountrySpecs = (id) => {
    dispatch(setTopLoader(true));
    setBtnLoading(true);
    countrySpecs({ id })
      .then((response) => {
        if (response.data.success === true) {
          setCountrySpecsData(response.data.data);
        }
        dispatch(setTopLoader(false));
        setBtnLoading(false);
      })
      .catch((error) => {
        console.log(error);
        showError(error.response.data.message);
        dispatch(setTopLoader(false));
        setBtnLoading(false);
      });
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };

  const onSubmit = (data) => {
    dispatch(setTopLoader(true));
    setBtnLoading(true);
    saveBankAccount(data)
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          dispatch(setAddBankState(!addBankState));
        }
        dispatch(setTopLoader(false));
        setBtnLoading(false);
      })
      .catch((error) => {
        console.log(error);
        showError(error.response.data.message);
        dispatch(setTopLoader(false));
        setBtnLoading(false);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
        maxWidth: "400px",
        margin: "0 auto",
      }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        Bank Account
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 3 }}>
        <img src={theme.palette.mode == "dark" ? "/img/Stripe wordmark - white (small).png" : "/img/Stripe wordmark - blurple (small).png"} alt="Stripe logo" width="20%" />
        <Typography variant="body1">Trusted and Powered by Stripe</Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="account_holder_name"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return <TextFieldComponent type={"text"} id="account_holder_name" label="Account Holder Name*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} marginTop={0} />;
          }}
          rules={{ required: "Account Holder Name is required" }}
        />
        <Controller
          name="country"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return <SelectBox id={"country"} label="Select Country*" items={countryListCode} error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} defaultValue={value} />;
          }}
          rules={{ required: "Account Holder Name is required" }}
        />
        <Controller
          name="routing_number"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return <TextFieldComponent type={"text"} id="routing_number" label="Routing Number*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} marginTop={0} />;
          }}
          rules={{ required: "Routing Number is required" }}
        />
        <Controller
          name="account_number"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return <TextFieldComponent type={"text"} id="account_number" label="Account Number*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />;
          }}
          rules={{ required: "Account Number is required" }}
        />
        <LoadingButtons loading={btnLoading} name="Save Account" variant="contained" type="submit" />
      </form>
    </Box>
  );
};

export default AddWithdrawalMethod;
