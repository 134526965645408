import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { LinearProgress } from "@mui/material";

const DataDialog = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div>
      <Dialog fullScreen={fullScreen} fullWidth={props.fullWidth} open={props.open} aria-labelledby="responsive-dialog-title" maxWidth={props.maxWidth ? props.maxWidth : "xl"}>
        {props.loading && <LinearProgress />}
        <DialogTitle id="responsive-dialog-title">{props.header}</DialogTitle>
        <DialogContent sx={{ minWidth: props.minWidth ? props.minWidth : "800px", maxWidth: props.styleMaxWith !== undefined && props.styleMaxWith !== null ? props.styleMaxWith : "100%" }}>
          <DialogContent>{props.children}</DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClickCancel} variant={props.onClickCloseButtonVariant !== undefined ? props.onClickCloseButtonVariant : "text"}>
            Close
          </Button>
          {props.onClickSave && (
            <React.Fragment>
              {props.onClickSaveButtonType === undefined ? (
                <Button
                  type={props.onClickSaveButtonType !== undefined ? props.onClickSaveButtonType : "submit"}
                  variant={props.onClickSaveButtonVariant !== undefined ? props.onClickSaveButtonVariant : "text"}
                  color={props.onClickSaveButtonColor !== undefined ? props.onClickSaveButtonColor : undefined}
                  disabled={props.loading}
                >
                  {props.onClickSaveText}
                </Button>
              ) : (
                <Button
                  type={props.onClickSaveButtonType !== undefined ? props.onClickSaveButtonType : "submit"}
                  variant={props.onClickSaveButtonVariant !== undefined ? props.onClickSaveButtonVariant : "text"}
                  color={props.onClickSaveButtonColor !== undefined ? props.onClickSaveButtonColor : undefined}
                  disabled={props.loading}
                  onClick={props.onClickSave}
                >
                  {props.onClickSaveText}
                </Button>
              )}
            </React.Fragment>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default DataDialog;
