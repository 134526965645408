
import React, {useState, useEffect} from "react";
import { Helmet } from "react-helmet";
import JobTypes from "../../components/admin/JobTypes/JobTypes";
import PageToolbar from "../../components/admin/PageToolbar/PageToolbar";
import { setTopBarTitle } from "../../features/dashboardSlice/dashboardSlice";
import { useDispatch } from "react-redux";

const JobTypesPage = (props) => {

  // component states

  const title = "ImageMall | Job Types";
  //Redux states
  const dispatch = useDispatch()
  
  // use effect
  useEffect(() => {
    // hide top loader after component load
    dispatch(setTopBarTitle('Job Types'))
  }, [])
  
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageToolbar />
      <JobTypes />
    </React.Fragment>
  );
}

export default JobTypesPage;