
import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import { setTopBarTitle } from "../../features/dashboardSlice/dashboardSlice";
import { useDispatch } from "react-redux";
import ToolbarAlert from "../../components/admin/ToolbarAlert/ToolbarAlert";
import { Divider } from "@mui/material";
import ActivityLog from "../../components/admin/ActivityLog/ActivityLog";

const ActivityLogPage = (props) => {

  // component states

  const title = "ImageMall | Activity Log";
  //Redux states
  const dispatch = useDispatch()
  
  // use effect
  useEffect(() => {
    // hide top loader after component load
    dispatch(setTopBarTitle('Activity Log'))
  }, [])
  
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ToolbarAlert
        color="warning"
        alert="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vel urna facilisis, viverra urna ac, porttitor ipsum."
      />
      <Divider />
      <ActivityLog />
    </React.Fragment>
  );
}

export default ActivityLogPage;