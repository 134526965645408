import React, { useEffect, useState } from "react";
import { CardContent, Divider, Grid, Typography } from "@mui/material";
import PageContainer from "../PageContainer/PageContainer";
import CardComponent from "../../common/CardComponent/CardComponent";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { setExceptionRead, setTopLoader } from "../../../features/mainSlice/mainSlice";
import { getAll, read } from "../../../services/ExceptionService";
import EnhancedTable from "./EnhancedTable";
import DataDialog from "../../common/DataDialog/DataDialog";
import moment from "moment";
import TagIcon from "@mui/icons-material/Tag";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import DataObjectOutlinedIcon from "@mui/icons-material/DataObjectOutlined";
import JSONFormatter from "json-formatter-js";
import { Box } from "@mui/system";
import DOMPurify from "dompurify";
import { useTheme } from "@emotion/react";
import { snackBarPosition } from "../../../services/CommonService";
const ExceptionLog = () => {
  // component states
  const [rows, setRows] = useState([]);
  const [isRowsSet, setIsRowsSet] = useState(false);
  const [exceptionDialogOpen, setExceptionDialogOpen] = useState(false);
  const [exceptionDetails, setExceptionDetails] = useState(null);
  const [jsonData, setJsonData] = useState({});
  // snackbar
  const { enqueueSnackbar } = useSnackbar();
  const exceptionRead = useSelector((state) => state.main.exceptionRead);
  const dispatch = useDispatch();
  const theme = useTheme();
  // for
  useEffect(() => {
    getException();
  }, []);

  function createData(message, line, fix, data) {
    return {
      message,
      line,
      fix,
      data,
    };
  }
  const headCells = [
    {
      id: "message",
      numeric: false,
      disablePadding: true,
      label: "Message",
      align: "left",
    },
    {
      id: "line",
      numeric: true,
      disablePadding: false,
      label: "Line",
      align: "center",
    },
    {
      id: "fix",
      numeric: true,
      disablePadding: false,
      label: "Fixed",
      align: "center",
    },
  ];

  const getException = () => {
    dispatch(setTopLoader(true));
    setRows([]);
    getAll()
      .then((response) => {
        let rows = [];
        rows = [...rows];
        response.data.data.map((item, index) => {
          rows.push(createData(item.message, item.line, item.fix, item));
        });

        setRows(rows);
        setIsRowsSet(true);
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        console.log(error);
        showError(error.response.data.message);
        dispatch(setTopLoader(false));
      });
  };

  const handleRowClick = (event, data) => {
    var dta = JSON.parse(data.data.data);
    const formatter = new JSONFormatter(dta);
    formatter.openAtDepth('Infinity')
    setJsonData(formatter.render());
    setExceptionDetails(data);
    setExceptionDialogOpen(true);
    read({id:data.data.id})
      .then((response) => {
        dispatch(setExceptionRead(!exceptionRead));
        getException()
      })
      .catch((error) => {
        console.log(error);
        showError(error.response.data.message);
      });
  };

  const showError = (message) => {
    enqueueSnackbar(message, { variant: "error", anchorOrigin: snackBarPosition });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, { variant: "success", anchorOrigin: snackBarPosition });
  };
  const hadndleExceptionDialogClose = () => {
    setExceptionDialogOpen(false);
    setExceptionDetails(null);
  };
  return (
    <PageContainer>
      <Divider />
      <DataDialog onClickCancel={hadndleExceptionDialogClose} open={exceptionDialogOpen} header="Exception Details" loading={false} maxWidth="md" fullWidth={true}>
        {exceptionDetails !== null && exceptionDetails.data.code && (
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item sm={4}>
              <Box sx={{ display: "flex", pl: 1.5, pb: 1.2 }}>
                <TagIcon fontSize="small" />
                <Typography sx={{ ml: 1 }} variant="body2">
                  Code
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={8}>
              <Typography variant="body2">{exceptionDetails.data.code}</Typography>
            </Grid>
          </Grid>
        )}
        {exceptionDetails !== null && exceptionDetails.data.message && (
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item sm={4}>
              <Box sx={{ display: "flex", pl: 1.5, pb: 1.2 }}>
                <TagIcon fontSize="small" />
                <Typography sx={{ ml: 1 }} variant="body2">
                  Message
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={8}>
              <Typography variant="body2">{exceptionDetails.data.message}</Typography>
            </Grid>
          </Grid>
        )}
        {exceptionDetails !== null && exceptionDetails.data.line && (
          <Grid container spacing={2} sx={{ mb: 1 }}>
            <Grid item sm={4}>
              <Box sx={{ display: "flex", pl: 1.5, pb: 1.2 }}>
                <TagIcon fontSize="small" />
                <Typography sx={{ ml: 1 }} variant="body2">
                  Line
                </Typography>
              </Box>
            </Grid>
            <Grid item sm={8}>
              <Typography variant="body2">{exceptionDetails.data.line}</Typography>
            </Grid>
          </Grid>
        )}
        {exceptionDetails !== null && exceptionDetails.data.name && (
          <React.Fragment>
            <Grid container spacing={2} sx={{ mb: 1 }}>
              <Grid item sm={4}>
                <Box sx={{ display: "flex", pl: 1.5, pb: 1.2 }}>
                  <FormatListBulletedIcon fontSize="small" />
                  <Typography sx={{ ml: 1 }} variant="body2">
                    Name
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={8}>
                <Typography variant="body2">{exceptionDetails.data.name}</Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        {exceptionDetails !== null && exceptionDetails.data.user_name && (
          <React.Fragment>
            <Grid container spacing={2} sx={{ mb: 1 }}>
              <Grid item sm={4}>
                <Box sx={{ display: "flex", pl: 1.5, pb: 1.2 }}>
                  <PersonOutlineOutlinedIcon fontSize="small" />
                  <Typography sx={{ ml: 1 }} variant="body2">
                    Exception Logged By
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={8}>
                <Typography variant="body2">{exceptionDetails.data.user_name}</Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        {exceptionDetails !== null && exceptionDetails.data.created_at && (
          <React.Fragment>
            <Grid container spacing={2} sx={{ mb: 1 }}>
              <Grid item sm={4}>
                <Box sx={{ display: "flex", pl: 1.5, pb: 1.2 }}>
                  <AccessTimeOutlinedIcon fontSize="small" />
                  <Typography sx={{ ml: 1 }} variant="body2">
                  Exception Logged at
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={8}>
                <Typography variant="body2">{moment(exceptionDetails.data.created_at).calendar()}</Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        {exceptionDetails !== null && exceptionDetails.data.description && (
          <React.Fragment>
            <Grid container spacing={2} sx={{ mb: 1 }}>
              <Grid item sm={4}>
                <Box sx={{ display: "flex", pl: 1.5, pb: 1.2 }}>
                  <InfoOutlinedIcon fontSize="small" />
                  <Typography sx={{ ml: 1 }} variant="body2">
                    Description
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={8}>
                <Typography variant="body2">{exceptionDetails.data.description}</Typography>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        {exceptionDetails !== null && exceptionDetails.data.data && (
          <React.Fragment>
            <Grid container spacing={2} sx={{ mb: 1 }}>
              <Grid item sm={4}>
                <Box sx={{ display: "flex", pl: 1.5, pb: 1.2 }}>
                  <DataObjectOutlinedIcon fontSize="small" />
                  <Typography sx={{ ml: 1 }} variant="body2">
                    Data
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={8}>
                <Box sx={{ backgroundColor: theme.palette.mode === "dark" && "aliceblue" }}>
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(jsonData) }} />
                </Box>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </DataDialog>
      <Grid container spacing={4}>
        <Grid item sm={12}>
          <CardComponent>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item sm={12}>
                  {/* {isRowsSet && <DataTable rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection disableSelectionOnClick experimentalFeatures={{ newEditingApi: false }} />} */}
                  {isRowsSet && <EnhancedTable headCells={headCells} rows={rows} header="Exception Logs" handleRowClick={handleRowClick} />}
                </Grid>
              </Grid>
            </CardContent>
          </CardComponent>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default ExceptionLog;
