import { requestGet, requestPost } from "./RequestService"

const authenticate = async (data) => {
    return await requestPost('/login', data)
    // return await requestPost('authenticate', data)
}
const signUp = async (data) => {
    return await requestPost('/sign-up', data)
}

const isAuthenticated = async () => {
    return await requestGet('/check-authenticated')
}
const logOut = async () => {
    return await requestGet('/logout')
}

const getNav = async () => {
    return await requestGet('/get-nav')
}
const getActions = async () => {
    return await requestGet('/get-action-permissions')
}
const forgetPassword = async (data) => {
    return await requestPost('/forget-password/link', data)
}
const resetPassword = async (data) => {
    return await requestPost('/forget-password/reset', data)
}
const verify = async (data) => {
    return await requestPost('/verify', data)
}
const resendVerificationCode = async (data) => {
    return await requestPost('/resend-code', data)
}
const getGoogleLoginUrl = async (data) => {
    return await requestGet('/get-google-login-url');
}
const googleAuthCallBack = async (search) => {
    return await requestPost(`/auth/google/callback${search}`, []);
}
const guestLogin = async (data) => {
    return await requestPost('/guest-login', data);
}
export { authenticate, isAuthenticated, logOut, getNav, getActions, signUp, forgetPassword, resetPassword, verify, resendVerificationCode, getGoogleLoginUrl, googleAuthCallBack, guestLogin }