import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import styles from "../../../components/common/Login/Login.module.css";
import { LoadingButton } from "@mui/lab";
import { Link as UiLink } from "@mui/material";
import { useDispatch } from "react-redux";
import { useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import { useForm, Controller } from "react-hook-form";
import { setTopLoader } from "../../../features/mainSlice/mainSlice";
import TextFieldComponent from "../TextFieldComponent/TextFieldComponent";
import { resetPassword } from "../../../services/AuthService";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const PasswordReset = () => {
  // component states
  const [loginBtnLoad, setLoginBtnLoad] = useState(false);
  const dispatch = useDispatch();
  const [searchParams] = new useSearchParams()
  const navigate = useNavigate()
  // snackbar
  const { enqueueSnackbar } = useSnackbar();

  // form
  const { handleSubmit, control, resetField, watch } = useForm({
    mode: "onChange",
  });

  const password = useRef({});
  password.current = watch("password", "");

  useEffect(() => {}, []);

  const onSubmit = (data) => {
    // Show loaders
    setLoginBtnLoad(true);
    dispatch(setTopLoader(true));

    const email = searchParams.get("email")
    const expires = searchParams.get("expires")
    const token = searchParams.get("token")
    resetPassword({ email, expires, token, password:data.password, confirm_password:data.confirm_password })
      .then((response) => {
        if (response.data.success) {
          showSuccess(response.data.message);
          resetField("email");
          navigate('/login')
        }
        setLoginBtnLoad(false);
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        showError(error.response.data.message);
        setLoginBtnLoad(false);
        dispatch(setTopLoader(false));
      });
  };
  const showError = (message) => {
    enqueueSnackbar(message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" } });
  };
  // Load sing up page inside of upload process page
  // use effect
  useEffect(() => {
    // hide top loader after component load
  }, []);
  return (
    <Container maxWidth="lg" sx={{ marginTop: 10, marginBottom: 6 }}>
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "80vh", marginTop: 2, marginBottom: 2 }}>
        <Grid item xs={3}>
          <Card className={styles.login_card}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <CardContent>
                <Typography variant="h4" component={"div"}>
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography>Reset Password</Typography>
                    </Grid>
                  </Grid>
                </Typography>
                <Typography sx={{ marginTop: 2 }} component="div">
                <Typography variant="caption" sx={{ fontWeight: 600, marginTop: 2 }}>
                  Enter your new password
                </Typography>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                      return <TextFieldComponent type={"password"} id="password" label="Password*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />;
                    }}
                    rules={{
                      required: "Password is required",
                      minLength: {
                        value: 6,
                        message: "Password is too short min:6",
                      },
                      maxLength: {
                        value: 14,
                        message: "Password is too long max:14",
                      },
                    }}
                    
                  />
                </Typography>
                <Typography sx={{mt:2}}></Typography>
                <Typography variant="caption" sx={{ fontWeight: 600 }}>
                  Confirm password
                </Typography>
                <Controller
                  name="confirm_password"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return <TextFieldComponent type={"password"} id="confirm_password" label="Confirm Password*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />;
                  }}
                  rules={{
                    required: "Password is required",
                    minLength: {
                      value: 6,
                      message: "Password is too short min:6",
                    },
                    maxLength: {
                      value: 14,
                      message: "Password is too long max:14",
                    },
                    validate: (value) => value === password.current || "The passwords do not match",
                  }}
                />
                <Grid container marginTop={3}>
                  <Grid item xs={6}>
                    <UiLink href="/login" underline="none" sx={{ fontSize: 14 }}>
                      {"Need to login ?"}
                    </UiLink>
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent="flex-end">
                    <LoadingButton type="submit" loading={loginBtnLoad} variant={"contained"} sx={{}} size="large">
                      {" "}
                      Reset{" "}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions></CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PasswordReset;
