import React from "react";
import { Helmet } from "react-helmet";
import { Container } from "@mui/system";
import { useSearchParams } from "react-router-dom";
import PortfolioView from "../../components/admin/Portfolio/PortfolioView/PortfolioView";

const Portfolio = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const name = searchParams.get("name");
  const vId = searchParams.get("v_id");
  // component states

  const title = "ImageMall | Vendor - "+name;
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container sx={{ marginTop: 6, marginBottom: 6 }}>
        <PortfolioView vendor={false} vId={vId}/>
      </Container>
    </React.Fragment>
  );
};

export default Portfolio;
