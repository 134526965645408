import { Alert, AlertTitle, Button, Chip, Container, Divider, FormControlLabel, FormGroup, Grid, Link as UiLink, Stack, Step, StepContent, StepLabel, Stepper, Typography, useTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
// import { setClientUploadedImages } from '../../features/commonSlice/commonSlice'
import { Box } from "@mui/system";
import { BrowserView, MobileView } from "react-device-detect";
import LoadingButton from "@mui/lab/LoadingButton";
import SelectComponent from "../../components/client/selectComponent/SelectComponent";
import MediaCard from "../../components/client/mediaCard/MediaCard";
import SignUp from "../../components/common/SignUp/SignUp";
import { useDispatch, useSelector } from "react-redux";
import { getClientJobId, getJobId } from "../../services/JobService";
import DropZoneClient from "../../components/common/DropZoneClient/DropZoneClient";
import { deleteFiles, UploadFiles } from "../../services/UploadFilesService";
import { clearFileNames, setFileNames, setIsUploadComplete } from "../../features/dropzoneSlice/dropzoneSlice";
import axios from "axios";
import TextFieldComponent from "../../components/common/TextFieldComponent/TextFieldComponent";
import { Controller, useForm } from "react-hook-form";
import { setClientUploadProcessLogin } from "../../features/commonSlice/commonSlice";
import GoogleButton from "react-google-button";
import { Checkbox } from "@mui/material";
import { snackBarPosition } from "../../services/CommonService";
import { useSnackbar } from "notistack";
import { authenticate } from "../../services/AuthService";
import Cookies from "js-cookie";
import { setAuthentication, setAuthToken } from "../../features/mainSlice/mainSlice";
import { Elements } from "@stripe/react-stripe-js";
import { getClientPublicKey } from "../../services/PaymentService";
import { loadStripe } from "@stripe/stripe-js";
import { getAmounts, getServiceTypes } from "../../services/ClientServie";
import StripeForm from "../../components/client/StripeForm/StripeForm";

const UploadImages = () => {
  // component states
  const [btnLoading, setBtnLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [stepsError, setStepsError] = useState({ step_0: false, step_1: false, step_2: false, step_3: false, step_4: false });
  const [stepsErrorMsg, setStepsErrorMsg] = useState({ step_0: "", step_1: "", step_2: "", step_3: "", step_4: "" });
  const [selectError, setSelectError] = useState(false);
  const [selectedServiceType, setSelectedServiceType] = useState("");
  const [jobId, setJobId] = useState(undefined);
  const [selectValue, setSelectValue] = useState({});
  const [descriptions, setDescriptions] = useState({});
  const [loginLoadingBtn, setLoginLoadingBtn] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [stripePromise, setStripePromise] = useState(null);

  const [pricePerImage, setPricePerImage] = useState(0);
  const [fileCount, setFileCount] = useState(0);
  const [total, setTotal] = useState(0);

  const theme = useTheme();
  // Redux states
  const clientUploadProcessLogin = useSelector((state) => state.common.clientUploadProcessLogin);
  const fileNames = useSelector((state) => state.dropzone.fileNames);
  const auth = useSelector((state) => state.main.auth);
  const dispatch = useDispatch();
  const { handleSubmit, control, resetField } = useForm({
    mode: "onChange",
  });
  // snackbar
  const { enqueueSnackbar } = useSnackbar();
  const title = "ImageMall | Upload Images";

  const styles = {
    main: {
      minHeight: "100vh",
    },
  };

  // dropzone ============================================================================
  const [files, setFiles] = useState([]);
  const [rejectFiles, setRejectFiles] = useState([]);
  const [totalFilesSize, setTotalFilesSize] = useState(0);
  const [uploading, setUploading] = useState(false);
  // On Drop Event
  const onDrop = (acceptedFiles, rejectedFiles) => {
    const newFiles = acceptedFiles.map((file, index) => ({
      file,
      progress: 0,
      uploaded: false,
      uploaded_size: 0,
      total_size: 0,
      failed: false,
      job_id: jobId,
    }));
    const reFiles = rejectedFiles.map((file) => ({
      file,
    }));
    const totalSize = acceptedFiles.reduce((acc, file) => acc + file.size, 0);

    setFiles([...files, ...newFiles]);
    setRejectFiles([...rejectFiles, ...reFiles]);
    setTotalFilesSize(totalFilesSize + totalSize);
    acceptedFiles.length > 0 && setUploading(false);
  };
  const uploadFile = (fileIndex) => {
    const fileToUpload = files[fileIndex].file;
    const job_id = files[fileIndex].job_id;
    const formData = new FormData();
    const source = axios.CancelToken.source();
    formData.append("file", fileToUpload);
    formData.append("job_id", job_id);

    UploadFiles(formData, "client/jobs/upload-files", {
      onUploadProgress: (progressEvent) => {
        const uploaded_size = progressEvent.loaded;
        const total_size = progressEvent.total;
        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setFiles((prevFiles) => prevFiles.map((prevFile, index) => (index === fileIndex ? { ...prevFile, progress, uploaded_size, total_size, source } : prevFile)));
      },
      cancelToken: source.token,
    })
      .then((response) => {
        setFiles((prevFiles) => prevFiles.map((prevFile, index) => (index === fileIndex ? { ...prevFile, uploaded: true } : prevFile)));
        dispatch(setFileNames(response.data.data));
      })
      .catch(() => {
        setFiles((prevFiles) => prevFiles.map((prevFile, index) => (index === fileIndex ? { ...prevFile, failed: true } : prevFile)));
      });
  };

  const upload = () => {
    setUploading(true);
    dispatch(setIsUploadComplete(false));
    files.map((item, index) => {
      !item.uploaded && !item.failed && uploadFile(index);
    });
    dispatch(setIsUploadComplete(true));
  };

  const cancelUpload = (source, index) => {
    source.cancel("Request canceled");
    const newItems = [...files]; // create a copy of the original array
    newItems.splice(index, 1); // remove the object at the specified index
    setFiles(newItems);
  };

  const removeUploaded = (filename, index) => {
    const newItems = [...fileNames]; // create a copy of the original array
    const newItems2 = [...files]; // create a copy of the original array
    deleteFiles("client/jobs/file-delete", { job_id: jobId, filename: newItems[index].filename });
    newItems.splice(index, 1); // remove the object at the specified index
    newItems2.splice(index, 1); // remove the object at the specified index
    // re create the filesname array with image names
    let newNames = [];
    for (let i = 0; i < newItems.length; i++) {
      const count = i + 1;
      const paddedCount = count.toString().padStart(4, "0");
      const fileName = "IMG" + paddedCount;
      const extention = newItems[i].filename.slice(newItems[i].filename.indexOf("."));
      const fname = { filename: fileName + extention, original_name: newItems[i].original_name };
      newNames.push(fname);
    }
    dispatch(clearFileNames());
    dispatch(setFileNames(newNames));
    setFiles(newItems2);
  };
  // dropzone ============================================================================
  useEffect(() => {
    if (activeStep === 4) {
      getAmounts({ service_type: selectedServiceType, count: fileNames.length }).then((response) => {
        if (response.data.success) {
          const data = response.data.data;
          setPricePerImage(data.price_per_image);
          setFileCount(data.count);
          setTotal(data.total);
        }
      });
    }
  }, [activeStep]);
  //select box values set
  useEffect(() => {
    let selectBoxes = [];
    let descriptions = [];
    fileNames.map((item, index) => {
      const key = "selectBox_" + index;
      const desKey = "description_" + index;
      selectBoxes[key] = "";
      descriptions[desKey] = "";
    });
    setSelectValue(selectBoxes);
    setDescriptions(descriptions);
  }, [fileNames]);
  // component states
  useEffect(() => {
    //get job id
    if (auth) {
      getJobId()
        .then((response) => {
          setJobId(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      getClientJobId()
        .then((response) => {
          setJobId(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    getClientPublicKey().then((response) => {
      const stripePromise = loadStripe(response.data.data.key);
      setStripePromise(stripePromise);
    });
    getServiceTypes().then((response) => {
      setServiceTypes(response.data.data);
    });
  }, []);

  const handleServiceTypeChange = (event) => {
    setSelectedServiceType(event.target.value);
  };

  // Handle Steps
  const handleServiceTypeStep = (event) => {
    if (selectedServiceType === "") {
      setStepsError({ step_0: true });
      setStepsErrorMsg({
        step_0: (
          <Typography variant="caption" color="error">
            {" "}
            Please Select Service Type{" "}
          </Typography>
        ),
      });
      setSelectError(true);
      return;
    }
    setStepsError({ step_0: false });
    setStepsErrorMsg({ step_0: "" });
    setActiveStep(1);
    setSelectError(false);
  };

  const handleUploadStep = () => {
    if (fileNames.length === 0) {
      setStepsError({ step_1: true });
      setStepsErrorMsg({
        step_1: (
          <Typography variant="caption" color="error">
            {" "}
            Please upload atleast a one image{" "}
          </Typography>
        ),
      });
      setSelectError(true);
      return;
    }
    setStepsError({ step_1: false });
    setStepsErrorMsg({ step_1: "" });
    setActiveStep(2);
  };
  const handleDescribeStep = () => {
    setStepsError({ step_2: false });
    setStepsErrorMsg({ step_2: "" });
    if (auth) {
      setActiveStep(4);
    } else {
      setActiveStep(3);
    }
  };
  const handleRegisterOrLogin = () => {
    setStepsError({ step_3: false });
    setStepsErrorMsg({ step_3: "" });
    setActiveStep(4);
  };
  // End Handle Steps

  const handleBackStep = () => {
    if (auth && activeStep === 4) {
      setActiveStep(activeStep - 2);
    } else {
      setActiveStep(activeStep - 1);
    }
  };
  // const handleServiceTypeChange = (event) => {
  //     setServiceType(event.target.value)
  // }

  const handleOnDrop = (status) => {
    setStepsError({ step_1: false });
    setStepsErrorMsg({ step_1: "" });
    setActiveStep(1);
    // setBtnLoading(status)
  };

  const getRoomTypes = () => {
    var roomsTypeItems = [
      { name: "Living Roo", value: "living_room" },
      { name: "Dining Room", value: "dining_room" },
      { name: "Bedroom", value: "bedroom" },
      { name: "Kids Room", value: "blue_skies" },
      { name: "Kitchen", value: "kitchen" },
      { name: "Bathroom", value: "bathroom" },
      { name: "Study Room", value: "study_room" },
      { name: "Office", value: "office" },
      { name: "Basement", value: "basement" },
      { name: "Other", value: "other" },
    ];
    return roomsTypeItems;
  };
  const roomsTypeOnChange = (e, name) => {
    const { value } = e.target;
    setSelectValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const descriptionChange = (e, name) => {
    const { value } = e.target;
    setDescriptions((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const onSubmitLogin = (data) => {
    data["remember_me"] = rememberMe;
    setLoginLoadingBtn(true);
    // Send auth request
    authenticate(data)
      .then((response) => {
        if (response.data.success === true) {
          Cookies.set("isLoggedIn", true, { secure: true });
          Cookies.set("admin", true, { secure: true });
          Cookies.set("token", response.data.data.token, { secure: true });
          dispatch(setAuthToken(response.data.data.token));
          dispatch(setAuthentication(true));
          showSuccess(response.data.message);
          setActiveStep(4);
        }
        setLoginLoadingBtn(false);
      })
      .catch((error) => {
        showError(error.response.data.message);
        setLoginLoadingBtn(false);
        console.log("error", error);
      });
    resetField("password");
  };
  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };
  // Load sing up page inside of upload process page
  const handdleUiChangeToSignUp = () => {
    dispatch(setClientUploadProcessLogin(false));
  };
  const remameberMeOnChange = (e) => {
    setRememberMe(e.target.checked);
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <Container sx={{ marginTop: 15 }}>
        <Grid container style={styles.main}>
          <Grid item xs={12}>
            <Box sx={{ width: "100%" }}>
              <BrowserView>
                <Stepper activeStep={activeStep} orientation={"horizontal"}>
                  <Step key={"Select Service Type"}>
                    <StepLabel error={stepsError.step_0} optional={stepsErrorMsg.step_0}>
                      {"Select Service Type"}
                    </StepLabel>
                  </Step>
                  <Step key={"Upload Images"}>
                    <StepLabel error={stepsError.step_1} optional={stepsErrorMsg.step_1}>
                      {"Upload Images"}
                    </StepLabel>
                  </Step>
                  <Step key={"Describe The Images"}>
                    <StepLabel error={stepsError.step_2} optional={stepsErrorMsg.step_2}>
                      {"Describe The Images"}
                    </StepLabel>
                  </Step>
                  {!auth && (
                    <Step key={"Register"}>
                      <StepLabel error={stepsError.step_3} optional={stepsErrorMsg.step_3}>
                        {"Register"}
                      </StepLabel>
                    </Step>
                  )}
                  <Step key={"Pay"}>
                    <StepLabel error={stepsError.step_4} optional={stepsErrorMsg.step_4}>
                      {"Pay"}
                    </StepLabel>
                  </Step>
                </Stepper>
              </BrowserView>
              <MobileView>
                <Stepper activeStep={activeStep} orientation={"vertical"}>
                  <Step key={"Select Service Type"}>
                    <StepLabel error={stepsError.step_0} optional={stepsErrorMsg.step_0}>
                      {"Select Service Type"}
                    </StepLabel>
                    <StepContent>
                      <Box>
                        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "50vh" }}>
                          <Grid item xs={12} sx={{ textAlign: "center" }}>
                            <Typography variant="h5">What type of service are you looking for?</Typography>
                            <Typography variant="subtitle2" sx={{ paddingBottom: 4 }}>
                              Help us direct you to the right place by telling us the service you are lokking for <strong>select service type.</strong>{" "}
                            </Typography>
                            <SelectComponent serviceTypes={serviceTypes} key="service-types" error={selectError} handleChange={handleServiceTypeChange} value={selectedServiceType} />
                          </Grid>
                        </Grid>
                        <Grid>
                          <Grid item xs={12} sx={{ textAlign: "right" }}>
                            <Button variant="contained" onClick={handleServiceTypeStep} sx={{ mt: 1, mr: 1 }}>
                              Continue
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </StepContent>
                  </Step>
                  <Step key={"Upload Images"}>
                    <StepLabel error={stepsError.step_1} optional={stepsErrorMsg.step_1}>
                      {"Upload Images"}
                    </StepLabel>
                    <StepContent>
                      <Box>
                        <Stack sx={{ width: "100%" }} spacing={2}>
                          <Alert severity="info">
                            <AlertTitle>Upload the photos you want to stage.</AlertTitle>
                            Press continue when you are done. You will tell us about the images in the <strong>next step.</strong>
                          </Alert>
                        </Stack>
                        <DropZoneClient onDrop={onDrop} files={files} rejectFiles={rejectFiles} totalFilesSize={totalFilesSize} uploading={uploading} upload={upload} removeUploaded={removeUploaded} cancelUpload={cancelUpload} />
                        <Grid>
                          <Grid item xs={12} sx={{ textAlign: "right", mt: 3, mr: 1 }}>
                            <Button variant="outlined" onClick={() => handleBackStep()} sx={{ mr: 1 }}>
                              Back
                            </Button>
                            <Button variant="contained" onClick={handleUploadStep}>
                              Continue
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </StepContent>
                  </Step>
                  <Step key={"Describe The Images"}>
                    <StepLabel error={stepsError.step_2} optional={stepsErrorMsg.step_2}>
                      {"Describe The Images"}
                    </StepLabel>
                  </Step>
                  {!auth && (
                    <Step key={"Register"}>
                      <StepLabel error={stepsError.step_3} optional={stepsErrorMsg.step_3}>
                        {"Register"}
                      </StepLabel>
                    </Step>
                  )}
                  <Step key={"Pay"}>
                    <StepLabel error={stepsError.step_4} optional={stepsErrorMsg.step_4}>
                      {"Pay"}
                    </StepLabel>
                  </Step>
                </Stepper>
              </MobileView>
            </Box>
            <BrowserView>
              <Container maxWidth={"lg"} sx={{ marginTop: 6 }}>
                {activeStep === 0 && (
                  <Box>
                    <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "50vh" }}>
                      <Grid item xs={12} sx={{ textAlign: "center" }}>
                        <Typography variant="h5">What type of service are you looking for?</Typography>
                        <Typography variant="subtitle2" sx={{ paddingBottom: 4 }}>
                          Help us direct you to the right place by telling us the service you are lokking for <strong>select service type.</strong>{" "}
                        </Typography>
                        <SelectComponent serviceTypes={serviceTypes} key="service-types" error={selectError} handleChange={handleServiceTypeChange} value={selectedServiceType} />
                      </Grid>
                    </Grid>
                    <Grid>
                      <Grid item xs={12} sx={{ textAlign: "right" }}>
                        <Button variant="contained" onClick={handleServiceTypeStep} sx={{ mt: 1, mr: 1 }}>
                          Continue
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {activeStep === 1 && (
                  <Box>
                    <Stack sx={{ width: "100%" }} spacing={2}>
                      <Alert severity="info">
                        <AlertTitle>Upload the photos you want to stage.</AlertTitle>
                        Press continue when you are done. You will tell us about the images in the <strong>next step.</strong>
                      </Alert>
                    </Stack>
                    <DropZoneClient onDrop={onDrop} files={files} rejectFiles={rejectFiles} totalFilesSize={totalFilesSize} uploading={uploading} upload={upload} removeUploaded={removeUploaded} cancelUpload={cancelUpload} />
                    <Grid>
                      <Grid item xs={12} sx={{ textAlign: "right", mt: 3, mr: 1 }}>
                        <LoadingButton color="primary" onClick={() => handleBackStep()} loading={btnLoading} variant={"outlined"} sx={{ mr: 1 }}>
                          {" "}
                          Back{" "}
                        </LoadingButton>
                        <LoadingButton color="primary" onClick={handleUploadStep} loading={btnLoading} variant={"contained"} sx={{}}>
                          {" "}
                          Continue{" "}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {activeStep === 2 && (
                  <Box>
                    <Stack sx={{ width: "100%" }} spacing={2}>
                      <Alert severity="info">
                        <AlertTitle>Tell us about your images.</AlertTitle>
                        Choose the type of room for each image, then tell us about the styles you prefer. You can also give us links to instagram pages you like.
                      </Alert>
                    </Stack>
                    <MediaCard jobId={jobId} roomsTypeItems={getRoomTypes()} roomsTypeOnChange={roomsTypeOnChange} selectValue={selectValue} description={descriptions} descriptionChange={descriptionChange} />
                    <Grid>
                      <Grid item xs={12} sx={{ textAlign: "right", mt: 3, mr: 1, mb: 5 }}>
                        <LoadingButton color="primary" onClick={() => handleBackStep()} loading={btnLoading} variant={"outlined"} sx={{ mr: 1 }}>
                          {" "}
                          Back{" "}
                        </LoadingButton>
                        <LoadingButton color="primary" onClick={handleDescribeStep} loading={btnLoading} variant={"contained"} sx={{}}>
                          {" "}
                          Continue{" "}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {activeStep === 3 && (
                  <Box>
                    <Grid container spacing={4}>
                      <Grid item sm={7}>
                        <Stack sx={{ width: "100%" }} spacing={2}>
                          <Alert severity="info">
                            <AlertTitle>Login or Register with ImageMall</AlertTitle>
                            After registering you will receive an email verification with a six-digit code to enter here before moving to payment.
                            <AlertTitle sx={{ mt: 2 }}>
                              Or, Use our <strong>Guest Checkout</strong>
                            </AlertTitle>
                            In a hurry? Click the guest checkout button to simply provide the email address where you wish to receive your finished images.
                          </Alert>
                        </Stack>
                      </Grid>
                      {clientUploadProcessLogin ? (
                        <Grid item sm={5}>
                          <Typography>Login</Typography>
                          <form onSubmit={handleSubmit(onSubmitLogin)}>
                            <Controller
                              name="email"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => <TextFieldComponent type={"email"} id="email" label="email *" autoFocus={true} error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />}
                              rules={{ required: "Email is required", pattern: { value: /^\S+@\S+$/i, message: "Invalid email address" } }}
                            />
                            <Controller
                              name="password"
                              control={control}
                              defaultValue=""
                              render={({ field: { onChange, value }, fieldState: { error } }) => <TextFieldComponent type={"password"} id="password" label="password *" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Password is required",
                                },
                                minLength: {
                                  value: 6,
                                  message: "Password is too short min:6",
                                },
                                maxLength: {
                                  value: 14,
                                  message: "Password is too long max:14",
                                },
                              }}
                            />
                            <Grid container sx={{ marginTop: 3 }}>
                              <Grid item xs={6}>
                                <FormGroup sx={{ ml: 1 }}>
                                  <FormControlLabel control={<Checkbox onChange={remameberMeOnChange} checked={rememberMe} />} label="Remember Me" />
                                </FormGroup>
                              </Grid>
                              <Grid item xs={6} display="flex" justifyContent="flex-end">
                                <LoadingButton type="submit" loading={loginLoadingBtn} variant={"contained"} sx={{}}>
                                  {" "}
                                  Login{" "}
                                </LoadingButton>
                              </Grid>
                            </Grid>
                          </form>
                          <Grid container sx={{ marginTop: 3 }}>
                            <Grid item xs={12} display="flex" justifyContent="center">
                              Don't have an account yet?
                              <UiLink component="button" underline="none" sx={{ fontSize: 14, marginLeft: 1 }} onClick={handdleUiChangeToSignUp}>
                                {" Sign up here."}
                              </UiLink>
                            </Grid>
                            <Grid item xs={12} sx={{ marginTop: 3 }}>
                              <Divider>
                                <Chip label="OR" />
                              </Divider>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center" sx={{ marginTop: 3 }}>
                              <GoogleButton
                                onClick={() => {
                                  console.log("Google button clicked");
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <SignUp main={false} />
                      )}
                    </Grid>
                    <Grid>
                      <Grid item xs={12} sx={{ textAlign: "right", mt: 3, mr: 1, mb: 5 }}>
                        <LoadingButton color="primary" onClick={() => handleBackStep()} loading={btnLoading} variant={"outlined"} sx={{ mr: 1 }}>
                          {" "}
                          Back{" "}
                        </LoadingButton>
                        {/* <LoadingButton color="primary" onClick={handleRegisterOrLogin} loading={btnLoading} variant={"contained"} sx={{}}>
                          {" "}
                          Continue{" "}
                        </LoadingButton> */}
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {activeStep === 4 && (
                  <Box>
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item xs={8}>
                          <Box>
                            <Grid container spacing={4}>
                              <Grid item sm={7} sx={{ textAlign: "left" }}>
                                <Typography variant="body1">Price per image ({selectedServiceType.replace("_", " ")})</Typography>
                                <Typography variant="body1">Total files </Typography>
                                <Typography variant="body1">-</Typography>
                              </Grid>
                              <Grid item sm={3} sx={{ textAlign: "right" }}>
                                <Typography variant="body1">
                                  $<strong>{pricePerImage.toFixed(2)}</strong>
                                </Typography>
                                <Typography variant="body1">
                                  <strong>{fileCount}</strong>
                                </Typography>
                                <Typography variant="caption" color={"text.secondary"}>
                                  <strong>
                                    ${pricePerImage.toFixed(2)} x {fileCount}{" "}
                                  </strong>
                                </Typography>
                              </Grid>
                            </Grid>
                            <Divider sx={{ mt: 2, mb: 2 }} />
                            <Typography variant="body1" color={"text.secondary"} gutterBottom>
                              Total amount
                            </Typography>
                            <Typography variant="h3" gutterBottom>
                              ${total.toFixed(2)}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box sx={{ border: "1px solid #ccc", p: 2 }}>
                            <Box sx={{ display: "flex", alignItems: "center", marginBottom: 4 }}>
                              <img src={theme.palette.mode == "dark" ? "/img/Stripe wordmark - white (small).png" : "/img/Stripe wordmark - blurple (small).png"} alt="Stripe logo" width="100px" />
                              <Typography variant="body1">Trusted and Powered by Stripe</Typography>
                            </Box>
                            {stripePromise && (
                              <Elements stripe={stripePromise}>
                                <StripeForm jobId={jobId} selectedServiceType={selectedServiceType} fileNames={fileNames} selectValue={selectValue} descriptions={descriptions} />
                              </Elements>
                            )}
                          </Box>
                        </Grid>
                        {/* {errorMessage && (
                          <Grid item xs={12}>
                            <Typography variant="body2" color="error">
                              {errorMessage}
                            </Typography>
                          </Grid>
                        )} */}
                      </Grid>
                    </form>
                    <Grid>
                      <Grid item xs={12} sx={{ textAlign: "right", mt: 3, mr: 1, mb: 5 }}>
                        <LoadingButton color="primary" onClick={() => handleBackStep()} loading={btnLoading} variant={"outlined"} sx={{ mr: 1 }}>
                          {" "}
                          Back{" "}
                        </LoadingButton>
                        {/* <LoadingButton color="primary" onClick={handleRegisterOrLogin} loading={btnLoading} variant={"contained"} sx={{}}>
                          {" "}
                          Complete{" "}
                        </LoadingButton> */}
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Container>
            </BrowserView>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default UploadImages;
