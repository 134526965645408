
import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import CreateUser from "../../components/admin/CreateUser/CreateUser";
import PageToolbar from "../../components/admin/PageToolbar/PageToolbar";
import { setTopBarTitle } from "../../features/dashboardSlice/dashboardSlice";
import { useDispatch } from "react-redux";

const CreateUserPage = () => {
  // component states
  const title = "ImageMall | Create User";
  //Redux states
  const dispatch = useDispatch()

  const setTitle = (title) =>{
    dispatch(setTopBarTitle("Create User"))
  }

  useEffect(()=>{
    setTitle("Create User")
  },[])
  
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageToolbar />
      <CreateUser />
    </React.Fragment>
  );
}

export default CreateUserPage;