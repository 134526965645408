import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const ImageListLoading = () => {

    return (
        <Box>
            <Skeleton variant="rectangular" height={150} animation="wave"/>
            <Box sx={{ pt: 0.5 }}>
                <Skeleton animation="wave"/>
                <Skeleton width="60%" animation="wave"/>
            </Box>
        </Box>
    )
}

export default ImageListLoading