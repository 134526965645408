import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import TextFieldComponent from "../TextFieldComponent/TextFieldComponent";
import GoogleButton from "react-google-button";
import styles from "./SignUp.module.css";
import { LoadingButton } from "@mui/lab";
import { setClientUploadProcessLogin, setClientUploadProcessGuestLogin } from "../../../features/commonSlice/commonSlice";
import { Checkbox, Chip, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Link as UiLink, Radio, RadioGroup, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { useRef } from "react";
import { getGoogleLoginUrl, signUp } from "../../../services/AuthService";
import { useSnackbar } from "notistack";
import {setTopLoader } from "../../../features/mainSlice/mainSlice";

import { snackBarPosition } from "../../../services/CommonService";

const SignUp = (props) => {
  // component states
  const [btnLoading, setLoginBtnLoading] = useState(false);
  const [loadingPostion, setLoadingPostion] = useState("center");
  const [partnerSignUp, setPartnerSignUp] = useState(false);
  const navigate = useNavigate();
  // Redux states
  const clientUploadProcessGuestLogin = useSelector((state) => state.common.clientUploadProcessGuestLogin);
  const clientUploadProcessLogin = useSelector((state) => state.common.clientUploadProcessLogin);
  // snackbar
  const { enqueueSnackbar } = useSnackbar();
  // form
  const { handleSubmit, control, watch, formState } = useForm({
    mode: "onChange",
  });
  const password = useRef({});
  password.current = watch("password", "");
  const dispatch = useDispatch();

  const handlePartnerCheckBox = (event) => {
    setPartnerSignUp(event.target.checked);
  };
  // Load sing up page inside of upload process page
  const handdleUiChangeToLogin = () => {
    dispatch(setClientUploadProcessLogin(true));
  };

  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };

  const handleGuestSignUp = () => {
    dispatch(setClientUploadProcessGuestLogin(true));
  };
  const onSubmit = (data) => {
    dispatch(setTopLoader(true));
    setLoginBtnLoading(true);

    signUp(data)
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          navigate("/verify?email="+data.email);
        }
        setLoginBtnLoading(false);
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        showError(error.response.data.message);
        setLoginBtnLoading(false);
        dispatch(setTopLoader(false));
      });
  };
  const signUpWithGoogle = () => {
    dispatch(setTopLoader(true));
    getGoogleLoginUrl().then((response)=>{
      console.log(response.data.data)
      if(response.data.success){
        window.location.href = response.data.data
      }
      console.log(response)
    }).catch((error) => {
      showError(error.response.data.message);
      setLoginBtnLoading(false);
      dispatch(setTopLoader(false));
    });
  }
  return (
    <Container maxWidth="lg" sx={{ marginTop: 10, marginBottom: 6 }}>
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "80vh", marginTop: "2%", marginBottom: "2%" }}>
        <Grid item xs={3}>
          <Card className={styles.login_card}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <CardContent>
                <Typography variant="h4" component={"div"}>
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography>Sign Up</Typography>
                      <Typography variant="h4" sx={{ fontWeight: 600 }}>
                        Create an account
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={2} display="flex" justifyContent="flex-end">
                          <img src="./favicon.png" className="login-logo" alt="logo" />
                        </Grid> */}
                  </Grid>
                </Typography>
                <Typography sx={{ fontSize: 14 }} color="text.secondary">
                  Fill out the form to get started.
                </Typography>
                <Typography sx={{ marginTop: 2 }} component="div">
                  <Grid container>
                    <Grid item xs={5.5}>
                      <Typography variant="caption" sx={{ fontWeight: 600 }}>
                        Enter your first name
                      </Typography>

                      <Controller
                        name="first_name"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => <TextFieldComponent type={"text"} id="firs-name" label="First Name *" autoFocus={true} error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />}
                        rules={{ required: "First name is required" }}
                      />
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={5.5}>
                      <Typography variant="caption" sx={{ fontWeight: 600 }}>
                        Enter your last name
                      </Typography>
                      <Controller
                        name="last_name"
                        control={control}
                        defaultValue=""
                        render={({ field: { onChange, value }, fieldState: { error } }) => <TextFieldComponent type={"text"} id="last-name" label="Last Name *" autoFocus={true} error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />}
                        rules={{ required: "Last name is required" }}
                      />
                    </Grid>
                  </Grid>
                </Typography>
                <Typography sx={{ marginTop: 2 }} component="div">
                  <Typography variant="caption" sx={{ fontWeight: 600 }}>
                    Enter your email
                  </Typography>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => <TextFieldComponent type={"email"} id="email" label="email *" autoFocus={true} error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />}
                    rules={{
                      required: "Email is required",
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message: "Invalid email address",
                      },
                    }}
                  />
                </Typography>
                <Typography sx={{ marginTop: 2 }} component="div">
                  <Grid container>
                    <Grid item xs={6} display="flex" justifyContent="left">
                      <Typography variant="caption" sx={{ fontWeight: 600 }}>
                        Enter your password
                      </Typography>
                    </Grid>
                  </Grid>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                      return <TextFieldComponent type={"password"} id="password" label="Password*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />;
                    }}
                    rules={{
                      required: "Password is required",
                      minLength: {
                        value: 6,
                        message: "Password is too short min:6",
                      },
                      maxLength: {
                        value: 14,
                        message: "Password is too long max:14",
                      },
                    }}
                  />
                  <Typography variant="caption" sx={{ fontWeight: 600, marginTop: 2 }}>
                    Confirm password
                  </Typography>
                  <Controller
                    name="confirm_password"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => {
                      return <TextFieldComponent type={"password"} id="confirm_password" label="Confirm Password*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />;
                    }}
                    rules={{
                      required: "Password is required",
                      minLength: {
                        value: 6,
                        message: "Password is too short min:6",
                      },
                      maxLength: {
                        value: 14,
                        message: "Password is too long max:14",
                      },
                      validate: (value) => value === password.current || "The passwords do not match",
                    }}
                  />
                </Typography>
                <Typography sx={{ marginTop: 2 }} component="div">
                  {partnerSignUp && (
                    <Grid container sx={{ marginBottom: 2, marginTop: 2 }}>
                      <Divider variant="middle" />
                      <Grid item xs={12} display="flex" justifyContent="center">
                        <FormControl>
                          <FormLabel id="">Select what partner you want to be.</FormLabel>

                          <Controller
                            name="partner"
                            control={control}
                            defaultValue=""
                            render={({ field }) => {
                              return (
                                <RadioGroup {...field} row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                  <Tooltip title="Be a vendor in imagemall">
                                    <FormControlLabel control={<Radio />} label="Vendor" value={"vendor"} />
                                  </Tooltip>
                                  <Tooltip title="Be a contractor in imagemall">
                                    <FormControlLabel control={<Radio />} label="Contractor" value={"contractor"} />
                                  </Tooltip>
                                </RadioGroup>
                              );
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Divider variant="middle" />
                    </Grid>
                  )}
                  <Grid container>
                    <Grid item xs={8}>
                      <FormGroup>
                        <FormControlLabel control={<Checkbox size="large" />} label="I want to be a imagemall partner!" onChange={handlePartnerCheckBox} />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={4} display="flex" justifyContent="flex-end">
                      <LoadingButton loading={btnLoading} variant={"contained"} sx={{}} size="large" disabled={!formState.isValid} type="submit">
                        {" "}
                        Sign Up{" "}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                  <Divider sx={{ marginTop: 3, marginBottom: 3 }}>
                    <Chip label="complicated to sign up ?. don't worry" />
                  </Divider>
                  <Grid container>
                    <Grid item xs={12} display="flex" justifyContent="center">
                      {props.main ? (
                        <Link to="/guest-login" className={styles.router_link}>
                          <LoadingButton color="success" loading={btnLoading} variant={"outlined"} sx={{}} size="large">
                            {" "}
                            Continue as a guest{" "}
                          </LoadingButton>
                        </Link>
                      ) : (
                        <LoadingButton color="success" onClick={handleGuestSignUp} loading={btnLoading} variant={"outlined"} sx={{}} size="large">
                          {" "}
                          Continue as a guest{" "}
                        </LoadingButton>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container sx={{ marginTop: 3 }}>
                    <Grid item xs={12} display="flex" justifyContent="center">
                      Already have an account?
                      {props.main ? (
                        <UiLink component="button" variant="body2" to="/login" underline="none" sx={{ fontSize: 14, marginLeft: 1 }}>
                          <Link to="/login" className={styles.router_link}>
                            {" Login. "}
                          </Link>
                        </UiLink>
                      ) : (
                        <UiLink component="button" variant="body2" underline="none" sx={{ fontSize: 14, marginLeft: 1 }} onClick={handdleUiChangeToLogin}>
                          {" Login. "}
                        </UiLink>
                      )}
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 3 }}>
                      <Divider>
                        <Chip label="OR" />
                      </Divider>
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="center" sx={{ marginTop: 3 }}>
                      <GoogleButton
                        label="Sign up with Google"
                        onClick={signUpWithGoogle}
                      />
                    </Grid>
                  </Grid>
                </Typography>
              </CardContent>
              <CardActions></CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SignUp;
