import React from 'react';

// icons
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import RawOnOutlinedIcon from '@mui/icons-material/RawOnOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
// components
import CreatePermission from '../pages/admin/CreatePermission';
import Dashboard from '../pages/admin/Dashboard';
import RolePermissionsPage from '../pages/admin/RolePermissions';
import CreateUserPage from '../pages/admin/CreateUser';
import ManageUsers from '../pages/admin/ManageUsers';
import CreateJob from '../pages/admin/CreateJob';
import JobTypes from '../pages/admin/JobTypes';
import ManageJob from '../pages/admin/ManageJob';
import Vendor from '../pages/admin/Vendor';
import Portfolio from '../pages/admin/Portfolio';
import ActivityLog from '../pages/admin/ActivityLog';
import ExceptionLog from '../pages/admin/ExceptionLog';
import Notifications from '../pages/admin/Notifications';
import Payments from '../pages/admin/Payments';
import PaymentFormPage from '../pages/admin/PaymentPage';
import Services from '../pages/admin/Services';
import SystemSettingsPage from '../pages/admin/SystemSetting';
import ViewJobPage from '../pages/admin/ViewJobPage';
import PayForVendors from '../pages/admin/PayForVendors';
import ManageFeedbacks from '../pages/admin/ManageFeedbacks';
import MyAccount from '../pages/admin/MyAccount';

const AdminRoutes = {
        "<Dashboard />" : <Dashboard />,
        "<HomeOutlinedIcon />" : <HomeOutlinedIcon />,
        "<CreatePermission />" : <CreatePermission />,
        "<VerifiedUserOutlinedIcon />" : <AdminPanelSettingsOutlinedIcon />,
        "<RolePermissions />" : <RolePermissionsPage />,
        "<AdminPanelSettingsOutlinedIcon />":<VerifiedUserOutlinedIcon />,
        "<CreateUser />": <CreateUserPage />,
        "<PersonAddOutlinedIcon />": <PersonAddOutlinedIcon />,
        "<ManageUsers />": <ManageUsers />,
        "<ManageAccountsOutlinedIcon />": <ManageAccountsOutlinedIcon />,
        "<CreateJob />": <CreateJob />,
        "<AddPhotoAlternateOutlinedIcon />" : <AddPhotoAlternateOutlinedIcon />,
        "<JobTypes />": <JobTypes />,
        "<RawOnOutlinedIcon />" : <RawOnOutlinedIcon />,
        "<ManageJob />": <ManageJob />,
        "<WorkOutlineOutlinedIcon />" : <WorkOutlineOutlinedIcon />,
        "<Vendor />" : <Vendor />,
        "<PersonSearchOutlinedIcon />" : <PersonSearchOutlinedIcon />,
        "<Portfolio />" : <Portfolio />,
        "<BadgeOutlinedIcon />" : <BadgeOutlinedIcon />,
        "<ActivityLog />" : <ActivityLog />,
        "<FormatListBulletedIcon />" : <FormatListBulletedIcon />,
        "<ExceptionLog />" : <ExceptionLog />,
        "<BugReportOutlinedIcon />" : <BugReportOutlinedIcon />,
        "<Notifications />" : <Notifications />,
        "<NotificationsNoneIcon />" : <NotificationsNoneIcon />,
        "<Payments />" : <Payments />,
        "<PaymentsOutlinedIcon />" : <PaymentsOutlinedIcon />,
        "<PaymentFormPage />" : <PaymentFormPage />,
        "<Services />" : <Services />,
        "<SystemSettingsPage />" : <SystemSettingsPage />,
        "<SettingsOutlinedIcon />" : <SettingsOutlinedIcon />,
        "<ViewJobPage />" : <ViewJobPage />,
        "<AccountBalanceIcon />" : <AccountBalanceIcon />,
        "<PayForVendors />" : <PayForVendors />,
        "<ChatOutlinedIcon />" : <ChatOutlinedIcon />,
        "<ManageFeedbacks />" : <ManageFeedbacks />,
        "<MyAccount />" : <MyAccount />,

}




export default AdminRoutes;