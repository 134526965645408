import Cookies from "js-cookie";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import { baseApiURL, baseWebURL } from "./RequestService";
Pusher.logToConsole = true;

// window.Pusher = Pusher

// const echo = new Echo({
//   authEndpoint: baseWebURL + "broadcasting/auth",
//   auth: {
//     headers: {
//       'Authorization': 'Bearer ' + Cookies.get("token") ?? "",
//     },
//   },
//   broadcaster: 'pusher',
//   key: process.env.REACT_APP_PUSHER_APP_KEY,
//   cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
//   forceTLS: false,
//   wsHost: process.env.REACT_APP_PUSHER_APP_WSHOST,
//   wsPort: 6001,
//   wssPort:6001,
//   enableStats: false,
//   encrypted: true,
//   devMode:true
// });

// const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
//   authEndpoint: baseApiURL + "broadcasting/auth",
//   auth: {
//     headers: {
//       'Authorization': 'Bearer ' + Cookies.get("token") ?? "",
//     },
//   },
//   forceTLS: false,
//   cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
//   wsHost: process.env.REACT_APP_PUSHER_APP_WSHOST,
//   wsPort: process.env.REACT_APP_PUSHER_APP_WSPORT,
//   encrypted: true,
//   enabledTransports: ['ws'],
// });

// production
const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
  authEndpoint: baseWebURL + "broadcasting/auth",
  auth: {
    headers: {
      'Authorization': 'Bearer ' + Cookies.get("token") ?? "",
    },
  },
  cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  wsHost: process.env.REACT_APP_PUSHER_APP_WSHOST,
  wsPort: process.env.REACT_APP_PUSHER_APP_WSPORT,
  forceTLS: false,
  enabledTransports:['ws']
});
const chnlActivity = pusher.subscribe('private-activity');
const chnlException = pusher.subscribe('private-exception');
const chnlNotifiy = pusher.subscribe('private-notifications.' + Cookies.get('br_id'));

// const chnlActivity = echo.channel('private-activity');
// const chnlException = echo.channel('private-exception');
// const chnlNotifiy = echo.channel('private-notifications.' + Cookies.get('br_id'));
export { chnlActivity, chnlException, chnlNotifiy }
// export { echo }