import { Avatar, Chip, Divider, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { baseWebURL } from "../../../services/RequestService";
import StarIcon from "@mui/icons-material/Star";
import { useState } from "react";
import { useEffect } from "react";
import InlineChipCarousel from "../InlineChipCarousel/InlineChipCarousel";
const VendorCard = (props) => {
  const { title, cover, avatar, name, avgRate, reviewCount, jobsDone, services } = props;
  const [findCover, setCover] = useState(null);
  useEffect(() => {
    find_cover();
  }, [cover]);
  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  const find_cover = () => {
    if (isJsonString(cover)) {
      const c = JSON.parse(cover);
      c?.map((item, index) => {
        if (item.cover === true) {
          return setCover(item.image);
        }
      });
    } else {
      setCover(cover);
    }
  };
  return (
    <Paper sx={{ my: 1, cursor: "pointer", height: "100%", borderTopLeftRadius:10, borderTopRightRadius:10 }} elevation={0} variant="outlined">
      {/* onMouseEnter={} onMouseLeave={} */}
      <React.Fragment>
        <img
          style={{
            width: "100%",
            height: 160,
            borderRadius: 10,
          }}
          alt={title}
          src={findCover !== null && findCover !== undefined ? baseWebURL + findCover : "/img/vendor-cover.png"}
          loading="lazy"
        />
        <Box sx={{ pr: 2, pt: 1, pl: 2 }}>
          <Box sx={{ display: "inline-flex", width: "100%" }}>
            {avatar !== null && avatar !== undefined ? (
              <Avatar src={baseWebURL + avatar} alt={name} sx={{ width: 30, height: 30 }} />
            ) : (
              <Avatar alt={name} sx={{ width: 30, height: 30 }}>
                {name[0].toUpperCase()}
              </Avatar>
            )}
            <Typography gutterBottom variant="body2" sx={{ mt: 0.5, ml: 1 }}>
              <strong>{name}</strong>
            </Typography>
          </Box>
          <Typography display="block" variant="caption" color="text.secondary" sx={{ pt: 1, pb: 1 }}>
            {title}
          </Typography>
          <InlineChipCarousel items={services} />
              {/* {services?.map((item, index) => {
                return <Chip label={<Typography variant="caption">
                  {item.types.name} <strong>${item.price.toFixed(2)}</strong>
                </Typography>} key={index} sx={{ mt: 0.2, mb: 0.2, mr:0.5 }} />;
              })} */}
          <Box sx={{ display: "inline-flex", width: "100%", pt: 1 }}>
            <StarIcon color="warning" />
            <Typography variant="body1" color="text.secondary">
              {" "}
              <strong>{avgRate !== null && avgRate !== undefined ? avgRate : 'Not rated yet'}</strong> ({reviewCount !== null && reviewCount !== undefined ? reviewCount : 0})
            </Typography>
          </Box>
          <Divider />
          <Typography align="right" variant="body2" color="text.secondary" sx={{ mt: 1, fontStyle: "italic" }}>
            Jobs Done <strong>• ({jobsDone})</strong>
          </Typography>
        </Box>
      </React.Fragment>
    </Paper>
  );
};

export default VendorCard;
