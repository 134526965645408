import { Card } from "@mui/material";
import React from "react";

const CardComponent = (props) => {

    return (
        <Card raised={false}>
            {props.children}
        </Card>
    )
}

export default CardComponent;