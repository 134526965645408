import { Avatar, CircularProgress, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import { useEffect } from "react";

const AvatarUpload = (props) => {
  const { image, onChange, size, loading } = props;
  let imgWidth = "100px";
  let imgHeight = "100px";

  if (size === "small") {
    imgWidth = "100px";
    imgHeight = "100px";
  } else if (size === "medium") {
    imgWidth = "150px";
    imgHeight = "150px";
  } else if (size === "large") {
    imgWidth = "200px";
    imgHeight = "200px";
  }

  return (
    <React.Fragment>
      <Tooltip title="Click to upload">
        <IconButton aria-label="upload picture" component="label">
          <input hidden accept="image/*" type="file" onChange={onChange} />
          {loading ? (
            <CircularProgress />
          ) : (
            <Avatar
              src={image}
              style={{
                margin: "10px",
                width: imgWidth,
                height: imgHeight,
              }}
            />
          )}
        </IconButton>
      </Tooltip>

      <Typography>
        <Tooltip title="Click to upload">
          <IconButton color="primary" aria-label="upload picture" component="label">
            <input hidden accept="image/*" type="file" onChange={onChange} />
            <PhotoCamera fontSize="large" />
          </IconButton>
        </Tooltip>
      </Typography>
    </React.Fragment>
  );
};

export default AvatarUpload;
