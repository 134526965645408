import React from "react";
import {
    CircularProgress, Button,
    Chip,
    Divider,
    Grid,
    Rating,
    Typography,
} from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { EditOutlined } from "@mui/icons-material";
import { baseWebURL } from "../../../../services/RequestService";

const ViewUser = (props) => {
    return (
        <Grid container spacing={8}>
            <Grid item sm={4} sx={{ textAlign: "center" }}>
                {props.userData.image ? (
                    <img
                        src={baseWebURL + props.userData.image}
                        style={{
                            margin: "10px",
                            width: "150px",
                            height: "150px",
                            borderRadius: "50%",
                            objectFit: "cover",
                        }}
                    />
                ) : (
                    <React.Fragment>
                        {props.userData.image !== null ? <CircularProgress /> : <img
                            src="/img/avatar.png"
                            style={{
                                margin: "10px",
                                width: "150px",
                                height: "150px",
                                borderRadius: "50%",
                                objectFit: "cover",
                            }}
                        />}

                    </React.Fragment>
                )}
                <Typography variant="subtitle1" align="center">
                    {props.userData.name}
                </Typography>
                {/* <Rating
                    name="text-feedback"
                    value={4}
                    readOnly
                    precision={0.5}
                    emptyIcon={
                        <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                /> */}
            </Grid>
            <Grid item sm={8}>
                <Typography align="right">
                    <Button onClick={props.handleEditUserClick} sx={{ mb: 3 }}>
                        <EditOutlined />
                        &nbsp; Edit User
                    </Button>
                </Typography>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    <Typography variant="body1" sx={{ fontSize: 11, textTransform: "uppercase" }}>
                        role
                    </Typography>
                    <Chip label={props.userData.role_name} />
                </Typography>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    <Typography variant="body1" sx={{ fontSize: 11, textTransform: "uppercase" }}>
                        email
                    </Typography>
                    {props.userData.email}
                </Typography>
                <Divider />
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    <Typography variant="body1" sx={{ fontSize: 11, textTransform: "uppercase" }}>
                        mobile
                    </Typography>
                    {props.userData.mobile}
                </Typography>
                <Divider />
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    <Typography variant="body1" sx={{ fontSize: 11, textTransform: "uppercase" }}>
                        country
                    </Typography>
                    {props.userData.country}
                </Typography>
                <Divider />
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    <Typography variant="body1" sx={{ fontSize: 11, textTransform: "uppercase" }}>
                        status
                    </Typography>
                    {props.userData.status ? "Active" : "Deactivated"}
                </Typography>
                <Divider />
                <Typography variant="subtitle1" sx={{ mt: 2 }}>
                    <Typography variant="body1" sx={{ fontSize: 11, textTransform: "uppercase" }}>
                        description
                    </Typography>
                    {props.userData.description}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default ViewUser