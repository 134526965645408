import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { Facebook, Twitter, LinkedIn, Link } from '@mui/icons-material';
import { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ShareIcon from '@mui/icons-material/Share';
function ShareBar({ url }) {
  const shareUrl = encodeURIComponent(url);
  const shareTitle = 'Check out this awesome work!';
  const [copied, setCopied] = useState(false)

  const shareOnFacebook = () => {
    const shareLink = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&title=${shareTitle}`;
    window.open(shareLink, '_blank', 'noopener,noreferrer');
  };

  const shareOnTwitter = () => {
    const shareLink = `https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareTitle}`;
    window.open(shareLink, '_blank', 'noopener,noreferrer');
  };

  const shareOnLinkedIn = () => {
    const shareLink = `https://www.linkedin.com/shareArticle?url=${shareUrl}&title=${shareTitle}`;
    window.open(shareLink, '_blank', 'noopener,noreferrer');
  };

  const copyLink = () => {
    setCopied(true)
    navigator.clipboard.writeText(url);
  };

  return (
    <Box display="flex" alignItems="center" gap={1}>
        <ShareIcon />
      <Tooltip title="Share on Facebook">
        <IconButton onClick={shareOnFacebook}>
          <Facebook />
        </IconButton>
      </Tooltip>
      <Tooltip title="Share on Twitter">
        <IconButton onClick={shareOnTwitter}>
          <Twitter />
        </IconButton>
      </Tooltip>
      <Tooltip title="Share on LinkedIn">
        <IconButton onClick={shareOnLinkedIn}>
          <LinkedIn />
        </IconButton>
      </Tooltip>
      <Tooltip title="Copy link">
        <Button variant="outlined" size='small' onClick={copyLink} endIcon={copied ? <CheckIcon /> : <Link />}>
          {copied ? 'Copied' : 'Copy'}
        </Button>
      </Tooltip>
    </Box>
  );
}
export default ShareBar