import { requestGet, requestPost } from "./RequestService"
const prefix = '/client'

const samples = async (data) => {
    return await requestGet(prefix+'/samples');
};
const getSample = async (data) => {
    return await requestPost(prefix+'/get-sample', data);
};
const getSamplePaginate = async (page) => {
    return await requestGet(prefix+'/samples?page='+page);
};
const zipCompress = async (data) => {
    return await requestPost(prefix+'/zip/compress', data);
};
const sendRating = async (url, data) => {
    return await requestPost(url, data);
};
const searchSamples = async (data) => {
    return await requestPost(prefix+'/samples/search', data);
};
const getServiceTypes = async () => {
    return await requestGet(prefix+'/service-types');
};
const getAmounts = async (data) => {
    return await requestPost(prefix+'/amount-details', data);
};
const createClientJob = async (data, authToken) => {
    return await requestPost(prefix+'/jobs/create-job', data, authToken);
};
export { samples, getSample, zipCompress, sendRating, getSamplePaginate, searchSamples, getServiceTypes, getAmounts, createClientJob }