import "../App.css";
import React, {useState, useEffect} from "react";
import { Helmet } from "react-helmet";
import Progress from "../components/common/Progress/Progress";
import GuestLogin from "../components/common/GuestLogin/GuestLogin";

const GuestLoginPage = () => {

  // component states

  const title = "ImageMall | Guest Login";

  // use effect
  useEffect(() => {
    // hide top loader after component load

  }, [])


  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <GuestLogin main={true}/>
    </>
  );
}


export default GuestLoginPage;