import { Box, Chip, Tooltip } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAvalableBalance } from "../../../features/commonSlice/commonSlice";
import { getVendorBalance } from "../../../services/PaymentService";
import { getAdminTotalTransaction, getCreatorSpends } from "../../../services/StatisticsService";

const TopBarEarningBadge = (props) => {
  const availableBalance = useSelector((state) => state.common.availableBalance);
  const role = useSelector((state) => state.common.role);
  const dispatch = useDispatch();

  // get vendor available balance
  useEffect(() => {
    if (role === "vendor") {
      getVendorBalance().then((response) => {
        dispatch(setAvalableBalance(response.data.data.toFixed(2)));
      });
    }else if (role === "creator") {
      getCreatorSpends().then((response) => {
        dispatch(setAvalableBalance(response.data.data.toFixed(2)));
      });
    }else if (role === "super_admin") {
      getAdminTotalTransaction().then((response) => {
        dispatch(setAvalableBalance(response.data.data.toFixed(2)));
      });
    }
  }, [availableBalance, role]);

  return (
    <Box>
      <Tooltip title={role === "vendor" ? "Balance" : "Total Spends"}>
        <Chip variant="outlined" color="success" sx={{ fontSize: "14px", marginRight: 1.5 }} label={"$"+availableBalance} />
      </Tooltip>
    </Box>
  );
}

export default TopBarEarningBadge;
