import { Button, Rating, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useState } from "react";
import { sendRating, zipCompress } from "../../../services/ClientServie";
import { useSnackbar } from "notistack";
import { setSnackbarLoading } from "../../../features/commonSlice/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { baseWebURL } from "../../../services/RequestService";
import { setRatingEffect } from "../../../features/jobSlice/jobSlice";
import { snackBarPosition } from "../../../services/CommonService";

const RateAndReview = (props) => {
  const { buttonLbl, job_id, btnIcon, url, zipDownload } = props;
  const [rating, setRating] = useState(null);
  const [review, setReview] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const ratingEffect = useSelector((state)=>state.job.ratingEffect)
  const dispatch = useDispatch();
  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };
  const handleSubmit = () => {
    sendRating(url, { points: rating, review: review, job_id: job_id })
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          if(zipDownload){
            handleDownloadZip("completed")
          }
          dispatch(setRatingEffect(!ratingEffect))
        } else {
          showError(response.data.message);
        }
      })
      .catch((error) => {
        showError(error.response.data.message);
      });
  };
  const handleDownloadZip = (working) => {
    dispatch(setSnackbarLoading(true));
    zipCompress({ job_id:job_id, working: working })
      .then((response) => {
        const filename = response.data.data.filename;
        const working = response.data.data.working;
        window.location.href =
          baseWebURL +
          "zip/download?job_id=" +
          job_id +
          "&working=" +
          working +
          "&filename=" +
          filename;
        dispatch(setSnackbarLoading(false));
      })
      .catch((response) => {
        console.log("error", response);
        dispatch(setSnackbarLoading(false));
      });
  };
  return (
    <Box>
      <Typography sx={{ mb: 2 }}>How would you rate this work.?</Typography>
      <Rating
        name="rate"
        value={rating}
        size="large"
        sx={{ mb: 2 }}
        onChange={(event, newValue) => {
          setRating(newValue);
        }}
      />
      <Typography sx={{ mb: 2 }}>
        Describe About Your Review (optional)
      </Typography>
      <TextField
        id="description"
        label="Description"
        multiline
        rows={5}
        fullWidth={true}
        value={review}
        onChange={(e) => {
          setReview(e.target.value);
        }}
      />
      <Typography align="right">
        <Button
          variant="contained"
          startIcon={btnIcon}
          onClick={handleSubmit}
          sx={{ mt: 2 }}
          disabled={rating === null}
        >
          {" "}
          {buttonLbl}
        </Button>
      </Typography>
    </Box>
  );
};

export default RateAndReview;
