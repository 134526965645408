import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import TextFieldComponent from "../TextFieldComponent/TextFieldComponent";
import GoogleButton from "react-google-button";
import styles from "./GuestLogin.module.css";
import { LoadingButton } from "@mui/lab";
import { Link, useNavigate } from "react-router-dom";
import { setClientUploadProcessGuestLogin, setClientUploadProcessLogin } from "../../../features/commonSlice/commonSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import { Chip, Divider, Link as UiLink } from "@mui/material";
import { useSnackbar } from "notistack";
import { setTopLoader } from "../../../features/mainSlice/mainSlice";
import { getGoogleLoginUrl, guestLogin } from "../../../services/AuthService";
import { Controller, useForm } from "react-hook-form";

const GuestLogin = (props) => {
  // component states
  const [loginBtnLoad, setLoginBtnLoad] = useState(false);

  // Redux states
  const clientUploadProcessGuestLogin = useSelector((state) => state.common.clientUploadProcessGuestLogin);
  const clientUploadProcessLogin = useSelector((state) => state.common.clientUploadProcessLogin);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate()
  // form
  const { handleSubmit, control } = useForm({
    mode: "onChange",
  });
  const onSubmit = (data) => {
    setLoginBtnLoad(true);
    guestLogin(data)
      .then((response) => {
        if (response.data.success === true) {
            showSuccess(response.data.message);
            navigate("/verify?email="+data.email);
          }
          setLoginBtnLoad(false);
          dispatch(setTopLoader(false));
      })
      .catch((error) => {
        if(error.response.data.data.email){
            showError(error.response.data.data.email[0]);
        }else{
            showError(error.response.data.message);
        }
        
        setLoginBtnLoad(false);
        dispatch(setTopLoader(false));
      });
  };
  const showError = (message) => {
    enqueueSnackbar(message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" } });
  };
  // Load sing up page inside of upload process page
  const handleUiChangeToGuestLogin = () => {
    dispatch(setClientUploadProcessGuestLogin(false));
  };
  const handleUiChangeToSignUp = () => {
    dispatch(setClientUploadProcessLogin(false));
  };
  const signUpWithGoogle = () => {
    dispatch(setTopLoader(true));
    getGoogleLoginUrl()
      .then((response) => {
        console.log(response.data.data);
        if (response.data.success) {
          window.location.href = response.data.data;
        }
        console.log(response);
      })
      .catch((error) => {
        showError(error.response.data.message);
        setLoginBtnLoad(false);
        dispatch(setTopLoader(false));
      });
  };
  return (
    <Container maxWidth="lg">
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "80vh" }}>
        <Grid item xs={3}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card className={styles.login_card}>
              <React.Fragment>
                <CardContent>
                  <Typography variant="h4" component={"div"}>
                    <Grid container>
                      <Grid item xs={10}>
                        <Typography>Guest Login</Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary">
                    Please provide an email address to send your finished images to.
                  </Typography>
                  <Typography sx={{ marginTop: 2 }} component="div">
                    <Typography variant="caption">Enter your email</Typography>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => <TextFieldComponent type={"email"} id="email" label="email *" autoFocus={true} error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />}
                      rules={{ required: "Email is required", pattern: { value: /^\S+@\S+$/i, message: "Invalid email address" } }}
                    />
                  </Typography>
                  <Typography sx={{ marginTop: 2 }} component="div">
                    <Grid container>
                      <Grid item xs={6}></Grid>
                      <Grid item xs={6} display="flex" justifyContent="flex-end">
                        <LoadingButton type="submit" loading={loginBtnLoad} variant={"contained"} sx={{}} size="large">
                          {" "}
                          Login{" "}
                        </LoadingButton>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginTop: 3 }}>
                      <Grid item xs={12} display="flex" justifyContent="center">
                        Need default register?
                        {props.main ? (
                          <Link to="/sign-up" className={styles.router_link}>
                            Sign up here
                          </Link>
                        ) : (
                          <UiLink component="button" underline="none" sx={{ fontSize: 14, marginLeft: 1 }} onClick={handleUiChangeToSignUp}>
                            {" Sign up here."}
                          </UiLink>
                        )}
                      </Grid>
                      <Grid item xs={12} sx={{ marginTop: 3 }}>
                        <Divider>
                          <Chip label="OR" />
                        </Divider>
                      </Grid>
                      <Grid item xs={12} display="flex" justifyContent="center" sx={{ marginTop: 3 }}>
                        <GoogleButton onClick={signUpWithGoogle} />
                      </Grid>
                    </Grid>
                  </Typography>
                </CardContent>
                <CardActions></CardActions>
              </React.Fragment>
            </Card>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GuestLogin;
