import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Tab, Tabs, Paper, Button, Box, Grid, Typography, Divider, useTheme } from "@mui/material";
import { Container } from "@mui/system";
import TextFieldComponent from "../../common/TextFieldComponent/TextFieldComponent";
import LoadingButtons from "../../common/LoadingButton/LoadingButtonComponent";
import { createWebhookEndpoint, get, store } from "../../../services/SystemSettingService";
import { get as ITGet, store as ITStore } from "../../../services/ImageTypeService";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { setTopLoader } from "../../../features/mainSlice/mainSlice";
import { useEffect } from "react";
import MultiSelect from "../../common/MultiSelect/MultiSelect";
import AddIcon from "@mui/icons-material/Add";
import DataDialog from "../../common/DataDialog/DataDialog";
import { snackBarPosition } from "../../../services/CommonService";

const SystemSetting = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [btnLoading, setBtnLoading] = useState(false);
  const [settings, setSettings] = useState([]);
  const [webhookData, setWebhookData] = useState({});
  const [openImageTypeAddDialod, setOpenImageTypeAddDialod] = useState(false);
  const [ITName, setITName] = useState("");
  const [ITType, setITType] = useState("");
  const [ITExt, setITExt] = useState("");
  const [ImageTypes, setImageTypes] = useState([]);
  const [webhookEndpoint, setWebhookEndpoint] = useState("");
  const [isStripeKeysSet, setIsStripeKeysSet] = useState(false);


  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { handleSubmit, control, formState, setValue } = useForm({
    mode: "onChange",
  });
  useEffect((item) => {
    getSettings();
    getImageTypes();
  }, []);
  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };

  const onSubmit = (data) => {
    dispatch(setTopLoader(true));
    setBtnLoading(true);
    store(data)
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
        }
        dispatch(setTopLoader(false));
        setBtnLoading(false);
      })
      .catch((error) => {
        showError(error.response.data.message);
        dispatch(setTopLoader(false));
        setBtnLoading(false);
      });
  };
  const getSettings = () => {
    dispatch(setTopLoader(true));
    get()
      .then((response) => {
        if (response.data.success === true) {
          const data = response.data.data;
          setValue("processing_charge", data.processing_charge.toFixed(2));
          setValue("withdrawal_threshold", data.withdrawal_threshold.toFixed(2));
          setValue("stripe_public_key", data.stripe_public_key);
          setValue("stripe_secret_key", data.stripe_secret_key);
          setValue("max_file_size", data.max_file_size.toFixed(2));
          const accepted_image_types = data.accepted_image_types;
          let ait = [];
          accepted_image_types.map((item) => {
            ait.push(item.name);
          });
          setValue("accepted_image_types", ait);
          setWebhookEndpoint(data.webhook_endpoint);
          setWebhookData(data.webhook_endpoint_data);
          setIsStripeKeysSet(data.stripe_public_key !== '' && data.stripe_secret_key !== '')
        }
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        console.log(error);
        showError(error.response.data.message);
        dispatch(setTopLoader(false));
      });
  };
  const handleCancel = () => {
    setOpenImageTypeAddDialod(false);
  };
  const handleAddImageTypeClick = () => {
    setOpenImageTypeAddDialod(true);
  };
  const ITnameOnChange = (e) => {
    setITName(e.target.value);
  };
  const ITtypeOnChange = (e) => {
    setITType(e.target.value);
  };
  const ITExtOnChange = (e) => {
    setITExt(e.target.value);
  };
  const getImageTypes = () => {
    dispatch(setTopLoader(true));
    ITGet()
      .then((response) => {
        if (response.data.success === true) {
          const data = response.data.data;
          setImageTypes(data);
        }
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        showError(error.response.data.message);
        dispatch(setTopLoader(false));
      });
  };
  const handleImageTypeSave = () => {
    if (ITName === "" || ITType === "" || ITExt === "") {
      showError("Name, Type, Extension is required");
      return;
    }
    setBtnLoading(true);
    ITStore({ name: ITName, type: ITType, extention:ITExt })
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
        }
        setBtnLoading(false);
        setOpenImageTypeAddDialod(false);
        getImageTypes();
      })
      .catch((error) => {
        showError(error.response.data.message);
        setBtnLoading(false);
      });
  };
  const webhookEndpointOnChange = (e) =>{
    setWebhookEndpoint(e.target.value)
  }
  const handleCreateWebhookClick = () =>{
    if (webhookEndpoint === "") {
      showError("endpoint is required");
      return;
    }
    setBtnLoading(true);
    createWebhookEndpoint({webhook_endpoint:webhookEndpoint})
    .then((response) => {
      if (response.data.success === true) {
        showSuccess(response.data.message);
      }
      setBtnLoading(false);
    })
    .catch((error) => {
      showError(error.response.data.message);
      setBtnLoading(false);
    });
  }
  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper elevation={0} sx={{ width: "100%", mx: "auto", my: 4 }}>
          <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)}>
            <Tab label="General" />
            <Tab label="Uploading Settings" />
            <Tab label="STRIPE" />
          </Tabs>
          <Box p={2}>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <LoadingButtons loading={btnLoading} name="Save Settings" variant="contained" type="submit" marginTop={0} size="small" />
            </Box>
            {/* Tab 1 */}
            {activeTab === 0 && (
              <Box>
                <Grid container spacing={4} sx={{ mb: 3, mt: 0.5 }}>
                  <Grid item sm={6}>
                    <Typography>ImageMall Processing Charge (IMPC)</Typography>
                    <Typography variant="caption" color={"text.secondary"}>
                      This charge added for the every job in the imagemall
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Controller
                      name="processing_charge"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return <TextFieldComponent size="small" type={"number"} id="price" label="Processing Charge* $" error={!!error} helperText={error ? error.message : null} value={value} marginTop={0} onChange={onChange} />;
                      }}
                    />
                  </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={4} sx={{ mb: 3, mt: 0.5 }}>
                  <Grid item sm={6}>
                    <Typography>Withdrawal Threshold</Typography>
                    <Typography variant="caption" color={"text.secondary"}>
                      This is the minimum amount that can withdraw for vendors.
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Controller
                      name="withdrawal_threshold"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return <TextFieldComponent size="small" type={"number"} id="withdrawal_threshold" label="Withdrawal Threshold* $" error={!!error} helperText={error ? error.message : null} value={value} marginTop={0} onChange={onChange} />;
                      }}
                    />
                  </Grid>
                </Grid>
                <Divider />
              </Box>
            )}
            {/* Tab 2 */}
            {activeTab === 1 && (
              <Box>
                <Grid container spacing={4} sx={{ mb: 3, mt: 0.5 }}>
                  <Grid item sm={6}>
                    <Typography>Accepted File Types</Typography>
                    <Typography variant="caption" color={"text.secondary"}>
                      Add type of files that accept when uploading images
                    </Typography>
                    <Box>
                      <Button startIcon={<AddIcon />} onClick={handleAddImageTypeClick}>
                        Add New Type
                      </Button>
                    </Box>
                    <DataDialog onClickCancel={handleCancel} open={openImageTypeAddDialod} header="Add Image Type" loading={false} maxWidth="sm" minWidth="300px">
                      <Box>
                        <TextFieldComponent size="small" type={"text"} id="name" label="Name*" marginTop={0} onChange={ITnameOnChange} />
                        <TextFieldComponent size="small" type={"text"} id="meme_type" label="Meme Type*" marginTop={3} onChange={ITtypeOnChange} />
                        <Typography variant="body2" sx={{ color: "text.secondary" }}>
                          Ex: image/png
                        </Typography>
                        <TextFieldComponent size="small" type={"text"} id="extentiont" label="Type*" marginTop={3} onChange={ITExtOnChange} />
                        <Typography variant="body2" sx={{ color: "text.secondary" }}>
                          Ex: .png
                        </Typography>

                        <LoadingButtons loading={btnLoading} name="Save" variant="contained" type="button" size="small" marginTop={2} onClick={handleImageTypeSave} />
                      </Box>
                    </DataDialog>
                  </Grid>
                  <Grid item sm={6}>
                    <Controller
                      name="accepted_image_types"
                      control={control}
                      defaultValue={[]}
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return <MultiSelect label="Select Accepted Types" items={ImageTypes} error={!!error} value={value} marginTop={0} onChange={onChange} />;
                      }}
                    />
                  </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={4} sx={{ mb: 3, mt: 0.5 }}>
                  <Grid item sm={6}>
                    <Typography>Upload Max File Size (MB)</Typography>
                    <Typography variant="caption" color={"text.secondary"}>
                      Max file size for one file (Mega Bytes)
                    </Typography>
                  </Grid>
                  <Grid item sm={6}>
                  <Controller
                      name="max_file_size"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return <TextFieldComponent size="small" type={"number"} id="max_file_size" label="Max File Size*" error={!!error} helperText={error ? error.message : null} value={value} marginTop={0} onChange={onChange} />;
                      }}
                    />
                  </Grid>
                </Grid>
                <Divider />
              </Box>
            )}
            {/* Tab 3 */}
            {activeTab === 2 && (
              <Box>
                <Grid container spacing={4} sx={{ mb: 3, mt: 0.5 }}>
                  <Grid item sm={6}>
                    <Typography>Stripe Secret Key</Typography>
                    <Typography variant="caption" color={"text.secondary"}>
                      Stripe payment gateway secret key for the payment process
                    </Typography>
                    <br />
                    <img src={theme.palette.mode == "dark" ? "/img/Powered by Stripe - white.svg" : "/img/Powered by Stripe - blurple.svg"} alt="Stripe logo" width="80px" />
                  </Grid>
                  <Grid item sm={6}>
                    <Controller
                      name="stripe_secret_key"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return <TextFieldComponent size="small" type={"text"} id="price" label="Stripe Secret Key*" error={!!error} helperText={error ? error.message : null} value={value} marginTop={0} onChange={onChange} />;
                      }}
                    />
                  </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={4} sx={{ mb: 3, mt: 0.5 }}>
                  <Grid item sm={6}>
                    <Typography>Stripe Public Key</Typography>
                    <Typography variant="caption" color={"text.secondary"}>
                      Stripe payment gateway public key for the payment process
                    </Typography>
                    <br />
                    <img src={theme.palette.mode == "dark" ? "/img/Powered by Stripe - white.svg" : "/img/Powered by Stripe - blurple.svg"} alt="Stripe logo" width="80px" />
                  </Grid>
                  <Grid item sm={6}>
                    <Controller
                      name="stripe_public_key"
                      control={control}
                      defaultValue=""
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        return <TextFieldComponent size="small" type={"text"} id="price" label="Stripe Public Key*" error={!!error} helperText={error ? error.message : null} value={value} marginTop={0} onChange={onChange} />;
                      }}
                    />
                  </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={4} sx={{ mb: 3, mt: 0.5 }}>
                  <Grid item sm={6}>
                    <Typography>Webhook Endpoint</Typography>
                    <Typography variant="caption" color={"text.secondary"}>
                      Webhooks allow you to automate custom backend actions in response to the transitions that happen in a payment flow.
                    </Typography>
                    <br />
                    <img src={theme.palette.mode == "dark" ? "/img/Powered by Stripe - white.svg" : "/img/Powered by Stripe - blurple.svg"} alt="Stripe logo" width="80px" />
                  </Grid>
                  <Grid item sm={6}>
                     <TextFieldComponent size="small" type={"text"} id="webhook_endpoint" label="Webhook Endpoint*" value={webhookEndpoint} marginTop={0} onChange={webhookEndpointOnChange} />
                     <LoadingButtons loading={btnLoading} name="Update Webhook" variant="contained" type="button" size="small" marginTop={2} disabled={!isStripeKeysSet} onClick={handleCreateWebhookClick}/>
                    <Typography sx={{ mt: 2 }} variant="body2">
                      Webhook Data
                    </Typography>
                    <Box sx={{ backgroundColor: "text.secondary", p: 2, color: "common.white" }}>{JSON.stringify(webhookData, null, 2)}</Box>
                  </Grid>
                </Grid>
              </Box>
            )}
            <Box mt={2} display="flex" justifyContent="flex-end">
              <LoadingButtons loading={btnLoading} name="Save Settings" variant="contained" type="submit" size="small" marginTop={0} />
            </Box>
          </Box>
        </Paper>
      </form>
    </Container>
  );
};

export default SystemSetting;
