import { Avatar, Button, CardContent, Chip, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import CardComponent from "../../common/CardComponent/CardComponent";
import PageContainer from "../PageContainer/PageContainer";
import { setTopLoader } from "../../../features/mainSlice/mainSlice";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { deleteUser, getUserById, getUsers, resetPassword, updateUser } from "../../../services/UsersService";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import { useNavigate } from "react-router-dom";
import { baseWebURL } from "../../../services/RequestService";
import TextFieldComponent from "../../common/TextFieldComponent/TextFieldComponent";
import { VisibilityOutlined } from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ConfirmDialog from "../../common/ConfirmDialog/ConfirmDialog";
import DataDialog from "../../common/DataDialog/DataDialog";
import DataTable from "../../common/DataTable/DataTable";
import ViewUser from "./ViewUser/ViewUser";
import EditUser from "./EditUser/EditUser";
import { Controller, useForm } from "react-hook-form";
import { getRoles } from "../../../services/PermissionService";
import { setDataDialogPasswordResetModalOpen } from "../../../features/usersSlice/usersSlice";
import LoadingButtons from "../../common/LoadingButton/LoadingButtonComponent";
import { snackBarPosition } from "../../../services/CommonService";
const ManageUsers = () => {
  const [rows, setRows] = useState([]);
  const [isRowsSet, setIsRowsSet] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dataDialogOpen, setDataDialogOpen] = useState(false);
  const [dataDialogEditOpen, setDataDialogEditOpen] = useState(false);
  const [dataDialogLading, setDataDialogLading] = useState(true);
  const [dataEditDialogLading, setDataEditDialogLading] = useState(true);
  const [selectId, setSelectId] = useState(null);
  const [userData, setUserData] = useState({});
  const [imageLoaded, setImageLoaded] = useState(false);
  const [preview, setPreview] = useState(undefined);
  const [profileImage, setProfileImage] = useState(undefined);
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [roles, setRoles] = useState([]);
  // form
  const { handleSubmit, control, reset, formState, watch } = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit: handleSubmit2,
    control: control2,
    reset: reset2,
    formState: formState2,
    watch: watch2,
  } = useForm({
    mode: "onChange",
  });
  const password = useRef({});
  password.current = watch2("password", "");

  // snackbar
  const { enqueueSnackbar } = useSnackbar();
  const dataDialogPasswordResetModalOpen = useSelector((state) => state.users.dataDialogPasswordResetModalOpen);
  const role = useSelector((state) => state.common.role);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  function createData(id, image, name, email, status, role_name, action) {
    return {
      id,
      image,
      name,
      email,
      status,
      role_name,
      action,
    };
  }
  // use effect
  useEffect(() => {
    getUser();
    dispatch(setTopLoader(true));
    if (role === "super_admin") {
      getRoles()
        .then((response) => {
          let roles = [];
          roles = [...roles];
          response.data.data.map((item) => {
            return roles.push({ name: item.name, value: item.id });
          });
          setRoles(roles);
          dispatch(setTopLoader(false));
        })
        .catch((error) => {
          console.log(error);
          dispatch(setTopLoader(false));
          // console.log("error", response.response);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUser = () => {
    dispatch(setTopLoader(true));
    setRows([]);
    getUsers()
      .then((response) => {
        let rows = [];
        rows = [...rows];
        response.data.data.map((item, index) => {
          const image = <Avatar src={item.image != null ? baseWebURL + item.image : ""} />;
          const status = <Chip label={item.status ? "Active" : "Deactivate"} size="small" variant="outlined" color={item.status ? "primary" : "error"} />;
          const action = (
            <React.Fragment>
              <IconButton onClick={(e) => handleViewClick(item.id)}>
                <VisibilityOutlined fontSize="small" color="action" />
              </IconButton>
              <IconButton onClick={(e) => handleEditClick(item.id)}>
                <EditOutlinedIcon fontSize="small" color="action" />
              </IconButton>
              <IconButton onClick={(e) => handleDelete(item.id)}>
                <DeleteOutlineOutlinedIcon fontSize="small" color="action" />
              </IconButton>
            </React.Fragment>
          );
          const created_at = new Date(item.created_at).toLocaleDateString();
          rows.push(createData(item.id, image, item.name, item.email, status, item.role_name, action));
        });

        setRows(rows);
        setIsRowsSet(true);
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        console.log(error);
        showError(error.response.data.message);
        dispatch(setTopLoader(false));
      });
  };

  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };
  const columns = [
    { field: "id", headerName: "ID", minWidth: 50, type: "number" },
    {
      field: "image",
      headerName: "Image",
      minWidth: 50,
      editable: false,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 50,
      editable: false,
      renderCell: (params) => {
        return params.value;
      },
    },
    {
      field: "role_name",
      headerName: "Role",
      minWidth: 150,
      editable: false,
    },
    {
      field: "action",
      headerName: "Action",
      align: "center",
      minWidth: 150,
      editable: true,
      renderCell: (params) => {
        return params.value;
      },
    },
  ];
  const handleConfirmDialog = (event) => {
    setDialogOpen(false);
    dispatch(setTopLoader(true));
    deleteUser({ id: selectId })
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          dispatch(setTopLoader(false));
          setDataDialogEditOpen(false);
          getUser();
        }
      })
      .catch((error) => {
        console.log(error);
        showError(error.response.data.message);
        dispatch(setTopLoader(false));
      });
  };
  const handleCancelDataDialog = () => {
    setDataDialogOpen(false);
    setDataDialogEditOpen(false);
    dispatch(setDataDialogPasswordResetModalOpen(false));
  };
  const handleDelete = (id) => {
    setDialogOpen(true);
    setSelectId(id);
  };
  const handleCancelDialog = () => {
    setDialogOpen(false);
  };
  const handleCreateUserClick = () => {
    navigate("/partners/users/create-user");
  };

  const handleViewClick = (id) => {
    setDataDialogOpen(true);
    setImageLoaded(true);
    setDataDialogLading(true);
    setUserData({});
    getUserById({ id })
      .then((response) => {
        setUserData(response.data.data);
        setDataDialogLading(false);
        setImageLoaded(false);
      })
      .catch((error) => {
        console.log(error);
        showError(error.response.data.message);
        setDataDialogLading(false);
      });
  };
  const handleEditClick = (id) => {
    reset();
    setDataDialogEditOpen(true);
    setImageLoaded(true);
    setDataEditDialogLading(true);
    setUserData({});
    getUserById({ id })
      .then((response) => {
        setUserData(response.data.data);
        setDataEditDialogLading(false);
        setImageLoaded(false);
      })
      .catch((error) => {
        console.log(error);
        showError(error.response.data.message);
        setDataEditDialogLading(false);
      });
  };
  const handleEditUserClick = (id) => {
    handleEditClick(id);
  };
  const handleFileUpload = (e) => {
    dispatch(setTopLoader(true));
    if (!e.target.files || e.target.files.length === 0) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
    setProfileImage(e.target.files[0]);
    dispatch(setTopLoader(false));
  };
  const onSubmit = (data) => {
    setDataEditDialogLading(true);
    setBtnLoading(true);
    if (profileImage != undefined) {
      data["image"] = profileImage;
    }
    data["id"] = userData.id;
    updateUser(data)
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          setDataEditDialogLading(false);
          setBtnLoading(false);
          setBtnDisabled(false);
          getUser();
          setDataDialogEditOpen(false);
        }
        reset();
        setPreview(undefined);
        setProfileImage(undefined);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 422) {
          showError(error.response.data.data[0]);
        } else {
          showError(error.response.data.message);
        }
        setDataEditDialogLading(false);
        setBtnLoading(false);
        setBtnDisabled(false);
        // console.log("error", response.response);
      });
  };
  // password reset
  const onResetPassword = (data) => {
    setBtnLoading(true);
    resetPassword({ user_id: userData.id, password: data.password })
      .then((response) => {
        if (response.data.success === true) {
          showSuccess(response.data.message);
          setBtnLoading(false);
          setBtnDisabled(false);
          reset2();
          dispatch(setDataDialogPasswordResetModalOpen(false));
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 422) {
          showError(error.response.data.data[0]);
        } else {
          showError(error.response.data.message);
        }
        setBtnLoading(false);
        setBtnDisabled(false);
        // console.log("error", response.response);
      });
  };
  const handleCancelPasswordResetDataDialog = () => {
    dispatch(setDataDialogPasswordResetModalOpen(false));
  };
  // password reset
  return (
    <PageContainer>
      <ConfirmDialog onClickConfirm={handleConfirmDialog} onClickCancel={handleCancelDialog} open={dialogOpen} header="Are You Sure?" message="Please confirm to delete this data. this action cannot revert" />
      <DataDialog onClickCancel={handleCancelDataDialog} open={dataDialogOpen} header="View User" loading={dataDialogLading} fullWidth={false} maxWidth={"lg"}>
        <ViewUser userData={userData} handleEditUserClick={() => handleEditUserClick(userData.id)} />
      </DataDialog>
      {/* // user edit dialog */}
      <DataDialog onClickCancel={handleCancelDataDialog} open={dataDialogEditOpen} header="Edit User" loading={dataEditDialogLading} fullWidth={false} maxWidth={"lg"}>
        {userData ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <EditUser userData={userData} handleFileUpload={handleFileUpload} preview={preview} formState={formState} control={control} roles={roles} watch={watch} handleDelete={() => handleDelete(userData.id)} />
            <Typography align="right">
              <LoadingButtons loading={btnLoading} name="Save" variant="contained" type="submit" disabled={!formState.isValid} />
            </Typography>
          </form>
        ) : (
          <Typography align="center">Loading..</Typography>
        )}
      </DataDialog>
      {/* password reset */}
      <DataDialog onClickCancel={handleCancelPasswordResetDataDialog} open={dataDialogPasswordResetModalOpen} header="Reset Password" loading={false}>
        <form onSubmit={handleSubmit2(onResetPassword)}>
          <Controller
            name="password"
            control={control2}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return <TextFieldComponent type={"password"} id="password" label="Password*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />;
            }}
            rules={{
              required: "Password is required",
              minLength: {
                value: 6,
                message: "Password is too short min:6",
              },
              maxLength: {
                value: 14,
                message: "Password is too long max:14",
              },
            }}
          />
          <Controller
            name="confirm_password"
            control={control2}
            defaultValue={""}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return <TextFieldComponent type={"password"} id="confirm_password" label="Confirm Password*" error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />;
            }}
            rules={{
              required: "Password is required",
              minLength: {
                value: 6,
                message: "Password is too short min:6",
              },
              validate: (value) => value === password.current || "The passwords does not match",
              maxLength: {
                value: 14,
                message: "Password is too long max:14",
              },
            }}
          />
          <Typography align="right">
            <LoadingButtons loading={btnLoading} name="Reset Password" variant="contained" type="submit" disabled={!formState2.isValid} />
          </Typography>
        </form>
      </DataDialog>

      <Grid container spacing={4}>
        <Grid item sm={12}>
          <CardComponent>
            <CardContent>
              <Typography align="right">
                <Button onClick={handleCreateUserClick} sx={{ mb: 3 }}>
                  <PersonAddOutlinedIcon />
                  &nbsp; Create User
                </Button>
              </Typography>
              <DataTable rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[10]} checkboxSelection disableSelectionOnClick experimentalFeatures={{ newEditingApi: true }} loading={rows.length === 0} />
            </CardContent>
          </CardComponent>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default ManageUsers;
