import { baseApiURL, requestGet, requestPost } from "./RequestService";
import axios from "axios";
import Cookies from "js-cookie";

const prefix = "/users";

const storeUser = async (data) => {
  return await axios.post(baseApiURL + prefix.replace('/','') + "/store", data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + Cookies.get("token") ?? "",
    },
  });
};
const getUsers = async () => {
  return await requestGet(prefix + "/get");
  // return await requestPost('authenticate', data)
};
const deleteUser = async (data) => {
  return await requestPost(prefix + "/delete", data);
  // return await requestPost('authenticate', data)
};
const getUserById = async (data) => {
  return await requestPost(prefix + "/get", data);
  // return await requestPost('authenticate', data)
};
const getVendors = async (data) => {
  return await requestGet(prefix + "/get-vendors", data);
  // return await requestPost('authenticate', data)
};
const getVendorContractors = async (data) => {
  return await requestGet(prefix + "/get-vendor-c", data);
  // return await requestPost('authenticate', data)
};
const getCreatorContractors = async (data) => {
  return await requestGet(prefix + "/get-creator-c", data);
  // return await requestPost('authenticate', data)
};
const updateUser = async (data) => {
  return await axios.post(baseApiURL + prefix.replace('/','') + "/update", data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "COntent-Type": "multipart/form-data",
      Authorization: "Bearer " + Cookies.get("token") ?? "",
    },
  });
};
const resetPassword = async (data) => {
  return await requestPost(prefix + "/reset-password", data);
  // return await requestPost('authenticate', data)
};

export { storeUser, getUsers, deleteUser, getUserById, updateUser, resetPassword, getVendors, getVendorContractors, getCreatorContractors };
