import "../App.css";
import React from "react";
import { Helmet } from "react-helmet";
import PasswordReset from "../components/common/PasswordReset/PasswordReset";


const PasswordResePage = () => {
  const title = "ImageMall | Password Reset";

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PasswordReset />
    </>
  );
};

export default PasswordResePage;
