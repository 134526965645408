import { Avatar, Button, Card, CardContent, Chip, Container, Divider, Grid, IconButton, Paper, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ImageGallery from "react-image-gallery";
import CountUp from "react-countup";
import { useRef } from "react";
import { Box } from "@mui/system";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import ReactCompareImage from "react-compare-image";
import DriveFolderUploadRoundedIcon from "@mui/icons-material/DriveFolderUploadRounded";
import NotesOutlinedIcon from "@mui/icons-material/NotesOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { useEffect } from "react";
const Home = () => {
  // component states
  const title = "ImageMall | Home";

  const secImages = useRef(null);
  const secServices = useRef(null);

  const navigate = useNavigate();

  const styles = {
    cover: {
      minHeight: "100vh",
      backgroundImage: `url(${"img/wave.svg"})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    servicesStyles: {
      minHeight: "50vh",
      backgroundImage: `url(${"img/rainbow-vortex.svg"})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    howItsWorkStyles: {
      minHeight: "50vh",
      marginBottom: "10vh",
      marginTop: "5vh",
    },
  };
  const sampleData = [
    {
      original: "https://imagemall.io/assets/client/assets/img/p1.jpg",
      thumbnail: "https://imagemall.io/assets/client/assets/img/p1.jpg",
    },
    {
      original: "https://imagemall.io/assets/client/assets/img/p2.jpg",
      thumbnail: "https://imagemall.io/assets/client/assets/img/p2.jpg",
    },
    {
      original: "https://imagemall.io/assets/client/assets/img/p3.jpg",
      thumbnail: "https://imagemall.io/assets/client/assets/img/p3.jpg",
    },
    {
      original: "https://imagemall.io/assets/client/assets/img/p4.jpg",
      thumbnail: "https://imagemall.io/assets/client/assets/img/p4.jpg",
    },
    {
      original: "https://imagemall.io/assets/client/assets/img/p5.jpg",
      thumbnail: "https://imagemall.io/assets/client/assets/img/p5.jpg",
    },
    {
      original: "https://imagemall.io/assets/client/assets/img/p6.jpg",
      thumbnail: "https://imagemall.io/assets/client/assets/img/p6.jpg",
    },
    {
      original: "https://imagemall.io/assets/client/assets/img/p7.jpg",
      thumbnail: "https://imagemall.io/assets/client/assets/img/p7.jpg",
    },
    {
      original: "https://imagemall.io/assets/client/assets/img/p8.jpg",
      thumbnail: "https://imagemall.io/assets/client/assets/img/p8.jpg",
    },
    {
      original: "https://imagemall.io/assets/client/assets/img/p9.jpg",
      thumbnail: "https://imagemall.io/assets/client/assets/img/p9.jpg",
    },
  ];

  const scrollToNextSection = (section) => {
    section.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleGetStartClick = () => {
    navigate("/upload-images");
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={styles.cover}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography variant="h1" sx={{ fontWeight: "bold", fontSmooth: "antialiased" }}>
            Upload<span style={{ color: "#2196f3" }}>,</span> Describe<span style={{ color: "#2196f3" }}>,</span> Done.
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: 700 }}>
            Welcome to imagemall
          </Typography>

          <Typography variant="h4">virtual staging and so much more.</Typography>
          <Button size="large" variant="contained" onClick={handleGetStartClick} sx={{ marginTop: 5, paddingLeft: 5, paddingRight: 5 }} endIcon={<ArrowForwardIcon />}>
            Start My Project
          </Button>
          <Typography variant="h6" sx={{ marginBottom: 4, marginTop: 4, fontWeight: 700 }}>
            <CountUp end={3000} duration={1} />+ Projects Done
          </Typography>
          <Box sx={{ textAlign: "center" }}>
            <IconButton onClick={() => scrollToNextSection(secImages)} className="fade-move-animation" color="primary">
              <KeyboardDoubleArrowDownIcon sx={{ fontSize: 50 }} />
            </IconButton>
            <br />
            <Typography className="animate-flicker" variant="body2">
              scroll
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {/* image section */}
      <Container ref={secImages} sx={{ mt: 10 }}>
        <ImageGallery items={sampleData} lazyLoad={true} thumbnailPosition={"bottom"} />
      </Container>
      {/* services section  */}
      <Container ref={secServices} sx={{ mt: 10 }}>
        <Chip color="primary" label="Services" sx={{ pl: 3, pr: 3 }} />
        <Typography variant="h4" sx={{ mt: 5 }}>
          Who are ImageMall and what do they do?
        </Typography>
        <Typography variant="subtitle1" width={400} sx={{ mt: 3 }} color="textSecondary">
          Imagine having an online platform where you can get your photos edited by the world’s best photo editors. ImageMall gathers digital talent from around the world to bring you the best in editing, virtual staging, rendering and more.
        </Typography>
        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item sm={3}>
            <Paper variant="elevation" elevation={1} sx={{ p: 3, height: "100%" }}>
              <Avatar variant="rounded" sx={{ backgroundColor: "warning.light" }}>
                &nbsp;
              </Avatar>
              <Typography variant="h5" sx={{ mt: 4 }}>
                Image Editing.
              </Typography>
              <Typography color="textSecondary" sx={{ mt: 2, fontSize: 18 }}>
                Upload your raw images and exposures, and our editors will color correct, balance and edit them to perfection. Widow pulls, clipping paths, advanced HDR merging, flash-ambient (flambient) and more.
              </Typography>
              <Button sx={{ mt: 5 }}>start</Button>
            </Paper>
          </Grid>
          <Grid item sm={3}>
            <Paper variant="elevation" elevation={1} sx={{ p: 3, height: "100%" }}>
              <Avatar variant="rounded" sx={{ backgroundColor: "secondary.light" }}>
                &nbsp;
              </Avatar>
              <Typography variant="h5" sx={{ mt: 4 }}>
                Virtual Staging.
              </Typography>
              <Typography color="textSecondary" sx={{ mt: 2, fontSize: 18 }}>
                Transform your photos of an empty space into inspiring, fully staged scenes. Our talented interior designers and 3D artists leverage an inventory of over 300,000+ pieces of digital furniture, decor and artwork to match any look you desire.
              </Typography>
              <Button sx={{ mt: 5 }}>start</Button>
            </Paper>
          </Grid>

          <Grid item sm={3}>
            <Paper variant="elevation" elevation={1} sx={{ p: 3, height: "100%" }}>
              <Avatar variant="rounded" sx={{ backgroundColor: "success.light" }}>
                &nbsp;
              </Avatar>
              <Typography variant="h5" sx={{ mt: 4 }}>
                Floor Plans.
              </Typography>
              <Typography color="textSecondary" sx={{ mt: 2, fontSize: 18 }}>
                Let us turn your black-and-white floor plans into 3D pop-up style plans that grab viewers and show them the potential of the space.
              </Typography>
              <Button sx={{ mt: 5 }}>start</Button>
            </Paper>
          </Grid>
          <Grid item sm={3}>
            <Paper variant="elevation" elevation={1} sx={{ p: 3, height: "100%" }}>
              <Avatar variant="rounded" sx={{ backgroundColor: "primary.light" }}>
                &nbsp;
              </Avatar>
              <Typography variant="h5" sx={{ mt: 4 }}>
                Blue Skies.
              </Typography>
              <Typography color="textSecondary" sx={{ mt: 2, fontSize: 18 }}>
                The weather is always bright and sunny over at ImageMall. Gray, gloomy skies are nothing to worry about - our editors can quickly replace them with realistic, clear blue skies to brighten the mood of any photo.
              </Typography>
              <Button sx={{ mt: 5 }}>start</Button>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Container ref={secServices} sx={{ mt: 10 }}>
        <Grid container spacing={6}>
          <Grid item sm={6}>
            <Card raised={true}>
              <ReactCompareImage leftImage="img/image-1.jpg" rightImage="img/image-2.jpg" />
            </Card>
          </Grid>
          <Grid item sm={6}>
            <Typography variant="h4" align="center">
              How does ImageMall work?
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 3 }} color="textSecondary">
              It’s as easy as 1-2-3. Just start a new project and upload your images. Then describe the changes you want and complete your payment with a credit card. A link to download your completed images will be sent to the email you provide us when ready.
            </Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item sm={4}>
                <DriveFolderUploadRoundedIcon sx={{ fontSize: 35 }} />
                <Typography variant="h6">Upload</Typography>
                <Typography variant="subtitle2" sx={{ mt: 1.5 }} color="textSecondary">
                  Upload the photos you want to edit.
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <NotesOutlinedIcon sx={{ fontSize: 35 }} />
                <Typography variant="h6">Describe</Typography>
                <Typography variant="subtitle2" sx={{ mt: 1.5 }} color="textSecondary">
                  Describe the images (if necessary) and tell us how you want them to look.
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <PaymentsOutlinedIcon sx={{ fontSize: 35 }} />
                <Typography variant="h6">Payment</Typography>
                <Typography variant="subtitle2" sx={{ mt: 1.5 }} color="textSecondary">
                  Make a quick credit card payment using our secure form. A link to your finished images will be sent to the email address you provide to us.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider sx={{ mb: 10, mt: 10 }} />
      </Container>
      <Container sx={{ mt: 10, mb: 15 }}>
        <Grid container spacing={4}>
          <Grid item sm={3}>
            <Typography align="center" variant="h4" sx={{ fontWeight: "bold" }}>
              <CountUp end={3000} duration={1} />+
            </Typography>
            <Typography align="center" color="textSecondary" sx={{ mt: 1, fontSize: 18 }}>
              Completed jobs in imagemall
            </Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography align="center" variant="h4" sx={{ fontWeight: "bold" }}>
              <CountUp end={111267} duration={1} />+
            </Typography>
            <Typography align="center" color="textSecondary" sx={{ mt: 1, fontSize: 18 }}>
              Awesome photos delivered
            </Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography align="center" variant="h4" sx={{ fontWeight: "bold" }}>
              <CountUp end={100} duration={1} />+
            </Typography>
            <Typography align="center" color="textSecondary" sx={{ mt: 1, fontSize: 18 }}>
              Vendors working on imagemall
            </Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography align="center" variant="h4" sx={{ fontWeight: "bold" }}>
                <AllInclusiveIcon sx={{fontSize:40}}/>
            </Typography>
            <Typography align="center" color="textSecondary" sx={{  fontSize: 18 }}>
             Hours saved
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Home;
