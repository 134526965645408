
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import CreateJob from "../../components/admin/CreateJob/CreateJob";
import PageToolbar from "../../components/admin/PageToolbar/PageToolbar";
import { setTopBarTitle } from "../../features/dashboardSlice/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import ContentAnimation from "../../components/common/Animations/ContentAnimation";
import SystemSetting from "../../components/admin/SystemSetting/SystemSetting";

const SystemSettingsPage = (props) => {

  // component states

  const title = "ImageMall | System Settings";
  const topLoader = useSelector((state) => state.main.topLoader);
  //Redux states
  const dispatch = useDispatch()

  // use effect
  useEffect(() => {
    // hide top loader after component load
    dispatch(setTopBarTitle('System Settings'))
  }, [])

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageToolbar alert="System setting are effect to the all over systems setting. carefull when change the settings" severity="warning" color="warning" close={true} />
      <SystemSetting />
    </React.Fragment>
  );
}

export default SystemSettingsPage;