import { requestGet, requestPost } from "./RequestService"
const prefix = '/notifications'

const getNotificationsTop = async (data) => {
    return await requestGet(prefix+'/top');
};
const getAll = async (data) => {
    return await requestGet(prefix+'/get');
};
const read = async (data) => {
    return await requestPost(prefix+'/read', data);
};
const topRead = async () => {
    return await requestGet(prefix+'/top-read');
};
export { getNotificationsTop, getAll, read, topRead }