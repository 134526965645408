import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import styles from "../../../components/common/Login/Login.module.css";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useForm, Controller } from "react-hook-form";
import { setAuthentication, setTopLoader } from "../../../features/mainSlice/mainSlice";
import TextFieldComponent from "../TextFieldComponent/TextFieldComponent";
import { useState } from "react";
import { resendVerificationCode, verify } from "../../../services/AuthService";
import { useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import { Button } from "@mui/material";
const CodeVerification = (props) => {
  const { emailVerification } = props;
  // component states
  const [loginBtnLoad, setLoginBtnLoad] = useState(false);
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // snackbar
  const { enqueueSnackbar } = useSnackbar();

  // form
  const { handleSubmit, control, formState } = useForm({
    mode: "onChange",
  });

  useEffect(() => {}, []);

  const onSubmit = (data) => {
    // Show loaders
    setLoginBtnLoad(true);
    dispatch(setTopLoader(true));
    if (!email) {
      showError("Url is not valid");
      setLoginBtnLoad(false);
      return;
    }
    verify({ code: data.code, emailVerification, email })
      .then((response) => {
        if (response.data.success) {
          if (emailVerification) {
            if (response.data.data.token !== null) {
              Cookies.set("isLoggedIn", true, { secure: true });
              Cookies.set("admin", true, { secure: true });
              Cookies.set("token", response.data.data.token, { secure: true });
              dispatch(setAuthentication(true));
              showSuccess(response.data.message);
              window.location.href = "/partners/dashboard";
            } else {
              showSuccess(response.data.message);
              navigate("/login");
            }
          } else {
            showSuccess(response.data.message);
            navigate(-1);
          }
        }
        setLoginBtnLoad(false);
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        showError(error.response.data.message);
        setLoginBtnLoad(false);
        dispatch(setTopLoader(false));
      });
  };
  const showError = (message) => {
    enqueueSnackbar(message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" } });
  };
  // Load sing up page inside of upload process page
  // use effect
  const resendCode = () => {
    setLoginBtnLoad(true);
    dispatch(setTopLoader(true));
    if (!email) {
      showError("Url is not valid");
      setLoginBtnLoad(false);
      return;
    }
    resendVerificationCode({ email })
      .then((response) => {
        if (response.data.success) {
          showSuccess(response.data.message);
        }
        setLoginBtnLoad(false);
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        showError(error.response.data.message);
        setLoginBtnLoad(false);
        dispatch(setTopLoader(false));
      });
  };
  return (
    <Container maxWidth="lg" sx={{ marginTop: 10, marginBottom: 6 }}>
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "80vh", marginTop: 2, marginBottom: 2 }}>
        <Grid item xs={3}>
          <Card className={styles.login_card}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <CardContent>
                <Typography variant="h4" component={"div"}>
                  <Grid container>
                    <Grid item xs={10}>
                      <Typography>Verify</Typography>
                    </Grid>
                  </Grid>
                </Typography>
                <Typography sx={{ marginTop: 2 }} component="div">
                  <Typography variant="caption">Please enter 6 digits code that we sent to your email.</Typography>
                  <Controller
                    name="code"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, value }, fieldState: { error } }) => <TextFieldComponent type={"text"} id="code" label="code *" autoFocus={true} error={!!error} helperText={error ? error.message : null} onChange={onChange} value={value} />}
                    rules={{ required: "Code is required" }}
                  />
                </Typography>
                <Grid container marginTop={3}>
                  <Grid item xs={6}>
                    <Button onClick={resendCode}>didn't receive the code ?</Button>
                  </Grid>
                  <Grid item xs={6} display="flex" justifyContent="flex-end">
                    <LoadingButton type="submit" loading={loginBtnLoad} variant={"contained"} sx={{}} size="large" disabled={!formState.isValid}>
                      {" "}
                      Verify{" "}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions></CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CodeVerification;
