import { CircularProgress, Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Cookies from "js-cookie";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setAuthentication, setAuthToken } from "../features/mainSlice/mainSlice";
import { googleAuthCallBack } from "../services/AuthService";

const GoogleAuthCallBack = () => {
  const title = "ImageMall | Google Login";
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  // use effect
  useEffect(() => {
    console.log(location.search);
    googleAuthCallBack(location.search)
      .then((response) => {
        if (response.data.success) {
          Cookies.set("isLoggedIn", true, { secure: true });
          Cookies.set("admin", true, { secure: true });
          Cookies.set("token", response.data.data.token, { secure: true });
          dispatch(setAuthToken(response.data.data.token));
          dispatch(setAuthentication(true));
          showSuccess(response.data.message);
          window.location.href = "partners/dashboard";
        }
      })
      .catch((error) => {
        showError(error.response.data.message);
        navigate("/login");
      });
  }, []);
  const showError = (message) => {
    enqueueSnackbar(message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" } });
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
          <Box textAlign="center">
            <Typography variant="body2" sx={{ mb: 2 }}>
              Authenticating...
            </Typography>
            <CircularProgress />
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default GoogleAuthCallBack;
