
import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import PageToolbar from "../../components/admin/PageToolbar/PageToolbar";
import { setTopBarTitle } from "../../features/dashboardSlice/dashboardSlice";
import { useDispatch } from "react-redux";
import PortfolioView from "../../components/admin/Portfolio/PortfolioView/PortfolioView";

const Portfolio = (props) => {

  // component states

  const title = "ImageMall | Portfolio";
  //Redux states
  const dispatch = useDispatch()
  
  // use effect
  useEffect(() => {
    // hide top loader after component load
    dispatch(setTopBarTitle('Portfolio'))
  }, [])
  
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageToolbar />
      <PortfolioView vendor={true} vId={null}/>
    </React.Fragment>
  );
}

export default Portfolio;