import { Alert, Button, Chip, Divider, Grid, IconButton, Rating, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import ImageShower from "../../components/common/ImageList/ImageList";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getSample } from "../../services/ClientServie";
import StarIcon from "@mui/icons-material/Star";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment/moment";
import { useRef } from "react";
import RateAndReview from "../../components/common/RateAndReview/RateAndReview";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { setRatingEffect } from "../../features/jobSlice/jobSlice";
import ShareBar from "../../components/common/ShareBar/ShareBar";
import { baseURL, baseWebURL } from "../../services/RequestService";

const SingleSample = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const sample_id = searchParams.get("sample_id");
  const navigate = useNavigate();
  const title = "ImageMall | Sample - #" + sample_id;
  const [dataLoading, setDataLoading] = useState(true);
  const [jobData, setJobData] = useState({});
  const reviewArea = useRef(null);
  const ratingEffect = useSelector((state) => state.job.ratingEffect);
  const dispatch = useDispatch();
  useEffect(() => {
    setDataLoading(true);
    getSample({ job_id: sample_id })
      .then((response) => {
        setJobData(response.data.data);
        setDataLoading(false);
      })
      .catch((error) => {
        goBack();
        setDataLoading(false);
      });
  }, []);

  useEffect(() => {
    console.log(ratingEffect);
    if (ratingEffect) {
      dispatch(setRatingEffect(!ratingEffect));
      goBack();
    }
  }, [ratingEffect]);

  const scrollToRateArea = () => {
    reviewArea.current?.scrollIntoView({ behavior: "smooth" });
  };
  const goBack = () => {
    navigate(-1);
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container sx={{ marginTop: 10, marginBottom: 6 }}>
        {!dataLoading ? (
          <React.Fragment>
            <Box sx={{ textAlign: "right" }}>
              <Tooltip title="Close">
                <IconButton onClick={() => goBack()} sx={{ mb: 1, mt: 1 }} color="default">
                  <CloseIcon sx={{ fontSize: 25 }} />
                </IconButton>
              </Tooltip>
              <Box display="flex" alignItems="center" justifyContent="flex-end" width="100%">
                <Divider sx={{ mb: 1 }} />
                <ShareBar url={baseURL + "sample?sample_id=" + jobData.job_id} />
                <Divider sx={{ mt: 1 }} />
              </Box>
            </Box>
            <Typography variant="h4">{jobData.name}</Typography>
            <Typography variant="h6">#{jobData.job_id}</Typography>
            <Grid container sx={{ mt: 2 }}>
              <Grid item sm={8}>
                <Box variant="h5">
                  <Chip
                    variant="outlined"
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}>
                        <Rating name="text-feedback" value={jobData.client_avg_rating !== null ? parseInt(jobData.client_avg_rating.avg_rate) : 0} readOnly precision={0.5} emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />} />
                        <Box sx={{ ml: 2 }}>
                          <Chip color="warning" label={jobData.client_avg_rating !== null ? jobData.client_avg_rating.avg_rate : 0} size="small" />
                        </Box>
                      </Box>
                    }
                    color="warning"
                  />{" "}
                  <Chip icon={<DownloadIcon fontSize="small" />} label={jobData.client_download_count !== null ? jobData.client_download_count.count + " Downloads" : 0 + " Downloads"} /> <Chip icon={<AccessTimeIcon fontSize="small" />} label={moment(jobData.completed_at).fromNow()} />{" "}
                  <Chip color="info" icon={<TimelapseIcon fontSize="small" />} variant="outlined" label={" expire at " + moment(jobData.expire_date).format("MMM Do YY")} />
                </Box>
              </Grid>
              <Grid item sm={4}>
                <Box sx={{ textAlign: "right" }}>
                  <Button variant="outlined" startIcon={<DownloadIcon />} onClick={scrollToRateArea}>
                    {" "}
                    Rate & Download
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Typography variant="h6">Please download and save your files immediately.</Typography>
            <Alert severity="info">After 30 days, these files will be deleted and this page will no longer be available.</Alert>
            <Divider sx={{ mt: 3 }}>
              <Chip label="Download Files" color="primary" />
            </Divider>
            <ImageShower loading={dataLoading} images={jobData.completed_file_names} jobId={jobData.job_id} path={"storage/completed-files/"} cols={5} />
            <Divider sx={{ mt: 3, mb: 3 }}>
              <Chip label="Rate and Review" color="warning" />
            </Divider>
            <Typography variant="h6" ref={reviewArea} sx={{ mb: 2 }}>
              Before you download these files, please give us your feedback on the preview.
            </Typography>
            <RateAndReview buttonLbl="Submit & Download Zip File" job_id={jobData.job_id} btnIcon={<DownloadIcon />} url="client/rating" zipDownload={true} />
          </React.Fragment>
        ) : (
          <Stack>
            <Skeleton height={60}></Skeleton>
            <Skeleton height={40}></Skeleton>
            <Skeleton height={30}></Skeleton>
          </Stack>
        )}
      </Container>
    </React.Fragment>
  );
};

export default SingleSample;
