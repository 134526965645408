import { Box, Button, IconButton, InputAdornment, TextField, withStyles } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect } from "react";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
const Search = (props) => {
    const {onClick,onClickClose, search, placeHolder} = props
    const [showIcon, setShowIcon] = useState(false)
    const [keyword, setKeyword] = useState('');
    useEffect(()=>{
      if(!search){
        setKeyword('')
      }
    },[search])
    const handleChange = (event) => {
        setKeyword(event.target.value);
    };
  return (
    <Box sx={{mb:3}} className="input-box-with-border-radius">
      <TextField
      sx={{ borderRadius:5}}
        placeholder={placeHolder}
        id="search"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {showIcon && <SearchIcon />}
            </InputAdornment>
          ),
          endAdornment:(
                <InputAdornment position="end">
                  {search && <IconButton
                    aria-label="Search"
                    onClick={()=>onClickClose(keyword)}
                    edge="end"
                    size='medium'
                    sx={{borderLeft:1}}
                  >
                    <CloseIcon />
                  </IconButton>
                  }
                  <IconButton
                    aria-label="Search"
                    onClick={()=>onClick(keyword)}
                    edge="end"
                    size='medium'
                    sx={{borderLeft:1}}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
          )
          
        }}
        fullWidth
        onFocus={()=>setShowIcon(true)}
        onBlur={()=>setShowIcon(false)}
        onChange={handleChange}
        value={keyword}
      />
    </Box>
  );
};

export default Search;
