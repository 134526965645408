import { Avatar, Box, Button, CardContent, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import moment from "moment/moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setTopLoader } from "../../../features/mainSlice/mainSlice";
import { get } from "../../../services/FeedBackService";
import CardComponent from "../../common/CardComponent/CardComponent";
import PageContainer from "../PageContainer/PageContainer";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useEffect } from "react";
const FeedBacks = () => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [page, setPage] = useState(1);
  const [loadMore, setLoadMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    getFeedbacks();
  }, []);
  const getFeedbacks = () => {
    dispatch(setTopLoader(true));
    setIsLoading(true);
    get(page)
      .then((response) => {
        const data = response.data.data.data;
        if (data.length > 0) {
          setFeedbacks((prevFeedbacks) => [...prevFeedbacks, ...data]);
          setPage(page + 1);
          setLoadMore(true);
          setIsLoading(false);
        } else {
          setLoadMore(false);
        }
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        dispatch(setTopLoader(false));
      });
  };
  return (
    <PageContainer>
      <Grid container spacing={4}>
        <Grid item sm={12}>
          <CardComponent>
            <CardContent>
              {feedbacks.map((item, index) => (
                <React.Fragment key={"feedback_" + index}>
                  <Grid container spacing={2} sx={{ p: 1, mb: 2, mt: 1 }}>
                    <Grid item sm={1}>
                      <Box>
                        <Avatar variant="square" sx={{ backgroundColor: "primary.main" }}>
                          {item.name[0].toUpperCase()}
                        </Avatar>
                      </Box>
                    </Grid>
                    <Grid item sm={11}>
                      <Box sx={{ display: "flex" }}>
                        <Typography variant="subtitle1">
                          <strong>{item.name}</strong>
                        </Typography>
                      </Box>
                      <Typography variant="subtitle1" color="textSecondary">
                        IP {item.ip}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ mt: 1 }}>
                        {item.feedback}
                      </Typography>
                      <Typography variant="subtitle2" color="textSecondary" sx={{ mt: 1 }}>
                      feedbacked {moment(item.created_at).fromNow()}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </React.Fragment>
              ))}
              {loadMore ? (
                <Button startIcon={<ExpandMoreOutlinedIcon />} fullWidth onClick={getFeedbacks}>
                  {isLoading ? <CircularProgress size={24} /> : "Load More"}
                </Button>
              ) : (
                <Typography align="center" variant="body2" color={"textSecondary"} sx={{mt:3}}>
                  No feedbacks to load..
                </Typography>
              )}
            </CardContent>
          </CardComponent>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default FeedBacks;
