import { Alert, Button, Chip, Container, Divider, Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import ProfileCard from "../../../common/ProfileCard/ProfileCard";
import PageContainer from "../../PageContainer/PageContainer";
import StarIcon from "@mui/icons-material/Star";
import { Box } from "@mui/system";
import RateAndReviewView from "../../../common/RateAndReviewView/RateAndReviewView";
import { EditOutlined } from "@mui/icons-material";
import { useState } from "react";
import { deleteSample, get, portfolioPreview, portfolioPreviewById, setPrimarySample, storePortfolioAvatar, storePortfolioSamples } from "../../../../services/PortfolioService";
import moment from "moment";
import { baseURL, baseWebURL } from "../../../../services/RequestService";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import StarsIcon from "@mui/icons-material/Stars";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuOptions from "../../../common/MenuOptions/MenuOptions";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { setTopLoader } from "../../../../features/mainSlice/mainSlice";
import DataDialog from "../../../common/DataDialog/DataDialog";
import VendorCard from "../../../common/VendorCard/VendorCard";
import HelpIcon from "@mui/icons-material/Help";
import ImageGallery from "react-image-gallery";
import { snackBarPosition } from "../../../../services/CommonService";

const sampleMenuItems = [
  { name: "Set as primary sample", value: "set_primary", icon: <ImageOutlinedIcon fontSize="small" sx={{ mr: 2 }} /> },
  { name: "Delete", value: "delete", icon: <DeleteOutlineIcon fontSize="small" sx={{ mr: 2 }} /> },
];
const topMenuItems = [
  { name: "Preview Card", value: "p_card", icon: <VisibilityOutlinedIcon fontSize="small" sx={{ mr: 2 }} /> },
  { name: "Preview Mode", value: "p_portfolio", icon: <VisibilityOutlinedIcon fontSize="small" sx={{ mr: 2 }} /> },
];
const PortfolioView = (props) => {
  const { vendor, vId } = props;
  const [vendorId, setVendorId] = useState(vId)
  const [edit, setEdit] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [profileImage, setProfileImage] = useState(undefined);
  const [preview, setPreview] = useState(undefined);
  const [avatarLoading, setAvatarLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rateAndReviews, setRateAndReviews] = useState([]);
  const [workSamples, setWorkSamples] = useState([]);

  const [baseImage, setBaseImage] = useState(undefined);
  const [baseImagePreview, setBaseImagePreview] = useState(undefined);
  const [editedImage, setEditedImage] = useState(undefined);
  const [editedImagePreview, setEditedImagePreview] = useState(undefined);

  const [sampleMenuOpen, setSampleMenuOpen] = useState(false);
  const [topMenuOpen, setTopMenuOpen] = useState(false);
  const [cardViewDialodOpen, setCardViewDialodOpen] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [portfolioViewDialodOpen, setPortfolioViewDialodOpen] = useState(false);
  const [samplesSlider, setSamplesSlider] = useState([]);
  // Data
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  useEffect(() => {
    if (vendor) {
      getPortfolio();
    } else {
      getPortfolioById(vendorId);
    }
  }, []);
  const getPortfolio = () => {
    dispatch(setTopLoader(true));
    setAvatarLoading(true);
    get()
      .then((response) => {
        if (response.data.success === true) {
          if (response.data.data.ed === true) {
            setEdit(true);
          } else {
            setEdit(false);
          }
          setVendorId(response.data.data.id)
          setData(response.data.data);
          setPreview(response.data.data.image);
          setRateAndReviews(response.data.data.ratings);
          if (response.data.data.portfolio !== null) {
            if (response.data.data.portfolio.samples !== null && typeof response.data.data.portfolio.samples === "object") {
              setWorkSamples(response.data.data.portfolio.samples);
            } else {
              setWorkSamples([]);
            }
          }
        }
        setAvatarLoading(false);
        setLoading(false);
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        console.log(error);
        setAvatarLoading(false);
        setLoading(false);
        dispatch(setTopLoader(false));
      });
  };
  const getPortfolioById = (id) => {
    setWorkSamples([]);
    setRateAndReviews([]);
    setPreview(undefined);
    setData([]);
    dispatch(setTopLoader(true));
    setAvatarLoading(true);
    portfolioPreviewById(id)
      .then((response) => {
        if (response.data.success === true) {
          if (response.data.data.ed === true) {
            setEdit(true);
          } else {
            setEdit(false);
          }
          setData(response.data.data);
          setPreview(response.data.data.image);
          setRateAndReviews(response.data.data.ratings);
          if (response.data.data.portfolio !== null) {
            if (response.data.data.portfolio.samples !== null && typeof response.data.data.portfolio.samples === "object") {
              setWorkSamples(response.data.data.portfolio.samples);
              let samples = [];
              response.data.data.portfolio.samples?.map((item) => {
                samples.push({
                  thumbnail: baseWebURL + item.image,
                  original: baseWebURL + item.image,
                });
              });
              setSamplesSlider(samples);
            } else {
              setWorkSamples([]);
            }
          }
        }
        setAvatarLoading(false);
        setLoading(false);
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        console.log(error);
        setAvatarLoading(false);
        setLoading(false);
        dispatch(setTopLoader(false));
      });
  };
  // Avatar
  const handleFileUpload = (e) => {
    setAvatarLoading(true);
    dispatch(setTopLoader(true));
    if (!e.target.files || e.target.files.length === 0) {
      setPreview(undefined);
      setAvatarLoading(false);
      return;
    }
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
    setProfileImage(e.target.files[0]);
    setAvatarLoading(false);
    storePortfolioAvatar({ image: e.target.files[0] })
      .then((response) => {
        if (response.data.success === true) {
          setAvatarLoading(false);
        }
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        console.log(error);
        setAvatarLoading(false);
        dispatch(setTopLoader(false));
      });
  };
  // Sample Base image
  const handleBaseImageChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setBaseImagePreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setBaseImagePreview(objectUrl);
    setBaseImage(e.target.files[0]);
  };
  //   // Sample Base image
  //   const handleEditedImageChange = (e) => {
  //     if (!e.target.files || e.target.files.length === 0) {
  //       setEditedImagePreview(undefined);
  //       return;
  //     }
  //     const objectUrl = URL.createObjectURL(e.target.files[0]);
  //     setEditedImagePreview(objectUrl);
  //     setEditedImage(e.target.files[0]);
  //   };
  // Samples
  const handleSamplesCancel = () => {
    setBaseImagePreview(undefined);
    setEditedImagePreview(undefined);
  };
  const handleSamplesSave = () => {
    dispatch(setTopLoader(true));
    storePortfolioSamples({ image: baseImage })
      .then((response) => {
        if (response.data.success === true) {
          setWorkSamples(response.data.data);
          setBaseImagePreview(undefined);
          setBaseImage(undefined);
        }
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        console.log(error);
        setAvatarLoading(false);
        setBaseImagePreview(undefined);
        setBaseImage(undefined);
        dispatch(setTopLoader(false));
      });
  };
  const handleTopMenuClick = (val) => {
    if (val === "p_card") {
      setCardViewDialodOpen(true);
      setLoading(true);
      getPreviewData("card");
    } else if (val === "p_portfolio") {
      setEdit(false);
      setEditMode(true);
    }
    setTopMenuOpen(false);
  };
  const getPreviewData = (type) => {
    portfolioPreview(type)
      .then((response) => {
        if (response.data.success) {
          const data = response.data.data
          const services = data.services?.map((item)=>({
            name:item.service,
            price:item.price

          }))
          data['services'] = services
          setPreviewData(data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(true);
      });
  };
  const setPrimary = (id) => {
    dispatch(setTopLoader(true));
    setPrimarySample({ id: id })
      .then((response) => {
        if (response.data.success === true) {
          setWorkSamples(response.data.data);
          showSuccess(response.data.message);
        } else {
          showError(response.data.message);
        }
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        showError(error.response.data.message);
        dispatch(setTopLoader(false));
      });
  };
  const deleteSampleFun = (id) => {
    dispatch(setTopLoader(true));
    deleteSample({ id: id })
      .then((response) => {
        if (response.data.success === true) {
          setWorkSamples(response.data.data);
          showSuccess(response.data.message);
        } else {
          showError(response.data.message);
        }
        dispatch(setTopLoader(false));
      })
      .catch((error) => {
        showError(error.response.data.message);
        dispatch(setTopLoader(false));
      });
  };
  const handleSampleMenuClick = (val, samplId) => {
    if (val === "set_primary") {
      setPrimary(samplId);
    } else if (val === "delete") {
      deleteSampleFun(samplId);
    }
    setSampleMenuOpen(false);
  };
  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };
  const handleCardViewClose = () => {
    setCardViewDialodOpen(false);
  };
  const handleEditModeClick = () => {
    setEdit(true);
    setEditMode(false);
  };

  return (
    <React.Fragment>
      <DataDialog onClickCancel={handleCardViewClose} open={cardViewDialodOpen} header="Preview" loading={loading} maxWidth="sm" minWidth="200px" styleMaxWith={"350px"}>
        <Box>
          <Typography align="right">
            <Tooltip title="Your card shows like below to contractors when they finding vendors. make your card with good attraction. get more jobs.">
              <HelpIcon color="info" />
            </Tooltip>
          </Typography>
          {!loading && <VendorCard  avatar={previewData.avatar} title={previewData.title} cover={previewData.cover} name={previewData.name} avgRate={previewData.avg_rate} reviewCount={previewData.review_count} jobsDone={previewData.jobs_done} services={previewData.services}/>}
        </Box>
      </DataDialog>
      {!loading && (
        <PageContainer>
          <Grid item sm={4}>
            <ProfileCard
              handleFileUpload={handleFileUpload}
              edit={edit}
              loading={avatarLoading}
              image={preview === undefined ? "/img/avatar.png" : preview}
              name={data.name}
              title={data.portfolio !== null && data.portfolio.title}
              ratePoints={data.avg_rate}
              totalReview={data.total_review}
              from={data.country}
              since={data.since}
              jobsDone={data.jobs_done}
              lastDelivery={data.last_delivery}
              description={data.description}
              services={data.services !== null ? data.services : []}
              totalReviews={data.total_reviews}
              url={baseURL + "portfolio?v_id=" + vendorId + "&name=" + data.name}
            />
          </Grid>
          <Grid item sm={8}>
            <Paper variant="outlined" sx={{ p: 2 }}>
              <Grid container spacing={2}>
                <Grid item sm={6}>
                  <Typography variant="subtitle2">
                    <strong>Samples Of Work</strong>
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography align="right" variant="subtitle2">
                    {editMode && (
                      <Button startIcon={<EditOutlined />} onClick={handleEditModeClick}>
                        Edit Mode
                      </Button>
                    )}
                    {edit && <MenuOptions menuItems={topMenuItems} label={<MoreVertIcon />} optionClick={handleTopMenuClick} menuOpen={topMenuOpen} />}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            {edit ? (
              <Grid container spacing={2} sx={{ pt: 2 }}>
                {workSamples?.map((item, index) => {
                  return (
                    <Grid item sm={4} key={index}>
                      <Paper variant="outlined" sx={{ p: 1 }}>
                        <Grid container>
                          <Grid item sm={12} sx={{ position: "relative" }}>
                            {item.cover && (
                              <Box sx={{ position: "absolute", top: 0, left: 0 }}>
                                {" "}
                                <Tooltip title="Sample Cover Image">
                                  <Chip icon={<StarsIcon />} label="Primary" size="small" color="success"/>
                                </Tooltip>
                              </Box>
                            )}
                            <img src={baseWebURL + item.image} alt="" width="100%" height={"auto"} />
                          </Grid>
                        </Grid>

                        {edit && (
                          <React.Fragment>
                            <Divider sx={{ mt: 1 }} />
                            <Box sx={{ textAlign: "right" }}>
                              <MenuOptions menuItems={sampleMenuItems} label={<MoreHorizIcon />} optionClick={handleSampleMenuClick} menuOpen={sampleMenuOpen} sampleId={index} />
                            </Box>
                          </React.Fragment>
                        )}
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item sm={1}></Grid>
                <Grid item sm={10} sx={{ textAlign: "center" }}>
                  {samplesSlider.length > 0 ? <ImageGallery items={samplesSlider} lazyLoad={true} thumbnailPosition={"right"} /> : <Typography>No samples to show..</Typography>}
                </Grid>
                <Grid item sm={1}></Grid>
              </Grid>
            )}

            {edit && (
              <Grid container spacing={2} sx={{ pt: 2 }}>
                <Grid item sm={4}>
                  <Paper variant="outlined" sx={{ p: 1 }}>
                    <Grid container>
                      <Grid item sm={12} sx={{ textAlign: "center", alignSelf: "center" }}>
                        {baseImagePreview !== undefined && <img src={baseImagePreview} width="100%" height={"130"} />}
                        <IconButton color="primary" aria-label="upload picture" component="label" size="large">
                          <input hidden accept="image/*" type="file" onChange={handleBaseImageChange} />
                          <AddCircleOutlinedIcon sx={{ fontSize: "3rem" }} />
                        </IconButton>
                      </Grid>
                      {/* <Grid item sm={6} sx={{ textAlign: "center", alignSelf: "center" }}>
                    {editedImagePreview !== undefined && <img src={editedImagePreview} width="100%" height={'100'}/>}
                      <IconButton color="primary" aria-label="upload picture" component="label" size="large">
                        <input hidden accept="image/*" type="file" onChange={handleEditedImageChange}/>
                        <AddCircleOutlinedIcon sx={{ fontSize: "3rem" }} />
                      </IconButton>
                      <Typography>Edited Image</Typography>
                    </Grid> */}
                    </Grid>
                    <Divider sx={{ mt: 1, mb: 1 }} />
                    <Box sx={{ mt: 1, textAlign: "right" }}>
                      <Button size="small" variant="outlined" color="primary" sx={{ mr: 2 }} onClick={handleSamplesCancel}>
                        Cancel
                      </Button>
                      <Button disabled={baseImagePreview === undefined} size="small" variant="contained" color="success" onClick={handleSamplesSave}>
                        Save
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            )}
            <Paper variant="outlined" sx={{ mt: 3 }}>
              <Box sx={{ display: "flex", pl: 1.5, pb: 1.7, pt: 2 }}>
                <Typography variant="subtitle1" sx={{ mr: 2 }}>
                  <strong>Reviews</strong>
                </Typography>
                <StarIcon sx={{ color: "#faaf00" }} />
                <Typography variant="subtitle1">
                  {console.log(data.avg_rate)}
                  <strong>
                    {data.avg_rate === null ? "Not rated yet" : data.avg_rate} ({data.total_reviews}){" "}
                  </strong>
                </Typography>
              </Box>
              <Divider />
              {rateAndReviews?.map((item, index) => {
                return <RateAndReviewView key={index} avatar={baseWebURL + item.image} name={item.name} rate={item.points} review={item.review} country={item.country} published={moment(item.published).fromNow()} />;
              })}
              {rateAndReviews.length === 0 && <Typography sx={{ p: 2 }}>No reviews to show</Typography>}
            </Paper>
          </Grid>
        </PageContainer>
      )}
    </React.Fragment>
  );
};

export default PortfolioView;
