import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import PinterestIcon from "@mui/icons-material/Pinterest";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Card, Container, Grid, IconButton, Typography, Link as UiLink } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setFeedbackDrawerOpen } from "../../../features/commonSlice/commonSlice";
import ScrollToTop from "../../common/ScrollToTop/ScrollToTop";

const date = new Date();
const year = date.getFullYear();

const Footer = () => {
  const dispatch = useDispatch();
  return (
    <Box sx={{ mt: 10, mb: 10 }}>
      <Container>
        <Grid container spacing={4}>
          <Grid item sm={1}></Grid>
          <Grid item sm={4}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              <img alt="Logo" src={"./favicon.png"} style={{ width: "20px" }} /> ImageMall
            </Typography>
            <Typography variant="subtitle2">At ImageMall our mission is to bring you the very best in digital artists from all over the world.</Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Services
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
              <Link to="#" className="router-link">
                Virtual Staging.
              </Link>
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
              <Link to="#" className="router-link">
                Real Estate Photo Editing.
              </Link>
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
              <Link to="#" className="router-link">
                Floor Plans.
              </Link>
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
              <Link to="#" className="router-link">
                Blue Sky Replacement.
              </Link>
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
              <Link to="#" className="router-link">
                Twilight Images.
              </Link>
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
              <Link to="#" className="router-link">
                3D Rendering
              </Link>
            </Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Extra
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
              <Link to="#" className="router-link">
                Need help ?
              </Link>
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
              <a href="https://scatterscape.com" className="router-link" target="_blank">
                scatterscape.com
              </a>
            </Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Contact
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
              2063 Riggs
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
              Sandy UT 84092
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
              United States
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
              <Link to="#" className="router-link" onClick={() => dispatch(setFeedbackDrawerOpen(true))}>
                Send Feedback
              </Link>
            </Typography>
          </Grid>
          <Grid item sm={1}></Grid>
        </Grid>
        <Box sx={{ p: 2, marginTop: 4 }}>
          <Grid container>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Typography variant="caption" sx={{ fontWeight: 600 }}>
                {" "}
                &copy; 2021 - {year} ImageMall. v2.0 All rights reserved{" "}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ textAlign: "center" }}>
            <IconButton size={"small"} aria-label="upload picture" component="label">
              <FacebookIcon />
            </IconButton>
            <IconButton size={"small"} aria-label="upload picture" component="label">
              <TwitterIcon />
            </IconButton>
            <IconButton size={"small"} aria-label="upload picture" component="label">
              <PinterestIcon />
            </IconButton>
          </Box>
        </Box>
      </Container>
      <ScrollToTop />
    </Box>
  );
};

export default Footer;
