import React, { useState } from "react";
import { CardElement, useStripe, useElements, CardNumberElement } from "@stripe/react-stripe-js";
import { TextField, Button, Typography, Grid, Paper, Box, FormControlLabel, Checkbox, Link, CircularProgress, Divider, IconButton, RadioGroup, Radio, Chip, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material";
import { Container } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { getDefaultMethod, getPaymentDetails, payByCard, payByCustomer } from "../../../services/PaymentService";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import LoadingButtons from "../LoadingButton/LoadingButtonComponent";
import moment from "moment/moment";
import { snackBarPosition } from "../../../services/CommonService";
const StripeForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [saveCard, setSaveCard] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [dataLoading, setDataLoading] = useState(true);
  const [data, setData] = useState([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState([]);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("saved");
  const navigate = useNavigate();

  const theme = useTheme();
  let [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const payment_id = searchParams.get("payment_id");
  useEffect(() => {
    getPayment();
    defaultMethod();
  }, []);
  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };
  const getPayment = () => {
    if (payment_id !== "") {
      getPaymentDetails({ payment_id: payment_id })
        .then((response) => {
          setData(response.data.data);
          setDataLoading(false);
        })
        .catch((error) => {
          navigate("*");
        });
    } else {
      navigate("*");
    }
  };
  const defaultMethod = () => {
    getDefaultMethod()
      .then((response) => {
        setDefaultPaymentMethod(response.data.data);
        const so = response.data.data === false ? "new" : "saved";
        setSelectedOption(so);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  const handleSubmit = async (event) => {
    setBtnLoading(true);
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    if (name === "" || email === "") {
      setErrorMessage("All fields are required. please fill the payment details");
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: name,
        email: email,
      },
    });

    if (error) {
      setErrorMessage(error.message);
      return;
    }

    if (saveCard) {
      const { token, tokenError } = await stripe.createToken(cardElement);
      if (tokenError) {
        setErrorMessage(tokenError.message);
        return;
      }
      // Submit the payment method to your backend API for processing
      payCard(paymentMethod, token);
    } else {
      payCard(paymentMethod);
    }
  };
  const payCard = (paymentMethod, cardToken = null) => {
    setBtnLoading(true);
    payByCard({ payment_method: paymentMethod, payment_id: payment_id, save_card: saveCard, name: name, email: email, tc_agree: agreedToTerms, card_token: cardToken })
      .then((response) => {
        if (response.data.data.status === "succeeded" || response.data.data.status === "processing") {
          showSuccess(response.data.message);
          navigate("/partners/jobs/manage");
        } else {
          showSuccess(response.data.message);
        }
        setBtnLoading(false);
      })
      .catch((error) => {
        setBtnLoading(false);
        showError(error.response.data.message);
      });
  };
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const goBack = () => {
    navigate(-1);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handlePayment = () => {
    setBtnLoading(true);
    payByCustomer({ payment_id: payment_id, tc_agree: agreedToTerms })
      .then((response) => {
        if (response.data.data.status === "succeeded" || response.data.data.status === "processing") {
          showSuccess(response.data.message);
          navigate("/partners/jobs/manage");
        } else {
          showSuccess(response.data.message);
        }
        setBtnLoading(false);
      })
      .catch((error) => {
        showError(error.response.data.message);
        setBtnLoading(false);
      });
  };
  return (
    <Container sx={{ mt: 4 }}>
      {!loading ? (
        <Grid container spacing={4}>
          <Grid item sm={7}>
            <Tooltip title="Close">
              <IconButton onClick={() => goBack()} sx={{ mb: 1, mt: 1 }} color="default">
                <CloseIcon sx={{ fontSize: 25 }} />
              </IconButton>
            </Tooltip>
            <Paper sx={{ padding: 4 }} elevation={0}>
              {!dataLoading ? (
                <Box>
                  <Grid container spacing={4}>
                    <Grid item sm={7} sx={{ textAlign: "left" }}>
                      <Typography variant="body1">Price per image </Typography>
                      <Typography variant="body1">Total output files </Typography>
                      <Typography variant="body1">-</Typography>
                      <Typography variant="body1">Amount</Typography>
                      <Typography variant="body1">ImageMall processing charges (IMPC)</Typography>
                      <Typography variant="body1">-</Typography>
                    </Grid>
                    <Grid item sm={3} sx={{ textAlign: "right" }}>
                      <Typography variant="body1">
                        $<strong>{data.pf.toFixed(2)}</strong>
                      </Typography>
                      <Typography variant="body1">
                        <strong>{data.fc}</strong>
                      </Typography>
                      <Typography variant="caption" color={"text.secondary"}>
                        <strong>
                          ${data.pf.toFixed(2)} x {data.fc.toFixed(2)}{" "}
                        </strong>
                      </Typography>
                      <Typography variant="body1">
                        $<strong>{data.jp.toFixed(2)}</strong>
                      </Typography>
                      <Typography variant="body1">
                        +$<strong>{data.pc.toFixed(2)}</strong>
                      </Typography>
                      <Typography variant="caption" color={"text.secondary"}>
                        <strong>
                          ${data.jp.toFixed(2)} + {data.pc.toFixed(2)}{" "}
                        </strong>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <Typography variant="body1" color={"text.secondary"} gutterBottom>
                    Total amount
                  </Typography>
                  <Typography variant="h3" gutterBottom>
                    ${data.t.toFixed(2)}
                  </Typography>
                </Box>
              ) : (
                <CircularProgress />
              )}
            </Paper>
          </Grid>
          <Grid item sm={5}>
            <Box sx={{ margin: "0 auto" }}>
              <Paper sx={{ padding: 4 }}>
                {/* <Typography variant="h6" mb={2}>
                  Payment Method
                </Typography> */}
                {defaultPaymentMethod && (
                  <RadioGroup name="paymentOption" value={selectedOption} onChange={handleOptionChange} row mb={2}>
                    <FormControlLabel value="saved" control={<Radio />} label="Saved Payment Method" />
                    <FormControlLabel value="new" control={<Radio />} label="Use Another Card" />
                  </RadioGroup>
                )}
                {selectedOption === "saved" && (
                  <React.Fragment>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <img src={theme.palette.mode == "dark" ? "/img/Stripe wordmark - white (small).png" : "/img/Stripe wordmark - blurple (small).png"} alt="Stripe logo" width="100px" />
                      <Typography variant="body1">Trusted and Powered by Stripe</Typography>
                    </Box>
                    <Divider />
                    <form>
                      <Grid container spacing={4} sx={{ mt: 2, mb: 3 }}>
                        <Grid item sm={2}>
                          {defaultPaymentMethod.card.brand.toLowerCase() === "visa" ? <img src="/img/visa_icon.svg" alt="" width="50px" style={{ backgroundColor: "#80808033" }} /> : <img src="/img/mastercard.svg" alt="" width="50px" style={{ backgroundColor: "#80808033" }} />}
                        </Grid>
                        <Grid item sm={10}>
                          <Box>
                            <strong>
                              {defaultPaymentMethod.card.brand} •••• {defaultPaymentMethod.card.last4}
                            </strong>
                          </Box>
                          <Box color="text.secondary">
                            Expires {moment(defaultPaymentMethod.card.exp_month, "MM").format("MMM")} {defaultPaymentMethod.card.exp_year}{" "}
                          </Box>
                        </Grid>
                      </Grid>
                      <Divider />
                      <FormControlLabel
                        control={<Checkbox checked={agreedToTerms} onChange={() => setAgreedToTerms(!agreedToTerms)} />}
                        label={
                          <>
                            I agree to the{" "}
                            <Link href="/terms-and-conditions" target="_blank" rel="noopener">
                              terms and conditions
                            </Link>
                          </>
                        }
                        sx={{ fontSize: "10px" }}
                      />
                      <Grid item xs={12}>
                        <LoadingButtons loading={btnLoading} variant="contained" type="button" name="Countinue Payment" color="primary" fullWidth={true} disabled={!agreedToTerms} onClick={handlePayment} />
                        <Typography variant="body2" component="p" color="text.secondary" align="right" sx={{ mt: 2 }}>
                          <img src={theme.palette.mode == "dark" ? "/img/Powered by Stripe - white.svg" : "/img/Powered by Stripe - blurple.svg"} alt="Stripe logo" width="80px" style={{ position: "absloute" }} />
                        </Typography>
                      </Grid>
                    </form>
                  </React.Fragment>
                )}
                {selectedOption === "new" && (
                  <React.Fragment>
                    {/* <Typography variant="h4" align="center" gutterBottom>
                      Payment Details
                    </Typography> */}
                    <form onSubmit={handleSubmit}>
                      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 3 }}>
                        <img src={theme.palette.mode == "dark" ? "/img/Stripe wordmark - white (small).png" : "/img/Stripe wordmark - blurple (small).png"} alt="Stripe logo" width="100px" />
                        <Typography variant="body1">Trusted and Powered by Stripe</Typography>
                      </Box>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField label="Name" variant="outlined" value={name} onChange={handleNameChange} fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField label="Email" variant="outlined" type="email" value={email} onChange={handleEmailChange} fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                          <Box sx={{ border: "1px solid #ccc", p: 2 }} >
                            <CardElement options={{ style: { base: { fontSize: "16px", color: theme.palette.mode == "dark" &&  'white'} } }} />
                          </Box>
                          <Box sx={{ fontSize: "10px", mt: 2 }}>
                            <FormControlLabel control={<Checkbox checked={saveCard} onChange={() => setSaveCard(!saveCard)} />} label="Save this card for instant payments" />
                            <FormControlLabel
                              control={<Checkbox checked={agreedToTerms} onChange={() => setAgreedToTerms(!agreedToTerms)} />}
                              label={
                                <>
                                  I agree to the{" "}
                                  <Link href="/terms-and-conditions" target="_blank" rel="noopener">
                                    terms and conditions
                                  </Link>
                                </>
                              }
                              sx={{ fontSize: "10px" }}
                            />
                          </Box>
                        </Grid>
                        {errorMessage && (
                          <Grid item xs={12}>
                            <Typography variant="body2" color="error">
                              {errorMessage}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <LoadingButtons loading={btnLoading} variant="contained" type="submit" name="Countinue Payment" color="primary" fullWidth={true} disabled={!agreedToTerms} marginTop={0} />
                          <Typography variant="body2" component="p" color="text.secondary" align="right" sx={{ mt: 2 }}>
                            <img src={theme.palette.mode == "dark" ? "/img/Powered by Stripe - white.svg" : "/img/Powered by Stripe - blurple.svg"} alt="Stripe logo" width="80px" style={{ position: "absloute" }} />
                          </Typography>
                        </Grid>
                      </Grid>
                    </form>
                  </React.Fragment>
                )}
              </Paper>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Grid>
      )}
    </Container>
  );
};

export default StripeForm;
