import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container } from "@mui/system";
import { Box, Chip, Grid, Button, Typography, CircularProgress, Paper, Skeleton } from "@mui/material";
import { getSamplePaginate, searchSamples } from "../../services/ClientServie";
import { useSnackbar } from "notistack";
import moment from "moment";
import StarIcon from "@mui/icons-material/Star";
import DownloadIcon from "@mui/icons-material/Download";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { baseWebURL } from "../../services/RequestService";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { useInView } from "react-intersection-observer";
import Search from "../../components/common/Search/Search";
import { useNavigate } from "react-router-dom";
import { snackBarPosition } from "../../services/CommonService";
const Samples = () => {
  const [loading, setLoading] = useState(true);
  const [getSamples, setSamples] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLoadingEnd, setPageLoadingEnd] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const [search, setSearch] = useState(false);
  const snackbarLoading = useSelector((state) => state.common.snackbarLoading);
  const [fillStart, setFillStart] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const title = "ImageMall | Samples";
  // use effect
  const getSamplesLoading = [];
  for (let index = 0; index < 12; index++) {
    getSamplesLoading.push({ test: index });
  }
  // scrolling
  const { ref, inView } = useInView({
    threshold: 0,
  });
  useEffect(() => {
    getSamplesFunc(pageNumber);
  }, []);

  useEffect(() => {
    if (inView && !search) {
      setPageNumber((current) => current + 1);
    }
  }, [inView]);
  useEffect(() => {
    if (requestData.length > 0) {
      if (pageNumber <= requestData.last_page) {
        getSamplesFunc(pageNumber);
      }
    }
    setPageLoadingEnd(true);
  }, [pageNumber]);

  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };

  const handleSampleClick = (job_id) => {
    navigate("/sample?sample_id=" + job_id);
  };

  const getSamplesFunc = (page_number) => {
    getSamplePaginate(page_number)
      .then((response) => {
        if (response.data.data.data.length === 0) setPageLoadingEnd(true);
        setRequestData(response.data.data);
        let all = new Set([...getSamples, ...response.data.data.data]);
        setSamples([...all]);
        setLoading(false);
      })
      .catch((error) => {
        showError(error.response.data.message);
        setLoading(false);
      });
  };
  const handleImageLoading = (c) => {
    if (c === getSamples.length) {
      setLoading(false);
    }
  };
  const handleSearchClick = (keyword) => {
    if (keyword === "") {
      setSearch(false);
      return;
    }
    setSearch(true);
    setSamples([]);
    setLoading(true);
    searchSamples({ keyword })
      .then((response) => {
        if (response.data.data.length === 0) setPageLoadingEnd(true);
        setRequestData(response.data.data);
        setSamples(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        showError(error.response.data.message);
      });
  };
  const handleSearchClose = () => {
    window.location.reload()
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div id="loadMore">
        <Container maxWidth="lg" sx={{ marginTop: 10, marginBottom: 6 }}>
          <Grid container spacing={2}>
            <Grid item sm={3}></Grid>
            <Grid item sm={6}>
              <Search onClick={handleSearchClick} onClickClose={handleSearchClose} search={search} placeHolder="Search Sample" />
            </Grid>
            <Grid item sm={3}>
              <Box sx={{ overflowY: "auto", whiteSpace: "nowrap" }} className="scroll-bar-none">
                <Button color="primary" size="small" variant="outlined" sx={{ borderRadius: 5, mt: "5px", mr: 1, borderLeft: 2 }}>
                  Most Rated
                </Button>
                <Button color="primary" size="small" variant="outlined" sx={{ borderRadius: 5, mt: "5px", mr: 1, borderLeft: 2 }}>
                  Most Downloaded
                </Button>
                <Button color="primary" size="small" variant="outlined" sx={{ borderRadius: 5, mt: "5px", borderLeft: 2 }}>
                  HDR Photo Editing
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {!loading ? (
              <React.Fragment>
                {getSamples?.map((item, index) => {
                  return (
                    <Grid item xs={3} sx={{ mt: 0 }} key={index}>
                      <Paper sx={{ my: 1, cursor: "pointer" }} onClick={() => handleSampleClick(item.job_id)} elevation={0} onMouseEnter={() => setFillStart(true)} onMouseLeave={() => setFillStart(false)}>
                        <React.Fragment>
                          <img
                            style={{
                              width: "100%",
                              height: 160,
                              borderRadius: 10,
                            }}
                            alt={item.title}
                            src={baseWebURL + "storage/completed-files/" + item.job_id + "/thumbnails/IMG0001.jpg"}
                            loading="lazy"
                          />
                          <Box sx={{ pr: 2 }}>
                            <Typography gutterBottom variant="body2">
                              {item.name + " " + index}
                            </Typography>
                            <Typography display="block" variant="caption" color="text.secondary">
                              {item.job_id}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                              {item.average_rating !== null ? item.average_rating : 0} {fillStart ? <StarIcon fontSize="inherit" color="warning" /> : <StarBorderIcon fontSize="inherit" color="warning" />} • {item.client_download_count !== null ? item.client_download_count.count : 0}{" "}
                              <DownloadIcon fontSize="inherit" /> • {moment(item.completed_at).fromNow()}
                            </Typography>
                          </Box>
                        </React.Fragment>
                      </Paper>
                    </Grid>
                  );
                })}
              </React.Fragment>
            ) : (
              <Grid container spacing={3}>
                {getSamplesLoading.map((item, index) => {
                  return (
                    <Grid item xs={3} sx={{ mt: 2 }} key={index}>
                      <Box sx={{ pt: 0.5 }}>
                        <Skeleton variant="rectangular" width={"100%"} height={160} sx={{ borderRadius: 6 }} key={"loading_" + index} />
                        <Skeleton />
                        <Skeleton width="60%" />
                        <Skeleton width="80%" />
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Grid>

          <Box ref={ref} sx={{ pb: 1, mt: 2, textAlign: "center" }}>
            {pageLoadingEnd ? <Chip label="No data to load" /> : <CircularProgress />}
          </Box>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Samples;
