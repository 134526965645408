import React from "react";
import { Container } from "@mui/system";
import { Grid } from "@mui/material";

const PageContainer = (props) => {

    return (
        <Container maxWidth="lg" sx={{ mt: 5, mb:5 }}>
            <Grid container spacing={2}>
                {props.children}
            </Grid>
        </Container>
    )
}

export default PageContainer;