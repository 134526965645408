import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { getEarningBarData } from "../../../services/PaymentService";
import { getAdminEarningBarData, getCreatorEarningBarData } from "../../../services/StatisticsService";
const EarningsBar = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const role = useSelector((state) => state.common.role);

  useEffect(() => {
    if (role === "vendor") {
      getEarningBarData().then((response) => {
        if (response.data.data) {
          setData(response.data.data);
          setLoading(false);
        }
      });
    } else if (role === "super_admin") {
      getAdminEarningBarData().then((response) => {
        if (response.data.data) {
          setData(response.data.data);
          setLoading(false);
        }
      });
    } else if (role === "creator") {
      getCreatorEarningBarData().then((response) => {
        if (response.data.data) {
          setData(response.data.data);
          setLoading(false);
        }
      });
    }
  }, [role]);
  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={2}>
        {!loading ? (
          <React.Fragment>
            <Grid item sm={role === "vendor" || role === "super_admin" ? 7 : 9}>
              <Box sx={{ backgroundColor: "primary.main", p: 3, color: "common.white", borderRadius: 2, minHeight: "200px" }}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  <strong>
                    <React.Fragment>{role === "vendor" && " Your Earnings"} </React.Fragment> <React.Fragment>{role === "creator" && "Your Spending"}</React.Fragment>
                    <React.Fragment>{role === "super_admin" && "System Total Transactions"}</React.Fragment>
                  </strong>
                </Typography>
                <Grid container spacing={2}>
                  <Grid item sm={3}>
                    <Typography variant="subtitle2">Today so far</Typography>
                    <Typography variant="h5">
                      <strong>${data.todayAmount.toFixed(2)}</strong>
                    </Typography>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography variant="subtitle2">Yesterday</Typography>
                    <Typography variant="h5">
                      <strong>${data.yesterdayAmount.toFixed(2)}</strong>
                    </Typography>
                    <Typography variant="body2">
                      {data.lastWeekSameDayStatus === "up" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon color="error" />}
                      {data.lastWeekSameDayStatus === "up" ? "+" : "-"}${data.lastWeekSameDayAmount}
                    </Typography>
                    <Typography variant="caption">vs same day last week</Typography>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography variant="subtitle2">Last 7 days</Typography>
                    <Typography variant="h5">
                      <strong>${data.last7DaysAmount.toFixed(2)}</strong>
                    </Typography>
                    <Typography variant="body2">
                      {data.previous7DaysStatus === "up" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon color="error" />}
                      {data.previous7DaysStatus === "up" ? "+" : "-"}${data.previous7DaysAmount.toFixed(2)}
                    </Typography>
                    <Typography variant="caption">vs previous 7 days</Typography>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography variant="subtitle2">This Month</Typography>
                    <Typography variant="h5">
                      <strong>${data.thisMonthAmount.toFixed(2)}</strong>
                    </Typography>
                    <Typography variant="body2">
                      {data.lastYearSameMonthStatus === "up" ? <ArrowDropUpIcon /> : <ArrowDropDownIcon color="error" />}
                      {data.lastYearSameMonthStatus === "up" ? "+" : "-"}${data.lastYearSameMonthAmount}
                    </Typography>
                    <Typography variant="caption">vs same period last year</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item sm={role === "vendor" || role === "super_admin" ? 2 : 3}>
              <Box sx={{ backgroundColor: "primary.main", p: 3, color: "common.white", borderRadius: 2, minHeight: "200px" }}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  <strong>
                    <React.Fragment>{role === "vendor" && " Your Earnings"} </React.Fragment>
                    <React.Fragment>{role === "super_admin" && "Total Transactions"}</React.Fragment>
                    <React.Fragment>{role === "creator" && "Total Spends"}</React.Fragment>
                  </strong>
                </Typography>
                <Typography variant="h5">
                  <strong>${data.totalAmount.toFixed(2)}</strong>
                </Typography>
                {/* <Typography variant="body2">
                  <ArrowDropDownIcon color="error" /> $8.00
                </Typography>
                <Typography variant="caption"> last payment</Typography> */}
              </Box>
            </Grid>
            {(role === "vendor" || role === "super_admin") && (
              <Grid item sm={3}>
                <Box sx={{ backgroundColor: "primary.main", p: 3, color: "common.white", borderRadius: 2, minHeight: "200px" }}>
                  <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    {role === "vendor" && <strong>Available Balance</strong>}
                    {role === "super_admin" && <strong>Total Withdrawal</strong>}
                  </Typography>
                  <Typography variant="h5">
                    {role === "vendor" && <strong>${data.availableBlance.toFixed(2)}</strong>}
                    {role === "super_admin" && <strong>${data.totalWithdrawalAmount.toFixed(2)}</strong>}
                  </Typography>
                  {role === "vendor" && (
                    <React.Fragment>
                      <Typography variant="body2">${data.lastWithdrawal.toFixed(2)}</Typography>
                      <Typography variant="caption"> last withdrawal</Typography>
                    </React.Fragment>
                  )}
                </Box>
              </Grid>
            )}
          </React.Fragment>
        ) : (
          <Grid item sm={12} sx={{ textAlign: "center" }}>
            <CircularProgress />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default EarningsBar;
