import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingButtons = (props) => {
    let endicond = ""
    if(props.loading){
        endicond = <CircularProgress variant='indeterminate' color="inherit" size={15} />
        return ( <LoadingButton fullWidth={props.fullWidth === undefined ? false : props.fullWidth} loading={props.loading}  variant={props.variant} loadingIndicator={endicond}  size={props.size === undefined ? "medium" : props.size} sx={{marginTop:props.marginTop === undefined ? 3 : props.marginTop}} onClick={props.onClick} disabled={props.disabled} type={props.type} color={props.color ? props.color : 'primary'}> {props.name} </LoadingButton>);
    }else{
        return ( <LoadingButton fullWidth={props.fullWidth === undefined ? false : props.fullWidth}  loading={props.loading} variant={props.variant}  size={props.size === undefined ? "medium" : props.size} sx={{marginTop:props.marginTop === undefined ? 3 : props.marginTop}} onClick={props.onClick} disabled={props.disabled} type={props.type} color={props.color ? props.color : 'primary'}> {props.name} {props.childern}</LoadingButton>);
    }
    
    
}

export default LoadingButtons;