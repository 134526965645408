
import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import ManageUsers from "../../components/admin/ManageUsers/ManageUsers";
import PageToolbar from "../../components/admin/PageToolbar/PageToolbar";
import { setTopBarTitle } from "../../features/dashboardSlice/dashboardSlice";
import { useDispatch } from "react-redux";

const CreateUserPage = () => {
  // component states
  const title = "ImageMall | Manage Users";
  //Redux states
  const dispatch = useDispatch()

  const setTitle = (title) =>{
    dispatch(setTopBarTitle(title))
  }

  useEffect(()=>{
    setTitle("Manage Users")
  },[])
  
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageToolbar />
      <ManageUsers />
    </React.Fragment>
  );
}

export default CreateUserPage;