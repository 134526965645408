import "../App.css";
import React from "react";
import { Helmet } from "react-helmet";
import CodeVerification from "../components/common/CodeVerification/CodeVerification";


const VerifyPage = () => {
  const title = "ImageMall | Verify";

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CodeVerification emailVerification={true} />
    </>
  );
};

export default VerifyPage;
