import React from "react";
import { useTheme } from "@emotion/react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createClientJob } from "../../../services/ClientServie";
import { snackBarPosition } from "../../../services/CommonService";
import { Box } from "@mui/system";
import { Checkbox, FormControlLabel, Link as UiLink, Typography} from "@mui/material";
import LoadingButtons from "../../common/LoadingButton/LoadingButtonComponent";
import { useSelector } from "react-redux";
import { baseURL } from "../../../services/RequestService";

const StripeForm = (props) => {
  const {jobId, selectedServiceType, fileNames, selectValue, descriptions} = props
  const [btnLoading, setBtnLoading] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const authToken = useSelector((state)=>state.main.authToken)
  const {enqueueSnackbar} = useSnackbar();
  const stripe = useStripe();
  const elements = useElements();
  const theme = useTheme();
  const navigate = useNavigate();

  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: snackBarPosition,
    });
  };
  const showSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: snackBarPosition,
    });
  };
  const handleConfirmAndPay = async (event) => {
    setBtnLoading(true);
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {},
    });

    if (error) {
      showError(error.message);
      setBtnLoading(false);
      return;
    }
    createClientJob({ payment_method: paymentMethod, job_id: jobId, type_id: selectedServiceType, work_file_names: fileNames, image_descriptions: [selectValue, descriptions] }, authToken)
      .then((response) => {
        if (response.data.data.status === "succeeded" || response.data.data.status === "processing") {
          showSuccess(response.data.message);
          window.location.href = baseURL+"partners/jobs/manage";
        } else {
          showSuccess(response.data.message);
        }
        setBtnLoading(false);
      })
      .catch((error) => {
        setBtnLoading(false);
        showError(error.response.data.message);
      });
  };
  return (
    <React.Fragment>
      <CardElement options={{ style: { base: { fontSize: "16px", color: theme.palette.mode == "dark" && "white" } } }} />
      <Box sx={{ mt: 4 }}>
        <FormControlLabel
          control={<Checkbox checked={agreedToTerms} onChange={() => setAgreedToTerms(!agreedToTerms)} />}
          label={
            <>
              I agree to the{" "}
              <UiLink href="/terms-and-conditions" target="_blank" rel="noopener">
                terms and conditions
              </UiLink>
            </>
          }
          sx={{ fontSize: "8px" }}
        />
      </Box>
      <LoadingButtons loading={btnLoading} variant="contained" type="submit" name="Confirm & Pay" color="primary" fullWidth={true} disabled={!agreedToTerms} marginTop={4} onClick={handleConfirmAndPay} />
      <Typography variant="body2" component="p" color="text.secondary" align="right" sx={{ mt: 2 }}>
        <img src={theme.palette.mode == "dark" ? "/img/Powered by Stripe - white.svg" : "/img/Powered by Stripe - blurple.svg"} alt="Stripe logo" width="80px" style={{ position: "absloute" }} />
      </Typography>
    </React.Fragment>
  );
};

export default StripeForm;
