import React from "react";
import {
  Button,
  Chip,
  Divider,
  Grid,
  Rating,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";

import StarIcon from "@mui/icons-material/Star";
import { DeleteOutlineOutlined, EditOffOutlined } from "@mui/icons-material";
import { baseWebURL } from "../../../../services/RequestService";
import AvatarUpload from "../../../common/AvatarUpload/AvatarUpload";
import SelectBox from "../../../common/SelectBoxCompnent/SelectBoxComponent";
import { Controller } from "react-hook-form";
import TextFieldComponent from "../../../common/TextFieldComponent/TextFieldComponent";
import { useState } from "react";
import { useRef } from "react";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import { useDispatch, useSelector } from "react-redux";
import { setDataDialogPasswordResetModalOpen } from "../../../../features/usersSlice/usersSlice";
import { useEffect } from "react";
import { countryList } from "../../../../services/CommonService";
const EditUser = (props) => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const role = useSelector((state) => state.common.role);
  useEffect(() => {
    if (Object.keys(props.userData).length !== 0) {
      setUserData(props.userData);
      setLoading(false);
    }
  }, [props]);
  const dispatch = useDispatch();
  const statuses = [
    { name: "Active", value: 1 },
    { name: "Deactivate", value: 0 },
  ];
  const handleResetPasswordClick = () => {
    dispatch(setDataDialogPasswordResetModalOpen(true));
  };
  const previewImage = () => {
    if (
      userData.image === null &&
      props.preview === undefined &&
      userData.image === undefined
    ) {
      return "/img/avatar.png";
    } else {
      if (props.preview === undefined) {
        return baseWebURL + userData.image;
      } else {
        return props.preview;
      }
    }
  };
  return (
    <Grid container spacing={8}>
      {!loading ? (
        <Grid item sm={4} sx={{ textAlign: "center" }}>
          <AvatarUpload
            onChange={props.handleFileUpload}
            image={previewImage()}
            loading={false}
          />
          <Typography variant="subtitle1" align="center">
            {userData.name}
          </Typography>
          
        </Grid>
      ) : (
        <Grid item sm={4} sx={{ textAlign: "center" }}>
          <Skeleton
            variant="circular"
            width={150}
            height={150}
            sx={{ marginLeft: 3 }}
          />
          <Skeleton variant="text" sx={{ marginTop: 2 }} />
          <Skeleton variant="text" />
        </Grid>
      )}

      {!loading ? (
        <Grid item sm={8}>
          <Typography align="right">
            <Button onClick={props.handleDelete} sx={{ mb: 3 }}>
              <DeleteOutlineOutlined />
              &nbsp; Delete User
            </Button>
            <Button onClick={handleResetPasswordClick} sx={{ mb: 3 }}>
              <LockResetOutlinedIcon />
              &nbsp; Reset Password
            </Button>
          </Typography>
          <Controller
            name="name"
            control={props.control}
            defaultValue={props.userData.name}
            render={({
              field: {
                onChange,
                value = userData.name !== null ? userData.name : "",
              },
              fieldState: { error },
            }) => {
              return (
                <TextFieldComponent
                  type={"text"}
                  id="name"
                  label="Name*"
                  error={!!error}
                  helperText={error ? error.message : null}
                  onChange={onChange}
                  value={value}
                  marginTop={0}
                />
              );
            }}
            rules={{ required: "Name is required" }}
          />
          {role === "super_admin" && (
            <Controller
              name="role_id"
              control={props.control}
              defaultValue={userData.role_id}
              render={({
                field: {
                  onChange,
                  value = userData.role_id !== undefined
                    ? userData.role_id
                    : "",
                },
                fieldState: { error },
              }) => {
                return (
                  <SelectBox
                    id={"role_id"}
                    label="Select Role*"
                    items={props.roles}
                    error={!!error}
                    helperText={error ? error.message : null}
                    onChange={onChange}
                    defaultValue={value}
                    rules={{ required: "Role is required" }}
                  />
                );
              }}
            />
          )}
          {role !== "super_admin" && (
            <Typography sx={{ visibility: "hidden" }}>*</Typography>
          )}
          <Controller
            name="email"
            control={props.control}
            defaultValue={userData.email}
            render={({
              field: {
                onChange,
                value = userData.email !== null ? userData.email : "",
              },
              fieldState: { error },
            }) => {
              return (
                <TextFieldComponent
                  type={"text"}
                  id="email"
                  label="Email*"
                  error={!!error}
                  helperText={error ? error.message : null}
                  onChange={onChange}
                  value={value}
                  marginTop={0}
                />
              );
            }}
            rules={{
              required: "Email is required",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Invalid email address",
              },
            }}
          />
          <Controller
            name="country"
            control={props.control}
            defaultValue={userData.country !== null ? userData.country : ""}
            render={({
              field: {
                onChange,
                value = userData.country !== null ? userData.country : "",
              },
              fieldState: { error },
            }) => {
              return (
                <SelectBox
                  id={"country"}
                  label="Select Country"
                  items={countryList}
                  error={!!error}
                  helperText={error ? error.message : null}
                  onChange={onChange}
                  defaultValue={value}
                />
              );
            }}
          />
          <Controller
            name="mobile"
            control={props.control}
            defaultValue={userData.mobile !== null ? userData.mobile : ""}
            render={({
              field: {
                onChange,
                value = userData.mobile !== null ? userData.mobile : "",
              },
              fieldState: { error },
            }) => {
              return (
                <TextFieldComponent
                  type={"mobile"}
                  id="mobile"
                  label="Mobile"
                  error={!!error}
                  helperText={error ? error.message : null}
                  onChange={onChange}
                  value={value}
                  marginTop={0}
                />
              );
            }}
          />
          <Controller
            name="status"
            control={props.control}
            defaultValue={userData.status}
            render={({
              field: {
                onChange,
                value = userData.status !== undefined ? userData.status : "",
              },
              fieldState: { error },
            }) => {
              return (
                <SelectBox
                  id={"status"}
                  label="Select Status*"
                  items={statuses}
                  error={!!error}
                  helperText={error ? error.message : null}
                  rules={{ required: "Status is required" }}
                  onChange={onChange}
                  defaultValue={value}
                />
              );
            }}
          />
          <Controller
            name="description"
            control={props.control}
            defaultValue={
              userData.description !== null ? userData.description : ""
            }
            render={({
              field: {
                onChange,
                value = userData.description !== null
                  ? userData.description
                  : "",
              },
              fieldState: { error },
            }) => {
              return (
                <TextField
                  id="description"
                  label="Describe About User"
                  multiline
                  rows={5}
                  onChange={onChange}
                  value={value}
                  fullWidth
                />
              );
            }}
          />
        </Grid>
      ) : (
        <Grid item sm={8}>
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={40} sx={{ marginTop: 2 }} />
          <Skeleton variant="rectangular" height={40} sx={{ marginTop: 2 }} />
          <Skeleton variant="rectangular" height={40} sx={{ marginTop: 2 }} />
          <Skeleton variant="rectangular" height={40} sx={{ marginTop: 2 }} />
          <Skeleton variant="rectangular" height={40} sx={{ marginTop: 2 }} />
          <Skeleton variant="rectangular" height={140} sx={{ marginTop: 2 }} />
        </Grid>
      )}
    </Grid>
  );
};

export default EditUser;
