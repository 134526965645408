import React from 'react';

import Home from '../pages/client/Home'
import LoginPage from '../pages/LoginPage'
import SignUpPage from '../pages/SignUpPage'
import UploadImages from '../pages/client/UploadImages'
import Samples from '../pages/client/Samples'
import GuestLoginPage from '../pages/GuestLoginPage';
import SingleSample from '../pages/client/SingleSample';
import Portfolio from '../pages/client/Portfolio';
import ForgetPasswordPage from '../pages/ForgetPasswordPage';
import PasswordResePage from '../pages/PasswordResetPage';
import VerifyPage from '../pages/VerifyPage';
import GoogleAuthCallBack from '../pages/GoogleAuthCallBack';


const ClientRoutes = [
    {
        path: '/',
        exact: true,
        name: 'Home',
        element: <Home />,
        key:'route_home',
        is_nav:true,
    },
    {
        path: '/login',
        name: 'Login',
        element: <LoginPage />,
        key:'route_login',
        is_nav:false,
    },
    {
        path: '/sign-up',
        name: 'Sign Up',
        element: <SignUpPage />,
        key:'route_sign_up',
        is_nav:false,
    },
    {
        path: '/guest-login',
        name: 'Guest Login',
        element: <GuestLoginPage />,
        key:'route_guest_sign_up',
        is_nav:false,
    },
    {
        path: '/upload-images',
        name: 'Upload Images',
        element: <UploadImages />,
        key:'route_upload_images',
        is_nav:true,
    },
    {
        path: '/samples',
        name: 'Samples',
        element: <Samples />,
        key:'route_samples',
        is_nav:true,
    }
    ,
    {
        path: '/sample',
        name: 'Sample',
        element: <SingleSample />,
        key:'route_single_samples',
        is_nav:false,
    }
    ,
    {
        path: '/portfolio',
        name: 'Portfolio',
        element: <Portfolio />,
        key:'route_portfolio',
        is_nav:false,
    },
    {
        path: '/forget-password',
        name: 'Forget Password',
        element: <ForgetPasswordPage />,
        key:'route_forget_password',
        is_nav:false,
    },
    {
        path: '/password-reset',
        name: 'Password Reset',
        element: <PasswordResePage />,
        key:'route_password_reset',
        is_nav:false,
    },
    {
        path: '/verify',
        name: 'Verify',
        element: <VerifyPage />,
        key:'route_password_reset',
        is_nav:false,
    },
    {
        path: 'auth-google',
        name: 'Google Login',
        element: <GoogleAuthCallBack />,
        key:'route_google_auth',
        is_nav:false,
    }
]




export default ClientRoutes;