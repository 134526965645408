import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Progress from "../../components/common/Progress/Progress";
import AreaChartComponent from "../../components/admin/AreaChart/AreaChartComponent";
import { useDispatch, useSelector } from "react-redux";
import { setTopBarTitle } from "../../features/dashboardSlice/dashboardSlice";
import EarningsBar from "../../components/admin/EarningsBar/EarningsBar";
import { CircularProgress, Divider, Grid } from "@mui/material";
import { Container } from "@mui/system";
import CardComponentWithHeader from "../../components/common/CardComponentWithHeader/CardComponentWithHeader";
import { getEarningAreaChart } from "../../services/PaymentService";
import moment from "moment";
import { getAdminJobsDoneAreaChart, getAdminTransactionsAreaChart, getCCJobsChart, getClientPostedJobsChart, getCreatorJobsDoneAreaChart, getCreatorTransactionsAreaChart, getVCJobsChart, getVendorJobsDoneAreaChart } from "../../services/StatisticsService";
import PageToolbar from "../../components/admin/PageToolbar/PageToolbar";

const Dashboard = (props) => {
  // component states
  const title = "ImageMall | Dashboard";
  const [earningAreaChartData, setEarningAreaChartData] = useState([]);
  const [earningAreaChartLoading, setEarningAreaChartLoading] = useState(true);
  const [jobsDoneAreaChartData, setJobsDoneAreaChartData] = useState([]);
  const [jobsDoneAreaChartLoading, setJobsDoneAreaChartLoading] = useState(true);
  const [cVAreaChartLoading, setCVAreaChartLoading] = useState(true);
  const [cCAreaChartLoading, setCCAreaChartLoading] = useState(true);
  const [clientPostedJobsChartData, setClientPostedJobsChartData] = useState([]);
  const [cCJobsChartData, setCCJobsChartData] = useState([]);
  const [vCJobsChartData, setVCJobsChartData] = useState([]);
  const [clientPostedJobsChartLoading, setClientPostedJobsChartLoading] = useState({});
  const role = useSelector((state) => state.common.role);

  const today = moment().format("YYYY-MM-DD"); // Today's date
  const thirtyDaysAgo = moment().subtract(30, "days").format("YYYY-MM-DD"); // Date 30 days ago
  //Redux states
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setTopBarTitle("Dashboard"));
    if (role === "vendor") {
      getEarningAreaChart().then((response) => {
        setEarningAreaChartData(response.data.data);
        setEarningAreaChartLoading(false);
      });
      getVendorJobsDoneAreaChart().then((response) => {
        setJobsDoneAreaChartData(response.data.data);
        setJobsDoneAreaChartLoading(false);
      });
    } else if (role === "super_admin") {
      getAdminTransactionsAreaChart().then((response) => {
        setEarningAreaChartData(response.data.data);
        setEarningAreaChartLoading(false);
      });
      getAdminJobsDoneAreaChart().then((response) => {
        setJobsDoneAreaChartData(response.data.data);
        setJobsDoneAreaChartLoading(false);
      });
    } else if (role === "creator") {
      getCreatorTransactionsAreaChart().then((response) => {
        setEarningAreaChartData(response.data.data);
        setEarningAreaChartLoading(false);
      });
      getCreatorJobsDoneAreaChart().then((response) => {
        setJobsDoneAreaChartData(response.data.data);
        setJobsDoneAreaChartLoading(false);
      });
    } else if (role === "client") {
      getClientPostedJobsChart().then((response) => {
        setClientPostedJobsChartData(response.data.data);
        setClientPostedJobsChartLoading(false);
      });
    }else if (role === "creator_contractor") {
      getCCJobsChart().then((response) => {
        setCCJobsChartData(response.data.data);
        setCCAreaChartLoading(false);
      });
    }else if (role === "vendor_contractor") {
      getVCJobsChart().then((response) => {
        setVCJobsChartData(response.data.data);
        setCVAreaChartLoading(false);
      });
    }
  }, [role]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageToolbar alert="Welcome to ImageMall! We're thrilled to have you here and look forward to helping you get started." severity="success" color="success" close={true} />
      <Divider />
      <Container sx={{ mb: 4 }}>
        {role !== "client" && role !== "creator_contractor" && role !== 'vendor_contractor' && <EarningsBar />}

        <Grid container spacing={2}>
          {role !== "client" && role !== "creator_contractor" && role !== 'vendor_contractor' && (
            <Grid item sm={6} sx={{ textAlign: "center" }}>
              <CardComponentWithHeader
                cardTitle={
                  <React.Fragment>
                    {role === "vendor" && (
                      <React.Fragment>
                        Earnings by date <strong>{thirtyDaysAgo + " - " + today}</strong>
                      </React.Fragment>
                    )}
                    {role === "super_admin" && (
                      <React.Fragment>
                        Transactions by date <strong>{thirtyDaysAgo + " - " + today}</strong>
                      </React.Fragment>
                    )}
                    {role === "creator" && (
                      <React.Fragment>
                        Spendings by date <strong>{thirtyDaysAgo + " - " + today}</strong>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                }
              >
                {!earningAreaChartLoading ? <AreaChartComponent width={530} height={250} data={earningAreaChartData} dataKey="amount" reversed={true} /> : <CircularProgress />}
              </CardComponentWithHeader>
            </Grid>
          )}
          {role !== "client" && role !== "creator_contractor" && role !== 'vendor_contractor' && (
            <Grid item sm={6} sx={{ textAlign: "center", mt: role === "client" ? 3 : 0 }}>
              <CardComponentWithHeader
                cardTitle={
                  <React.Fragment>
                    {role === "vendor" && (
                      <React.Fragment>
                        Completed jobs <strong>{thirtyDaysAgo + " - " + today}</strong>
                      </React.Fragment>
                    )}
                    {role === "super_admin" && (
                      <React.Fragment>
                        All Completed Jobs <strong>{thirtyDaysAgo + " - " + today}</strong>
                      </React.Fragment>
                    )}
                    {role === "creator" && (
                      <React.Fragment>
                        All Completed Jobs <strong>{thirtyDaysAgo + " - " + today}</strong>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                }
              >
                {!jobsDoneAreaChartLoading ? <AreaChartComponent width={530} height={250} data={jobsDoneAreaChartData} dataKey="jobs" reversed={true} /> : <CircularProgress />}
              </CardComponentWithHeader>
            </Grid>
          )}
          {role === "client" && (
            <Grid item sm={6} sx={{ textAlign: "center", mt: role === "client" ? 3 : 0 }}>
              <CardComponentWithHeader
                cardTitle={
                  <React.Fragment>
                     <React.Fragment>Posted Jobs <strong>{thirtyDaysAgo + " - " + today}</strong></React.Fragment>
                  </React.Fragment>
                }
              >
                {!clientPostedJobsChartLoading ? <AreaChartComponent width={530} height={250} data={clientPostedJobsChartData} dataKey="jobs" reversed={true} /> : <CircularProgress />}
              </CardComponentWithHeader>
            </Grid>
          )}
          {role === "creator_contractor" && (
            <Grid item sm={6} sx={{ textAlign: "center", mt: 3}}>
              <CardComponentWithHeader
                cardTitle={
                  <React.Fragment>
                     <React.Fragment>Jobs Done <strong>{thirtyDaysAgo + " - " + today}</strong></React.Fragment>
                  </React.Fragment>
                }
              >
                {!cCAreaChartLoading ? <AreaChartComponent width={530} height={250} data={cCJobsChartData} dataKey="jobs" reversed={true} /> : <CircularProgress />}
              </CardComponentWithHeader>
            </Grid>
          )}
          {role === "vendor_contractor" && (
            <Grid item sm={6} sx={{ textAlign: "center", mt: 3 }}>
              <CardComponentWithHeader
                cardTitle={
                  <React.Fragment>
                     <React.Fragment>Jobs Done <strong>{thirtyDaysAgo + " - " + today}</strong></React.Fragment>
                  </React.Fragment>
                }
              >
                {!cVAreaChartLoading ? <AreaChartComponent width={530} height={250} data={vCJobsChartData} dataKey="jobs" reversed={true} /> : <CircularProgress />}
              </CardComponentWithHeader>
            </Grid>
          )}
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Dashboard;
