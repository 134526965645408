import { Avatar, Badge, Button, ClickAwayListener, Divider, Grid, IconButton, List, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, Paper, Popper, Tooltip, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { baseWebURL } from "../../../services/RequestService";
import BugReportOutlinedIcon from "@mui/icons-material/BugReportOutlined";
import moment from "moment";
import WorkIcon from "@mui/icons-material/Work";
import { useState } from "react";
import { useEffect } from "react";
import RawOnOutlinedIcon from "@mui/icons-material/RawOnOutlined";
import { Box } from "@mui/system";
import { getExceptionsTop, topRead } from "../../../services/ExceptionService";
// import { chnlException } from "../../../services/PusherService";
import { setExceptionRead, setTopLoader } from "../../../features/mainSlice/mainSlice";
import { useNavigate } from "react-router-dom";
import { chnlException } from "../../../services/PusherService";

const ExceptionNotifyMenuBox = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [exceptions, setExceptions] = useState([]);
  const [exceptionsCount, setExceptionsCount] = useState(0);
  const userAvatar = useSelector((state) => state.common.userAvatar);
  const exceptionRead = useSelector((state) => state.main.exceptionRead);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    // Pusher
    chnlException.bind(
      "exception-log",
      function (data) {
        console.log(data);
        if (data.exception !== undefined) {
          setExceptions((current) => [data.exception, ...current]);
          setExceptionsCount(data.count);
        }
      },
      chnlException.unbind()
    );

  });
  // exceptions
  useEffect(() => {
    getExceptions();
  }, [exceptionRead]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
    topRead()
    dispatch(setExceptionRead(!exceptionRead))
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const getExceptions = () => {
    // Get nav routes
    getExceptionsTop().then((response) => {
      if (response.data.success) {
        setExceptions(response.data.data.exceptions);
        setExceptionsCount(response.data.data.count);
        dispatch(setTopLoader(false));
      }
    });
  };
  const seeAllExceptions = () => {
    handleClose();
    navigate("/partners/exception-log");
  };
  return (
    <React.Fragment>
      <Tooltip title="Exception Logs">
        <IconButton sx={{ flexGrow: 0, marginRight: 1 }} onClick={handleClick}>
          <Badge badgeContent={exceptionsCount} color="error">
            <BugReportOutlinedIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popper anchorEl={anchorEl} open={open} placement="bottom-end" sx={{ minWidth: "400px", maxWidth: "400px" }} disablePortal>
        <ClickAwayListener onClickAway={handleClose}>
          <Paper sx={{ mt: "40px" }} elevation={12}>
            <Grid container spacing={2} sx={{ p: 1 }}>
              <Grid item sm={8}>
                <Typography variant="body2">
                  <strong>Exception</strong>
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <Typography align="right" sx={{ mt: -1 }}>
                  <IconButton onClick={handleClick}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ mt: 1 }} />
            <Box sx={{ maxHeight: "450px", overflowY: "auto" }}>
              {exceptions?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <ListItemButton selected={!item.read} onClick={seeAllExceptions}>
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            color: "error.main",
                            bgcolor: "primary.lighter",
                          }}
                        >
                          <BugReportOutlinedIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="body2">
                            {item.message.substring(0, 25)} {item.message.length > 25 && "..."}
                          </Typography>
                        }
                        secondary={
                          item.user_name
                        }
                      />
                      <ListItemSecondaryAction>
                        <Typography variant="caption" noWrap>
                          {moment(item.created_at).fromNow()}
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItemButton>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </Box>
            <ListItemButton sx={{ textAlign: "center", py: `${12}px !important` }} onClick={seeAllExceptions}>
              <ListItemText
                primary={
                  <Typography variant="body2" color="primary">
                    Show all exceptions
                  </Typography>
                }
              />
            </ListItemButton>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </React.Fragment>
  );
};

export default ExceptionNotifyMenuBox;
