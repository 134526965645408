
import React, {useState, useEffect} from "react";
import { Helmet } from "react-helmet";
import JobTypes from "../../components/admin/JobTypes/JobTypes";
import PageToolbar from "../../components/admin/PageToolbar/PageToolbar";
import { setTopBarTitle } from "../../features/dashboardSlice/dashboardSlice";
import { useDispatch } from "react-redux";
import FeedBacks from "../../components/admin/FeedBacks/FeedBacks";

const ManageFeedbacks = (props) => {

  // component states

  const title = "ImageMall | Feedbacks";
  //Redux states
  const dispatch = useDispatch()
  
  // use effect
  useEffect(() => {
    // hide top loader after component load
    dispatch(setTopBarTitle('Feedbacks'))
  }, [])
  
  return (
    <React.Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageToolbar />
      <FeedBacks />
    </React.Fragment>
  );
}

export default ManageFeedbacks;