import { requestGet, requestPost } from "./RequestService"

const prefix = '/jobs'
const clientPrefix = 'client/jobs'

const create = async (data) => {
    return await requestPost(prefix + '/create', data)
}
const getById = async (data) => {
    return await requestPost(prefix + '/get', data)
}
const getByJobId = async (data) => {
    return await requestPost(prefix + '/get-job', data)
}
const getAll = async (type) => {
    return await requestGet(prefix + '/get?type='+type)
}
const update = async (data) => {
    return await requestPost(prefix + '/update', data)
}
const deleteData = async (data) => {
    return await requestPost(prefix + '/delete', data)
}
const getLastCount = async (data) => {
    return await requestPost(prefix + '/get-last-c', data)
}
const fileDownload = async (data) => {
    return await requestGet(prefix + '/file/download?job_id=' + data.job_id + '&working=' + data.working + '&filename=' + data.filename)
}
const zipCompress = async (data) => {
    return await requestPost(prefix + '/zip/compress', data);
};
const getJobId = async () => {
    return await requestGet(prefix + '/get-job-id')
}
const getClientJobId = async () => {
    return await requestGet(clientPrefix + '/get-job-id')
}
const deleteCreatedJobFiles = async (data) => {
    return await requestPost(prefix + '/file-delete-created', data);
};
const jobAcception = async (data) => {
    return await requestPost(prefix + '/acception', data);
};
const deliver = async (data) => {
    return await requestPost(prefix + '/deliver', data);
};
const cancel = async (data) => {
    return await requestPost(prefix + '/cancel', data);
};
const jobCompletion = async (data) => {
    return await requestPost(prefix + '/completion', data);
};
const assign = async (data) => {
    return await requestPost(prefix + '/assign', data);
};
const vCancel = async (data) => {
    return await requestPost(prefix + '/v-cancel', data);
};

export { create, getById, getAll, update, deleteData, getJobId, deleteCreatedJobFiles, getLastCount, fileDownload, jobAcception, cancel, zipCompress, deliver, jobCompletion, assign, vCancel, getByJobId, getClientJobId }