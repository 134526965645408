import { Box, Card, CardHeader, Typography } from "@mui/material";
import React from "react";

const CardComponentWithHeader = (props) => {
  const { cardTitle } = props;
  return (
    <Card raised={false}>
      <CardHeader sx={{ backgroundColor: "primary.main", color: "common.white", p: 1 }} title={<Typography variant="body1"><i>{cardTitle}</i></Typography>} />
      <Box sx={{ p: 2 }}>{props.children}</Box>
    </Card>
  );
};

export default CardComponentWithHeader;
