import axios from "axios"
import Cookies from 'js-cookie'

// 'https://v2api.imagemall.io/public/api/'
// 'https://v2api.imagemall.io/public/'
// 'http://localhost:8000/api/' 
// 'http://localhost:8000/'
const baseApiURL =  process.env.REACT_APP_BASE_API_URL
const baseWebURL =  process.env.REACT_APP_BASE_WEB_URL
const baseURL =  process.env.REACT_APP_BASE_URL

const sendRequest = axios.create({
    baseURL: baseApiURL, 
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + Cookies.get("token") ?? ""
    }
})


const requestGet = async (url) => {
    return await sendRequest.get(url)
}
const requestPost = async (url, data, authToken=null) => {
    if(authToken !== null){
        return await sendRequest.post(url, data, {headers:{Authorization:'Bearer '+authToken}})
    }
    return await sendRequest.post(url, data)
}


export { requestGet, requestPost, baseWebURL,baseApiURL, baseURL }